import React from 'react';
import { withStyles } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

export const StyledCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#FFB500",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
