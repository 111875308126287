import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { FormControl, makeStyles } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectStatus(props) {
  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "97%",
    },
  }));
  const classes = useStyles();
  const valueSetter = (value, options) => {
    if (value.length == 0) {
      return value;
    } else if (value.length > 0 && value[0].id) {
      return value;
    } else if (options.length > 0 && value.length > 0) {
      let values = [];
      options.map((option) => {
        if (value.indexOf(option.id) > -1) {
          values.push(option);
        }
      });
      props.fnc(values);
      return values;
    } else {
      return [];
    }
  };
  const name = (
    <div className="controllabelCreate">
      {props.label}
      {/* {!props.isAstrikReq && <span style={{ color: "red" }}>*</span>} */}
    </div>
  );

  const MyTag = (tagValue, getTagProps) => {
    if (tagValue.length === 8) {
      return <p {...getTagProps(0)}>{"All"}</p>;
    }
    return tagValue.map((option, index) => {
      const label =
        index !== tagValue.length - 1 ? `${option.id},` : `${option.id}`;
      return <p {...getTagProps({ index })}>{label}</p>;
    });
  };

  const renderOptions = (option, { selected }) => {
    return (
      <React.Fragment>
        <Checkbox
          icon={
            <CheckBoxOutlineBlankIcon
              style={{ color: option.color }}
              fontSize="small"
            />
          }
          checkedIcon={
            <CheckBoxIcon style={{ color: option.color }} fontSize="small" />
          }
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.label}
      </React.Fragment>
    );
  };
  return (
    <Autocomplete
      multiple
      limitTags={3}
      id="checkboxes-tags-demo"
      options={props.options}
      value={
        props.value && props.value.filter((item) => item.id).length > 0
          ? props.value
          : valueSetter(props.value, props.options)
      }
      onChange={(e, selctedOptions) => props.fnc(e, selctedOptions)}
      renderTags={(tagValue, getTagProps) => MyTag(tagValue, getTagProps)}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(option, { selected }) =>
        renderOptions(option, { selected })
      }
      renderInput={(params) => (
        <FormControl className={classes.formControl}>
          <TextField
            {...params}
            style={{ overflow: "hidden" }}
            label={name}
            placeholder={props.value.length === 0 ? "Please Select" : ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}
    />
  );
}
