import { combineReducers } from 'redux';
import { STORE as cases } from './cases';
import { STORE as user } from './user';
import { STORE as relatedCase } from './relatedCase';
import { STORE as tprinfo } from './tprinfo';
import uploadTreatmentPlan from '../modules/uploadTreatmentPlan'
import surgicalCasesAttachments from '../modules/surgicalCasesAttachments'
import getAllTreatmentPlans from '../modules/listTreatmentPlanRequests'
import getTprFilter from '../modules/treatmentPlanFilter'
import saveTprFilter from '../modules/saveTreatmentPlanFilter'
import surgicalPlanErrorCodes from '../modules/surgicalPlanErrorCodes'
// import treatmentPlanUpload from '../modules/treatmentPlanUpload'
import surgicalCases from '../modules/surgicalCases'
import uploadTreatmentPlanMetadata from '../modules/uploadTreatmentPlanMetadata'
import patientScans from '../modules/patientScans'
import TPRHistory from '../modules/TPRHistory'
import getSurgeonPreferences from '../modules/getSurgeonPreferences'
import deleteSurgeonPreferences from '../modules/deleteSurgeonPreferences'
import getBranches from '../modules/branches'
import mainAndSubBranches from '../modules/MainAndSubBranches'
import getHospitals from '../modules/hospitals'
import getSurgeons from '../modules/surgeons'
import getMakoMapping from '../modules/makoMapping'
import createSurgeonPreference from '../modules/createSurgeonPreference'
import formChangeLog from '../modules/formChangeLog';

const rootReducer = combineReducers({
  cases,
  user,
  relatedCase,
  tprinfo,
  uploadTreatmentPlan,
  surgicalCasesAttachments,
  surgicalPlanErrorCodes,
  // treatmentPlanUpload,
  uploadTreatmentPlanMetadata,
  patientScans,
  getAllTreatmentPlans,
  TPRHistory,
  getTprFilter,
  surgicalCases,
  saveTprFilter,
  getSurgeonPreferences,
  deleteSurgeonPreferences,
  getBranches,
  mainAndSubBranches,
  getHospitals,
  getSurgeons,
  getMakoMapping,
  createSurgeonPreference,
  formChangeLog
  // tab,
  // filters,
  // status,
  // calendarTab,
  // caseUsageInfo,
  // addSetsReducer
});

export default rootReducer;
