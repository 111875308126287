import Button from "@material-ui/core/Button";
import React from 'react'
import Grid from "@material-ui/core/Grid";

const logout = () => {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_STRYKER_LOGOUT_URI;
}

const NoUserProfile = () => (
    <>
        <h3 style={{color:"red", textAlign:"center"}}>User Profile is not Available. Please Logout and Login With Other User</h3>
        <Button onClick={logout} style={{backgroundColor: 'gold', marginLeft: '50%'}}>Logout</Button>
    </>
)

export default NoUserProfile 