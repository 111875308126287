import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { REACT_APP_REDUX_DEBUGGING_ENABLED } from 'dotenv';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['tprinfo', 'uploadTreatmentPlanMetadata', 'uploadTreatmentPlan', 'surgicalCasesAttachments', 'surgicalPlanErrorCodes', 'relatedCase', 'patientScans', 'TPRHistory', 'listTreatmentPlanRequest', 'getTreatmentPlanFilter', 'getSurgeonPreferences', 'getSurgeons', 'getHospitals', 'getBranches', 'getAllTreatmentPlans', 'deleteSurgeonPreferences', 'formChangeLog']
}
//import {persistStore} from 'redux-presist';

function baselineMiddleware() {
  if (REACT_APP_REDUX_DEBUGGING_ENABLED === 'true') {
    return compose(applyMiddleware(thunkMiddleware, createLogger()));
  }

  return compose(applyMiddleware(thunkMiddleware));
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export default initialState => {
  const enhancers = composeWithDevTools(baselineMiddleware());
  const store = createStore(persistedReducer, initialState, enhancers);
  let persistor = persistStore(store)
  return { store, persistor };
};

// export const persistor =persistStore(store);

// export default(store, persistor)




