import React from "react";
import { useState } from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useEffect } from "react";
import { notificationBanner } from "../../graphql/mutations";
import { ASYNC_STORAGE } from "../../constants";
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#eaeaea',
        borderBottom: '1px solid grey'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dropzone: {
        padding: '26px',
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        outline: 'none',
    }
});

const Modal = (props) => {
    const [check, setCheck] = useState(false)
    const handleCheck = (event) => {
        setCheck(event.target.checked)
    }

    const handleSavePopup = async () => {
        let name = props.banner.map((item) => item.name).toString()


        if (check === true) {
            await notificationBanner(
                sfids[0],
                name,
                check
            )
        }
        props.setPop(false);
    }

    const current_user = JSON.parse(
        localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
    );
    const { sfids } = current_user;


    return <div>
        <Dialog
            open={props.pop}
        >
            <DialogContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ padding: "20px" }}>
                        {
                            props.banner.map((item, index) => {
                                return <Grid item xs={12}>
                                    <span>{item.notification_message__c}</span>
                                </Grid>
                            })
                        }
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={5} style={{ marginTop: "30px" }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox color="default" />}
                                    label="Do not show again"
                                    onClick={handleCheck}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "30px" }}>
                            <Button type="submit" variant="contained" style={{ backgroundColor: "#ffb500", color: "black", fontWeight: "bold" }} onClick={handleSavePopup}>Dismiss</Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    </div >;
};

export default Modal;