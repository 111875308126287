import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Dropdown from "../../components/controls/Dropdown";
import SearchBar from "material-ui-search-bar";
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import LinearProgressWithLabel from "../treatment-plan-form/LinearProgressBarWithLable";
import PatientScansTable from "./PatientScansTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getPatientScans } from "../../redux/modules/patientScans";
import Snackbars from "../../components/controls/Snackbar";
import moment from "moment";
import Buttons from "../../components/controls/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import JSZip from "jszip";
import { API } from "../../constants";
import { StyledRadioButton } from "../../components/controls/StyledRadioButton";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getFacilityNames } from "../../graphql/queries";
import CustomDropdown from "../../components/controls/CustomDropdown";
import { getUploadedBy } from "../../graphql/queries";
import { updateUploadedBy } from "../../graphql/mutations";
import { ASYNC_STORAGE } from "../../constants";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
  rootCard: {
    padding: "5px 35pc",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#eaeaea",
    borderBottom: "1px solid grey",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dropzone: {
    padding: "26px",
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
  },
  createButton: {
    background: "#FFB500",
    boxShadow: "none",
    color: "#000",
    fontWeight: "bold",
    margin: "10px",

    "&:hover": {
      background: "#FFD271",
      boxShadow: "none",
      color: "#000",
    },
    "&:focus": {
      background: "#FFB500!important",
      boxShadow: "none",
      color: "#000",
    },
  }
});

function PatientScans(props) {
  const { classes } = props;
  const [rows, setRows] = useState({});
  const [sortOrderVal, setSortOrderVal] = useState("");
  const [sortByVal, setSortByVal] = useState("");
  const [searched, setSearched] = useState("");
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Available");
  const [cases, setCases] = useState("MAKO");
  const [filteredScans, setFilteredScans] = useState(scans);
  const [uploadedBy, setUploadedBy] = useState([]);
  const [uploadedByOptions, setUploadedByOptions] = useState([
    { id: "All", label: "All" },
  ]);

  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [file_to_upload, setFile_to_upload] = useState(null);
  const [file_name_to_upload, setFile_name_to_upload] = useState("");
  const [isDisableAccept, setIsDisableAccept] = useState(true);
  const [isDisableCancle, setIsDisableCancle] = useState(false);
  const [isZip, setIsZip] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [folderZipState, setFolderZipState] = useState("");
  const [is_Loaded, setIs_Loaded] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [preSuccessMessage, setPreSuccessMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [value, setValue] = React.useState(cases);
  const [facilities, setFacilities] = React.useState([]);
  const [date, setdate] = useState(moment().subtract(10, 'days').format('YYYY-MM-DD')||"")
  const [notification, setNotification] = React.useState({
    severity: "",
    message: "",
  });
  const [statusOptions, setStatusOptions] = useState([
    {
      id: "Available",
      label: "Available",
    },
    {
      id: "Assigned",
      label: "Assigned",
    },
  ]);
  const [dateOptions, setDateOptions] = useState([
    {
      id: "Last 10 Days",
      label: "Last 10 Days",
    },
    {
      id: "Last 30 Days",
      label: "Last 30 Days",
    },
    {
      id: "All Time",
      label: "All Time",
    },
  ]);
  const [caseOptions, setCaseOptions] = useState([
    {
      id: "MAKO",
      label: "MAKO",
    },
    {
      id: "AS1",
      label: "AS-1",
    },
  ]);

  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );

  const fetchScans = () => {
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    if (cases === "MAKO") {
      const finalObj = {
        caseType: "MAKO",
        makoToken: mako_token,
        patientScanStatus: status,
        pageIndex: 0,
        date: date,
      };
      props.getPatientScans(finalObj);
    } else {
      const finalObj = {
        caseType: "AS1",
        as1Token: as1_token,
        patientScanStatus: status,
        pageIndex: 0,
        date: date,
      };
      props.getPatientScans(finalObj);
    }
  };

  function getFilter(data2, arr) {
    setLoading(true)
    getUploadedBy().then((res) => {
      let data = res.UploadedBy.find(
        (item) => item.user_sfid === current_user.sfids[0]
      );
      let filtered1 = []
      if (data !== null && data2 && data?.name !== "") {
        let data1 = data?.name?.split(',');
        if (data1) {
          let option = []
          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < data1.length; j++) {
              if (data1[j] === arr[i].label) {
                option.push(arr[i]);
              }
            }
          }
          setUploadedBy([...option]);

          data1.forEach((row) => {
            let obj = data2.filter((item) => (item.metadata.uploadedByUser === row || item.metadata.facilityName === row || item.metadata.uploadedByApplication === row))
            filtered1.push(...obj)
          });
          const finalObject = Object.assign(
            {},
            {
              content: filtered1,
              pageIndex: 0,
              totalPages: filtered1.length,
            }
          );
          setFilteredScans(finalObject);
        }
      } else {
        const finalObject = Object.assign(
          {},
          {
            content: data2,
            pageIndex: 0,
            totalPages: data2.length,
          }
        );
        setFilteredScans(finalObject);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    localStorage.setItem("selectedCaseFromPatientPage", "");
    setLoading(true);
    getFacilityNames()
      .then((res) => {
        setFacilities(res.get_facility_names);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "Facilities");
        setFacilities([]);
        setLoading(false);
      });
    fetchScans();
  }, []);

  useEffect(() => {
    let arr = [];
    if (props.scans.content) {
      let uploadByOptions = [];
      const scanContent =
        props.scans &&
        props.scans.content &&
        props.scans.content.map((scan) => {
          if (scan.metadata.uploadedByUser !== null) {
            uploadByOptions = [
              ...uploadByOptions,
              scan.metadata.uploadedByUser,
            ];
          } else {
            const facility = facilities.find(
              (i) =>
                i.customerId === scan.metadata.uploadedByScanFacility
            );
            console.log(facilities);
            const _facilityName = facility
              ? facility.customerName
              : scan.metadata.uploadedByScanFacility;
            uploadByOptions = [...uploadByOptions, _facilityName];
            scan.metadata.facilityName = _facilityName;
          }
          return scan;
        });
      const unqUploadByOptions = uploadByOptions.filter(
        (value, index) => uploadByOptions.indexOf(value) === index
      );
      unqUploadByOptions.length > 0 &&
        unqUploadByOptions.map((val) => {
          if (val) {
            arr.push({
              value: val,
              label: val,
            });
          }
        });
      setUploadedByOptions(arr);
      setSortByVal("");
      setSortOrderVal("");

      const scans = {
        ...props.scans,
        content: [...scanContent],
      };
      setRows(scans);
      setFilteredScans(scans);
    }
    if (props.scans.length !== 0) {
      getFilter(props.scans.content, arr)
    }
  }, [props.scans, facilities]);

  useEffect(() => {
    if (props.error) {
      displaySnackBarMessage("error", props.error);
    }
  }, [props.error]);

  const displaySnackBarMessage = (severity, errorMessage) => {
    setNotification({
      severity: severity,
      message: errorMessage,
    });
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setUploadedByOptions([{ id: "All", label: "All" }]);
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    if (cases === "MAKO") {
      const finalObj = {
        caseType: "MAKO",
        makoToken: mako_token,
        patientScanStatus: event.target.value,
        pageIndex: 0,
        date: date,
      };
      props.getPatientScans(finalObj);
    } else if (cases === "AS1") {
      const finalObj1 = {
        caseType: "AS1",
        as1Token: as1_token,
        patientScanStatus: event.target.value,
        pageIndex: 0,
        date: date,
      };
      props.getPatientScans(finalObj1);
    }
  };

  const handleDateChange = (event) => {
    const selectedValue = event.target.value;
    const dateMap = {
      'Last 10 Days': moment().subtract(10, 'days').format('YYYY-MM-DD'),
      'Last 30 Days': moment().subtract(30, 'days').format('YYYY-MM-DD'),
      // 'All Time': moment().format('YYYY-MM-DD'),
      'All Time': "",
    };
  
    setUploadedByOptions([{ id: 'All', label: 'All' }]);
    setdate(dateMap[selectedValue] );

    const tokenObj = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}');
    const commonProps = {
      patientScanStatus: status,
      pageIndex: 0,
      date: dateMap[selectedValue] 
    };
  
    if (cases === 'MAKO') {
      props.getPatientScans({
        caseType: 'MAKO',
        makoToken: tokenObj.mako_token,
        ...commonProps,
      });
    } else if (cases === 'AS1') {
      props.getPatientScans({
        caseType: 'AS1',
        as1Token: tokenObj.as1_token,
        ...commonProps,
      });
    }
  };
  const handleCaseChange = (event) => {
    localStorage.setItem("selectedCaseFromPatientPage", event.target.value);
    setCases(event.target.value);
    setValue(event.target.value);
    setUploadedByOptions([{ id: "All", label: "All" }]);
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    if (event.target.value === "MAKO") {
      const finalObj = {
        caseType: "MAKO",
        makoToken: mako_token,
        patientScanStatus: status,
        pageIndex: 0,
        date: date,
      };
      props.getPatientScans(finalObj);
    } else {
      const finalObj1 = {
        caseType: "AS1",
        as1Token: as1_token,
        patientScanStatus: status,
        pageIndex: 0,
        date:date,
      };

      props.getPatientScans(finalObj1);
    }
  };

  const handleUploadedByChange = async (event) => {
    let string = event.map((str) => str.label).toString();
    setSortByVal("");
    setSortOrderVal("");
    setUploadedBy(event);
    setSearched("");
    if (string) {
      let filtered;
      if (string.length > 0) {
        filtered = rows.content.filter((row) => {
          if (
            string.includes(row.metadata.uploadedByUser) ||
            string.includes(row.metadata.facilityName) ||
            string.includes(row.metadata.uploadedByScanFacility)
          )
            return row;
        });
      }
      const finalObject = Object.assign(
        {},
        {
          content: filtered,
          pageIndex: rows.pageIndex,
          totalPages: rows.totalPages,
        }
      );
      setFilteredScans(finalObject);
      let usersfid = current_user.sfids[0].toString();
      await updateUploadedBy(usersfid, string);
    }
    if (string.length === 0 || string === "All") {
      setFilteredScans(rows);
      let usersfid = current_user.sfids[0].toString();
      await updateUploadedBy(usersfid, string);
    }
  };

  const requestSearch = (searchedVal) => {
    setUploadedBy("All");
    setSearched(searchedVal);
    if (!searchedVal) {
      setFilteredScans(rows);
      return;
    }

    const filteredRows = rows.content.filter((row) => {
      return (
        row.patient.patientName
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        moment(row.patient.patientDateOfBirth)
          .format("MM/DD/YYYY")
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        (row.metadata &&
          row.metadata.referringPhysicianName &&
          row.metadata.referringPhysicianName
            .toLowerCase()
            .includes(searchedVal.toLowerCase()))
      );
    });
    const finalObject = Object.assign(
      {},
      {
        content: filteredRows,
        pageIndex: rows.pageIndex,
        totalPages: rows.totalPages,
      }
    );
    setFilteredScans(finalObject);
  };

  const handleRightArrow = (pageIndex) => {
    setSearched("");
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    setUploadedByOptions([{ id: "All", label: "All" }]);
    if (cases === "MAKO") {
      const finalObj = {
        caseType: "MAKO",
        makoToken: mako_token,
        patientScanStatus: status,
        pageIndex: pageIndex,
        date: date,
      };
      props.getPatientScans(finalObj);
    } else {
      const finalObj = {
        caseType: "AS1",
        as1Token: as1_token,
        patientScanStatus: status,
        pageIndex: pageIndex,
        date: date,
      };
      props.getPatientScans(finalObj);
    }
  };
  const handleLeftArrow = (pageIndex) => {
    setSearched("");
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    setUploadedByOptions([{ id: "All", label: "All" }]);
    if (cases === "MAKO") {
      const finalObj = {
        caseType: "MAKO",
        makoToken: mako_token,
        patientScanStatus: status,
        pageIndex: pageIndex,
        date: date,
      };
      props.getPatientScans(finalObj);
    } else {
      const finalObj = {
        caseType: "AS1",
        as1Token: as1_token,
        patientScanStatus: status,
        pageIndex: pageIndex,
        date: date,
      };
      props.getPatientScans(finalObj);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const sortData = (sortBy, sortOrder, rows) => {

    var itemsToSort = rows.content;
    var sortedItems = [];
    var compareFn = null;

    switch (sortBy) {
      case "physician":
        compareFn = (i, j) => {
          if (
            i.metadata.referringPhysicianName <
            j.metadata.referringPhysicianName
          ) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (
              i.metadata.referringPhysicianName >
              j.metadata.referringPhysicianName
            ) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      //sorting for name filed
      case "name":
        compareFn = (i, j) => {
          const nameA = i.patient.patientName || "";
          const nameB = j.patient.patientName || "";

          return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        };

        break;
      //sorting for num of slices
      case "slices":
        compareFn = (i, j) => {
          if (i.metadata.totalNumberOfImages < j.metadata.totalNumberOfImages) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (
              i.metadata.totalNumberOfImages > j.metadata.totalNumberOfImages
            ) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };

        break;
      case "scanType":
        compareFn = (i, j) => {
          const valueA = i.metadata.studyDescription;
          const valueB = j.metadata.studyDescription;
          return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        };
        break;
        case "scanCheck":
          compareFn = (i, j) => {
            const resultSummaryi = i?.additionalData?.jobs?.mic?.resultSummary;
            const treatmentSummaryi = i?.additionalData?.jobs?.mic?.treatmentsSummary;
            const resultSummaryj = j?.additionalData?.jobs?.mic?.resultSummary;
            const treatmentSummaryj = j?.additionalData?.jobs?.mic?.treatmentsSummary;
        
            const verifyMotion = "Please ensure motion check passes on your planning laptop prior to creating the TPR";
        
            const hasMotionDetectedi =
              treatmentSummaryi &&
              treatmentSummaryi.every((treatment) =>
                treatment.failureExplanations?.includes(verifyMotion)
              );
        
            const hasMotionDetectedj =
              treatmentSummaryj &&
              treatmentSummaryj.every((treatment) =>
                treatment.failureExplanations?.includes(verifyMotion)
              );
        
            const valueA =
              resultSummaryi === "fail" && hasMotionDetectedi
                ? "warning"
                : resultSummaryi || "inprocess";
        
            const valueB =
              resultSummaryj === "fail" && hasMotionDetectedj
                ? "warning"
                : resultSummaryj || "inprocess";
        
            return sortOrder === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          };
          break;
      //sorting for dob
      case "DOB":
        compareFn = (i, j) => {
          if (i.patient.patientDateOfBirth < j.patient.patientDateOfBirth) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.patient.patientDateOfBirth > j.patient.patientDateOfBirth) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };

        break;
      //sorting for date
      case "date":
        compareFn = (i, j) => {
          const d1 = i.metadata.dateTimeLastModified || "";
          const d2 = j.metadata.dateTimeLastModified || "";
          if (d1 < d2) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (d1 > d2) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };

        break;
      case "uploadby":
        compareFn = (a, b) => {
          const facilityA = (a.metadata.uploadedByScanFacility || "").toLowerCase();
          const facilityB = (b.metadata.uploadedByScanFacility || "").toLowerCase();
          const facilityNameA = (a.metadata.facilityName || "").toLowerCase();
          const facilityNameB = (b.metadata.facilityName || "").toLowerCase();
          const userA = (a.metadata.uploadedByUser || "").toLowerCase();
          const userB = (b.metadata.uploadedByUser || "").toLowerCase();
          const dataA = (facilityA.length > 0 && userA.length > 0) ?
            userA : (facilityA.length > 0 && userA.length === 0) ? facilityNameA : facilityA;
          const dataB = (facilityB.length > 0 && userB.length > 0) ?
            userB : (facilityB.length > 0 && userB.length === 0) ? facilityNameB : facilityB;
          const valueA = `${dataA}`;
          const valueB = `${dataB}`;
          if (sortOrder === "asc") {
            if (valueA !== valueB) {
              return valueA.localeCompare(valueB);
            }
          } else {
            if (valueA !== valueB) {
              return valueB.localeCompare(valueA);
            }
          }
        };
        break;
      default:
    }

    sortedItems = itemsToSort.sort(compareFn);
    const finalObject = Object.assign(
      { pageIndex: 0, totalPages: 1 },
      { content: sortedItems }
    );
    return finalObject;
  };

  const requestSort = (pSortBy) => {
    var sortOrder = sortOrderVal;
    return (event) => {
      if (
        pSortBy === "physician" ||
        pSortBy === "name" ||
        pSortBy === "slices" ||
        pSortBy === "DOB" ||
        pSortBy === "date" ||
        pSortBy === "uploadby" ||
        pSortBy === "scanType" ||
        pSortBy === "scanCheck"
      ) {
        sortOrder = sortOrder === "asc" || sortOrder === "" ? "desc" : "asc";
      }
      var sortedItems = sortData(pSortBy, sortOrder, filteredScans);
      setSortOrderVal(sortOrder);
      setSortByVal(pSortBy);
      setFilteredScans(sortedItems);
    };
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles, e) => {
    let dt = e.dataTransfer;
    let files = dt && dt.files;

    let isLoaded = false;
    let isTPR = false;
    let fileList = [];
    let dirCounter = 0;
    let folderZip = "";
    let fileToUpload = null;
    let fileNameToUpload = "";

    if (files && files.length < 1) {
      setIsDisableAccept(true);
      setIsDisableCancle(false);
      isLoaded = false;
      setTimeout(() => {
        handleClearField();
      }, 3000);
    } else {
      const name = acceptedFiles[0].name;
      fileNameToUpload = name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      isLoaded = true;
      if (
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length > 1
      ) {
        isLoaded = false;
        setTimeout(() => {
          handleClearField();
        }, 3000);
      } else {
        if (ext === "zip") {
          setIsZip(true);
          setProgressMessage("Loading Zip File Please wait...");

          if (!isTPR) {
            handleFiles(e.dataTransfer && e.dataTransfer.items, false);
          }
          fileToUpload = files && files.length ? files[0] : "";
          if (isLoaded) {
            if (isTPR) {
              isLoaded = false;
              setIsDisableAccept(false);
              setIsDisableCancle(false);
              setProgressMessage("Loading Zip File Please wait...");
            }
          }
        } else if (e.dataTransfer.items[0].webkitGetAsEntry().isDirectory) {
          setProgressMessage("Loading files from directory...");
          let fileName_ToUpload = e.dataTransfer.files[0].name + ".zip";
          fileNameToUpload = fileName_ToUpload;
          let fileRecords = e.dataTransfer.items;
          let entry = fileRecords[0].webkitGetAsEntry();
          let dirReader = entry.createReader();
          if (isTPR) {
            processDirectory(dirReader);
          } else {
            fileList = [];
            scanFiles(entry);
            setTimeout(function () {
              processLatestFile();
            }, 3000);
          }
        } else {
          isLoaded = false;
          setIsDisableAccept(true);
          setIsDisableCancle(false);
          setTimeout(() => {
            handleClearField();
          }, 3000);
        }
      }
    }

    function scanFiles(item) {
      if (item.isFile) {
        let fileToAdd = item;
        Object.defineProperty(fileToAdd, "name", {
          writable: true,
          value: item.fullPath,
        });
        fileList.push(fileToAdd);
      } else {
        let progressMessageVar = "Processing files in " + item.name;
        setProgressMessage(progressMessageVar);
      }
      if (item.isDirectory) {
        let directoryReader = item.createReader();
        let fnReadEntries = (function () {
          return function (entries) {
            entries.forEach(function (entry) {
              scanFiles(entry);
            });
            if (entries.length > 0) {
              directoryReader.readEntries(fnReadEntries);
            }
          };
        })();
        directoryReader.readEntries(fnReadEntries);
      }
    }

    function processLatestFile() {
      if (fileList.length < 1) {
        isLoaded = false;
        setProgressMessage("");
        setIsDisableAccept(true);
        setIsDisableCancle(false);
        handleClearField();
      } else {
        setProgressMessage("Processing Files..");
        let newZip = new JSZip();
        let latestFile = "";
        let tempf = "";
        let f = "";
        fileList.forEach((rec) => {
          f = new File([rec], rec.name);
          if (latestFile !== "") {
            tempf = new File([latestFile], latestFile.name);
          }
          if (
            latestFile === "" ||
            f.lastModifiedDate > tempf.lastModifiedDate
          ) {
            latestFile = rec;
          }
        });
        let filesRecList = [];
        setProgressMessage("Generating Zip..");
        for (let index = 0; index < fileList.length; index++) {
          generateZip(
            index,
            newZip,
            fileList[index],
            filesRecList,
            fileList.length,
            latestFile
          );
        }
      }
    }

    function processDirectory(rootDir) {
      if (!isTPR) {
        setProgressMessage("Looking for Files..");
      }
      fileList = [];
      let folder_Zip = new JSZip();
      rootDir.readEntries(function (results) {
        if (results) {
          getAllFilesFromDirectory(results);
        }
      });
      folderZip = folder_Zip;
    }

    function getAllFilesFromDirectory(files) {
      dirCounter = dirCounter + 1;

      files.forEach((rec) => {
        if (rec.isDirectory) {
          if (!isTPR) {
            let progMessage = "Processing files in " + rec.name;
            setProgressMessage(progMessage);
          }
          dirCounter = dirCounter + 1;

          let dirReader = rec.createReader();
          dirReader.readEntries(function (results) {
            getAllFilesFromDirectory(results);
            dirCounter = dirCounter - 1;

            if (dirCounter === 0) {
              if (fileList) {
                if (fileList.length > 0) {
                  generateZipForPatientScan();
                }
              } else {
                processLatestFile();
              }
            }
          });
        } else if (rec.isFile) {
          if (isTPR) {
            fileList.push(rec);
          }
        }
      });

      dirCounter = dirCounter - 1;

      if (dirCounter === 0) {
        if (fileList) {
          if (fileList.length > 0) {
            generateZipForPatientScan();
          }
        } else {
          processLatestFile();
        }
      }
    }

    function generateZipForPatientScan() {
      let zip = new JSZip();
      fileList.forEach((fileRecord) => {
        fileRecord.file(function (fileRec) {
          zip.file(fileRec.name, fileRec);

          zip.generateAsync({ type: "blob" }).then(function (content) {
            fileToUpload = content;
            setFile_to_upload(fileToUpload);
            setIsDisableAccept(false);
            setIsDisableCancle(false);
            isLoaded = false;
            setProgressMessage("");
          });
        });
      });
    }

    function generateZip(
      currFileCount,
      zip,
      fileRecord,
      filesRecList,
      totalRecords,
      latestFile
    ) {
      if (fileRecord.name.startsWith("/")) {
        fileRecord.name = fileRecord.name.slice(1);
      }

      fileRecord.file(function (fileRec) {
        let reader = new FileReader();
        reader.onload = function (rdr) {
          let readResult = rdr.target.result;

          zip.file(fileRecord.name, readResult);

          if (currFileCount + 1 === totalRecords) {
            zip
              .generateAsync({ type: "blob" })
              .then(
                function (content) {
                  fileToUpload = content;
                  setFile_to_upload(fileToUpload);

                  processEntry(latestFile, "", false, content);

                  let prgMessage = "Successfully zipped " + latestFile.name;

                  setProgressMessage(prgMessage);

                  setIsDisableAccept(false);
                  setIsDisableCancle(false);
                  isLoaded = false;
                },
                function (err) {
                  console.log("####Error:" + err.message);
                }
              )
              .catch((Error) => {
                console.log(JSON.stringify(Error));
              });
          }
        };
        reader.readAsArrayBuffer(fileRec);
      });
    }

    function handleFiles(filesList, isDirectory) {
      if (filesList && filesList.length > 0) {
        let files = filesList;
        let myFiles = [];
        webkitCloneFiles1(files, myFiles, isDirectory);
      }
    }

    function webkitCloneFiles(files, list, isDirectory) {
      let entry = files[0];
      if (files.length > 1) {
        entry = getLatestFileFromDirectory(files);
      }
      processEntry(entry, list, isDirectory);
    }

    function getLatestFileFromDirectory(files) {
      let fileRec;
      files.forEach((rec) => {
        if (rec.isDirectory) {
          //Process Directory and return latest file;
          let dirReader = rec.createReader();
          dirReader.readEntries(function (results) {
            let tempFile = getLatestFileFromDirectory(results);
            if (fileRec && tempFile.lastModified > fileRec.lastModified) {
              fileRec = tempFile;
            }
          });
        } else if (rec.isFile) {
          if (fileRec) {
            if (rec.lastModified > fileRec.lastModified) {
              fileRec = rec;
            }
          } else {
            fileRec = rec;
          }
        }
      });
      return fileRec;
    }

    function webkitCloneFiles1(files, list, isDirectory) {
      for (let i = 0, len = files.length; i < len; i++) {
        let entry = files[i].webkitGetAsEntry();
        processEntry(entry, list, isDirectory);
      }
    }

    function processEntry(entry, list, isDirectory, content) {
      if (!entry) {
        /* prevent accidental drags */
        return;
      }
      if (entry.isFile) {
        entry.file(function (thisFile) {
          let fileExtension = thisFile.name.split(".").pop();

          if (!fileExtension) {
          } else if (thisFile.name.indexOf(".") === -1) {
          } else if (fileExtension.toLowerCase() === "zip") {
            let new_zip = new JSZip();

            new_zip
              .loadAsync(thisFile)
              .then(function (zip) {
                let maxDate = "";
                let fileName = "";
                setProgressMessage("Processing Zip File...");

                zip.forEach(function (relativePath, zipEntry) {
                  if (!zipEntry.dir) {
                    let prog_Message = "Checking file " + zipEntry.name;
                    setProgressMessage(prog_Message);

                    let fname = zipEntry.name.slice(
                      zipEntry.name.lastIndexOf("/") + 1
                    );

                    if (fname.indexOf(".") === -1) {
                      if (maxDate === "" || zipEntry.date > maxDate) {
                        maxDate = zipEntry.date;
                        fileName = zipEntry.name;
                      }
                    }
                  }
                });
                let progress_Message = "Succesfully read" + fileName;
                setProgressMessage(progress_Message);
                setIsDisableAccept(false);
              })
              .catch((Error) => {
                console.log(JSON.stringify(Error));
              });
          }
        });
      } else if (entry.isDirectory) {
        let dirReader = entry.createReader();
        dirReader.readEntries(
          function (results) {
            if (results.length > 0) {
              webkitCloneFiles(results, list, isDirectory);
            }
          },
          function (error) {
            console.log(error);
          }
        );
      }
    }

    setFolderZipState(folderZip);
    setFile_name_to_upload(fileNameToUpload);
    setFile_to_upload(fileToUpload);
    setIs_Loaded(isLoaded);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });

  const myCustomFileGetter = (event) => {
    const files = [];
    const file_list = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    for (var i = 0; i < file_list.length; i++) {
      const file = file_list.item(i);

      Object.defineProperty(file, "myProp", {
        value: true,
      });

      files.push(file);
    }
    return files;
  };

  const handleClearField = () => {
    setIsZip(false);
    setIsDisableAccept(true);
    setIsDisableCancle(false);
    setIs_Loaded(false);
    setProgressMessage("");
    setErrorMessage("");
    setFolderZipState("");
    setFile_name_to_upload("");
    setFile_to_upload(null);
  };

  const handleOpenUpload = () => {
    handleClearField();
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setValue(cases);
    handleClearField();
    setOpenUpload(false);
  };

  const handleAccept = () => {
    acceptFileCall();
  };

  const acceptFileCall = async () => {
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );

    handleCloseUpload();
    let file = file_to_upload;
    let formdata = new FormData();

    if (file_to_upload) {
      formdata.append("file", file, file_name_to_upload);
    } else {
      formdata.append("file", file);
    }

    setIsDisableAccept(false);

    const config = {
      method: "POST",
      url: API.MIB.PATIENT_SCAN_URI,
      data: formdata,
      headers: {
        Authorization:
          value === "MAKO" ? "Bearer " + mako_token : "Bearer " + as1_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Custom-Header": "value",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentCompleted = Math.floor((loaded * 100) / total);
        if (percentCompleted < 100) {
          catchUploadProgress(percentCompleted);
          setProgressBarValue(percentCompleted);
        }
      },
    };

    await axios(config)
      .then((response) => {
        if (response.data) {
          const percentCompleted = 100;
          let premessage = "Finalizing Uploaded file.......";
          let preMessageFlag = "info";
          setProgressBarValue(100);
          catchUploadProgress(percentCompleted, premessage, preMessageFlag);
          getPatientScansTableDetails(response.data[0].patientScanId);
        } else {
          let percentCompleted = 0;
          let errorFlag = "error";
          let erMessage = "Something went wrong please try again later";
          catchUploadProgress(percentCompleted, erMessage, errorFlag);
          setErrorMessage(erMessage);
          setTimeout(() => {
            setErrorMessage("");
          }, 1000);
        }
      })
      .catch((error) => {
        let percentCompleted = 0;
        let errorFlag = "error";
        let erMessage = "Something went wrong please try again later";
        catchUploadProgress(percentCompleted, erMessage, errorFlag);
        setErrorMessage(erMessage);
        setTimeout(() => {
          setErrorMessage("");
        }, 1000);
      });
  };

  const getPatientScansTableDetails = async (patientScanId) => {
    const { mako_token, as1_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    const percentCompleted = 0;
    const config = {
      method: "GET",
      url: API.MIB.PATIENT_SCAN_URI + patientScanId + "/metadata",
      data: {},
      headers: {
        Authorization:
          value === "MAKO" ? "Bearer " + mako_token : "Bearer " + as1_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Custom-Header": "value",
      },
    };
    let response = await axios(config);
    let intervalFunc = setInterval(async () => {
      // check if complete info fethced or not
      if (!response.data.patient) {
        const premessage = "Please wait, it's taking a bit longer than usual.";
        catchUploadProgress(100, premessage, "info");
        response = await axios(config);
      } else {
        if (response.data) {
          let successFlag = "success";
          let successMessage = "File uploaded successfully";
          catchUploadProgress(100, successMessage, successFlag);
          setTimeout(() => {
            setProgressBarValue(0);
            setSuccessMessage("");
            fetchScans();
          }, 1000);
          clearInterval(intervalFunc);
        } else {
          let errorFlag = "error";
          let erMessage = "Something went wrong please try again later";
          catchUploadProgress(percentCompleted, erMessage, errorFlag);
          setErrorMessage(erMessage);
          setTimeout(() => {
            setErrorMessage("");
          }, 1000);
          clearInterval(intervalFunc);
        }
      }
    }, 1000);
  };

  const catchUploadProgress = (uploadProgressValue, message, statusFlag) => {
    setProgressBarValue(uploadProgressValue);

    if (statusFlag) {
      setProgressMessage(true);

      if (statusFlag === "info") {
        setPreSuccessMessage(message);
        setSuccessMessage("");
      }

      if (statusFlag === "success") {
        setSuccessMessage(message);
        setPreSuccessMessage("");
      }

      if (statusFlag === "error") {
        setErrorMessage(message);
        setPreSuccessMessage("");
        setSuccessMessage("");
      }
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <Snackbars
        severity={notification.severity}
        open={open}
        handleClose={handleClose}
        message={notification.message}
      />
      <Grid container style={{ background: "#fafafa", display: 'grid', gridTemplateColumns: '1fr 2.5fr auto' }}>
        <Grid item xs={12} className="pagetitleHead ">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              padding: "20px 0px 18px 15px",
            }}
          >
            Patient Scans
          </div>
        </Grid>
        <Grid item xs={12} align="right" >
          {/* <Link to="/NewSurgicalCase" style={{ textDecoration: "none" }}>
            <Button
              size="medium"
              variant="contained"
              className={classes.createButton}
            >
              CREATE CASE
            </Button>
          </Link> */}
        </Grid>
        <Grid item xs={12} className="pagetitleHead">
          <div style={{ marginRight: "10px" }}>
            <Buttons
              Icon="Submit"
              toSave={true}
              fnc={handleOpenUpload}
              name="Upload"
            />
          </div>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} spacing={0} sm={4}> */}
      {progressBarValue > 0 ? (
        <CardContent className={classes.rootCard}>
          <div>
            {preSuccessMessage ? (
              <Alert
                severity="info"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "2px",
                }}
              >
                {preSuccessMessage}
              </Alert>
            ) : null}

            {successMessage ? (
              <Alert
                severity="success"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "2px",
                }}
              >
                {successMessage}
              </Alert>
            ) : null}

            {errorMessage ? (
              <Alert
                severity="error"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "2px",
                }}
              >
                {errorMessage}
              </Alert>
            ) : null}
            {alertOpen ? (
              <div
                style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr" }}
              >
                <div></div>
                <Alert
                  onClose={(e) => handleClose(e)}
                  severity={alertType}
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "0px",
                    paddingBottom: "2px",
                  }}
                >
                  {alertMessage}
                </Alert>
              </div>
            ) : null}
          </div>

          {progressBarValue > 0 ? (
            <Fade in={progressBarValue > 0}>
              <div className={classes.progressRoot}>
                <LinearProgressWithLabel value={progressBarValue} />
              </div>
            </Fade>
          ) : null}
        </CardContent>
      ) : null}
      {/* </Grid> */}
      <div
        style={{
          display: "grid",
          gridTemplateRows: "-0.5fr 1fr",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            gap: "20px",
          }}
        >
          <Dropdown
            inpage={true}
            label={""}
            ctrllabel={""}
            fnc={(e) => handleCaseChange(e)}
            options={caseOptions}
          />
          <Dropdown
            inpage={true}
            label={"Uploaded within"}
            ctrllabel={""}
            fnc={(e) => handleDateChange(e)}
            options={dateOptions}
          />
          <Dropdown
            inpage={true}
            label={"Status"}
            ctrllabel={""}
            fnc={(e) => handleStatusChange(e)}
            options={statusOptions}
          />
          <CustomDropdown
            inpage={true}
            label={"Uploaded By"}
            ctrllabel={""}
            value={uploadedBy}
            onChange={(e) => handleUploadedByChange(e)}
            options={uploadedByOptions}
          // allowSelectAll={true}
          />
          <div style={{ display: "grid", gridTemplateRows: "0.5fr auto" }}>
            <div
              style={{ fontWeight: "600", fontSize: "13px", marginTop: "9px" }}
            >
              {"Search"}
            </div>
            <SearchBar
              style={{ border: "1px solid lightgrey", borderRadius: "0px" }}
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </div>
        </div>
        <div style={{ marginLeft: "5px" }}>
          {props.loading || (loading && Object.keys(rows).length) ? (
            <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
          ) : Object.keys(filteredScans).length ? (
            <PatientScansTable
              cases={cases}
              sortBy={sortByVal}
              sortOrder={sortOrderVal}
              requestSort={requestSort}
              sortData={sortData}
              scans={filteredScans}
              handleLeftArrow={handleLeftArrow}
              handleRightArrow={handleRightArrow}
            />
          ) : null}
        </div>
      </div>
      <Dialog
        style={{ root: { minWidth: "600px" } }}
        open={openUpload}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.root} id="responsive-dialog-title">
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>
            {"Upload Patient Scan"}
          </div>
        </DialogTitle>
        <DialogContent>
          <section>
            <div
              {...getRootProps({
                className: classes.dropzone,
                onClick: (event) => event.stopPropagation(),
              })}
            >
              <input {...getInputProps()} />
              <div style={{ display: "grid", gridTemplateRows: "1fr 1fr" }}>
                <FontAwesomeIcon
                  style={{ marginInline: "auto", fontSize: "3em" }}
                  icon={faUpload}
                />
                <p>
                  Upload file/folder by dragging and dropping onto the dashed
                  region
                </p>
              </div>
            </div>
          </section>
        </DialogContent>
        <DialogContent>
          <RadioGroup
            style={{ display: "contents" }}
            aria-label="procedureType"
            name="procedureType1"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="MAKO"
              control={<StyledRadioButton />}
              label="MAKO"
            />
            <FormControlLabel
              value="AS1"
              control={<StyledRadioButton />}
              label="AS-1"
            />
          </RadioGroup>
        </DialogContent>
        <DialogContent>
          {progressMessage ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Alert
                icon={false}
                severity="info"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "0px",
                  paddingBottom: "2px",
                }}
              >
                {progressMessage}
              </Alert>
            </Grid>
          ) : null}
          {progressMessage ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Alert severity="warning">
                Note :Please do not close browser or browser tab while{" "}
                <strong>Loading... zip file</strong>
              </Alert>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Buttons
            Icon="Submit"
            toSave={true}
            fnc={handleAccept}
            disabled={isDisableAccept}
            name="ACCEPT"
          />
          <Buttons
            Icon="Submit"
            disabled={isDisableCancle}
            fnc={handleCloseUpload}
            name="CANCEL"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    scans:
      state && state.patientScans && state.patientScans.patientScansMetadata,
    error:
      state &&
      state.patientScans &&
      state.patientScans.patientScansMetadataError,
    loading: state && state.patientScans && state.patientScans.loading,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { getPatientScans })(PatientScans)
);
