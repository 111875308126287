import {
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../components/controls/AutoComplete";
import Buttons from "../../../components/controls/Button";
import MultilineTextFields from "../../../components/controls/MultilineText";
import { ASYNC_STORAGE } from "../../../constants";
import { getProductSystems } from "../../../graphql/queries";
import { resetSurgicalPreference, setSurgicalPrefValue } from "../../../redux/modules/createSurgeonPreference";
import SurgicalPrefTable from "./SurgicalPrefTable";

const SurgicalPreference = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      flexGrow: 1,
    },
    formControl: {
      margin: "25px 15px",
      fontWeight: "bold",
      fontSize: "14px",
    },
    label: {
      margin: "5px 5px",
      fontWeight: "bold",
      fontSize: "13px",
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const setSurgicalPreference = (name, value) =>
    dispatch(setSurgicalPrefValue(name, value));
  const { combination, surgicalPreferenceObj } = useSelector(
    (state) => state.createSurgeonPreference
  );
  const [options, setOptions] = useState([]);
  const [selectedLoanerSet, setSelectedLoanerSet] = useState('');
  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );

  useEffect(() => {
    // dispatch(resetSurgicalPreference());
    setSelectedLoanerSet('')
  },[combination.procedure.id])

  const onChangeComments = (event) => {
    if(!props.isFormDirty){
      props.valueChanged("JR Pref Comments")
    }
    setSurgicalPreference("comments", event.target.value)
  };
  const handleProductChange = (event) => {
    if (event.target.value.length >= 2) {
      const products = getProductSystems(
        event.target.value,
        combination.procedure.id,
        combination.branch,
        // props.caseDetails.storageExist,
        false,
        current_user.sfids[0]
      )
        .then((res) => {
          let prevSetId = "";
          return res.get_parts_in_sets.filter((item, index) => {
            let _prevSetId = prevSetId;
            prevSetId = item.set_id;
            item.catalog_category = item.catalog_category;
            item.set_id = item.set_id;
            return _prevSetId !== item.set_id; // to get unique set
          });
        })
        .then((res) => {
          setOptions(res);
        });
    }
  };

  const handleAddRow = () => {
    if(!selectedLoanerSet){
      props.displaySnackBarMessage("error","Please select a set.");
      return;
    }
    const isDuplicateSet =
    surgicalPreferenceObj.loanerSets &&
    surgicalPreferenceObj.loanerSets.length > 0 &&
    surgicalPreferenceObj.loanerSets.findIndex((item) => item.set_id === selectedLoanerSet.set_id) > -1;
    if (isDuplicateSet) {
      props.displaySnackBarMessage(
        "error",
        "Selected set already exists. Please add different set."
      );
      return;
    }

    if(!props.isFormDirty){
      props.valueChanged("JR Pref Sets")
    }

    const item = {
      set_id: selectedLoanerSet.set_id,
      set_name: selectedLoanerSet.set_name,
      loaner__c: false,
      isPrefItemsValid: true,
      catalog_category: selectedLoanerSet.catalog_category
    };
    const rows = [...surgicalPreferenceObj.loanerSets, item];
    setSurgicalPreference("loanerSets", rows)
    setSelectedLoanerSet('');
    setOptions([]);
  };

  const handleDeleteRow = (id) => {
    if(!props.isFormDirty){
      props.valueChanged("JR Pref Sets")
    }
    const remainingRows = surgicalPreferenceObj.loanerSets.filter((item) => item.set_id !== id);
    setSurgicalPreference("loanerSets", remainingRows)
  };

  return (
    <Grid container style={{ padding: "20px", background: "#fafafa" }}>
      <Grid container alignItems="center">
        <Grid item xs={4} sm={4} justify="flex-start">
          <Typography className={classes.label}>Loaner Set</Typography>
          <Autocomplete
            id="laoner"
            getOptionLabel={(option) => {
              return typeof option === "string" ? option : option.set_name;
            }}
            filterOptions={(x) => {
              return x;
            }}
            options={options}
            value={selectedLoanerSet ? selectedLoanerSet.set_name : ''}
            autoComplete
            includeInputInList
            freeSolo
            disableOpenOnFocus
            onChange={(e, newInputValue) => {
              const selectedOp = newInputValue
                ? options.findIndex(
                    (op) => op.set_name === newInputValue.set_name
                  )
                : -1;
              setSelectedLoanerSet(selectedOp > -1 ? newInputValue : "");
            }}
            renderInput={(params) => (
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  {...params}
                  label={"Search"}
                  variant="outlined"
                  style={{ margin: "-25px 10px 0px -10px" }}
                  name="loanerSets"
                  value={selectedLoanerSet ? selectedLoanerSet.set_name : ''}
                  onChange={(event) => handleProductChange(event)}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={2} sm={2} justify="center" style={{paddingLeft: '10px'}}>
          <Buttons Icon="Submit" toSave={true} name="ADD" fnc={handleAddRow} />
        </Grid>
        <Grid item xs={6} sm={6} justify="flex-end">
          <Typography>
            <MultilineTextFields
              fnc={onChangeComments}
              label="Comments"
              value={surgicalPreferenceObj.comments}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <SurgicalPrefTable rows={surgicalPreferenceObj.loanerSets} handleDeleteRow={handleDeleteRow} />
      </Grid>
    </Grid>
  );
};

export default SurgicalPreference;
