// Actions
export const ACTION_TYPES = {
  SET_TAB: 'SET_TAB',
  RESTORE_TAB: 'RESTORE_TAB'
};

// Action Creators
export const ACTION_CREATORS = {
  
  setTab: (activeTab) => {
    return {
      type: ACTION_TYPES.SET_TAB,
      activeTab
    };
  },
};

// Store
const initialState = {
  activeTab: 0
};

export const STORE = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TAB:
      return { ...state, activeTab: action.activeTab };
    default:
      return state;
  }
};
