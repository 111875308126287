export const caseType = [
    {
        id: 'MAKO',
        label: 'MAKO'
    },
    {
        id: 'AS1',
        label: 'AS-1'
    }]

export const createdInOption = [
    {
        id: 'Last 10 Days',
        label: 'Last 10 Days'
    },
    {
        id: 'Last 1 Month',
        label: 'Last 1 Month'
    },
    {
        id: 'Last 3 Months',
        label: 'Last 3 Months'
    }]
    
export const makoStatusOptions = [
    {
        id: 'Created',
        label: 'Created'
    },
    {
        id: 'Submitted',
        label: 'Submitted'
    },
    {
        id: 'InDesign',
        label: 'In Design'
    },
    {
        id: 'Rejected',
        label: 'Rejected'
    },
    {
        id: 'TreatmentPlanAvailable',
        label: 'Treatment Plan Available'
    },
    {
        id: 'TreatmentPlanDelivered',
        label: 'Treatment Plan Downloaded'
    },
    {
        id: 'Cancelled',
        label: 'Cancelled'
    }
]

export const makoStatusIHSOptions = [
    {
        id: 'Submitted',
        label: 'Submitted'
    },
    {
        id: 'Created',
        label: 'Created'
    },
    {
        id: 'InDesign',
        label: 'In Design'
    },
    {
        id: 'Rejected',
        label: 'Rejected'
    },
    {
        id: 'TreatmentPlanAvailable',
        label: 'Treatment Plan Available'
    },
    {
        id: 'TreatmentPlanDelivered',
        label: 'Treatment Plan Downloaded'
    },
    {
        id: 'Cancelled',
        label: 'Cancelled'
    }
]

export const as1StatusOptions = [
    {
        id: 'Created',
        label: 'Created-Awaiting CT Scan'
    },
    {
        id: 'Submitted',
        label: 'Submitted'
    },
    {
        id: 'InDesign',
        label: 'In Design'
    },
    {
        id: 'Rejected',
        label: 'Rejected-Rework Required'
    },
    {
        id:'Approved',
        label: 'Surgical Plan Approved'
    },
    {
        id: 'TreatmentPlanAvailable',
        label: 'Surgical Plan Available'
    },
    {
        id: 'ConditionallyApproved',
        label: 'Surgical Plan Conditionally Approved'
    },
    {
        id: 'ChangeRequested',
        label: 'Surgical Plan Changes Requested'
    },
    {
        id: 'FinalPlanAvailable',
        label: 'Final Plan Available & Manf'
    },
    {
        id: 'ShippedToSales',
        label: 'Shipped To Sales'
    },
    {
        id: 'Completed',
        label: 'Completed'
    },
    {
        id: 'SignedOff',
        label: 'Signed Off'
    },
    {
        id: 'Cancelled',
        label: 'Cancelled'
    }
]

export const procedureOption = [
    {
        id: 'All',
        label: 'All'
    },
    {
        id: 'MCKPK',
        label: 'MCK Partial Knee'
    },
    {
        id: 'TH',
        label: 'Total Hip'
    },
    {
        id: 'TTK',
        label: 'Triathlon Total Knee'
    }]