import React, { Component } from "react";
import { getBranchSalesrep } from "../../graphql/queries";
import MultiSelectDropdown from "../controls/MultiSelectDropdown";
import { connect } from "react-redux";
import MultiSelectCheckBoxDropdown from "../controls/MultiSelectCheckBoxDropdown";

class CoveringReps extends Component {
  state = {
    CoveringRep: [],
  };
  async componentDidMount() {
    // await this.get_AllSalesrep();
  }
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user.branchIds !== prevProps.user.branchIds) {
      // await this.get_AllSalesrep();
    }
  }
  get_AllSalesrep = async () => {
    const { branchIds } = this.props.user;
    let CoveringRep = await getBranchSalesrep(branchIds);
    CoveringRep = CoveringRep.get_user_by_branch.map((item) => {
      item.title = item.userName;
      item.id = item.userID;
      return item;
    });
    this.setState({
      loading: false,
      CoveringRep: CoveringRep,
    });
  };
  render() {
    const { CoveringRep } = this.state;
    // return <MultiSelectDropdown label={"Covering Reps"}
    //     options={this.props.options}
    //     fnc={this.props.onChangeCoveringRep}
    //     value={this.props.coveringRepVal}
    // />
    return (
      <MultiSelectCheckBoxDropdown
        label={"Covering Reps"}
        disabled={this.props.disabled}
        options={this.props.options}
        fnc={(e, options) => this.props.onChangeCoveringRep(e, options)}
        value={this.props.coveringRepVal}
        selectedTeams={this.props.selectedTeams}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps, null)(CoveringReps);
