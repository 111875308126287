
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    width: "100%",
  },
  dot: {
    height: "25px",
    width: "25px",
    padding: "10px 14px",
    borderRadius: "89%",
    backgroundColor: "#FFD271",
    fontSize: "16px",
    fontWeight: "700"
  },
  container: {
    maxHeight: 550,
    border: "1px solid lightgrey",
  },
});

export default function SurgicalPrefTable(props) {
  const [rows2, setrows2] = React.useState([]);

  useEffect(() => {
   let t1 = [];
          let t2 = props.rows;
          let t3 = [];
      
          if (t2.length > 0 && t2[0].catalog_category == undefined) {
      
                        for (let i = 0; i < t2.length; i++) {
                          let check = false;
                         
                          for (let j = 0; j < t3.length; j++) {
                             if (t2[i].category == t3[j]) {
                              check = true;
                              break;
                             } else if (t2[i].catalog_category  == t3[j]) {
                              check = true;
                              break;
                             }
                          }
                          // t2.includes(t2[i].category);
                
                          if (check == false) {
                            t3.push(t2[i].category != undefined ? t2[i].category : t2[i].catalog_category );
                   
                            t1.push({
                              "catalog_category":  t2[i].category != undefined ? t2[i].category : t2[i].catalog_category ,
                              "requested_set_name" : [
                              {
                                "catalog_category":  t2[i].category,
                                "isPrefItemsValid":  t2[i].isPrefItemsValid,
                                "loaner__c": t2[i].loaner__c,
                                "set_id": t2[i].set_id,
                                "set_name": t2[i].set_name
                              }
                              ],
                            })
                        
                          }
                          else {
                            let index = -1;
                 
                            for (let k = 0; k < t1.length; k++) {
                          
                              if ((t1[k].catalog_category == t2[i].catalog_category) && t2[i].catalog_category != undefined) {
                             
                                index = k;
                               break;
                              } else  if ((t1[k].catalog_category == t2[i].category) && t2[i].category != undefined) {
                           
                                index = k;
                                 break;
                                }

                              
                           }
                   
                      if (index !== -1) {
                           t1[index].requested_set_name.push({
                            "category__category":  t2[i].category      ,
                            "isPrefItemsValid":  t2[i].isPrefItemsValid,
                            "loaner__c": t2[i].loaner__c,
                            "set_id": t2[i].set_id,
                            "set_name": t2[i].set_name
                          })
                        }
                          }
                          }
                      }
            else {
           
               for (let i = 0; i < t2.length; i++) {
                let check = false;
               
                for (let j = 0; j < t3.length; j++) {
                   if (t2[i].catalog_category == t3[j]) {
                    check = true;
                    break;
                   }  else if (t2[i].category  == t3[j]) {
                    check = true;
                    break;
                   }
                }
                // t2.includes(t2[i].category);
            
                if (check == false) {
                  t3.push(t2[i].category != undefined ? t2[i].category : t2[i].catalog_category )
                  t1.push({
                    "catalog_category":   (t2[i].category != undefined ? t2[i].category : t2[i].catalog_category ),
                    "requested_set_name" : [
                    {
                      "catalog_category":  t2[i].catalog_category,
                      "isPrefItemsValid":  t2[i].isPrefItemsValid,
                      "loaner__c": t2[i].loaner__c,
                      "set_id": t2[i].set_id,
                      "set_name": t2[i].set_name
                    }
                    ],
                  })
                }
                else {
                  let index = -1;
            
                  for (let k = 0; k < t1.length; k++) {
                    if ((t1[k].catalog_category == t2[i].catalog_category) && t2[i].catalog_category != undefined) {
                      index = k;
                       break;
                      } else  if ((t1[k].catalog_category == t2[i].category) && t2[i].category != undefined) {
                        index = k;
                         break;
                        }
                 }
            
            if (index !== -1) {
                 t1[index].requested_set_name.push({
                  "category__category":  t2[i].catalog_category      ,
                  "isPrefItemsValid":  t2[i].isPrefItemsValid,
                  "loaner__c": t2[i].loaner__c,
                  "set_id": t2[i].set_id,
                  "set_name": t2[i].set_name
                })
              }
                }
                }
              }
    setrows2(t1);

}, [props])

  let [selectedId, setData] = useState('-1');
  const handleCheckBoxClicks = (index, item) => {

    setData(index == selectedId ? -1 :  index);

  //  checks(item);
  }
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">PRODUCT CATEGORY</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">SURGEON IS LOANER</TableCell>
                <TableCell align="left">Active</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "auto", height: "150px" }}>
              {rows2 &&
                rows2.length > 0 &&
                rows2.map((row, idx) => {
               return (
                <>
                  <TableRow key={rows2.set_id} hover>
                    <TableCell align="left">
                    {idx == selectedId ? 
                         <td style={{fontSize: '25px'}}   onClick={() => handleCheckBoxClicks(idx, row)}> -</td> : <td style={{fontSize: '25px'}}   onClick={() => handleCheckBoxClicks(idx, row)} > +</td>
                        
                        }
                    </TableCell>
                    <TableCell align="left" style={{width:"30%"}}>
                     { row.catalog_category}
                 
                    </TableCell>
                    <TableCell align="left">
                   
                     <span className={classes.dot}>  {row.requested_set_name.length} </span>
                    </TableCell>
                    <TableCell align="left">

                      {
                      /* {row.isPrefItemsValid || row.isPrefItemsValid.toString().toLowerCase() === 'true' ? "True" : "False"} */}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ cursor: "pointer" }}>
                       
                      </div>
                    </TableCell>
                    <TableCell align="left">
                    </TableCell>
                  </TableRow>
                  {idx == selectedId ? 
  <>
{row.requested_set_name.map((item2, idx2) => {
  return  ( 
    
<tr>
<td style={{width:"6%"}}>
      {/* <FormControlLabel
        control={
          <Checkbox
            key={idx2}
            disabled={isDisabled || item.status__c == SETS_PARTS_STATUS.ASSIGNED || 
              item2.status__c == SETS_PARTS_STATUS.SHIPPED || 
              item2.status__c == SETS_PARTS_STATUS.SHIPPED_READY }
            color="default"
            checked={isChecked[idx2]}
            onChange={() => handleCheckBoxClick(idx2)}
            name={rows.name}
            value="left"
            inputProps={{ 'aria-label': 'checkbox with default color' }} />
        }
      /> */}
    </td>
    <td > { item2.set_name} </td> 
    <td></td>
    <td >  <Checkbox
                        key={item2.set_id}
                        disabled
                        color="default"
                        // checked={row.loaner__c}
                        // onChange={e => handleCheckBoxClick(idx)}
                        name={"loaner"}
                      /> </td>
                      <td> {item2.isPrefItemsValid || item2.isPrefItemsValid.toString().toLowerCase() === 'true' ? "True" : "False"} </td>
    <td> 
    <DeleteRoundedIcon
                          onClick={() => props.handleDeleteRow(item2.set_id)}
                        />
                         </td>

</tr>
)})}
</> : ''}
                  </>
                )})}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
