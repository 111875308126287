import React, { Component } from "react";
import axios from "axios";
import { API, USER_TYPE, ASYNC_STORAGE } from "../constants";

//MP: Added new parameter (pricing) To manage non-lot case
const getCaseById = async (externalId, sfId, pricing) => {
  const { access_token } = JSON.parse(localStorage.getItem("PU:TOKEN") || "{}");
  try {
    let url = `${API.MASTER_DATA.GET_CASE_By_ID}`;
    if (externalId) {
      url = url + `?externalId=${externalId}&price=${pricing}`;
    } else {
      url = url + `?sfId=${sfId}&price=${pricing}`;
    }
    let response = await axios.get(url, {
      headers: { Authorization: access_token },
    });
    return response;
  } catch (error) {
    console.log(Object.keys(error), error.message);
  }
};

export const isUserHasMPSrole = () => {
  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );
  const { personas } = current_user;
  return (
    personas &&
    personas.length > 0 &&
    (personas.indexOf(USER_TYPE.MPS) > -1 ||
      personas.indexOf(USER_TYPE.MAKO_MPS) > -1 ||
      personas.indexOf(USER_TYPE.InHouse) > -1 ||
      personas.indexOf(USER_TYPE.IHS) > -1)
  );
};

export const INITIAL_FILTER = {
  status: "New,Requested,Assigned,Shipped/Ready for Surgery",
  mako: isUserHasMPSrole(),
  as1: false,
  isWebOpsError: false,
  filterBranch: "",
  division: "",
  filterSalesRep: "",
  filterHospital: "",
  filterSalesTeam: "",
  filterSurgeon: "",
  searchText: "",
  startDate: "",
  endDate: "",
  surgery_date_confirmed: true,
};

export default getCaseById;
