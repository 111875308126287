
import { getAllBranches2, getMainBranches, getSubBranches2 } from '../../graphql/queries'

// import { createOrUpdateTPRFilter } from '../../graphql/mutations'
//ActionTypes

const LOAD = 'branches/LOAD'
const LOAD_SUCCESS = 'branches/LOAD_SUCCESS'
const LOAD_FAILURE = 'branches/LOAD_FAILURE'
const LOAD_MAIN_BRANCH_SUCCESS = 'branches/LOAD_MAIN_BRANCH_SUCCESS'
const LOAD_SUB_BRANCH_SUCCESS = 'branches/LOAD_SUB_BRANCH_SUCCESS'
const LOAD_MAIN_BRANCH_FAILURE = 'branches/LOAD_MAIN_BRANCH_FAILURE'
const LOAD_SUB_BRANCH_FAILURE = 'branches/LOAD_SUB_BRANCH_FAILURE'
const RESET_BRANCHES = 'branches/RESET_BRANCHES'

const initialState = {
    loaded: false,
    loading: false,
    branchList: [],
    mainBranchList: [],
    subBranchList: [],
    branchListError: {}
}

export default function branches(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                branchList:action.payload,
                branchListError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                branchList: {},
                branchListError: action.payload
            }
        case LOAD_MAIN_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                mainBranchList:action.payload,
                branchListError:{}
            }
        case LOAD_MAIN_BRANCH_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                mainBranchList: [],
                branchListError: action.payload
            }
        case LOAD_SUB_BRANCH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                subBranchList:action.payload,
                branchListError:{}
            }
        case LOAD_SUB_BRANCH_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                subBranchList: {},
                branchListError: action.payload
            }
        case RESET_BRANCHES:
            return {
                ...state,
                branchList: {},
                mainBranchList: [],
                subBranchList: [],
                branchListError: {}
            }
        default:
            return state
    }
}

export function getBranches() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getAllBranches2()
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.get_branches
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}

export function getMainBranchList() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getMainBranches()
            .then(response => {
                dispatch({
                    type: LOAD_MAIN_BRANCH_SUCCESS,
                    payload: response.get_main_branches
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_MAIN_BRANCH_FAILURE,
                    payload: error
                })
            })
    }
}

export function getSubBranchList() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getSubBranches2()
            .then(response => {
                dispatch({
                    type: LOAD_SUB_BRANCH_SUCCESS,
                    payload: response.get_sub_branches
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_SUB_BRANCH_FAILURE,
                    payload: error
                })
            })
    }
}

export function resetBranches() {
    return dispatch => {
        dispatch({
            type: RESET_BRANCHES
        })
    }
}