import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../components/controls/Dropdown";
import MultilineTextFields from "../../../components/controls/MultilineText";
import { getFieldPicklistValues } from "../../../graphql/queries";
import { resetTprPreference, setTprPreferenceValue } from "../../../redux/modules/createSurgeonPreference";
import {getProcedurePicklistValues} from "../../../graphql/queries";

const TprPreference = (props) => {
  const dispatch = useDispatch();
  const setTprPreference = (name, value) => dispatch(setTprPreferenceValue(name, value));
  const {combination, tprPreferenceObj} = useSelector(state => state.createSurgeonPreference);
  const [FemoralComponentOptions, setFemoralComponentOptions] = useState({});
  const [InsertTypeOptions, setInsertTypeOptions] = useState({});
  const [BaseplateOptions, setBaseplateOptions] = React.useState({});
  const [ApproachOptions, setApproachOptions] = useState({});
  const [AcetabularComponentOptions, setAcetabularComponentOptions] = useState({});
  const [FemoralComponentHipsOptions, setFemoralComponentHipsOptions] = useState({});
  const [procedureApplicationVersionOptions, setProcedureApplicationVersionOptions] = React.useState([]);
  const [procedureApplicationVersionValue, setProcedureApplicationVersionValue] = React.useState("");  

   const [ApproachValue, setApproachValue] = React.useState("");
  //const [FemoralComponentHipsValue, setFemoralComponentHipsValue] = React.useState("");
  //const [BaseplateValue, setBaseplateValue] = React.useState("");
  //const [FemoralComponentValue, setFemoralComponentValue] = React.useState("");

  const compare = (a, b) => {
    const c1 = a.label;
    const c2 = b.label;
    var comp = 0;
    if (c1 > c2) comp = 1;
    else if (c1 < c2) comp = -1;
    return comp;
  };

  useEffect(() => {
    if(combination.procedure.label){
    //if(combination.procedure.label){
      getProcedurePicklistValues(combination.procedure.label).then((res) => {
        const a = res.get_procedure_picklist_values;
        return a.map((item) => {
          item.title = item.label;
          item.id = item.id;
          return item;
        });
      })
      .then((res) => {
        setProcedureApplicationVersionOptions(res)
      })
  }

}, [combination.procedure.label]);
    // Get Femoral and Approach Based on Procedure
    useEffect(() => {
        if(combination.procedure.label && !tprPreferenceObj.version__c){
        //if(combination.procedure.label){
          getProcedurePicklistValues(combination.procedure.label).then((res) => {
            const a = res.get_procedure_picklist_values;
            return a.map((item) => {
              item.title = item.label;
              item.id = item.id;
              return item;
            });
          })
          .then((res) => {
            setProcedureApplicationVersionOptions(res)
          })
      }else if(combination.procedure.label && tprPreferenceObj.version__c){
        getProcedurePicklistValues(combination.procedure.label,tprPreferenceObj.version__c).then((res) => {
          const a = res.get_procedure_picklist_values;
          return a.map((item) => {
            item.title = item.label;
            item.id = item.id;
            return item;
          });
        })
        .then((res) => {
          if(combination.procedure.label === 'Total Hip'){
            setApproachOptions(res);
          }else if(combination.procedure.label === 'Triathlon Total Knee' || combination.procedure.label === 'MCK Partial Knee'){
            setFemoralComponentOptions(res);
          }
          
        });
      }
  
    }, [combination.procedure.label,tprPreferenceObj.version__c]);
  
    // Get Base Plate and Insert Type Based on Femoral
    // Get Base Plate Based on Femoral
    useEffect(() => {
      if (combination.procedure.label && tprPreferenceObj.version__c && tprPreferenceObj.FemoralComponentValue) {
        getProcedurePicklistValues(combination.procedure.label,tprPreferenceObj.version__c,null,tprPreferenceObj.FemoralComponentValue).then((res) => {
          const a = res.get_procedure_picklist_values;
          return a.map((item) => {
            item.title = item.label;
            item.id = item.id;
            return item;
          });
        })
        .then((res) => {
            setBaseplateOptions(res);
        });
       
      }
    }, [tprPreferenceObj.version__c,tprPreferenceObj.FemoralComponentValue]);
  
    //MP: Get Insert Type Based on BaseplateValue
    useEffect(() => {
      if (combination.procedure.label && tprPreferenceObj.version__c && tprPreferenceObj.FemoralComponentValue && tprPreferenceObj.BasePlateValue) {
        getProcedurePicklistValues(combination.procedure.label,tprPreferenceObj.version__c,null,tprPreferenceObj.FemoralComponentValue,null,tprPreferenceObj.BasePlateValue).then((res) => {
          const a = res.get_procedure_picklist_values;
          return a.map((item) => {
            item.title = item.label;
            item.id = item.id;
            return item;
          });
        })
        .then((res) => {
            setInsertTypeOptions(res);
        });
      }
      }, [tprPreferenceObj.version__c,tprPreferenceObj.FemoralComponentValue,tprPreferenceObj.BasePlateValue]);
  
    // // Get Femoral and Acetabuler Based on Approach
    useEffect(() => {
        if (combination.procedure.label && tprPreferenceObj.version__c && tprPreferenceObj.ApproachValue) {
          getProcedurePicklistValues(combination.procedure.label,tprPreferenceObj.version__c,tprPreferenceObj.ApproachValue).then((res) => {
            const a = res.get_procedure_picklist_values;
            return a.map((item) => {
              item.title = item.label;
              item.id = item.id;
              return item;
            });
          })
          .then((res) => {
            setFemoralComponentHipsOptions(res);
            const acetabularOptions = res.length > 0 && res[0].acetabular && res[0].acetabular.map((item) => {
              item.title = item.label;
              item.id = item.id;
              return item;
            })
            setAcetabularComponentOptions(acetabularOptions)
          });
          
        } else {
          setAcetabularComponentOptions({});
          setFemoralComponentHipsOptions({});
        }
    }, [tprPreferenceObj.version__c,tprPreferenceObj.ApproachValue]);


  const handleChange = name => (event) => {
    if(!props.isFormDirty){
      props.valueChanged(name)
    }
    // if ((name === 'FemoralComponentValue' || name === 'ApproachValue') && event.target.value === ''){
    //   dispatch(resetTprPreference());;
    // } else {
    //   setTprPreference(name, event.target.value);
    // }
    if ((name === 'version__c') && event.target.value === ''){
      dispatch(resetTprPreference());
    } else {
      setTprPreference(name, event.target.value);
    }
  }

  const onChangeComments = (event) => {
    if(!props.isFormDirty){
      props.valueChanged("MAKO Pref comments")
    }
    setTprPreference("comments", event.target.value)
  };

  const onChangeProcedureApplicationVersionValue = (event) => {
    //formValChanged("ProcedureApplicationVersions");
    setProcedureApplicationVersionValue(event.target.value);
    setFemoralComponentOptions({});
    setInsertTypeOptions({});
    setBaseplateOptions({});
    setApproachOptions({});
    setApproachValue('');
    setAcetabularComponentOptions({});
    setFemoralComponentHipsOptions({});
    
  }

  return (
    <Grid container style={{ padding: "20px", background: "#fafafa" }}>
      <Grid container sm={6} xs={12}>

      <Grid item sm={11} xs={12} 
          className={(combination.procedure.label === "Total Hip" || combination.procedure.label === "Triathlon Total Knee" || combination.procedure.label === "MCK Partial Knee") ? "display" : "hide"}
        >
          <Dropdown
            //Isdisabled={controlDisabled}
            label={"Procedure Application Version"}
            ctrllabel={"Please Select"}
            f//nc={onChangeProcedureApplicationVersionValue}
            fnc={handleChange("version__c")}
            options={procedureApplicationVersionOptions}
            //value={procedureApplicationVersionValue}
            value={tprPreferenceObj.version__c}
          />
        </Grid>

        <Grid item sm={11} xs={12}>
          {combination.procedure.label === "Total Hip" ? (
            <Dropdown
              label={"Approach"}
              ctrllabel={"Please Select"}
              fnc={handleChange("ApproachValue")}
              options={ApproachOptions}
              value={tprPreferenceObj.ApproachValue}
            />
          ) : (
            <Dropdown
              label={"Femoral Component"}
              ctrllabel={"Please Select"}
              fnc={handleChange("FemoralComponentValue")}
              options={FemoralComponentOptions}
              value={tprPreferenceObj.FemoralComponentValue}
            />
          )}
        </Grid>

        <Grid item sm={11} xs={12}>
          {combination.procedure.label === "Total Hip" ? (
            <Dropdown
              label={"Femoral Component"}
              ctrllabel={"Please Select"}
              fnc={handleChange("FemoralComponentHipsValue")}
              options={FemoralComponentHipsOptions}
              value={tprPreferenceObj.FemoralComponentHipsValue}
            />
          ) : (
            <Dropdown
              label={"Base Plate"}
              ctrllabel={"Please Select"}
              fnc={handleChange("BasePlateValue")}
              options={BaseplateOptions}
              value={tprPreferenceObj.BasePlateValue}
            />
          )}
        </Grid>
        
        <Grid item sm={11} xs={12}>
          {combination.procedure.label === "Total Hip" ? (
            <Dropdown
              label={"Acetabular Component"}
              ctrllabel={"Please Select"}
              fnc={handleChange("AcetabularComponentValue")}
              options={AcetabularComponentOptions}
              value={tprPreferenceObj.AcetabularComponentValue}
            />
          ) : (
            <Dropdown
              label={"Insert Type"}
              ctrllabel={"Please Select"}
              fnc={handleChange("InsertTypeValue")}
              options={InsertTypeOptions}
              value={tprPreferenceObj.InsertTypeValue}
            />
          )}
        </Grid>

       
      </Grid>
      <Grid container sm={6} xs={12}>
        <Grid item sm={11} xs={12}>
          <Typography>
            <MultilineTextFields
              fnc={onChangeComments}
              label="Comments"
              value={tprPreferenceObj.comments}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TprPreference;
