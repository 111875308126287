import axios from 'axios'
import { API } from '../../constants';
//ActionTypes

const LOAD = 'makoMapping/LOAD'
const LOAD_SUCCESS = 'makoMapping/LOAD_SUCCESS'
const LOAD_FAILURE = 'makoMapping/LOAD_FAILURE'
const RESET_BRANCHES = 'makoMapping/RESET_BRANCHES'

const initialState = {
    loaded: false,
    loading: false,
    makoMapping: {},
    makoError: {}
}

export default function makoMapping(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                makoMapping:action.payload,
                makoError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                makoMapping: {},
                makoError: action.payload
            }
        default:
            return state
    }
}

export function getMakoMapping(makoToken) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        const url = encodeURI(`${API.MASTER_DATA.MAKO_MAPPING}`)
        const headers = {headers: {
            Authorization: "Bearer " + makoToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Custom-Header": "value"
        }}
        axios
            .get(url, headers)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    error
                })
            })
    }
}