import axios from "axios";
import { API } from "../../constants";

//Actions Types
const LOADING_CALENDAR_CASES = "LOADING_CALENDAR_CASES";
const CALENDAR_CASES_SUCCESS = "CALENDAR_CASES_SUCCES";
const CALENDAR_CASES_FAILURE = "CALENDAR_CASES_FAILURE";
const RESET_CALENDAR_CASES = "RESET_CALENDAR_CASES";
const LOAD_CASES_SUCCESS = "LOAD_CASES_SUCCESS";
const LOAD_CASES_FAILURE = "LOAD_CASES_FAILURE";
const RESET_CASES = "RESET_CASES";
const SET_APPLIED_FILTERS = "SET_APPLIED_FILTERS";
const RESET_APPLIED_FILTERS = "RESET_APPLIED_FILTERS";

export const surgicalCasesSuccess = (cases) => ({
  type: LOAD_CASES_SUCCESS,
  payload: cases
});

export const surgicalCasesFailure = (error) => ({
  type: LOAD_CASES_FAILURE,
  payload: error,
});

export const casesReset = () => ({
  type: RESET_CASES,
});

export const setAppliedFilters = (filters) => ({
  type: SET_APPLIED_FILTERS,
  payload: filters,
});

export const resetAppliedFilters = () => ({
  type: RESET_APPLIED_FILTERS,
});

export const loadingCalendarCases = () => ({
  type: LOADING_CALENDAR_CASES,
});

export const calendarCasesSuccess = (cases, appointments) => ({
  type: CALENDAR_CASES_SUCCESS,
  payload: {
    cases,
    appointments,
  },
});

export const calendarCasesFailure = (error) => ({
  type: CALENDAR_CASES_FAILURE,
  payload: error,
});

export const calendarCasesReset = () => ({
  type: RESET_CALENDAR_CASES,
});

const initialState = {
  cases: [],
  appointments: [],
  appliedFilters: {},
  loading: true,
  error: false,
  errorMessage: "",
};

export default function calendarCases(state = initialState, action = {}) {
  switch (action.type) {
    case LOADING_CALENDAR_CASES:
      return {
        ...state,
        loading: true,
      };
    case CALENDAR_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        cases: action.payload.cases,
        appointments: action.payload.appointments,
      };
    case CALENDAR_CASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case RESET_CALENDAR_CASES:
      return {
        ...state,
        loading: false,
        error: false,
        cases: [],
        appointments: [],
      };
      case LOAD_CASES_SUCCESS:
        return {
          ...state,
          loading: false,
          cases: action.payload
        };
      case LOAD_CASES_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: action.payload,
        };
      case RESET_CASES:
        return {
          ...state,
          loading: false,
          error: false,
          cases: []
        };
      case SET_APPLIED_FILTERS:
        return {
          ...state,
          appliedFilters: action.payload
        };  
      case RESET_APPLIED_FILTERS:
        return {
          ...state,
          appliedFilters: {}
        };              
    default:
      return state;
  }
}
