import axios from 'axios';
import { API } from '../../constants';

//ActionTypes

const LOAD_TREATMENT_PLAN_METADATA = 'uploadTreatmentPlanMetadata/LOAD_TREATMENT_PLAN_METADATA'
const UPLOAD_TREATMENT_PLAN_METADATA_SUCCESS = 'uploadTreatmentPlanMetadata/UPLOAD_TREATMENT_PLAN_METADATA_SUCCESS'
const UPLOAD_TREATMENT_PLAN_METADATA_FAILURE = 'uploadTreatmentPlanMetadata/UPLOAD_TREATMENT_PLAN_METADATA_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN_METADATA = 'uploadTreatmentPlanMetadata/RESET_UPLOAD_TREATMENT_PLAN_METADATA'

const initialState = {
    loadingTreatmentPlanMetadata: false,
    loadedTreatmentPlanMetadat: false,
    treatmentPlanMetadata: {},
    treatmentPlanMetadataError: {}

}

export default function uploadTreatmentPlanMetadata(state = initialState, action = {}) {
    switch (action.type) {
        case RESET_UPLOAD_TREATMENT_PLAN_METADATA:
            return {
                ...state,
                treatmentPlanUploaded: {},
                treatmentPlanError: {}
            }
        case LOAD_TREATMENT_PLAN_METADATA:
            return {
                ...state,
                loadingTreatmentPlanMetadata: true
            }
        case UPLOAD_TREATMENT_PLAN_METADATA_SUCCESS:
            return {
                ...state,
                loadingTreatmentPlanMetadata: false,
                loadedTreatmentPlanMetadata: true,
                treatmentPlanMetadata: action.payload,
                treatmentPlanMetadataError: {}
            }
        case UPLOAD_TREATMENT_PLAN_METADATA_FAILURE:
            return {
                ...state,
                loadingTreatmentPlanMetadata: false,
                loadedTreatmentPlanMetadata: false,
                treatmentPlanMetadata: {},
                treatmentPlanMetadataError: action.payload
            }
        default:
            return state
    }
}

export function postTreatmentPlanMetadata(metadata, rhsToken) {
    return dispatch => {
        dispatch({
            type: LOAD_TREATMENT_PLAN_METADATA
        })
        const urlPath = API.RHS.TREATMENT_PLAN_METADATA_URI;
        const headers = {headers: {
            Authorization: "Bearer " + rhsToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Custom-Header": "value",
        }}
        axios
            .post(urlPath, metadata, headers)
            .then(response => {
                dispatch({
                    type: UPLOAD_TREATMENT_PLAN_METADATA_SUCCESS,
                    payload: response
                })
            })
            .catch(error => {
                dispatch({
                    type: UPLOAD_TREATMENT_PLAN_METADATA_FAILURE,
                    error
                })
            })
    }
}

