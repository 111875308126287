import React, { Component } from 'react';
import Dropdown from '../controls/Dropdown'
import { connect } from 'react-redux'
import { Procedures } from '../services.js/Procedure';
// import { PROCEDURES_OPTIONS } from "../../constants"

class LoadProcedure extends Component {
    state = {
        LoadProcedures: [],
    }
    async componentDidMount() {
        await this.get_Procedures();
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user.branchIds !== prevProps.user.branchIds) {
            await this.get_Procedures();
        }
    }
    async componentWillReceiveProps(nextProps) {
        if (nextProps.reset !== this.props.reset) {
            this.setState({
                loading: false,
                LoadProcedures: [],
            });
            await this.get_Procedures();
        }
    }

    get_Procedures = async () => {
        const { branchIds } = this.props.user
        let LoadProcedures = await Procedures()
        LoadProcedures = LoadProcedures.map(item => {
            item.label = item.label;
            item.id = item.id;
            return item;
        })
        this.setState({
            loading: false,
            LoadProcedures,
        });
    };
    render() {
        /**MP:API CORS issue in dev env**/
        const { LoadProcedures } = this.state;
        // const LoadProcedures = PROCEDURES_OPTIONS;
        return <Dropdown inpage={this.props.inpage} isAstrikReq={this.props.isAstrikReq} label={this.props.isSurgeon? "Procedure for Surgical Case" : "Procedure"} ctrllabel={"Please Select"} fnc={this.props.onChangeProcedureValue} options={LoadProcedures} value={this.props.ProcedureValue} Isdisabled={this.props.Isdisabled}/>
    }
}
const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(LoadProcedure);
