import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
//import { getDivisions, getBranches } from './Form.queries';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%'
  },
  inputFieldStyle:{
    border: '1px solid lightgrey',
    height: '51px'
  },
  underline: {
    height: '51px',
    border: '1px solid lightgrey',
    '&:after': {
      borderBottom: '2px solid gold',
    },
  }
}));

export default function Dropdown(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isAstrikReq = props.isAstrikReq;
  const name = <div className="controllabelCreate" >{props.label} { isAstrikReq ? <span style={{ color: "red" }} >*</span> : ''}</div>
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="ddl">{name}</InputLabel>
        <NativeSelect
          labelId="dropdown-filled-label"
          className={props.inpage ? classes.inputFieldStyle : props.value ? null :  'dropdownPlaceholder'}
          id="ddl"
          disabled={props.Isdisabled}
          inputProps={{
            name: props.label,
            id: 'ddl',
          }}
          input={props.inpage ? 
            <Input
              classes={{    
                underline:classes.underline
              }}
            /> : <Input/> 
          }
          onChange={(event) => props.fnc(event)}
          value={props.show ? props.options[0] && props.options[0].label : props.value} >
          {props.ctrllabel != "" ?  <option value="" selected>{props.ctrllabel}</option> : ""}
          { props.label === "Saved Search" && props.options && props.options.length > 0 ? props.options.map(option => <option value={option.name} lable={option.name} data-name={option.name} key={option.name}> {option.name} </option>) : 
          props.getValue && props.options && props.options.length > 0 ? props.options.map(option => <option value={option.label} lable={option.name} data-name={option.id} key={option.label}> {option.label} </option>) :
          props.label === 'Hospital' && props.options && props.options.length > 0 ? props.options.map(option => <option value={option.id} data-name={option.title} key={option.id}> {option.title} </option>) :
            props.label === 'Sales Rep' && props.options && props.options.length > 0 ? props.options.map(option => <option value={option.id} data-name={option.title} key={option.id}> {option.title} </option>) : 
          props.label === 'Sales Team' && props.options && props.options.length > 0 ? props.options.map(option => <option value={option.id} data-name={option.label} key={option.id}> {option.label} </option>) : 
          props.options && props.options.length > 0 ? props.options.map(option => option.label === "All Active"? "":<option value={option.id} data-name={option.label} key={option.id}> {option.label} </option>) :  ''}
        </NativeSelect>
      </FormControl>
    </div>
  );
}
