import { API, ASYNC_STORAGE,  } from '../../constants';
import GraphQLClient from '../../graphql/client';
import gql from 'graphql-tag';


const getRefreshToken = async refreshToken => {
    const mutation = gql`
        mutation refreshJwt($refreshToken: String) {
          refreshJwt(refresh_token: $refreshToken) {
            access_token
            refresh_token
            mako_token
            as1_token
            expires_in
            dips_token
          }
        }
      `;

    const results = await GraphQLClient.mutate({
        mutation,
        variables: {
            refreshToken
        }
    });
    if (results.data.refreshJwt) {
        const access_token = results.data.refreshJwt.access_token;
        const refresh_token = results.data.refreshJwt.refresh_token;
        const mako_token = results.data.refreshJwt.refresh_token;
        const as1_token = results.data.refreshJwt.refresh_token;
        const expires_in = results.data.refreshJwt.expires_in;
        const dips_token = results.data.refreshJwt.dips_token;
        localStorage.setItem(
            ASYNC_STORAGE.TOKEN,
            JSON.stringify({
                access_token,
                refresh_token,
                mako_token,
                as1_token,
                expires_in,
                dips_token
            })
        );
    }
};

const retryingFetch = async (...args) => {
    let failed = true;
    let attempts = 0;
    let error;
    let results;

    while (failed && attempts < 3) {
        failed = false;
        attempts++;
        try {
            results = await fetch(...args);
            if (!results.ok) {
                throw new Error(
                    `Network error retrieving Procedures: ${results.status
                    } ${results.statusText || 'Unable to retrieve response.'}`
                );
            }
        } catch (e) {
            error = e;
            failed = true;
        }
    }
    if (failed) {
        throw error;
    }
    return results.json();
}

export async function Procedures() {
    //let bearerToken = await localStorage.getItem(ASYNC_STORAGE.access_token);
    // const { access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}') 
    let bearerToken = '';
    const { refresh_token, expires_in, access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}');
    bearerToken = access_token;

    // if token is expired, renew silently
    if (Date.now().toString() >= expires_in) {
        await getRefreshToken(refresh_token);
        const { access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}');
        bearerToken = access_token;
    }

    let results = await retryingFetch(`${API.MASTER_DATA.PROCEDURES}`, {
        headers: { Authorization: bearerToken }
    });

    let procedureHash = {};
    if (results && Array.isArray(results)) {
        for (const url of results) {
            let newUrl = url;
            if(url.indexOf() > -1){
              
                newUrl = window.location.origin
            }

            let procedureList = await retryingFetch(newUrl);
            if (procedureList && Array.isArray(procedureList)) {
                procedureList.forEach(procedure => {
                    procedureHash[procedure.id] = procedure;
                });
            }
        }
    }

    let procedures = Object.values(procedureHash);

    procedures = procedures.map(r => {
        const nullify = field => (typeof field !== 'undefined' ? field : null);
        return {
            id: nullify(r.id),
            label: nullify(r.label),
            division: nullify(r.division)
        };
    });
    return procedures;
}
