import { Popper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CaseCard from "../../../../components/CaseCard";

const CaseDeatilsTooltip = (props) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: "white",
      border: "none",
      borderBottom: `5px solid ${props.itemColor ? props.itemColor : ""}`,
      boxShadow: `2px 2px 8px grey`
    },
  }));
  const classes = useStyles();
  return (
    <Popper
      style={{zIndex: 2147483647}}
      id={props.data.caseId}
      open={props.open}
      anchorEl={props.anchorEl}
      placement={"bottom-start"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className={classes.paper}>
        <CaseCard case={props.data} detailOnly />
      </div>
    </Popper>
  );
};

export default CaseDeatilsTooltip;
