import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ASYNC_STORAGE } from '../constants';
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
  //const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InBhcnZlZW4uZ295YWxAc3RyeWtlci5jb20iLCJpYXQiOjE2MDM0NDc1MzUsImV4cCI6MTYwMzUzMzkzNX0.l0YFG_pxuIIC1KQzdpvp2LE6Dzoc-pxpOctCMW1tEvw"//localStorage.getItem('');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: access_token ? `Bearer ${access_token}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

