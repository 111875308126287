import { createSelector } from 'reselect';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { DEFAULT_STATUSES } from '../../../src/constants';


// Actions
export const ACTION_TYPES = {
  SET_MY_CASES_FILTER: 'SET_MY_CASES_FILTER',
  SET_CALENDAR_FILTER: 'SET_CALENDAR_FILTER',
  REMOVE_MY_CASES_FILTER: 'REMOVE_MY_CASES_FILTER',
  RESET_MY_CASES_FILTER: 'RESET_MY_CASES_FILTER',
  REMOVE_CALENDAR_FILTER: 'REMOVE_CALENDAR_FILTER',
  SET_MY_CASES_SEARCH_FILTER: 'SET_MY_CASES_SEARCH_FILTER',
  SET_MY_ORDERS_FILTER: 'SET_MY_ORDERS_FILTER',
  SET_MY_ORDERS_SEARCH_FILTER: 'SET_MY_ORDERS_SEARCH_FILTER',
  REMOVE_MY_ORDERS_FILTER: 'REMOVE_MY_ORDERS_FILTER',
  SET_MY_PREFERENCES_FILTER: 'SET_MY_PREFERENCES_FILTER',
  REMOVE_MY_PREFERENCES_FILTER: 'REMOVE_MY_PREFERENCES_FILTER',
  RESTORE_FILTER: 'RESTORE_FILTER',
  REFRESH_PREFERENCE_VIEW: 'REFRESH_PREFERENCE_VIEW'
};

// Action Creators
export const ACTION_CREATORS = {
  setMyCasesSearchFilter: text => {
    return {
      type: ACTION_TYPES.SET_MY_CASES_SEARCH_FILTER,
      text
    };
  },
  removeMyCasesFilter: key => {
    return {
      type: ACTION_TYPES.REMOVE_MY_CASES_FILTER,
      key
    };
  },
  removeCalendarFilter: key => {
    return {
      type: ACTION_TYPES.REMOVE_CALENDAR_FILTER,
      key
    };
  },
  resetMyCasesFilter: () => {
    return {
      type: ACTION_TYPES.RESET_MY_CASES_FILTER
    };
  },
  setMyCasesFilter: (name, filter) => {
    return {
      type: ACTION_TYPES.SET_MY_CASES_FILTER,
      name,
      filter
    };
  },
  setCalendarFilter: (name, filter) => {
    return {
      type: ACTION_TYPES.SET_CALENDAR_FILTER,
      name,
      filter
    };
  },
  setMyOrdersFilter: (name, filter) => {
    return {
      type: ACTION_TYPES.SET_MY_ORDERS_FILTER,
      name,
      filter
    };
  },
  setMyOrdersSearchFilter: text => {
    return {
      type: ACTION_TYPES.SET_MY_ORDERS_SEARCH_FILTER,
      text
    };
  },
  removeMyOrdersFilter: key => {
    return {
      type: ACTION_TYPES.REMOVE_MY_ORDERS_FILTER,
      key
    };
  },
  setMyPreferencesFilter: (name, filter) => {
    return {
      type: ACTION_TYPES.SET_MY_PREFERENCES_FILTER,
      name,
      filter
    };
  },
  removeMyPreferencesFilter: key => {
    return {
      type: ACTION_TYPES.REMOVE_MY_PREFERENCES_FILTER,
      key
    };
  },
  restoreFilters: key => {
    return {
      type: ACTION_TYPES.RESTORE_FILTER
    };
  },
  refreshPreferenceView: () => {
    return {
      type: ACTION_TYPES.REFRESH_PREFERENCE_VIEW
    };
  }
};

// Selectors
const getUser = state => state.user;
const getMyCasesFilter = state => state.filters.cases.params;
const getMyCasesFilterName = state => state.filters.cases.name;
const getMyCasesSearchText = state => state.filters.cases.searchText;
const getCalendarFilter = state => state.filters.calendar.params;
const casesSource = state => state.cases.source;
const getMyOrdersFilter = state => state.filters.orders.params;
const getMyOrdersSearchText = state => state.filters.orders.searchText;
const getMyPreferencesFilter = state => state.filters.preferences.params;

// Internal
export const ROLE_CASES = {
  MY_CASES: 'My Cases',
  COVERING_CASES: 'Covering Cases',
  TEAM_CASES: 'Team Cases',
  BRANCH_CASES: 'Branch Cases'
};

const transformFilters = filterParams => {
  const dateFields = ['startDate', 'endDate'];
  const checkBoxFields = [
    'favorite',
    'calFavorite',
    'community',
    'myPreferences'
  ];
  return Object.keys(filterParams)
    .map(key => {
      let filterParam = filterParams[key];
      if (filterParam) {
        if (
          filterParam.label &&
          key !== 'filterSalesTeam' &&
          dateFields.indexOf(key) < 0 &&
          checkBoxFields.indexOf(key) < 0
        ) {
          return {
            key,
            label: filterParam.label
          };
        } else if (
          filterParam.label &&
          filterParam.value &&
          key === 'filterSalesTeam' &&
          dateFields.indexOf(key) < 0 &&
          checkBoxFields.indexOf(key) < 0
        ) {
          return {
            key,
            label: filterParam.label,
            value: filterParam.value
          };
        }

        if (dateFields.indexOf(key) > -1) {
          let label;
          let dateToFormat = moment.isMoment(filterParam)
            ? filterParam
            : moment(filterParam.label);
          label = `${
            key === 'startDate' ? 'From' : 'To'
          }: ${dateToFormat.format('YYYY-MM-DD')}`;
          return {
            key,
            label
          };
        }

        if (checkBoxFields.indexOf(key) > -1) {
          let label;
          switch (key) {
            case 'favorite':
            case 'calFavorite':
              label = 'Favorites';
              break;
            case 'myPreferences':
              label = 'My Preferences';
              break;
            default:
              label = 'Provider Community';
          }
          return {
            key,
            label
          };
        }
      }
    })
    .filter(filterParam => filterParam !== undefined && filterParam !== null)
    .reduce((accumulator, filterParam) => {
      accumulator.push(filterParam);
      return accumulator;
    }, []);
};

// Transforming case filters to create key, value and label object. Used only for cases.
const transformCaseFilters = filterParams => {
  const dateFields = ['startDate', 'endDate'];
  const checkBoxFields = [
    'favorite',
    'calFavorite',
    'community',
    'myPreferences'
  ];
  return Object.keys(filterParams)
    .map(key => {
      let filterParam = filterParams[key];
      if (filterParam) {
        if (
          filterParam.label &&
          key !== 'role' &&
          dateFields.indexOf(key) < 0 &&
          checkBoxFields.indexOf(key) < 0
        ) {
          return {
            key,
            label: filterParam.label,
            value: filterParam.value
          };
        } else if (
          filterParam.label &&
          filterParam.value &&
          key === 'role' &&
          dateFields.indexOf(key) < 0 &&
          checkBoxFields.indexOf(key) < 0
        ) {
          return {
            key,
            label: filterParam.label,
            value: filterParam.label
          };
        }

        if (dateFields.indexOf(key) > -1) {
          // let dateToFormat = moment.isMoment(filterParam)
          //   ? filterParam
          //   : moment(filterParam.label);
          // const value = dateToFormat.format('YYYY-MM-DD');
          const value = moment(
            filterParam.label ? filterParam.label : filterParam
          ).format('MM/DD/YYYY'); // dateToFormat.format('YYYY-MM-DD');
          const label = `${key === 'startDate' ? 'From' : 'To'}: ${value}`;

          return {
            key,
            label,
            value
          };
        }

        if (checkBoxFields.indexOf(key) > -1) {
          let label;
          switch (key) {
            case 'favorite':
            case 'calFavorite':
              label = 'Favorites';
              break;
            case 'myPreferences':
              label = 'My Preferences';
              break;
            default:
              label = 'Provider Community';
          }
          return {
            key,
            label
          };
        }
      }
    })
    .filter(filterParam => filterParam !== undefined && filterParam !== null)
    .reduce((accumulator, filterParam) => {
      accumulator.push(filterParam);
      return accumulator;
    }, []);
};

const applyFilter = (filter, cases, user, searchText = '') => {
  let startDate =
    filter.startDate && moment.isMoment(filter.startDate)
      ? filter.startDate
      : filter.startDate
        ? moment(filter.startDate.label)
        : moment().year(1);
  let endDate =
    filter.endDate && moment.isMoment(filter.endDate)
      ? filter.endDate
      : filter.endDate
        ? moment(filter.endDate.label)
        : moment().year(3000);
  startDate = startDate
    .hour(0)
    .minute(0)
    .second(0);
  endDate = endDate
    .hour(11)
    .minute(59)
    .second(59);

  const favorite = filter.favorite;
  const calFavorite = filter.calFavorite;
  const community = filter.community;

  // TODO: Refactor the filter code below like so for legibility
  const inDivision = item => {
    if (!filter.division) {
      return user.divisions ? user.divisions.indexOf(item.division) > -1 : true;
    }
    return item.division === filter.division.label;
  };

  let filteredCases = cases.filter(
    item =>
      inDivision(item) &&
      (!filter.filterBranch || item.branch === filter.filterBranch.label) &&
      (!filter.role ||
        (filter.role.label === ROLE_CASES.MY_CASES &&
          item.userRep === user.sfids[0]) ||
        (filter.role.label === ROLE_CASES.COVERING_CASES &&
          item.coveringRepsSFIDs &&
          item.coveringRepsSFIDs.includes(user.sfids[0])) ||
        (filter.role.label === ROLE_CASES.BRANCH_CASES &&
          user.branchIds.indexOf(item.caseBranchId) > -1) ||
        (filter.role.label === ROLE_CASES.TEAM_CASES &&
          user.teammate_sfids &&
          (user.teammate_sfids.includes(item.userRep) ||
            user.teammate_sfids.includes(item.coveringRepsSFIDs)))) &&
      (!filter.filterSalesRep ||
        item.salesRep === filter.filterSalesRep.label) &&
      ((!filter.status &&
        DEFAULT_STATUSES.indexOf(item.status.toLowerCase()) > -1) ||
        (filter.status && item.status === filter.status.label)) &&
      (!filter.procedure ||
        (item.procedure && item.procedure.includes(filter.procedure.label))) &&
      (!filter.filterHospital ||
        item.hospitalName === filter.filterHospital.label) &&
      (!filter.filterSurgeon ||
        item.surgeonName === filter.filterSurgeon.label) &&
      ((!filter.startDate && !filter.endDate) ||
        moment(item.surgeryDate).isBetween(startDate, endDate)) &&
      (!filter.favorite || item.favoriteCase === favorite) &&
      (!filter.calFavorite || item.favoriteCase === calFavorite) &&
      (!filter.community || item.communityCase === community) &&
      (!filter.filterSalesTeam ||
        item.salesTeamsSFIDs.includes(filter.filterSalesTeam.value))
  );

  // Search is additive
  if (searchText && searchText !== '') {
    return filteredCases.filter(item => {
      let patientId = item.patientId || '';
      let caseId = item.caseId || '';
      return (
        patientId.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        caseId.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    });
  }

  return orderBy(filteredCases, ['surgeryDate'], ['asc']);
};

const applyCalendarFilter = (filter, cases = [], user) => {
  if (Object.keys(filter).length === 0) {
    return cases.filter(item => {
      let isNotClosedOrCompleted =
        item.status &&
        ['closed', 'completed'].indexOf(item.status.toLowerCase()) < 0;

      let isTeamCaseOrMyCase =
        user.teammate_sfids.includes(item.userRep) ||
        user.teammate_sfids.includes(item.coveringRepsSFIDs) ||
        item.userRep === user.sfids[0];

      return isNotClosedOrCompleted && isTeamCaseOrMyCase;
    });
  }

  return applyFilter(filter, cases, user);
};

export const SELECTORS = {
  getCalendarMonthView: createSelector(
    [getCalendarFilter, casesSource, getUser],
    (filter, cases, user) => {
      let monthCases = applyCalendarFilter(filter, cases, user);
      return groupBy(monthCases, object =>
        moment(object.surgeryDate).format('YYYY-MM-DD')
      );
    }
  ),
  getMyCasesSearchText: createSelector(
    [getMyCasesSearchText],
    searchText => searchText
  ),
  getMyCasesFilterName: createSelector([getMyCasesFilterName], name => {
    return name || 'Filter Presets';
  }),
  getCalendarFilterParams: createSelector([getCalendarFilter], filterParams =>
    transformCaseFilters(filterParams)
  ),
  getMyCasesFilterParams: createSelector([getMyCasesFilter], filterParams =>
    transformCaseFilters(filterParams)
  ),

  getCasesByMyCasesFilter: createSelector(
    [getMyCasesFilter, casesSource, getUser, getMyCasesSearchText],
    applyFilter
  ),
  getCasesByCalendarFilter: createSelector(
    [getCalendarFilter, casesSource, getUser],
    (filter, cases = [], user) => {
      if (Object.keys(filter).length === 0) {
        return cases.filter(item => {
          let isNotClosedOrCompleted =
            item.status &&
            ['closed', 'completed'].indexOf(item.status.toLowerCase()) < 0;

          let isTeamCaseOrMyCase =
            user.teammate_sfids.includes(item.userRep) ||
            user.teammate_sfids.includes(item.coveringRepsSFIDs) ||
            item.userRep === user.sfids[0];

          return isNotClosedOrCompleted && isTeamCaseOrMyCase;
        });
      }

      return applyFilter(filter, cases, user);
    }
  ),
  getMyOrdersFilterParams: createSelector([getMyOrdersFilter], filterParams =>
    transformFilters(filterParams)
  ),
  getMyOrdersSearchText: createSelector(
    [getMyOrdersSearchText],
    searchText => searchText
  ),
  getMyPreferencesFilterParams: createSelector(
    [getMyPreferencesFilter],
    filterParams => transformCaseFilters(filterParams) // transformFilters(filterParams)
  )
};

// Store
const initialState = {
  calendar: {
    name: '',
    params: {
      role: { value: ROLE_CASES.MY_CASES, label: ROLE_CASES.MY_CASES }
    }
  },
  cases: {
    name: '',
    params: {
      role: { value: ROLE_CASES.MY_CASES, label: ROLE_CASES.MY_CASES }
    },
    searchText: null
  },
  orders: {
    name: '',
    params: {},
    searchText: null
  },
  preferences: {
    name: '',
    params: {}
  },
  refreshToggle: false // for preference tab, to refresh page as soon as filter is applied. For cases/calendar, this refresh logic is handled in cases-reducer file.
};

export const STORE = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_MY_CASES_SEARCH_FILTER:
      return Object.assign({}, state, {
        cases: Object.assign({}, state.cases, {
          searchText: action.text
        })
      });
    case ACTION_TYPES.SET_CALENDAR_FILTER:
      return Object.assign({}, state, {
        calendar: Object.assign({}, state.calendar, {
          name: action.name,
          params: action.filter
        })
      });
    case ACTION_TYPES.SET_MY_CASES_FILTER:
      return Object.assign({}, state, {
        cases: Object.assign({}, state.filters, {
          name: action.name,
          params: action.filter
        })
      });
    case ACTION_TYPES.REMOVE_MY_CASES_FILTER:
      let myCasesParams = Object.keys(state.cases.params)
        .filter(paramKey => paramKey !== action.key)
        .reduce((accumulator, paramKey) => {
          accumulator[paramKey] = state.cases.params[paramKey];
          return accumulator;
        }, {});

      return Object.assign({}, state, {
        cases: Object.assign({}, state.cases, {
          name: null,
          params: myCasesParams
        })
      });
    case ACTION_TYPES.REMOVE_CALENDAR_FILTER:
      let calendarParams = Object.keys(state.calendar.params)
        .filter(paramKey => paramKey !== action.key)
        .reduce((accumulator, paramKey) => {
          accumulator[paramKey] = state.calendar.params[paramKey];
          return accumulator;
        }, {});

      return Object.assign({}, state, {
        calendar: Object.assign({}, state.calendar, {
          name: null,
          params: calendarParams
        })
      });
    case ACTION_TYPES.RESET_MY_CASES_FILTER:
      return Object.assign({}, state, {
        cases: initialState.cases
      });
    case ACTION_TYPES.SET_MY_ORDERS_FILTER:
      return Object.assign({}, state, {
        orders: Object.assign({}, state.filters, {
          name: action.name,
          params: action.filter
        })
      });
    case ACTION_TYPES.SET_MY_ORDERS_SEARCH_FILTER:
      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          searchText: action.text
        })
      });
    case ACTION_TYPES.REMOVE_MY_ORDERS_FILTER:
      let myOrdersParams = Object.keys(state.orders.params)
        .filter(paramKey => paramKey !== action.key)
        .reduce((accumulator, paramKey) => {
          accumulator[paramKey] = state.orders.params[paramKey];
          return accumulator;
        }, {});

      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          name: null,
          params: myOrdersParams
        })
      });
    case ACTION_TYPES.SET_MY_PREFERENCES_FILTER:
      return Object.assign({}, state, {
        preferences: Object.assign({}, state.filters, {
          name: action.name,
          params: action.filter
        })
      });
    case ACTION_TYPES.REFRESH_PREFERENCE_VIEW:
      return Object.assign({}, state, {
        refreshToggle: !state.refreshToggle
      });
    case ACTION_TYPES.REMOVE_MY_PREFERENCES_FILTER:
      let myPreferencesParams = Object.keys(state.preferences.params)
        .filter(paramKey => paramKey !== action.key)
        .reduce((accumulator, paramKey) => {
          accumulator[paramKey] = state.preferences.params[paramKey];
          return accumulator;
        }, {});

      return Object.assign({}, state, {
        preferences: Object.assign({}, state.preferences, {
          name: null,
          params: myPreferencesParams
        })
      });
    case ACTION_TYPES.RESTORE_FILTER:
      return initialState;
    default:
      return state;
  }
};
