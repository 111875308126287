import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { bindActionCreators } from 'redux';
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import { connect } from 'react-redux'

function LogoutMenu({caseActions, ...props }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogoutClick = () => {
        caseActions.setCaseDetail(null)
        localStorage.clear();
        window.location.href = process.env.REACT_APP_STRYKER_LOGOUT_URI;
        localStorage.removeItem("filterObj");
        localStorage.removeItem("checkFilterName");
        localStorage.removeItem("SelectedFilter");
    };
    

    return (
        <div>
            <Button aria-controls="menu" aria-haspopup="true" className="logoutButton" onClick={handleClick}>
                {`Hi, ${props.loginName}`} <i className="material-icons right"><ArrowDropDownIcon /></i>
            </Button>
            <Menu
                style={{top: '16px'}}
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem className="logoutMenuItem" onClick={handleLogoutClick}>Logout</MenuItem>
            </Menu>
        </div>
    );
}
function mapDispatchToProps(dispatch) {
    return {
        caseActions: bindActionCreators(userCase, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(LogoutMenu);