import React from 'react';

// pass comma seperate string and count to get required number of values to show on UI
export const getDisplayLabel = (stringVal, count = 2) => {
    let label = stringVal || "";
    const valArr = stringVal && stringVal.split(",");
    if(valArr && valArr.length > count){
        label = valArr.slice(0, count).join(",")+` (+${valArr.length - count})`;
    }
    return label;
}