
import { getSurgeons as getUserSurgeons } from '../../graphql/queries'

//ActionTypes

const LOAD = 'surgeons/LOAD'
const LOAD_SUCCESS = 'surgeons/LOAD_SUCCESS'
const LOAD_FAILURE = 'surgeons/LOAD_FAILURE'
const RESET_SURGEONS = 'surgeons/RESET_SURGEONS'

const initialState = {
    loaded: false,
    loading: false,
    surgeonsList: [],
    surgeonsListError: {}
}

export default function surgeons(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                surgeonsList:action.payload,
                surgeonsListError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                surgeonsList: {},
                surgeonsListError: action.payload
            }
        case RESET_SURGEONS:
            return {
                ...state,
                surgeonsList: {},
                surgeonsListError: {}
            }
        default:
            return state
    }
}

//New Territory Design
export function getSurgeons() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getUserSurgeons()
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.get_surgeons
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}


export function resetBranches() {
    return dispatch => {
        dispatch({
            type: RESET_SURGEONS
        })
    }
}