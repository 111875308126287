import React, { useEffect } from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropdown from '../../components/controls/Dropdown'
import Buttons from '../../components/controls/Button'
import { getFieldPicklistValues } from '../../graphql/queries';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MultilineTextFields from '../../components/controls/MultilineText'
import CardContent from '@material-ui/core/CardContent';

export default function CancelSurgicalCase(props) {
    const [CanelSurgicalReasonOption, setCanelSurgicalReasonOption] = React.useState([])
    const [CanelReason, setCanelReason] = React.useState("")
    const [CancelComments, setCancelComments] = React.useState("")
    const [OtherVisible, setOtherVisible] = React.useState(false)
    const [dialogOpen, setdialogOpen] = React.useState(false)


    useEffect(() => {
        setdialogOpen(props.showCancelSurgicalCaseModalPopup)
        setCanelReason("")
        setOtherVisible(false)
    }, [props.showCancelSurgicalCaseModalPopup]);

    const compareOptions = (a, b) => {
        const c1 = a.label;
        const c2 = b.label; 
        var comp = 0;
        if (c1 > c2)
            comp = 1;
        else if (c1 < c2)   
            comp = -1;
        return comp;
    }

    useEffect(() => {
        getFieldPicklistValues('CustomObject',
            'Cases__c',
            'cancel_reason__c')
            .then(res => {
                return res.get_picklist_values.map(item => {
                    item.title = item.label;
                    return item;
                })
            })
            .then(res => { setCanelSurgicalReasonOption(res.sort(compareOptions)) })
    }, []);

    const handleChanges = (event) => {
        setCanelReason(event.target.value)
        if (event.target.value === "Other") {
            setOtherVisible(true)
        } else {
            setOtherVisible(false)
        }
    }

    const handleMulilSelectChanges = (event) => {
        setCancelComments(event.target.value)
    }

   
    const handleConfirm = async () => {
        setdialogOpen(false)
        props.handleCaseConfirmClick(CanelReason, CancelComments);
    };

    const handleSurgicalCaseClose = () => {
        setdialogOpen(false)
        props.handleSurgicalCaseClose()
    };

    return (
        <div>
            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="responsive-dialog-title" className="backgroundgreycolor borderbottom" style={{ padding: '8px 16px',  }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid item sm={12} xs={12} className="lFloat">
                                <CardContent>

                                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{"Confirm you would like to cancel this case"}</div>
                                    <div style={{ fontSize: '14px' }}>{"Please provide the reason for cancellation"}</div>

                                </CardContent>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid item sm={12} xs={12}>
                        <Grid item sm={11} xs={12}>
                            <Typography>
                                <Dropdown Isdisabled={false} label={"Cancel Reason"} fnc={handleChanges} ctrllabel={"Please Select"} value={CanelReason} options={CanelSurgicalReasonOption} />
                            </Typography>
                        </Grid>
                        {OtherVisible
                            ? <Grid item sm={11} xs={12}>
                                <Typography>
                                    <MultilineTextFields fnc={handleMulilSelectChanges} label="Cancel Comment" value={CancelComments} />
                                </Typography>
                            </Grid>
                            : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Buttons Icon="Confirm" toSave={true} disabled={CanelReason === '' || (OtherVisible && CancelComments === '')} fnc={handleConfirm} name="CONFIRM" />
                    <Buttons Icon="Submit" fnc={handleSurgicalCaseClose} name="CANCEL" />
                </DialogActions>
            </Dialog>
        </div >
    )
}