import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },

}));
export default function DropdownDivision(props) {
  const classes = useStyles();
  const name = <div className="controllabelCreate" >{props.label}<span style={{ color: "red" }} >*</span></div>
  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        shrink htmlFor="ddl">{name}</InputLabel>
      <NativeSelect
        // style={{
        //   borderStyle: 'groove',
        //   borderRadius: '5px'
        // }}
        labelId="dropdown-filled-label"
        id="ddl"
        disabled={Boolean(props.Isdisabled)}
        onChange={(event) => props.fnc(event)}
        value={props.value}
        className={props.value ? null : 'dropdownPlaceholder'}
        inputProps={{
          name: props.label,
          id: 'ddl',
        }}
      >
        <option value="" selected>{props.ctrllabel}</option>
        {props.options.map(option => <option value={option.id} data-name={option.label} key={option.id}> {option.label} </option>)}
      </NativeSelect>
    </FormControl>
  );
}

