import React, { useState, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { IDLE_LOGOUT_TIME } from "../../constants"
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

function IdleLogout({ caseActions }) {
    const [logoutMessage, setLogoutMessage] = useState(false)

    const idleTimerRef = useRef(null)

    const handleIdleTimeout = () => {
        setLogoutMessage(true)
    }

    const IdleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimerRef,
        timeout: IDLE_LOGOUT_TIME,
        onIdle: handleIdleTimeout,

    })
    const logout = () => {
        caseActions.setCaseDetail(null)
        localStorage.clear();
        window.location.href = process.env.REACT_APP_STRYKER_LOGOUT_URI;
        localStorage.removeItem("filterObj");
        localStorage.removeItem("checkFilterName");
        localStorage.removeItem("SelectedFilter");
    }
    const handleSavePopup = async () => {
        await logout()
        setLogoutMessage(false);
    }
    const message = "Your session has expired.Please login again to continue."

    return (
        <div idleTimer={IdleTimer}>
            <div>
                <Dialog open={logoutMessage}>
                    <DialogContent >
                        <div style={{ textAlign: "center", padding: "5px" }}>
                            <span style={{ display: "inline-block", margin: "0 auto", marginTop: "15px" }}>{message}</span>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    backgroundColor: "#ffb500",
                                    color: "black",
                                    fontWeight: "bold",
                                    display: "block",
                                    margin: "0 auto",
                                    marginTop: "60px",
                                    marginBottom: "10px"
                                }}
                                onClick={handleSavePopup}>Dismiss</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}


function mapDispatchToProps(dispatch) {
    return {
        caseActions: bindActionCreators(userCase, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(IdleLogout);