import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AutoComplete from '../../components/controls/AutoComplete'
import { TPR_STATUS, API, ASYNC_STORAGE } from '../../constants';
import axios from "axios";
import { FormControl } from '@material-ui/core';
import { default as ReactSelect } from 'react-select';
import Select, { components } from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import Snackbars from "../../components/controls/Snackbar";
import SimpleBackdrop from "../../components/controls/Spinner";

import {
  Chip,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Buttons from "../../components/controls/Button";
import { useRef } from "react";

const ShareDialog = (props) => {
  const useStyles2 = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '97%'
    }
  }));
  const customStyles = {
    control: base => ({
      ...base,
      height: '51px',
      minHeight: '51px',
      border: '1px solid lightgrey',
      borderBottom: '2px solid lightgrey',
      borderRadius: 0,
      overflow: 'overlay'
    })

  };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '97%'
    },
    chip: {
      margin: theme.spacing(0.5),
      color: 'black'
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: "97%",
    },
    inputColor: {
      border: '1px solid lightgrey',
      height: '51px',
      '&:after': {
        borderBottom: '2px solid gold',
        color: 'black'
      }
    },
    toAddress: {
      color: "#000",
      fontWeight: 600,
      marginLeft: "5px",
      fontSize: "16px",
      display: "grid",
      gridTemplateColumns: "1fr auto",
    },
  }));
  const classes = useStyles();
  const classes1 = useStyles2();
  const [emailAddresses, setEmailAddresses] = useState([]);
  const emailAddressesRef = useRef(emailAddresses);
  const [selectedPart, setSelectedPart] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [selectedOptionCustom, setSelectedOptionCustom] = React.useState("");
  const [demo, demo2] = React.useState([{ "id": "a1" }, { "id": "a1" }, { "id": "a3" }]);
  const theme = useTheme();
  const [notification, setNotification] = useState({
    severity: "",
    message: "",
  });
  const [openSnakBar, setOpenSnakBar] = useState(false);

  const [inputAddress, setInputAddress] = useState("");

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} >
          {/* <input
            type= "checkbox"
            checked= {props.isSelected}
            onChange= {()=> null}
          />{" "} */}
          <label>{props.data.label}   </label>
        </components.Option>
      </div>
    );
  }

  useEffect(() => {

    window.addEventListener("storage4", function () {
      setEmailAddressesFnc([]);
      setInputAddress("");
    })
  }, [])

  useEffect(() => {

    window.addEventListener("keyup", addEmailAddress);
    return () => {
      window.removeEventListener("keyup", addEmailAddress);
    };
  }, []);

  const handleUploadedByChange2 = (option, event) => {
  }

  const handleUploadedByChange = (option, event) => {
    let row = [];
    let c = emailAddresses;
    let check = c.includes(option.label);
    if (check == true) {
      return;
    }

    c.push(option.label);


    // const values = option.email.split(/[\s, ;]+/);
    let arr = [...emailAddressesRef.current, c];
    setEmailAddressesFnc(c);
    setInputAddress(option.label);


  }

  const addEmailAddress = (e) => {
    if (
      e.target.name === "ss_email" &&
      e.target.value &&
      (e.key === "Enter" || e.keyCode === 13)
    ) {

      const values = e.target.value.split(/[\s, ;]+/);

      let arr = [...emailAddressesRef.current, ...values];

      setEmailAddressesFnc(arr);
      setInputAddress("");
    }
  };

  const displaySnackBarMessage = (severity, errorMessage) => {
    setNotification({
      severity: severity,
      message: errorMessage,
    });
    setOpenSnakBar(true);
  };

  const shareId = () => {
  if (emailAddresses.length == 0) {
    alert("Please Select User(s)");
    return;
  }
 let t = localStorage.getItem("selectedCaseFromPatientPage");
    setLoader(true);
    var l2 = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN));
    let token = '';
    if (props.cases == 'AS1') {
        token = l2.as1_token;
    }
    else {
        token = l2.mako_token;
    }
    let use = emailAddresses;
    let patientScanId1 = 'a91beff5-75fd-4565-b55b-8bda5fc5e6cd'
    let patientScanId = props.selectedPatientId;
    let userMailIds = [];
    const dicomTags = '(0008,1030)';
    let head = {
      headers: {
        Authorization: "Bearer " + token
      }
    }
    // console.log("change in environment variable");
    let url2 = API.REACT_APP_MIB_REQUEST + '/patientscans/' + patientScanId + `/metadata`
    axios
      .get(url2, head)
      .then((data) => {
        if (data.data.additionalData && data.data.additionalData.jointReplacement && data.data.additionalData.jointReplacement.sharedScan && data.data.additionalData.jointReplacement.sharedScan.users) {
          userMailIds = data.data.additionalData.jointReplacement.sharedScan.users;
        }
        const concatId = use.concat(userMailIds);
        let data4 = {
          "jointReplacement":
          {
            "sharedScan": {
              "users": concatId
            }
          }
        }

        axios
          .patch(url2, data4, head)
          .then((data) => {
            setLoader(false);
            setEmailAddressesFnc([]);
      setInputAddress("");
            displaySnackBarMessage("success", "Shared Successfully");
          }).catch((error) => {
            setLoader(false);
            console.log(error);
            displaySnackBarMessage(
              "error",
              "Something went wrong. Please try again."
            );
          }
          );
      }).catch((error) => {
        setLoader(false);
        console.log(error);
        displaySnackBarMessage(
          "error",
          "Something went wrong. Please try again."
        );
      }
      );
  }

  const setEmailAddressesFnc = (emails) => {
    emailAddressesRef.current = emails;
    setEmailAddresses(emails);
  };

  const onChangeEmail = (event) => {
    setInputAddress(event.target.value);
  };

  const onSendEmail = () => {
    const validEmails = emailAddresses.every((email) => isValideEmail(email));
    if (validEmails) {
      const emailsTo = [...props.defaultTo, ...emailAddresses];
      props.onSend(emailsTo.join(";"));
    } else {
      props.displaySnackBarMessage(
        "error",
        "Invalid Email(s) exist(s). Please remove to proceed"
      );
    }
  };

  const handleDelete = (index) => {
    let addrss = [...emailAddresses];
    addrss.splice(index, 1);
    setEmailAddressesFnc(addrss);
  };

  const isValideEmail = (email) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return email.match(validRegex);
  };

  const onSelectParts = (selectedPart) => {
    setSelectedPart(selectedPart);
    return 'a1';
  };


  const filterOptions =
    (input) => {
      // if (input) {
      //   return candidate.value === customOptions[0].value;
      // }
      return true;
    };

  const demoValue = (selectedPart) => {
    setSelectedPart(selectedPart);
    return 'a1';
  };

  // let demo = ['a1', 'a2', 'a3'];

  return (
    <>
    <SimpleBackdrop open={loader} />
    <Snackbars
      severity={notification.severity}
      open={openSnakBar}
      handleClose={() => setOpenSnakBar(false)}
      message={notification.message}
    />
    <Dialog
      // style={ {maxWidth: "800px"}}
      // style={{zIndex:10000}}
      // style={{zIndex:1}}
      style={{ marginTop: "60px" }}
      maxWidth={false}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle>
        {"Emailss"}
        <CloseIcon
          style={{ float: "right", cursor: "pointer" }}
          onClick={props.onClose}
        />
       
      </DialogTitle> */}
      <DialogContent style={{ maxWidth: "1100px", minWidth: "1050px", height: "596px" }}>
        <DialogContentText id="alert-dialog-description">
          <Grid container xs={12} style={{ marginTop: "10px" }}>
            <Grid item xs={12} align="left">
              {/* <AutoComplete
           className= {classes.inputColor}
                        fnc={onSelectParts}
                        // onInputChange={Hospitallocationfunc}
                        // onInputChange={demoValue}
                        label={"Search"}
                        value={selectedPart}
                        options={props.datas}
                      
                         /> */}

              <FormControl className={classes.formControl} >
                <label style={{ fontSize: 12 }} className="controllabelCreate" >Search MPS User</label>

                {/* <Select
      options={props.datas}
  /> */}

                <ReactSelect
                  // isOptionSelected={isOptionSelected}
                  options={props.datas}
                  // isMulti
                  // closeMenuOnSelect={false}
                  // hideSelectedOptions={false}
                  components={{
                    Option
                  }}
                  // onChange={onChange}
                  onChange={(e) => handleUploadedByChange(e, Option)}

                  closeMenuOnSelect={false}
                  allowSelectAll={true}
                  // value={props.datas.email}
                  placeholder="Search MPS "
                  id="mps"
                  // filterOption={filterOptions}
                  value={selectedOptionCustom}
                  styles={customStyles}
                />
              </FormControl>


              {/* <TextField
                className={classes.textField}
                name="ss_email"
                placeholder={"Enter MPS name"}
                label={
                  <div className="controllabelCreate">{"Search MPS user"}</div>
                }
                onChange={onChangeEmail}
               
                value={inputAddress}
                InputLabelProps={{ shrink: true }}
              /> */}
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ marginTop: "280px" }}>
            <Grid item xs={11} align="left">
              <Typography className={classes.toAddress}>{"Shared with users: "}</Typography>
              <Divider />
            </Grid>

            <Grid item xs={11} align="left">
              {/* {props.defaultTo.map((val) => (
                <Chip label={val} color="primary" className={classes.chip} />
              ))} */}
            </Grid>

            <Grid item xs={11} align="left">
              {emailAddresses.map((val, index) => (
                <Chip
                  label={val}
                  onDelete={() => handleDelete(index)}
                  color={isValideEmail(val) ? "#d9d9d9" : "secondary"}
                  className={classes.chip}
                />
              ))}
            </Grid>
          </Grid>

        </DialogContentText>

        <Grid container xs={12} style={{ marginTop: "110px", justifyContent: 'center' }}>
          <Buttons
            Icon="Submit"
            toSave={true}
            fnc={shareId}
            // onClick={props.onClose}
            name="SHARE"
          />
          <Buttons Icon="Submit" fnc={props.onClose} name="CANCEL" style={{ marginLeft: '90px' }} />
        </Grid>

      </DialogContent>
    </Dialog>
    </>
  );
};

export default ShareDialog;
