import React from "react";
import CardViewDialog from "./Dialogs/CardViewDialog";
import { CALENDAR_STATUS } from "../../../constants";
import "../../../styles/style.css";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import CaseDetailsTootip from "./Dialogs/CaseDetailsTootip";

const MonthViewComponent = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const itemColor =
    props.data &&
    CALENDAR_STATUS.find((item) => item.label == props.data.status).color;

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      flexGrow: 1,
    },
    caseButton: {
      float: "right",
      marginTop: "4px",
      fontSize:"12px",
      height:"20px",
      zIndex: 999,
      backgroundColor: "#ffb400",
      border: "none",
      borderRadius: "4px",
      "&:focus": {
        background: '#FFB500!important'
      },
    },
  }));
  const classes = useStyles();
  const onHover = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setOpen(true);
  };
  const onHoverOut = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setOpen(false);
  };
  return (
    <>
      <div
        onClick={() => {props.viewSurgicalCase(props.data.externalId, props.data.sfId)}}
        onMouseOver={(e) => onHover(e)}
        onMouseOut={(e) =>  onHoverOut(e)}
        style={{
          height: "30px",
          padding: "0px",
          whiteSpace: "nowrap",
          borderRadius: "3px",
          borderBottom: `3px solid ${itemColor ? itemColor : ""}`,
          whiteSpace: "nowrap",
          backgroundColor: "white",
        }}
      >
        <p style={{ margin: "5px" }}>{props.data ? props.data.title : ""}</p>
        <strong style={{ marginTop: "6px" }}>
          {props.data ? props.data.hospitalName : ""}
        </strong>
      </div>
      {props.data && props.data.otherCases > 0 && (
        <Button
          onClick={() => {
            setDialogOpen(true);
          }}
          variant="contained"
          className={classes.caseButton}
          size="small"
        >
          {`+${props.data && props.data.otherCases} Case(s)`}
        </Button>
      )}
      <CardViewDialog
        title={props.data.startDate}
        dialogOpen={dialogOpen}
        setDialogOpen={() => setDialogOpen(false)}
        data={props.data.totalCases}
      />
      <CaseDetailsTootip
        itemColor={itemColor}
        data={props.data}
        open={open}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default MonthViewComponent;
