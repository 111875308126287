import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {displayStatusLabel} from '../../utils/tpr'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableHeader: {
    fontWeight: 'bold',
    padding: '14px',
    background: 'rgb(250, 250, 250)'
  },
  treatmentPlan: {
    border: '1px solid lightgray',
    display: 'grid',
    gridTemplateRows: '0fr 1fr'
  }
});
export default function TPRHistoryTable(props) {

  const classes = useStyles();
  const compare = (a, b) => {
    const c1 = a.tpr_name;
    const c2 = b.tpr_name;
    var comp = 0;
    if (c1 > c2)
      comp = 1;
    else if (c1 < c2)
      comp = -1;
    return comp;
  }
  const rows = props.relatedListHistory.TreatmentPlanRequest ? props.relatedListHistory && props.relatedListHistory.TreatmentPlanRequest &&
    props.relatedListHistory.TreatmentPlanRequest.get_tpr_list && props.relatedListHistory.TreatmentPlanRequest.get_tpr_list.sort(compare) : []
    
    rows.sort((a,b)=>{
      const lastNumberA = parseInt(a.tpr_name.split('-').pop());
      const lastNumberB = parseInt(b.tpr_name.split('-').pop());
      return lastNumberA - lastNumberB;
    });
    const onChangeTab = (row) => {
    props.onPress(2, row.tpr_id)
  }
  return (
    <div className={classes.treatmentPlan}>
      <div className={classes.tableHeader}>
        Treatment Plan Request
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>TPR ID</TableCell>
                <TableCell align="left">STATUS</TableCell>
                <TableCell align="left">PROCEDURE TYPE</TableCell>
                <TableCell align="left">SURGERY START DATE/TIME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows ? rows.map((row) => (
                <TableRow key={row.tpr_id} onClick={() => onChangeTab(row)} hover className={classes.tableRow}>
                  <TableCell component="th" scope="row">
                    <a> {row.tpr_name}</a>
                  </TableCell>
                  <TableCell align="left">{displayStatusLabel(row.tpr_status)}</TableCell>
                  <TableCell align="left">{row.tpr_procedure}</TableCell>
                  <TableCell align="left">{moment(props.caseDetails.surgeryDate).format('MM/DD/yyyy, hh:mm A')}</TableCell>
                </TableRow>
              )) : "There are no Treatment Plan Requests for this Case."}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
