import moment from 'moment';
import { tpr_intial_set, tpr_search_set, dispatchpatientInfo_set } from '../../redux/reducers/tprinfo'
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import { createOrUpdateTPR } from '../../graphql/mutations'
import React, { Suspense, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { ASYNC_STORAGE, AS1_STATUSES, API } from '../../constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getMakoMapping } from '../../redux/modules/makoMapping';

const AddTreatmentPlan = React.lazy(() => import('../../container/treatment-plan-form/astpr'));

export default function TPRDetails(props) {
    const dispatch = useDispatch();
    let Case_Data = null
    let salesRepArray;
    const { as1_token, mako_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
    const [surgnApprval, setSurgeonApproval] = React.useState(true);
    const [isAddTPR, setIsAddTPR] = React.useState(false);
    const [isTPRLoaded, setisTPRLoaded] = React.useState(false);
    const [error, setError] = React.useState(false);
    const makoMapping = mako_token => dispatch(getMakoMapping(mako_token))
    const TreatmentPlanRequest_INITIATE_SET = response => dispatch(tpr_intial_set(response));
    const TreatmentPlanRequest_SEARCH_SET = response => dispatch(tpr_search_set(response));
    const GetCaseById_Set = (response) => dispatch(userCase.setCaseDetail(response));
    const dispatchpatientinfo = searchvalues => dispatch(dispatchpatientInfo_set(searchvalues));
    const getStatus = (status) => {
        let as1Status = null
        if (props.caseDetails.mako) {
            return status
        }
        AS1_STATUSES.map((item) => { //do map on items because map returns a new array. It’s good practice to use .map than forEach in your case
            if (item.id === status) {
                as1Status = item.label;
            }
        });
        return as1Status
    }
    var url = API.RHS.TPR_URI;
    var header = {
        'Authorization': `Bearer ${props.caseDetails.mako ? mako_token : as1_token}`,
        'Content-Type': 'application/json'
    }

    const RHSPostCall = useCallback(async () => {

        const filterSalesRepArr = props.caseDetails.caseRepsData.filter(word => word.rep_type === 'Sales Rep')
        if (filterSalesRepArr.length === 1) {
            salesRepArray = filterSalesRepArr.map(function (obj) {
                return {
                    firstName: obj.first_name,
                    lastName: obj.last_name,
                    email: obj.email,
                    user_id: obj.user_id
                }
            });
        }
        const coveringrepArr = props.caseDetails.caseRepsData
        let othercoveringobj = [];
        coveringrepArr.map(item => {
            if (salesRepArray[0].firstName !== item.first_name) {
                const obj = {
                    firstName: item.first_name,
                    lastName: item.last_name,
                    email: item.email
                };
                othercoveringobj.push(obj);
            }
        });

        const data =
        {
            externalReference: `${props.caseDetails.caseId}-1`,
            otherContacts: othercoveringobj,
            inhouseSharingDateTime: "",
            needByDate: "",
        }
        if (props.caseDetails.mako) {
            const makoTreatment = {
                region: `${props.caseDetails.branch}`,
                treatment: {
                    hospitalName: props.caseDetails.hospitalName.split('-')[1].trim(),
                    hospitalReference: props.caseDetails.hospitalid__c,
                    surgeonName: {
                        firstName: props.caseDetails.surgeonFirstName,
                        lastName: props.caseDetails.surgeonLastName,
                        email: props.caseDetails.surgeonEmail
                    },
                    surgeryDate: (moment(props.caseDetails.surgeryDate).format('YYYY-MM-DD')),
                    operativeSide: props.caseDetails.left ? "Left" : props.caseDetails.right ? "Right" : "",
                },
                additionalData: {}
            }
            Object.assign(data, makoTreatment)
        }
        if (props.caseDetails.as1) {
            const as1AdditionalData = {
                treatment: {
                    surgeryDate: (moment(props.caseDetails.surgeryDate).format('YYYY-MM-DD')),
                },
                region: `${props.caseDetails.branch}`,
                additionalData: {
                    operativeSide: props.caseDetails.left ? "Left" : props.caseDetails.right ? "Right" : "",
                    surgicalCaseID: `${props.caseDetails.caseId}`,
                    branchID: props.caseDetails.branchSfId,
                    hospitalName: props.caseDetails.hospitalName.split('-')[1].trim(),
                    hospitalReference: props.caseDetails.hospitalid__c,
                    surgeonName: {
                        firstName: props.caseDetails.surgeonFirstName,
                        lastName: props.caseDetails.surgeonLastName,
                        email: props.caseDetails.surgeonEmail
                    },
                    surgeonApproval: surgnApprval ? "On" : "Off",
                    surgeonSignOffReceived: false
                }
            }
            Object.assign(data, as1AdditionalData)
        }
        props.caseDetails.mako ? url += `?workflow=Robotics` : url += `?workflow=KIB`
        var TPRIntiateConfig = {
            method: 'post',
            url: url,
            headers: header,
            data: data
        };

        try {
            await axios(TPRIntiateConfig)
                .then(async (response) => {
                    await dispatch(TreatmentPlanRequest_INITIATE_SET(response));
                    await dispatchpatientinfo(null)
                    await RHSGETCall(response.data.requestId, true);
                })
                .catch(error => {
                    setError(true);
                    let msg = "We've had trouble while creating Treatment Plan Request."
                    if (error.response.status === 401 || error.response.status === 403) {
                        msg = `Treatment Plan Request cannot be created; ${error.response.status}:Client cannot be authenticated`;
                    }
                    props.displaySnackBarMessage("error", msg)
                    return false
                })
        }
        catch (ex) {
            setError(true);
            props.displaySnackBarMessage("error", "We've had trouble while creating Treatment Plan Request.")
        }
        return true
    }, [])

    const RHSGETCall = useCallback(async (tprId, isrequired) => {
        var geturl = `${API.RHS.TPR_URI}/${tprId}`
        var TPRGetConfig = {
            method: 'get',
            url: geturl,
            headers: header,
            data: {}
        };
        try {
            await axios(TPRGetConfig)
                .then(response => {
                    dispatch(TreatmentPlanRequest_SEARCH_SET(response.data));
                    if (isrequired)
                        HeroukuPostCall(response.data).catch(e => console.error(e));
                })
                .then(async (response) => {
                    if (!isrequired) {
                        let retryCount = 0;
                        let intervalFunc = setInterval(() => {
                            retryCount++;
                            if (props.caseDetails && props.caseDetails.status === 'New' && retryCount < 3)
                                GetCaseById()
                            else
                                clearInterval(intervalFunc);
                        }, 7000)
                    }
                })
                .catch(error => {
                    setError(true);
                    let msg = "We've had trouble while fetching the Treatment Plan Request."
                    if (error.response.status === 401 || error.response.status === 403) {
                        msg = `Treatment Plan Request cannot be fetched; ${error.response.status}:Client cannot be authenticated`;
                    }
                    props.displaySnackBarMessage("error", msg)
                    return false
                })
        }
        catch (ex) {
            setError(true);
            props.displaySnackBarMessage("error", "We've had trouble while fetching the Treatment Plan Request.")
        }
    }, [])

    const HeroukuPostCall = useCallback(async (TPRGetData) => {
        const payload = {
            tpr_name: TPRGetData.externalReference,
            assigned_rep__c: props.caseDetails.userRep,
            primary_mps__c: salesRepArray && salesRepArray.length > 0 ? salesRepArray[0].user_id : null,
            covering_reps__c: props.caseDetails.coveringRep,
            hospital__c: props.caseDetails.hospitalid__c,
            surgeon__c: props.caseDetails.hospital_staffid__c,
            surgery_date__c: TPRGetData.treatment ? TPRGetData.treatment.surgeryDate : null,
            branch__c: props.caseDetails.branchSfId,
            status__c: getStatus(TPRGetData.metadata.commentedRequestState.requestState),
            external_tpr_id__c: TPRGetData.metadata.requestId,
            sfid: null,
            external_id__c: null,
            case_sfid: props.caseDetails.sfId,
            right__c: props.caseDetails.right,
            left__c: props.caseDetails.left,
            patient_scan_id__c: null,
            cancellation_reason__c: null,
            internal_comments__c: null,
            patient_info_id__c: null,
            need_by_date__c: null,
            baseplate__c: null,
            surgeon_approval__c: props.caseDetails.as1 && surgnApprval ? "On" : "Off",
            femoral_component__c: null

        }
        try {
            let res = await createOrUpdateTPR(payload)
            if (res.data) {
                let retryCount = 0;
                let intervalFunc = setInterval(() => {
                    retryCount++;
                    if (props.caseDetails && props.caseDetails.status === 'New' && retryCount <= 3)
                        GetCaseById()
                    else
                        clearInterval(intervalFunc);
                }, 7000)
            }
        } catch (ex) {
            props.displaySnackBarMessage("error", "We've had trouble saving your case to Salesforce.")
            console.error(ex);
        }
    }, [])

    const GetCaseById = async () => {

        const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}')
        let getCaseUrl = `${API.MASTER_DATA.GET_CASE_By_ID}`;
        if (props.caseDetails.externalId) {
            getCaseUrl = getCaseUrl + `?externalId=${props.caseDetails.externalId}`;
        }
        else {
            getCaseUrl = getCaseUrl + `?sfId=${props.caseDetails.sfId}`;
        }
        var config = {
            method: 'get',
            url: getCaseUrl,
            headers: { Authorization: access_token },
            data: {}
        }
        try {
            let response = await axios(config)
            GetCaseById_Set(response.data)
            Case_Data = response.data
        }
        catch (ex) {
            // alert("We've had trouble getting the Case Id.");
            props.displaySnackBarMessage("error", "We've had trouble getting the Case Id.")

        }
    }
    
    // On Mount - Get RHS Mako Mapping 
    useEffect(() => {
        if(props.caseDetails.mako)
            makoMapping(mako_token)
    },[])

    useEffect(() => {
        GetCaseById()
            .then(async (response) => {
                localStorage.setItem('comingFromCardView', false);
                if (Case_Data.status != "Cancelled") {
                    if (Case_Data.tprId.length === 0) {
                        // Adding TPR
                        await RHSPostCall()
                        setIsAddTPR(true); 
                        setisTPRLoaded(true);
                    }
                    else if (Case_Data.tprId.length === 1) {
                        const tprId = Case_Data.tprId[0].tpr_rhs_id
                        await RHSGETCall(tprId, false)
                        setisTPRLoaded(true);
                    }
                    else if (Case_Data.tprId.length > 1) {
                        const tprId = props.selectedRhsTprId
                        if (tprId !== undefined) {
                            await RHSGETCall(tprId, false)
                            setisTPRLoaded(true);
                        } else {
                            props.onPress(1, '')
                        }
                    } else {
                        alert("Multiple TPR Created")
                        setisTPRLoaded(false);
                    }
                } else if (Case_Data.status === "Cancelled" && Case_Data.tprId.length === 0) {
                    props.displaySnackBarMessage("warning", "No Treatment Plan exist against  this Surgical Case")
                    props.onPress(0, '')
                } else if (Case_Data.status === "Cancelled" && Case_Data.tprId.length === 1) {
                    const tprId = Case_Data.tprId[0].tpr_rhs_id
                    await RHSGETCall(tprId, false)
                    setisTPRLoaded(true);
                }
                else if (Case_Data.status === "Cancelled" && Case_Data.tprId.length > 1) {
                    const tprId = props.selectedRhsTprId
                    if (tprId !== undefined) {
                        await RHSGETCall(tprId, false)
                        setisTPRLoaded(true);
                    } else {
                        props.onPress(1, '')
                    }
                }
            })
    }, [Case_Data])

    return (
        <div>
            {error ? <></>
                : isTPRLoaded ? <div>
                    <Suspense fallback={<div style={{ display: 'grid', gridTemplateColumns: '7fr .5fr 7fr' }}><div /><CircularProgress style={{ color: 'gold' }} disableShrink /></div>}>
                        <AddTreatmentPlan isAddTPR={isAddTPR} selectedRhsTprId={props.selectedRhsTprId} caseDetails={props.caseDetails} displaySnackBarMessage={props.displaySnackBarMessage} />
                    </Suspense>
                </div> : <div style={{ display: 'grid', gridTemplateColumns: '7fr .5fr 7fr' }}><div /><CircularProgress style={{ color: 'gold' }} disableShrink /></div>}
        </div>
    );
}

