import React, { Component } from 'react';
import { getBranchSalesrep } from '../../graphql/queries'
import AutoComplete from '../../components/controls/AutoComplete'
import { connect } from 'react-redux'

class SalesRep extends Component {
    state = {
        SalesRep: [],
    }
    async componentDidMount() {
        await this.get_AllSalesrep();
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user.branchIds !== prevProps.user.branchIds) {
            await this.get_AllSalesrep();
        }
    }
    get_AllSalesrep = async () => {
        const { branchIds } = this.props.user
        const SalesRep = await getBranchSalesrep(branchIds)
        const SalesRep1 = SalesRep.get_user_by_branch.map(item=>{
            item.title=item.userName;
            item.id=item.userID;
            return item;
        })
        this.setState({
            loading: false,
            SalesRep:SalesRep1,
        });
    };
    render() {
        const {SalesRep}= this.state;
        return <AutoComplete label={this.props.label} isAstrikReq={this.props.isAstrikReq} fnc={this.props.onChangeSalesRep} options={this.props.options} value={this.props.value} Isdisabled={this.props.Isdisabled}/>
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(SalesRep);