import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
const useStyles = makeStyles((theme) => ({

  textField: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
}));

export default function InputTextField(props) {
  const classes = useStyles();
  const name = <div className="controllabelCreate"  >{props.label} {props.isAstrikReq ? <span style={{ color: "red" }} >*</span> : ''}</div>
  return (
    <FormControl className={classes.formControl} noValidate autoComplete="off">
      <TextField id="outlined-basic"
        name={props.name}
        inputProps={{ maxLength: props.maxLength }}
        placeholder={props.label}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={props.isdisabled}
        label={name} value={props.value} onChange={props.fnc} />
    </FormControl>
  );
}
