import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/controls/Dropdown";
import AutoComplete from "../../../components/controls/AutoComplete";
import { FormControl, Grid, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { ASYNC_STORAGE, PROCEDURES_OPTIONS } from "../../../constants";
import {
  getHospitals,
  getSurgeons,
} from "../../../graphql/queries";
import { makoProcedureType } from "../SurgeonPreferenceUtils";
import { useDispatch, useSelector } from "react-redux";
import { resetSurgicalPreference, resetTprPreference, setCombinationValue } from "../../../redux/modules/createSurgeonPreference";
import { Procedures } from "../../../components/services.js/Procedure";
import SurgicalPreference from "./SurgicalPreference";
import TprPreference from "./TprPreference";
import SimpleBackdrop from "../../../components/controls/Spinner";
import { formValueChanged } from "../../../redux/modules/formChangeLog";
import InputTextField from "../../../components/controls/Input";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  TextField: { marginTop: "20px" },
}));

const CreateOrUpdatePreference = (props) => {
  const classes = useStyles();
  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );
  const dispatch = useDispatch();
  const setCombination = (name, vaue) => dispatch(setCombinationValue(name, vaue));
  const valueChanged = (name) => dispatch(formValueChanged(name))
  const { isFormDirty } = useSelector(state => state.formChangeLog)
  const [loading, setLoading] = useState(true);
  const {combination} = useSelector(state => state.createSurgeonPreference)
  const [branchOptions, setBranchOptions] = useState([]);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [surgeonOptions, setSurgeonOptions] = useState([]);
  const [procedureOptions, setProcedureOptions] = useState([]);
  const [procedureTypeOptions, setSrocedureTypeOptions] = useState(makoProcedureType);
  const [procedureOptions1, setProcedureOptions1] = useState();

  useEffect(() => {
    const { branchIds } = current_user;
    // Get All Hospitals for User
    getHospitals()
    .then((res) => {
      const sortedByHospitalName = res.data.get_hospitals.sort(compareHospital)
      return sortedByHospitalName.map((item) => {
        item.title = item.hospitalName;
        item.id = item.hospitalId;
        return item;
      });
    })
    .then((res) => {
      res.map(r => r.hospitalId == combination.hospital.hospitalId ? setCombination('hospital', r) : null)
      setHospitalOptions(res);
    });

    //Get All Surgeons for User
    getSurgeons()
    .then((res) => {
      const sortedBySurgeonName = res.get_surgeons && res.get_surgeons.sort(compareSurgeon)
      return sortedBySurgeonName.map((item) => {
        item.title = item.surgeonName;
        item.id = item.surgeonID;
        return item;
      });
    })
    .then((res) => {
      res.map(r => r.surgeonID == combination.surgeon.surgeonID ? setCombination('surgeon', r) : null)
      setSurgeonOptions(res);
      setLoading(false);
    });

    //Load Procedures
    Procedures()
    .then(res => {
      setProcedureOptions(res)
      setProcedureOptions1(res)
    })

  }, []);

  const compareHospital = (a, b) => {
    const c1 = a.hospitalName.split(" - ");
    const c2 = b.hospitalName.split(" - ");
    var comp = 0;
    if (c1[1] > c2[1])
      comp = 1;
    else if (c1[1] < c2[1])
      comp = -1;
    return comp;
  }

  const compareSurgeon = (a, b) => {
    const c1 = a.surgeonName.split(" - ");
    const c2 = b.surgeonName.split(" - ");
    var comp = 0;
    if (c1[1] > c2[1])
      comp = 1;
    else if (c1[1] < c2[1])
      comp = -1;
    return comp;
  }

  const handleHospitalChangeDropdown = (hospitalObj) => {
    setCombination('hospital', hospitalObj ? hospitalObj : {})
    let _branchId = ''
    let _branchName = ''
    if(hospitalObj){
      _branchId = props.surgicalPreference ? hospitalObj.branchID : hospitalObj.mainBranch;
      _branchName = props.surgicalPreference ? hospitalObj.branchName : hospitalObj.mainBranchName
    }
    setCombination('branch', _branchId);
    setCombination('branchName', _branchName);
  };

  const handleSurgeonChangeDropdown = (surgeonObj) => {
    setCombination('surgeon', surgeonObj ? surgeonObj : {});
  };

  const handleProcedureChangeDropdown = (event) => {
    formValChanged("JR Pref Procedure")
    const id = event.target.value;
    const label = event.target.selectedOptions[0].label
    setCombination('procedure', {id, label});
    dispatch(resetSurgicalPreference());
  };

  const handleProcedureTypeChangeDropdown = (event) => {
    formValChanged("MAKO Pref Procedure Type")
    const id = event.target.value === "Please Select" ? '' : event.target.value ;
    const label = event.target.value;
    setCombination('procedure', {id, label});
    dispatch(resetTprPreference());
  };

  const formValChanged = (name) => {
    if(!isFormDirty){
      valueChanged(name)
    }
  }

  const onChangeNicknameValue = (event) => {
    setCombination('nickname',event.target.value)
  }

  return (
    <Grid container>
      <SimpleBackdrop open={loading} />
      <Grid container style={{ padding: "20px", background: "#fafafa" }}>
        <Grid item xs={3}>
          <AutoComplete
            isAstrikReq={true}
            isSurgeon={true}
            fnc={handleHospitalChangeDropdown}
            label={"Hospital"}
            value={combination.hospital}
            options={hospitalOptions}
            Isdisabled={props.isUpdate}
          />
        </Grid>
        <Grid item xs={3}>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="ddl">
                <div className="controllabelCreate">{"Branch"}</div>
              </InputLabel>
              <TextField
                id="outlined-basic"
                className={classes.TextField}
                placeholder={"Branch"}
                disabled
                value={combination.branchName}
              />
            </FormControl>
          </div>
          {/* <Dropdown
            inpage={true}
            label={"Branch"}
            options={branchOptions}
            value={combination.branch}
            Isdisabled={true}
          /> */}
        </Grid>
        <Grid item xs={3}>
          <AutoComplete
            isAstrikReq={true}
            page={"listing"}
            isSurgeon={true}
            fnc={handleSurgeonChangeDropdown}
            label={"Surgeon"}
            value={combination.surgeon}
            options={surgeonOptions}
            Isdisabled={props.isUpdate}
          />
        </Grid>
        <Grid item xs={3}>
          {props.surgicalPreference ? (
            <Dropdown
              inpage={true}
              label={"Procedure"}
              ctrllabel={"Please Select"}
              fnc={handleProcedureChangeDropdown}
              options={procedureOptions1}
              value={combination.procedure.id}
              Isdisabled={props.isUpdate}
            />
          ) : (
            <Dropdown
              inpage={true}
              label={"Procedure"}
              ctrllabel={""}
              fnc={handleProcedureTypeChangeDropdown}
              options={procedureTypeOptions}
              value={combination.procedure.id}
              Isdisabled={props.isUpdate}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <InputTextField label="Nickname" value={combination.nickname} fnc={onChangeNicknameValue}  />
        </Grid>
      </Grid>
      <div></div>
      <Grid container style={{ padding: "20px", background: "#fafafa" }}>
        {combination.procedure.id !== "" && props.surgicalPreference && (
          <SurgicalPreference
            displaySnackBarMessage={props.displaySnackBarMessage}
            valueChanged={formValChanged}
            isAstrikReq={isFormDirty}
          />
        )}
        {combination.procedure.id !== "" && props.tprPreference && (
          <TprPreference
            valueChanged={formValChanged}
            isAstrikReq={isFormDirty}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateOrUpdatePreference;
