
import { getSurgeonPreference } from '../../graphql/queries'
import { getSurgeonPreferenceByFilter } from '../../graphql/queries'

// import { createOrUpdateTPRFilter } from '../../graphql/mutations'
//ActionTypes

const LOAD = 'getSurgeonPreferences/LOAD'
const LOAD_SUCCESS = 'getSurgeonPreferences/LOAD_SUCCESS'
const LOAD_FAILURE = 'getSurgeonPreferences/LOAD_FAILURE'
const RESET_GET_SURGEON_PREFERENCES = 'getSurgeonPreferences/RESET_GET_SURGEON_PREFERENCES'

const initialState = {
    loaded: false,
    loading: false,
    surgeonList: [],
    surgeonListError: {}
}

export default function getSurgeonPref(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                surgeonList:action.payload,
                surgeonListError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                surgeonList: {},
                surgeonListError: action.payload
            }
        case RESET_GET_SURGEON_PREFERENCES:
            return {
                ...state,
                surgeonList: {},
                surgeonListError: {}
            }
        default:
            return state
    }
}

export function getSurgeonPreferences(userBranchId, filters) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        if(userBranchId && filters && Object.keys(filters).length ){
            getSurgeonPreferenceByFilter(userBranchId, filters)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data.get_surgeon_pref
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
        } else {
            getSurgeonPreference(userBranchId)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data.get_surgeon_pref
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
        }
    }
}

export function resetGetSurgeonPreferences() {
    return dispatch => {
        dispatch({
            type: RESET_GET_SURGEON_PREFERENCES
        })
    }
}