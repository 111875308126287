import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({  
    saveButton: {
    margin: '8px',
width:"50%",
    background: '#FFB500',
    color: '#000',
    fontWeight: 'bold',
    "&:hover": {
      background: '#FFD271',
      color: '#000'
    },
    "&:focus": {
      background: '#FFB500!important',
      color: '#000'
    },
  }
}));

export default function ButtonControl(props) {
    const classes = useStyles();
  
    return (
      <div>
        <Button
          variant="contained"
          autoFocus={props.autoFocus}
          className={props.toClone ? classes.cloneButton : props.toSubmit ? classes.submitButton : props.toSave ? classes.saveButton : props.toNavigate ? classes.navigateButton :classes.button}
          disabled={props.disabled}
          onClick={props.fnc}
          startIcon={<props.Icon />}>
          {props.name}
  
        </Button>
      </div>
    );
  }
  