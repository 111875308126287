import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Upload from './Upload'
import Parts from './Parts'
import Pricing from './Pricing'
import SurgerySheet from './SurgerySheet'
import { tabChangeToIndex } from '../../redux/modules/formChangeLog';
import { useDispatch, useSelector } from 'react-redux';
import MAKOTracking from './MAKOTracking'
import CASETracking from './CASETracking';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        'box-shadow': '0px !important',
        // width: 100,
    },
    camelCase: {
        textTransform: 'none',
        padding: "0px",
        'font-family': 'Arial, Helvetica, sans-serif',
        color: '#000',
        'font-size': '14px',
    },
    camelCaseTPR: {
        textTransform: 'none',
        padding: "0px",
        'font-family': 'Arial, Helvetica, sans-serif',
        color: '#000',
        'font-size': '14px',
        display: 'none',
    },
    upload: {

    }

}));

export default function AddPartsImagesSurgerySheet(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch()
    const setTabChangeToIndex = (index) => dispatch(tabChangeToIndex(index, 2))
    const { isFormDirty, tabChangeIndex, tabSwitchConfirm, tabLevel } = useSelector(state => state.formChangeLog)
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if(tabLevel === 2 && isFormDirty && tabSwitchConfirm){
          setValue(tabChangeIndex);
        }
    }, [tabSwitchConfirm])
    
    const handleChange = (event, newValue) => {
        if(isFormDirty){
            setTabChangeToIndex(newValue)
        } else {
            setValue(newValue);
        }
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>

            <AppBar position="static" color="default" className="customTab">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs"
                    padd>
                    <Tab className={classes.camelCase} label="Parts" {...a11yProps(0)} />
                    <Tab className={classes.camelCase} label="Pricing" {...a11yProps(1)} />
                    <Tab className={classes.camelCase} label="Uploads" {...a11yProps(2)} />
                    {props && props.caseDetails && props.caseDetails.mako === true ?
                        <Tab className={classes.camelCase} label="Mako Tracking" {...a11yProps(3)} /> : 
                        <Tab className={classes.camelCase} label="Case Tracking" {...a11yProps(3)}/>
                    }
                    <Tab className={classes.camelCase} label="Surgery Sheet" {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <Parts caseDetails={props.caseDetails} displaySnackBarMessage={props.displaySnackBarMessage}/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <Pricing caseDetails={props.caseDetails} displaySnackBarMessage={props.displaySnackBarMessage} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction} className={classes.upload}>
                <Upload caseDetails={props.caseDetails} displaySnackBarMessage={props.displaySnackBarMessage} />
            </TabPanel>
            {props && props.caseDetails && props.caseDetails.mako === true ?
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <MAKOTracking caseDetails={props.caseDetails} handleChangeIndex={handleChangeIndex} displaySnackBarMessage={props.displaySnackBarMessage} />
                </TabPanel> : 
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <CASETracking caseDetails={props.caseDetails} handleChangeIndex={handleChangeIndex} displaySnackBarMessage={props.displaySnackBarMessage} />
                </TabPanel>
            }
            <TabPanel value={value} index={4} dir={theme.direction}>
                <SurgerySheet caseDetails={props.caseDetails} handleChangeIndex={handleChangeIndex} displaySnackBarMessage={props.displaySnackBarMessage} />
            </TabPanel>
        </div>
    );

}