import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
//import { makeStyles,useTheme } from '@material-ui/core/styles';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
}));


export default function DatePicker(props) {
  const classes = useStyles();
  const isAstrikReq = props.isAstrikReq;
  const name = <div className="controllabelCreate" >{props.label} {isAstrikReq ? <span style={{ color: "red" }} >*</span> : ''}</div>
  return (
    <FormControl className={classes.container} noValidate>
      <TextField
        id="date"
        label={name}
        type="date"
        value={props.value}
        disabled={props.isdisabled}
        className={props.value ? classes.textField : classes.textField + ' datetimePlaceholder'}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => props.fnc(event)}
        InputProps={{ inputProps: 
          { 
            max: props.isMaxDateReq ? moment(new Date()).format("YYYY-MM-DD") : "",
            min: props.minDate ? moment(new Date(props.minDate)).format("YYYY-MM-DD") : "" 
          }}}
      />
    </FormControl>
  );
}
