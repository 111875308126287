import axios from 'axios'
import { API } from '../../constants';

//ActionTypes

const LOAD = 'listTreatmentPlanRequest/LOAD'
const LOAD_SUCCESS = 'listTreatmentPlanRequest/LOAD_SUCCESS'
const LOAD_FAILURE = 'listTreatmentPlanRequest/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'listTreatmentPlanRequest/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    listOfAllTreatmentPlans: {},
    listOfAllTreatmentPlansError: ''
}

export default function listTreatmentPlanRequest(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                listOfAllTreatmentPlans: action.payload,
                listOfAllTreatmentPlansError: ''
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: true,
                listOfAllTreatmentPlans: {},
                listOfAllTreatmentPlansError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                listOfAllTreatmentPlans: {},
                listOfAllTreatmentPlansError: ''
            }
        default:
            return state
    }
}

export function getListOfTreatmentPlanRequets(listTreatmentPlanRequest) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        const { caseType, makoToken, as1Token, requestState, dateTimeFrom, dateTimeTo, branch, procedure, pageIndex, regions, externalReference } = listTreatmentPlanRequest
    
        let _regions = regions === 'All' ? '' : regions;
        let url
        const urlPath = API.RHS.LIST_ALL_TREATMENT_PLAN_REQUESTS_URI;
        if (dateTimeTo === undefined || dateTimeFrom === undefined) {
            return;
        }
        if(makoToken) {
            if(_regions){
                if(_regions === 'Mako R&D Branch'){
                    url = `${urlPath}?requestState=${requestState}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}&regions=Mako R%26D Branch&pageIndex=${pageIndex ? pageIndex : 0}&pageSize=${100}`
                } else {
                    url = encodeURI(`${urlPath}?requestState=${requestState}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}&regions=${_regions}&pageIndex=${pageIndex ? pageIndex : 0}&pageSize=${100}`)
                }
            } if(externalReference){
                url = encodeURI(`${urlPath}?pageIndex=${pageIndex ? pageIndex : 0}&externalReference=${externalReference}`)
            } 
            else {
                url = encodeURI(`${urlPath}?requestState=${requestState}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}&pageIndex=${pageIndex ? pageIndex : 0}&pageSize=${100}`)
            }
        } else if(as1Token){
            if(_regions){
                if(_regions === 'Mako R&D Branch'){
                    url = `${urlPath}?requestState=${requestState}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}&regions=Mako R%26D Branch&pageIndex=${pageIndex ? pageIndex : 0}&pageSize=${100}`
                } else {
                    url = encodeURI(`${urlPath}?requestState=${requestState}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}&regions=${_regions}&pageIndex=${pageIndex ? pageIndex : 0}&pageSize=${100}`)
                }
            } if(externalReference){
                url = encodeURI(`${urlPath}?pageIndex=${pageIndex ? pageIndex : 0}&externalReference=${externalReference}`)
            } 
            else {
                url = encodeURI(`${urlPath}?requestState=${requestState}&dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}&pageIndex=${pageIndex ? pageIndex : 0}&pageSize=${100}`)
            }
        }
        const headers = {headers: {
            Authorization: caseType === 'MAKO' ? "Bearer " + makoToken : "Bearer " + as1Token,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Custom-Header": "value",
        }}
        axios
            .get(url, headers)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                let errMsg;
                if (error.response.status === 401 || error.response.status === 403) {
                    errMsg = `Treatment Plan Request list cannot be fetched; ${error.response.status}:Client cannot be authenticated`;
                }
                dispatch({
                    type: LOAD_FAILURE,
                    payload: errMsg
                })
            })
    }
}

export function resetTprList () {
    return dispatch => {
        dispatch({
            type: RESET_UPLOAD_TREATMENT_PLAN
        })
    }
}