import React, { useEffect, useCallback } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dropdown from "../../components/controls/Dropdown";
import Buttons from "../../components/controls/Button";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import { getBranchSalesrep, getSubInventory, getShipToValues } from "../../graphql/queries";
import MultilineTextFields from "../../components/controls/MultilineText";
import { ACTION_CREATORS as userCase } from "../../redux/reducers/cases";
import moment from "moment";
import InputTextField from "../../components/controls/Input";
import SalesRep from "../../components/new-case-form/SalesRep";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  SHIPTO,
  REPLENISH,
  ASYNC_STORAGE,
  API,
  IMAGE_FETCH_URL,
  TPR_STATUS,
} from "../../constants";
import { updateSurgerySheet } from "../../graphql/mutations";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import getCaseById from "../../utils/calendar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";
import EmailDialog from "./EmailDialog";
import {
  formValueChanged,
  resetFormChangeIndicator,
} from "../../redux/modules/formChangeLog";
import Switch from "@material-ui/core/Switch";
import { setRHSStatus } from "../../utils/tpr";
import { ModelNPricing, checkPricingForUsages, createPayloadForAPI } from "./ModelNPricing"
import { ACTION_CREATORS as tab } from '../../redux/reducers/tab';
import { TextField } from "@material-ui/core";
import Modal from "./Modal"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    flexGrow: 1,
    background: "#FAFAFA",
  },
  formControl: {
    margin: "25px 15px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#FFB500",
    },
    "&$checked + $track": {
      backgroundColor: "#FFD271",
    },
  },
  checked: {},
  track: {
    backgroundColor: "grey",
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export default function SurgerySheet(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const GetCaseById_Set = (response) =>
    dispatch(userCase.setCaseDetail(response));
  const SET_TAB = (response) => dispatch(tab.setTab(response));
  const surgicalCaseDetails = useSelector(
    (state) =>
      state.cases && state.cases.currentState && state.cases.currentState
  );
  const valueChanged = (name) => dispatch(formValueChanged(name));
  const resetChangeLogIndicator = () => dispatch(resetFormChangeIndicator());
  const { isFormDirty } = useSelector((state) => state.formChangeLog);
  const [loader, setLoader] = React.useState(false);
  const [branchesValue, setbranchesValue] = React.useState(
    surgicalCaseDetails.branchSfId
  );
  const [searchfromSetOptions, setSearchfromSetOptions] = React.useState([]);
  const [partsOptions, setPartsOptions] = React.useState([]);
  const [qtyOptions, setQtyOptions] = React.useState([]);
  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );
  const [isSurgerySheetPDFVisible, SetIsSurgerySheetPDFVisible] =
    React.useState(false);
  const [showPrice, setShowPrice] = React.useState(false);
  const [methodName, setMethodName] = React.useState(
    showPrice ? "invoiceWithPrice" : "invoiceWithoutPrice"
  );
  const [internalComments, setInternalComments] = React.useState(
    props.caseDetails.internalComment
  );
  const [hospitalSignatureLink, setHospitalSignatureLink] = React.useState("");
  const [repSignatureLink, setrepSignatureLink] = React.useState("");
  const [hospitalNameValue, setHospitalNameValue] = React.useState(
    props.caseDetails.signatureHospitalRepName
  );
  const [salesRepsValue, setSalesRepsValue] = React.useState({});
  const [externalComments, setExternalComments] = React.useState(
    props.caseDetails.externalComments
  );
  const [salesRepsOptions, setsalesRepsOptions] = React.useState({});
  const [disableControl, setDisableControl] = React.useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(true);
  const [isMultipleContract, setIsMultipleContract] = useState(false);
  const [newCaseData, setNewCaseData] = useState([]);
  const [customInventoryValues, setCustomInventoryValues] = useState([]);
  const [shipMethodValues, setShipMethodValues] = React.useState([]);
  const [shipPriorityValues, setShipPriorityValues] = React.useState([]);
  const [replenishToValues, setReplenishToValues] = React.useState([]);
  const [customReplenish, setCustomReplenish] = React.useState([]);
  const [customReplenish2, setCustomReplenish2] = React.useState([]);
  const [customShipTo, setCustomShipTo] = React.useState([]);

  const compare = (a, b) => {
    const c1 = a.userName;
    const c2 = b.userName;
    var comp = 0;
    if (c1 > c2) comp = 1;
    else if (c1 < c2) comp = -1;
    return comp;
  };

  const getCases = async () => {
    const res = await getCaseById(
      props.caseDetails.externalId,
      props.caseDetails.sfId
    ).then((res) => {
      GetCaseById_Set(res.data);
      userCase.setCaseDetail(res.data);
      setLoader(false);
    });
  };

  const conditionCheck = async (caseData, isMultiContracts) => {
    let nonPriceProducts = checkPricingForUsages(caseData);
    if (isMultiContracts && caseData.changedetectflag__c) {
      props.displaySnackBarMessage(
        "error",
        "Surgery date changed. Please select any contract on pricing tab."
      );
      nonPriceProducts.push("true");
    } else if (nonPriceProducts.length > 0 && isMultiContracts) {
      props.displaySnackBarMessage(
        "error",
        "Multiple cap contracts are found, Please select any contract on pricing tab."
      );
    } else if (nonPriceProducts.length > 0) {
      props.displaySnackBarMessage(
        "error",
        "Pricing not available for part/s - " +
        nonPriceProducts.toString() + ". Please go to parts tab and delete the invalid part/s to proceed."
      );
      return false;
    }
    return nonPriceProducts;
  }
  /**MP: To check pricing for the products **/
  const priceCheck = async () => {
    setLoader(true);
    const modelNData = await ModelNPricing(
      props.caseDetails,
      props.caseDetails.capPrice,
      "",
      "autoSave"
    );

    if (modelNData?.data?.[0]?.status === "E") {
      props.displaySnackBarMessage("error", modelNData?.data?.[0].message);
      return false;
    }

    if (props.caseDetails.capPrice) {
      if (modelNData?.data && modelNData?.data.constructPrograms.length === 0 && modelNData?.data?.constructPrice.length === 0) {
        props.displaySnackBarMessage('error', `There is no cap data found, List price is applied.`)
      }
    }

    const isMultiContracts = modelNData?.data?.constructPrograms?.length > 1;
    setIsMultipleContract(isMultiContracts);

    await createPayloadForAPI(props.caseDetails, modelNData.data);

    if (isMultiContracts) {
      return true;
    }

  };

  useEffect(() => {
    setLoader(true);
    (async () => {
      await getCases();
    })()

  }, [])

  useEffect(() => {
    customShipToValues();
    getSubInventoryData();
    setDisableControl(props.caseDetails.status === "Completed");
    setTimeout(() => {
      setOpenTooltip(false);
    }, 5000);
    return () => {
      resetChangeLogIndicator();
    };
  }, [props.caseDetails]);

  useEffect(() => {
    const qtyArray = generateQtyOptions();
    setQtyOptions(qtyArray);

    getBranchSalesrep(branchesValue)
      .then((res) => {
        const sortedUserByBranch = res.get_user_by_branch.sort(compare);
        return sortedUserByBranch.map((item) => {
          item.title = item.userName;
          item.id = item.userID;
          return item;
        });
      })
      .then((res) => {
        setsalesRepsOptions(res);
        if (props.caseDetails.signatureSalesRepName != "") {
          const userObj = res.find(
            (users) =>
              users.userName === props.caseDetails.signatureSalesRepName
          );
          setSalesRepsValue(userObj);
        }
      });
    const repSignature = props.caseDetails.image
      ? props && props.caseDetails.image && props.caseDetails.image.find(img => img.signature === 'rep_Sig')
      : null;

    if (repSignature) {
      setrepSignatureLink(`${IMAGE_FETCH_URL}${repSignature?.imageurl?.split(',')[0]}`);
    }
    const hospitalSignature = props.caseDetails.image
      ? props && props.caseDetails.image && props.caseDetails.image.find(img => img.signature === 'hosPital_Sig')
      : null;

    if (hospitalSignature) {
      setHospitalSignatureLink(
        `${IMAGE_FETCH_URL}${hospitalSignature?.imageurl?.split(',')[0]}`
      );
    }
  }, [branchesValue]);
  const onChangeInternalComments = (event) => {
    if (!isFormDirty) {
      valueChanged(event.target.name);
    }
    setInternalComments(event.target.value);
  };
  const onChangeExternalComments = (event) => {
    if (!isFormDirty) {
      valueChanged(event.target.name);
    }
    setExternalComments(event.target.value);
  };
  const onChangeSalesRep = (SalesRepName) => {
    if (!isFormDirty) {
      valueChanged("SalesRepName");
    }
    setSalesRepsValue(SalesRepName);
  };
  const onChangeHospitalName = (event) => {
    if (!isFormDirty) {
      valueChanged(event.target.name);
    }
    setHospitalNameValue(event.target.value);
  };
  const onChangeReplenish = (event) => {
    alert(event.target.value);
  };
  const onChangeShipto = (event) => {
    alert(event.target.value);
  };
  const generateQtyOptions = () => {
    return Array.from(new Array(100), (val, index) => ({
      value: index + 1,
      label: index + 1,
    }));
  };

  async function customShipToValues() {
    await getShipToValues("CustomObject", "casesusage__c", "false").then((res) => {
      setShipPriorityValues(res.get_ship_to_values.ship_priority_values);
      setShipMethodValues(res.get_ship_to_values.ship_method_values);
    }).catch(error => {
      setLoader(false);
      console.log(error);
    })
  }

  async function getSubInventoryData() {
    setLoader(true);
    await getSubInventory(props.caseDetails.hospitalid__c, props.caseDetails.branchSfId).then((res) => {
      let inventoryArr = [{ id: "Home", label: "Home", "labeltoShow": "yess" }];
      let inventoryArrShipTo = [{ id: "Home", label: "Home" }];
      let hospital = [];
      let branch = [];
      let subBranch = [];
      let replenishToHospital = [];
      let replenishToBranch = [];
      let replenishToSubBranch = [];
      let res2;
      for (let i = 0; i < res.get_Sub_Inventory.length; i++) {
        if (res.get_Sub_Inventory[i].subinvdescofHospital != null) {
          hospital.push({ id: res.get_Sub_Inventory[i].subinvnameofHospital, label: res.get_Sub_Inventory[i].subinvdescofHospital });
          replenishToHospital.push({ id: res.get_Sub_Inventory[i].oraclesitenumber, label: res.get_Sub_Inventory[i].subinvdescofHospital });
        }
        if (res.get_Sub_Inventory[i].subinvdescofbranch != null) {
          branch.push({ id: res.get_Sub_Inventory[i].subinvnameofbranch, label: res.get_Sub_Inventory[i].subinvdescofbranch });
          replenishToBranch.push({ id: res.get_Sub_Inventory[i].AddressERPCode, label: res.get_Sub_Inventory[i].subinvdescofbranch });
        }
        if (res.get_Sub_Inventory[i].subinvdescofSubbranch != null) {
          subBranch.push({ id: res.get_Sub_Inventory[i].subinvnameofSubbranch, label: res.get_Sub_Inventory[i].subinvdescofSubbranch });
          replenishToSubBranch.push({ id: res.get_Sub_Inventory[i].ERPCodeofSubBranch, label: res.get_Sub_Inventory[i].subinvdescofSubbranch });
        }
      }
      let concatArray = hospital.concat(branch);

      concatArray = concatArray.concat(subBranch);
      let concatArray2 = replenishToHospital.concat(replenishToBranch);

      concatArray2 = concatArray2.concat(replenishToSubBranch);
      inventoryArr = inventoryArr.concat(concatArray);
      let e = inventoryArr;
      let shipToCustom = [{ id: "Home", label: "Home" }];
      let replenishToCustom = [{ id: "Home", label: "Home" }];
      replenishToCustom = replenishToCustom.concat(concatArray2);
      shipToCustom = shipToCustom.concat(concatArray2);
      replenishToCustom.push({ id: "HospitalOwned", label: "Hospital Owned" });
      replenishToCustom.push({ id: "NationalLoaner", label: "National Loaner" });
      replenishToCustom.push({ id: "SalesRepInventory", label: "Sales Rep Inventory" });
      replenishToCustom.push({ id: "Other", label: "Other" });
      inventoryArr.push({ id: "HospitalOwned", label: "Hospital Owned" });
      inventoryArr.push({ id: "NationalLoaner", label: "National Loaner" });
      inventoryArr.push({ id: "SalesRepInventory", label: "Sales Rep Inventory" });
      inventoryArr.push({ id: "Other", label: "Other" });
      const t = inventoryArr;
      setCustomReplenish(inventoryArr);
      setCustomReplenish2(replenishToCustom);
      shipToCustom.push({ id: "HFPU", label: "HFPU" });
      shipToCustom.push({ id: "HospitalOwned", label: "Hospital Owned" });
      shipToCustom.push({ id: "NationalLoaner", label: "National Loaner" });
      shipToCustom.push({ id: "SalesRepInventory", label: "Sales Rep Inventory" });
      shipToCustom.push({ id: "Other", label: "Other" });
      setCustomShipTo(shipToCustom);
      setLoader(false);

    }).catch(error => {
      setLoader(false);
      console.log(error);
    })
  }

  function getCaseDataCall() {
    return new Promise(resolve => {
      setTimeout(() => {
        getCaseById(
          props.caseDetails.externalId,
          props.caseDetails.sfId
        ).then((res) => {
          userCase.setCaseDetail(res.data);
          resolve(res.data);
        })
      }, 5000);
    });
  }

  const handleSubmitUsage = async () => {
    /*let images =
      props.caseDetails.image &&
      props.caseDetails.image.find((img) => img.signature === "Images");
    if (!images) {
      props.displaySnackBarMessage("error", "Please upload image");
      return;
    } commeting as part of US-369113*/

    if ((props.caseDetails.mako) && (!props.caseDetails.rob__c || !props.caseDetails.sales_rep_present__c || !props.caseDetails.coveringuser_present__c)) {
      props.displaySnackBarMessage("error", "Please complete Mako Tracking.");
      return;
    } else if((!props.caseDetails.mako) && (!props.caseDetails.sales_rep_present__c || !props.caseDetails.coveringuser_present__c)) {
      props.displaySnackBarMessage("error", "Please complete Case Tracking.");
      return;
    }

    //To check pricing for parts
    if (props.caseDetails.usages.length > 0) {
      const pc = await priceCheck();
      //To check invalid part
      if (pc === false) {
        setLoader(false);
        return;
      }
      const caseD = await getCaseDataCall();
      const nonPriceProducts = await conditionCheck(caseD, pc);

      if (nonPriceProducts && nonPriceProducts.length > 0) {
        props.handleChangeIndex(1);
        return;
      } else if (nonPriceProducts === false) {
        setLoader(false);
        return;
      }

    }

    try {
      const result = await updateSurgerySheet(
        props.caseDetails.sfId,
        props.caseDetails.externalId,
        internalComments,
        externalComments,
        hospitalNameValue,
        typeof salesRepsValue === "object"
          ? salesRepsValue.title
          : salesRepsValue,
        "Completed"
      );
      if (
        result &&
        props.caseDetails.as1 &&
        props.caseDetails.tprId &&
        props.caseDetails.tprId[0] &&
        (props.caseDetails.tprId[0].tpr_status === "ShippedToSales" ||
          props.caseDetails.tprId[0].tpr_status === "Shipped To Sales")
      ) {
        const tprRhsId = props.caseDetails.tprId[0].tpr_rhs_id;
        const { as1_token } = JSON.parse(
          localStorage.getItem(ASYNC_STORAGE.TOKEN) || "{}"
        );
        await setRHSStatus(tprRhsId, "Completed", as1_token);
      }
      let retryCount = 0;
      let intervalFunc = setInterval(() => {
        retryCount++;
        if (
          props.caseDetails &&
          props.caseDetails.status !== "Completed" &&
          retryCount < 3
        )
          getCaseById(
            props.caseDetails.externalId,
            props.caseDetails.sfId
          ).then((res) => {
            GetCaseById_Set(res.data);
          });
        else clearInterval(intervalFunc);
      }, 7000);
      props.displaySnackBarMessage(
        "success",
        "Surgery sheet information submitted successfully."
      );
      setDisableControl(true);
      resetChangeLogIndicator();
      setLoader(false);
      return result.data;
    } catch (error) {
      props.displaySnackBarMessage(
        "error",
        "We are unable to save your Surgery Sheet Information in Heroku, please try again."
      );
      setLoader(false);
    }
  };
  const handleSaveUsage = async () => {
    try {
      const result = await updateSurgerySheet(
        props.caseDetails.sfId,
        props.caseDetails.externalId,
        internalComments,
        externalComments,
        hospitalNameValue,
        typeof salesRepsValue === "object"
          ? salesRepsValue.title
          : salesRepsValue,
        props.caseDetails.status
      );
      await getCaseById(
        props.caseDetails.externalId,
        props.caseDetails.sfId
      ).then((res) => {
        GetCaseById_Set(res.data);
      });
      props.displaySnackBarMessage(
        "success",
        "Surgery sheet information saved successfully."
      );
      resetChangeLogIndicator();
      setLoader(false);
      return result.data;
    } catch (error) {
      setLoader(false);
      props.displaySnackBarMessage(
        "error",
        "We are unable to save your Surgery Sheet Information in Heroku, please try again."
      );
    }
  };
  const handleShowPDF = () => {
    SetIsSurgerySheetPDFVisible(true);
  };
  const handleCloseModal = () => {
    SetIsSurgerySheetPDFVisible(false);
  };

  const handleEmail = async (emailToAddresses) => {
    setEmailDialog(false);
    setLoader(true);
    debugger;
    const caseItem = props.caseDetails;
    let surgeryDate = moment(caseItem.surgeryDate);

    const withOrWithoutPriceString =
      methodName === "invoiceWithPrice"
        ? "_SurgSheet_WPrice"
        : "_SurgSheet_WoPrice_";

    const subjectLine = `${caseItem.hospitalName ? caseItem.hospitalName : ""
      } / ${caseItem.surgeonLastName} / ${surgeryDate ? surgeryDate.format("MM/DD/YYYY") : ""
      } / ${caseItem.patientId ? caseItem.patientId : ""} / ${caseItem.procedures
        ? caseItem.procedures.map((procedure) => procedure.name).join(",")
        : ""
      }`;

    try {
      const response = await fetch(`${API.MASTER_DATA.EMAIL_SERVICE}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          caseId: caseItem.sfId,
          withOrWithoutPriceString,
          showHidePriceFlag: showPrice,
          toAddresses: emailToAddresses,
          subject: subjectLine,
          htmlBody: "Please find attached pdf",
        }),
      });
      const responseJson = await response.json();
      props.displaySnackBarMessage("success", responseJson.message);
    } catch (error) { }
    SetIsSurgerySheetPDFVisible(false);
    setLoader(false);
  };

  function getValueDummy6(value) {
    let c1 = value;
    c1 = shipPriorityValues.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  function getValueDummy7(value) {
    let c1 = value;
    c1 = shipMethodValues.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  function getValueDummy(value) {
    let c1 = value;
    c1 = customReplenish.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  function getValueDummy3(value) {
    let c1 = value;
    c1 = customReplenish2.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  function getValueDummy2(value) {
    let c1 = value;
    c1 = customReplenish2.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  function getValueDummy4(value) {
    let c1 = value;
    c1 = customReplenish2.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  function getValueDummy5(value) {
    let c1 = value;
    c1 = customReplenish2.map(data => {
      if (data.id == value) {
        return data.label;
      }
    })
    return c1;
  }

  const handleToggleChange = (event) => {
    const nonPriceProducts = checkPricingForUsages(props.caseDetails);
    if (event.target.checked === true && nonPriceProducts && nonPriceProducts.length > 0) {
      props.displaySnackBarMessage(
        "error",
        "Pricing is not applied. Please go to the pricing tab to retrieve pricing."
      );
      setShowPrice(false);
      return false;
    }
    setShowPrice(event.target.checked);
    setMethodName(
      event.target.checked ? "invoiceWithPrice" : "invoiceWithoutPrice"
    );
  };


  const currentUserEmail = current_user.email;
  const salesRepEmail = current_user.sales_rep_email;
  const csrEmails = props.caseDetails.csr_emails__c?.split(',');

  const defaultEmailAddress = [
    currentUserEmail,
    ...(currentUserEmail === salesRepEmail ? [] : [salesRepEmail]),
    ...(csrEmails?.length > 0 ? [csrEmails[0]] : []),
  ];

  return loader ? (
    <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
  ) : (
    <div>
      <Grid container item xs={12} spacing={0} className="backgroundgreycolor" id="parts3">
        <Grid
          item
          xs={12}
          sm={12}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <span style={{ "font-weight": "bold", fontSize: "14px", paddingLeft: "16px" }}>Surgery Sheet</span>
          </CardContent>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <div style={{ padding: "10px" }}>
              <TableContainer
                component={Paper}
                style={{ border: "1px solid lightgrey" }}
              >
                <Table aria-label="simple table">
                  <TableHead id="parts2">
                    <TableRow>
                      <TableCell>Part No - Name</TableCell>
                      <TableCell align="center">Inventory</TableCell>
                      <TableCell align="left">Wasted</TableCell>
                      <TableCell align="left">DNR</TableCell>
                      <TableCell align="left">Revision</TableCell>
                      <TableCell align="left">Replenish to</TableCell>
                      <TableCell align="left">Ship to</TableCell>
                      <TableCell align="left">Lot Y/N</TableCell>
                      <TableCell align="left">Lot #</TableCell>
                      <TableCell align="left">Qty</TableCell>
                      <TableCell align="left">ARO Reference Number</TableCell>
                      <TableCell align="left">Shipping Method</TableCell>
                      <TableCell align="left">Shipping Priority</TableCell>
                      <TableCell align="left">DNS</TableCell>
                    </TableRow>
                  </TableHead>
                  {surgicalCaseDetails &&
                    surgicalCaseDetails.usages.map((row) => (
                      <TableRow id="parts">
                        <TableCell size="small" component="th" scope="row">
                          {row.part_name}
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Dropdown
                            label={""}
                            ctrllabel={(row.inventory__c == null || row.inventory__c == "") ? "Select" : getValueDummy(row.inventory__c)}
                            value={row.inventory__c}
                            fnc={onChangeShipto}
                            Isdisabled={true}
                            options={SHIPTO}
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                checked={row.wasted}
                                onChange={""}
                                name="wasted"
                                value="wasted"
                                disabled={true}
                                inputProps={{
                                  "aria-label": "checkbox with default color",
                                }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                checked={!row.replenish__c}
                                onChange={""}
                                name="dnr"
                                value="dnr"
                                disabled={true}
                                inputProps={{
                                  "aria-label": "checkbox with default color",
                                }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={row.revisions__c}
                              onChange={""}
                              name="revisions"
                              value="revisions"
                              disabled={true}
                              inputProps={{ 'aria-label': 'checkbox with default color' }} />
                          }
                        />
                      </TableCell>
                        <TableCell size="small" align="left">
                          <Dropdown
                            label={""}
                            ctrllabel={!row.replenish__c ? "DNR" : (row.ship_to__c == null || row.ship_to__c == "") ? "Select" : getValueDummy4(row.ship_to__c)}
                            value={row.ship_to__c}
                            fnc={onChangeReplenish}
                            Isdisabled={true}
                            options={REPLENISH}
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Dropdown
                            label={""}
                            ctrllabel={!row.replenish__c ? "DNR" : (row.shipto_location__c == null || row.shipto_location__c == "") ? "Select" : getValueDummy5(row.shipto_location__c)}
                            value={row.shipto_location__c}
                            fnc={onChangeShipto}
                            Isdisabled={true}
                            options={SHIPTO}
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                checked={row.lot_controlled__c}
                                onChange={""}
                                name="lot"
                                value="lot"
                                disabled={true}
                                inputProps={{
                                  "aria-label": "checkbox with default color",
                                }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <FormControlLabel
                            control={
                              <TextField
                                inputProps={{ maxLength: 20 }}
                                name="lotNumber"
                                value={row.lot_number__c}
                                disabled={disableControl || !row.lot_controlled__c}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Dropdown
                            label={""}
                            ctrllabel={"Select"}
                            value={row.quantity__c === null ? row.cap_quantity : row.quantity__c}
                            options={qtyOptions}
                            Isdisabled={true}
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <InputTextField isdisabled={true} value={(row.aro_reference_number__c == null || row.aro_reference_number__c == "") ? "" : row.aro_reference_number__c} />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Dropdown
                            label={""}
                            ctrllabel={(row.ship_method__c == null || row.ship_method__c == "") ? "Select" : getValueDummy7(row.ship_method__c)}
                            value={row.ship_method__c}
                            fnc={onChangeShipto}
                            Isdisabled={true}
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Dropdown
                            label={""}
                            ctrllabel={(row.ship_priority__c == null || row.ship_priority__c == "") ? "Select" : getValueDummy6(row.ship_priority__c)}
                            value={row.ship_priority__c}
                            fnc={onChangeShipto}
                            Isdisabled={true}
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                checked={(row.dns__c == "true" || row.dns__c == "True" || row.dns__c == true) ? true : false}
                                onChange={""}
                                name="wasted"
                                value="wasted"
                                disabled={true}
                                inputProps={{
                                  "aria-label": "checkbox with default color",
                                }}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </TableContainer>
            </div>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <Typography>
              <MultilineTextFields
                disabled={disableControl}
                fnc={onChangeInternalComments}
                label="Internal Comments"
                value={internalComments}
              />
              <div
                style={{ color: "red", marginLeft: "5px", fontSize: "10px" }}
              >
                Please do not enter complaints in the text box.
              </div>
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <Typography>
              <MultilineTextFields
                disabled={disableControl}
                fnc={onChangeExternalComments}
                label="External Comments"
                value={externalComments}
              />
              <div
                style={{ color: "red", marginLeft: "5px", fontSize: "10px" }}
              >
                Please do not enter complaints in the text box.
              </div>
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <Typography>
              <InputTextField
                isdisabled={disableControl}
                label="Hospital Printed Name"
                value={hospitalNameValue}
                fnc={onChangeHospitalName}
              />
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <Typography>
              <SalesRep
                label={"Rep Printed Name"}
                isAstrikReq={true}
                onChangeSalesRep={onChangeSalesRep}
                options={salesRepsOptions}
                value={salesRepsValue}
                Isdisabled={disableControl}
              />
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={
            isSurgerySheetPDFVisible || hospitalSignatureLink === ""
              ? "hide"
              : "display"
          }
        >
          <CardContent>
            <Typography>
              <span></span>
              <label className="controllabelmargin"> Hospital Signature</label>
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={
            isSurgerySheetPDFVisible || repSignatureLink === ""
              ? "hide"
              : "display"
          }
        >
          <CardContent>
            <Typography>
              <label className="controllabelmargin"> Rep Signature</label>
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={
            isSurgerySheetPDFVisible || hospitalSignatureLink === ""
              ? "hide"
              : "display"
          }
        >
          <CardContent>
            <Typography>
              <img
                src={hospitalSignatureLink}
                height="150px"
                width="150px"
                alt="Hospital Signature"
              ></img>
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={
            isSurgerySheetPDFVisible || repSignatureLink === ""
              ? "hide"
              : "display"
          }
        >
          <CardContent>
            <Typography>
              <img
                src={repSignatureLink}
                height="150px"
                width="150px"
                alt="Rep Signature"
              ></img>
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className={isSurgerySheetPDFVisible ? "hide" : "display"}
        >
          <CardContent>
            <div class="rFloat btnSave">
              <Buttons
                Icon="Submit"
                toSubmit={true}
                disabled={disableControl}
                fnc={handleSubmitUsage}
                name="SUBMIT"
              />
            </div>
            <div class="rFloat btnSave">
              <Buttons
                Icon="Save"
                toSave={true}
                disabled={disableControl}
                fnc={handleSaveUsage}
                name="SAVE"
              />
            </div>
            <div class="rFloat btnSave">
              <Buttons
                Icon="PDF"
                toClone={true}
                disabled={false}
                fnc={handleShowPDF}
                name="VIEW PDF"
              />
            </div>
            <div class="rFloat btnSave" style={{ marginTop: "8px" }}>
              <LightTooltip
                title="View PDF with or without price"
                placement="top"
                arrow
                open={openTooltip}
                onClose={() => setOpenTooltip(false)}
                onOpen={() => setOpenTooltip(true)}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={showPrice}
                      onChange={(e) => handleToggleChange(e)}
                      color="primary"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.checked,
                        track: classes.track,
                      }}
                    />
                  }
                  label={"Price"}
                  labelPlacement="end"
                />
              </LightTooltip>
            </div>
          </CardContent>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          className={isSurgerySheetPDFVisible ? "display" : "hide"}
        >
          <CardContent>
            <div class="rFloat btnSave">
              <Buttons
                Icon="Close"
                toSave={true}
                disabled={false}
                fnc={handleCloseModal}
                name="CLOSE"
              />
            </div>
            <div class="rFloat btnSave">
              <Buttons
                Icon="EMAIL"
                toClone={true}
                disabled={false}
                fnc={() => setEmailDialog(true)}
                name="EMAIL"
              />
            </div>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className={isSurgerySheetPDFVisible ? "display" : "hide"}
        >
          <CardContent>
            <iframe
              id="serviceFrameSend"
              src={`${API.MASTER_DATA.HEROKUINVOICE}/${methodName}?case_id=${props.caseDetails.sfId}&toggle_price_flag=${showPrice}&external_id=${props.caseDetails.externalId}`}
              width="100%"
              height="1000"
              frameborder="0"
            />
          </CardContent>
        </Grid>
      </Grid>
      <EmailDialog
        open={emailDialog}
        onClose={() => setEmailDialog(false)}
        onSend={handleEmail}
        displaySnackBarMessage={props.displaySnackBarMessage}
        defaultTo={defaultEmailAddress}
      />
    </div>
  );
}
