
import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import RepsuiteErrorImage from '../../images/RepsuiteErrorImage.png'
import moment from 'moment';
import { getStatusColor } from '../../utils/surgicalCase';
import CommentHistory from './CommentHistory';

export default function CaseDetils(props) {

    const webOpsErrorFields = [
        'webOpsCreatedErrorMessage',
        'webOpsRequestedErrorMessage',
        'webOpsCompletedErrorMessage',
        'webOpsIntegrityErrorMessage'
    ];

    const webOpsErrMsg = props.caseDetails && webOpsErrorFields.map(item => props.caseDetails[item]).join(' ').trim();

    return (
        props.caseDetails ?
            <div>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">  Case ID</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.caseId} </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"> Surgery Start Date Time</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.surgeryDate ? moment(props.caseDetails.surgeryDate).format('MM/DD/yyyy, hh:mm A') : ''}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"> Status</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="reqbtn" style={{background:getStatusColor(props.caseDetails.status)}}> {props.caseDetails.status} </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"> Surgery Date Confirmed?</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">
                                    {props.caseDetails.surgery_date_confirmed__c ? "Yes" : "No"}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"> WebOps ID</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.webops_id__c} </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"> Delivery Date</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.deliveryDate ? moment.utc(props.caseDetails.deliveryDate).format('L') : '--'}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Division</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.division}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Patient ID</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.patientId}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Branch</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.branch}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"></label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3} className="chkPadding chkheightView">
                            <div>
                                <Checkbox
                                    color="default"
                                    checked={props.caseDetails.left === 'true' || props.caseDetails.left === true}
                                    disabled
                                    inputProps={{ 'aria-label': 'checkbox with default color' }} />
                                <label className="labelColor">Left</label>
                            </div>
                            <div>
                                <Checkbox
                                    color="default"
                                    checked={props.caseDetails.right === 'true' || props.caseDetails.right === true}
                                    disabled
                                    inputProps={{ 'aria-label': 'checkbox with default color' }} />
                                <label className="labelColor">Right</label>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Sub Branch/Agency</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.subBranchName}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Case Type</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.sets ? 'Info Only' : 'Loaner'}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Hospital</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue">{props.caseDetails.hospitalNoName}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"></label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3} className="chkPadding chkheightView">
                            <div>

                                <Checkbox
                                    color="default"
                                    checked={props.caseDetails.mako === "true" || props.caseDetails.mako === true}
                                    disabled
                                    inputProps={{ 'aria-label': 'checkbox with default color' }} />
                                <label className="labelColor">Mako</label>
                            </div>
                            <div>
                                <Checkbox
                                    color="default"
                                    checked={props.caseDetails.as1 === 'true' || props.caseDetails.as1 === true}
                                    disabled
                                    inputProps={{ 'aria-label': 'checkbox with default color' }} />
                                <label className="labelColor">AS-1</label>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Hospital Location Name</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent>
                                <label className="controlvalue">{props.caseDetails.hospAddrSiteNo + '-' + (props.caseDetails.subInvDesc ? props.caseDetails.subInvDesc : props.caseDetails.hospAddress)}</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"></label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3} className="chkPadding chkheightView">
                            <div>
                                <label className="controlvalue"><Checkbox
                                    color="default"
                                    checked={props.caseDetails.revision === 'true' || props.caseDetails.revision === true}
                                    disabled
                                    inputProps={{ 'aria-label': 'checkbox with default color' }} />
                                    <label className="labelColor">Revision</label>
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Surgeon No-Name</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.surgeonName}
                                </label>
                            </CardContent>
                        </Grid><Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Patient Gender</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.patient_gender? props.caseDetails.patient_gender.toUpperCase(): null}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"></label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {}
                                </label>
                            </CardContent>
                        </Grid>                        
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Cancel Reason</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.cancelReason}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Sales Rep</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.salesRep}
                                </label>
                            </CardContent>
                        </Grid>                        
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Delivery Options</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.deliveryOption}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Covering Reps</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.covering_reps__c}
                                </label>
                            </CardContent>
                        </Grid>                        
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Ship To</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.shipTo}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Procedure</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.procedures && props.caseDetails.procedures.length > 0 ? props.caseDetails.procedures[0].name : ''}
                                </label>
                            </CardContent>
                        </Grid>                        
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Integration Status</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {webOpsErrMsg ? <img width="30px" height="30px" src={RepsuiteErrorImage} /> : null}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Shipping Address</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {props.caseDetails.shippingAddress}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel"></label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"></label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Integration Error Message</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controlvalue"> {webOpsErrMsg}
                                </label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">
                                <label className="controllabel">Internal Comments</label>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CardContent className="cardPadding">                                
                                <label className="controlvalue">
                                    <CommentHistory caseItem={props.caseDetails} />
                                </label>
                            </CardContent>
                        </Grid>
                    </Grid>
                </div>
            </div> : ''
    );
}

