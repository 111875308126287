import { getBranchesAndSubBranches } from '../../graphql/queries'

// import { createOrUpdateTPRFilter } from '../../graphql/mutations'
//ActionTypes

const LOAD = 'branches/LOAD'
const LOAD_SUCCESS = 'branches/LOAD_SUCCESS'
const LOAD_FAILURE = 'branches/LOAD_FAILURE'
const RESET_BRANCHES = 'branches/RESET_BRANCHES'

const initialState = {
    loaded: false,
    loading: false,
    branchList: [],
    branchListError: {}
}

export default function MainAndSubBranches(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                branchList:action.payload,
                branchListError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                branchList: {},
                branchListError: action.payload
            }
        case RESET_BRANCHES:
            return {
                ...state,
                branchList: {},
                branchListError: {}
            }
        default:
            return state
    }
}

export function getMainAndSubBranches(branchId) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getBranchesAndSubBranches(branchId)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.get_branches
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}


export function resetBranches() {
    return dispatch => {
        dispatch({
            type: RESET_BRANCHES
        })
    }
}