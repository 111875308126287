import React, { Component } from 'react';
import Dropdown from '../controls/Dropdown'
import { getAllBranches, getMainBranches } from '../../graphql/queries'
import { connect } from 'react-redux'

class Branches extends Component {
    state = {
        AllBraches: [],
    }
    async componentDidMount() {
        await this.get_MainBranches();
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user.branchIds !== prevProps.user.branchIds) {
            await this.get_MainBranches();
        }
    }
    get_MainBranches = async () => {
        const compareBranch = (a, b) => {
            const c1 = a.label;
            const c2 = b.label;
            var comp = 0;
            if (c1 > c2)
              comp = 1;
            else if (c1 < c2)
              comp = -1;
            return comp;
          }
        let AllBraches = await getMainBranches();
        const uniqueChecker = [];
        const finalAllBranches = [];
        AllBraches.get_main_branches.map(item => {
            let index = uniqueChecker.indexOf(item.branchID);
            if (index === -1) {
                item.label = item.branchName;
                item.id = item.branchID;
                finalAllBranches.push({ id: item.branchID, label: item.branchName});
                uniqueChecker.push(item.id);
            }
        })
        if(finalAllBranches.length === 1){
            let event={};
            event.target={value:finalAllBranches[0]}
            this.props.handleBranchChangeDropdown(event)
        }
        const sortedBranch = finalAllBranches.sort(compareBranch)
        this.setState({
            loading: false,
            AllBraches: sortedBranch,
        });
    };
    render() {
        const { AllBraches } = this.state;
        return <Dropdown getValue={this.props.getValue} show={this.props.show} inpage={this.props.inpage} label={"Branch"} isAstrikReq={this.props.isAstrikReq} options={AllBraches} ctrllabel={this.props.placeHolder ? this.props.placeHolder : "Please Select"} fnc={this.props.handleBranchChangeDropdown} value={this.props.value} Isdisabled={this.props.Isdisabled}/> 
    }
}
const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(Branches);