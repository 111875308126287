
import { getSurgicalErrorCodes } from '../../graphql/queries'
//ActionTypes

const LOAD = 'surgicalPlanErrorCodes/LOAD'
const LOAD_SUCCESS = 'surgicalPlanErrorCodes/LOAD_SUCCESS'
const LOAD_FAILURE = 'surgicalPlanErrorCodes/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'surgicalPlanErrorCodes/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    errorCodes: [],
    errorCodeError: {}
}

export default function surgicalPlanErrorCodes(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errorCodes: action.payload
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                errorCodes: [],
                errorCodeError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                errorCodes: [],
                errorCodeError: {}
            }
        default:
            return state
    }
}

export function getSurgicalCasesErrorCodes() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getSurgicalErrorCodes()
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.get_surgical_plan_codes
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}