
import { getHospitals as getUserHospitals } from '../../graphql/queries'

// import { createOrUpdateTPRFilter } from '../../graphql/mutations'
//ActionTypes

const LOAD = 'hospitals/LOAD'
const LOAD_SUCCESS = 'hospitals/LOAD_SUCCESS'
const LOAD_FAILURE = 'hospitals/LOAD_FAILURE'
const RESET_HOSPITALS = 'hospitals/RESET_HOSPITALS'

const initialState = {
    loaded: false,
    loading: false,
    hospitalList: [],
    hospitalListError: {}
}

export default function hospitals(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                hospitalList:action.payload,
                hospitalListError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                hospitalList: {},
                hospitalListError: action.payload
            }
        case RESET_HOSPITALS:
            return {
                ...state,
                hospitalList: {},
                hospitalListError: {}
            }
        default:
            return state
    }
}

// New Territory Design
export function getHospitals() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getUserHospitals()
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data.get_hospitals
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}

export function resetBranches() {
    return dispatch => {
        dispatch({
            type: RESET_HOSPITALS
        })
    }
}