import React, { Component } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom'
import ListSurgicalCase from './container/SurgicalCase/ListSurgicalCase'
import NewSurgicalCase from './container/SurgicalCase/NewSurgicalCase'
import EditSurgicalCase from './container/SurgicalCase/EditSurgicalCase'
import ViewSurgicalCase from './container/SurgicalCase/ViewSurgicalCase'
import Notification from './container/Notification/EmailNotificaiton'
import TopHeader from './components/controls/TopHeader';
import Authenticate from './components/authenticate'
import CaseCalendar from './container/CaseCalendar';
import { connect } from 'react-redux'
import {LinkName,ASYNC_STORAGE} from './constants'
import '../src/styles/style.css'
import PatientScans from './container/PatientScans/PatientScans';
import SurgicalCaseCardView from './container/SurgicalCaseCardView';
import TreatmentPlanRequests from './container/TreatmentPlanRequest/TreatmentPlans'
import SurgeonPreferences from './container/SurgeonPreferences/SurgeonPreferences'
import RepsuiteSupport from './container/Support/RepsuiteSupport';
class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTopHeader: false
        }
    }

    /**
     * Once the component is mounted we are checking if the user is authorized and 
     * change the state of the showTopHeader to true.
     */
    componentDidMount() {
        const { userIsAuthorized } = this.props
        if (userIsAuthorized) {
            this.setState({ showTopHeader: true })
        }
    }

    /**
     * Once the component is updated with the newProps we are checking the prevProps if they
     * change we are changing the state of the showTopHeader to true.
     */
    componentDidUpdate(prevProps) {
        const { userIsAuthorized } = this.props
        if (userIsAuthorized !== prevProps.userIsAuthorized) {
            this.setState({ showTopHeader: true })
        }
    }

    checkActive(link = null) {
        const { pathname } = window.location;
        switch (link) {
        case LinkName.CASE_CALENDAR:
            return (
                pathname === "/" ||
                pathname === "/CaseCalendar"
            );            
          case LinkName.SURGICAL_CASES:
            return (
              pathname === "/ListSurgicalCase" ||
              pathname === "/EditSurgicalCase" ||
              pathname === "/NewSurgicalCase" ||
              pathname === "/ViewSurgicalCase"
            );
          // if a Navigation item needs to be active for more than one routes, add case here       
          default:
            return false;
        }
      }

    render() {
        const linkStyle = {
          textDecoration: "none",
          color: "white",
          fontSize: "13px",
          margin: "0px 5px",
          padding: "0px 10px 14px 10px",
        };
        const { access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
        return (
            <div class="App">
        <header>
        { access_token && this.state.showTopHeader ? (
                    <div>
                        <TopHeader />
                        <div style={{background: 'black', display: 'flex', gridTemplateColumns: '1fr 1fr'}}>
                            <div style={{padding: '16px 13px 16px 13px'}}>
                                <NavLink  activeClassName="active" style={linkStyle} isActive={() => this.checkActive(LinkName.CASE_CALENDAR)} to="/CaseCalendar">{LinkName.CASE_CALENDAR}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} isActive={() => this.checkActive(LinkName.SURGICAL_CASES)} to="/ListSurgicalCase">{LinkName.SURGICAL_CASES}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} to="/CaseCardView">{LinkName.CASE_CARD_VIEW}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} to="/TreatmentPlanRequest">{LinkName.TREATMENT_PLAN_REQUEST}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} to="/PatientScans">{LinkName.PATIENT_SCANS}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} to="/SurgeonPreferences">{LinkName.SURGEON_PREFERENCES}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} to="/Notification">{LinkName.NOTIFICATIONS}</NavLink>
                                <NavLink  activeClassName="active" style={linkStyle} to="/Support">{LinkName.SUPPORT}</NavLink>
                            </div>
                        </div>
                    </div>) : ''}
        </header>
        <main>
        <Switch>
                    <Route exact path="/" component={Authenticate(CaseCalendar)} />
                    <Route exact path="/CaseCalendar" component={Authenticate(CaseCalendar)} />
                    <Route exact path="/CaseCardView" component={Authenticate(SurgicalCaseCardView)} />
                    <Route exact path="/Notification" component={Authenticate(Notification)} />
                    <Route exact path="/NewSurgicalCase" component={Authenticate(NewSurgicalCase)} />
                    <Route exact path="/EditSurgicalCase" component={Authenticate(EditSurgicalCase)} />
                    <Route exact path="/ViewSurgicalCase" component={Authenticate(ViewSurgicalCase)} />
                    <Route exact path="/ListSurgicalCase" component={Authenticate(ListSurgicalCase)} />
                    <Route exact path="/PatientScans" component={Authenticate(PatientScans)} />
                    <Route exact path="/TreatmentPlanRequest" component={Authenticate(TreatmentPlanRequests)} />
                    <Route exact path="/SurgeonPreferences" component={Authenticate(SurgeonPreferences)} />
                    <Route exact path="/Support" component={Authenticate(RepsuiteSupport)} />
                </Switch>
        </main>

        <footer style={{height: '50px', background: '#545857', borderTop: '5px solid #ffb400'}}>
            <div style={{color: 'white', marginTop: '10px', textAlign: 'center'}}>
            © Stryker {new Date().getFullYear()} | RepSuite Version: {window.location.host && window.location.host.includes('sit') ? 'SIT' 
      : window.location.host && window.location.host.includes('uat') ? 'UAT' 
      : window.location.host && window.location.host.includes('localhost') ? 'LOCAL' : 'PROD'} - {process.env.REACT_APP_VERSION}
            </div>
        </footer>
    </div>
        );
    }
}

export const mapStateToProps = (state) => {
    const userIsAuthorized = state.user.first_name
    return { userIsAuthorized }
}

export default connect(mapStateToProps, null)(Layout);
