const COMBINATION = "COMBINATION";
const TPR_PREFERENCE = "TPR_PREFERENCE";
const RESET_TPR_PREFERENCE = "RESET_TPR_PREFERENCE";
const SURGICAL_PREFERENCE = "SURGICAL_PREFERENCE";
const RESET_SURGICAL_PREFERENCE = "RESET_SURGICAL_PREFERENCE";
const RESET_CREATE_PREFERENCE = "RESET_CREATE_PREFERENCE"
const SET_SURGICAL_PREFERENCE_DATA = "SET_SURGICAL_PREFERENCE_DATA"
const SET_TPR_PREFERENCE_DATA = "SET_TPR_PREFERENCE_DATA"
const SET_EDIT_PREFERENCE = "SET_EDIT_PREFERENCE"

export const setSurgicalPrefData = (preference) => ({
  type: SET_SURGICAL_PREFERENCE_DATA,
  payload: preference,
});

export const setTprPreferenceData = (preference) => ({
  type: SET_TPR_PREFERENCE_DATA,
  payload: preference,
});

export const setCombinationValue = (name, value) => ({
  type: COMBINATION,
  payload: {
    name,
    value,
  },
});

export const setSurgicalPrefValue = (name, value) => ({
  type: SURGICAL_PREFERENCE,
  payload: {
    name,
    value,
  },
});

export const setTprPreferenceValue = (name, value) => ({
  type: TPR_PREFERENCE,
  payload: {
    name,
    value,
  },
});

export const resetCreatePreference = () => ({
  type: RESET_CREATE_PREFERENCE
});

export const resetSurgicalPreference = () => ({
  type: RESET_SURGICAL_PREFERENCE
});

export const resetTprPreference = () => ({
  type: RESET_TPR_PREFERENCE
});

export const setSelectedPreference = (preference) => ({
  type: SET_EDIT_PREFERENCE,
  payload: preference,
});

const initialState = {
  loading: false,
  combination: {
    branch: "",
    branchName: "",
    hospital: {
      hospitalId: '',
      hospitalName: ''
    },
    surgeon: {
      surgeonID: '',
      surgeonName: ''
    },
    procedure: {
      id: '',
      label: ''
    },
    nickname : "",
  },
  tprPreferenceObj: {
    FemoralComponentValue: "",
    InsertTypeValue: "",
    BasePlateValue: "",
    ApproachValue: "",
    AcetabularComponentValue: "",
    FemoralComponentHipsValue: "",
    version__c: "",
    comments: ''
  },
  surgicalPreferenceObj: {
    loanerSets: [],
    comments: ''
  },
  selectedPreference: null
};

export default function createSurgeonPreference(state = initialState, action = {}) {
  switch (action.type) {
    case COMBINATION:
      return {
        ...state,
        combination: {
            ...state.combination,
            [action.payload.name]: action.payload.value,
        }
      };
    case TPR_PREFERENCE:
      return {
        ...state,
        tprPreferenceObj: {
            ...state.tprPreferenceObj,
            [action.payload.name]: action.payload.value,
        }
      };
    case RESET_TPR_PREFERENCE:
    return {
        ...state,
        tprPreferenceObj: {
            ...initialState.tprPreferenceObj,
            comments: state.tprPreferenceObj.comments
        }
    };      
    case SURGICAL_PREFERENCE:
      return {
        ...state,
        surgicalPreferenceObj: {
            ...state.surgicalPreferenceObj,
            [action.payload.name]: action.payload.value,
        }
      };
    case SET_SURGICAL_PREFERENCE_DATA:
      return {
        ...state,
        combination:{
          ...state.combination,
          branch: action.payload.branchid__c,
          branchName: action.payload.branch,
          hospital: {
            ...state.combination.hospital,
            hospitalId: action.payload.hospitalid__c,
            hospitalName: action.payload.hospitalName,
          },
          surgeon: {
            ...state.combination.surgeon,
            surgeonID: action.payload.contactid__c,
            surgeonName: action.payload.surgeonName,
          },
          procedure: {
            ...state.combination.procedure,
            id: action.payload.procedureid__c,
            label: action.payload.procedureName
          },
          nickname : action.payload.nick_name__c,
        },
        surgicalPreferenceObj: {
            ...state.surgicalPreferenceObj,
            loanerSets: action.payload.sets,
            comments: action.payload.surg_pref_comments__c
        }
      };
      case SET_TPR_PREFERENCE_DATA:
        return {
          ...state,
          combination:{
            ...state.combination,
            branch: action.payload.branchid__c,
            branchName: action.payload.branch,
            hospital: {
              ...state.combination.hospital,
              hospitalId: action.payload.hospitalid__c,
              hospitalName: action.payload.hospitalName,
            },
            surgeon: {
              ...state.combination.surgeon,
              surgeonID: action.payload.contactid__c,
              surgeonName: action.payload.surgeonName,
            },
            procedure: {
              ...state.combination.procedure,
              id: action.payload.procedureName,
              label: action.payload.procedureName
            },
            nickname : action.payload.nick_name__c,
          },
          tprPreferenceObj: {
              ...state.tprPreferenceObj,
              FemoralComponentValue: action.payload.femoral_component__c ? action.payload.femoral_component__c : '',
              InsertTypeValue: action.payload.insert_type__c ? action.payload.insert_type__c : '',
              BasePlateValue: action.payload.baseplate__c ? action.payload.baseplate__c : '',
              ApproachValue: action.payload.approach__c ? action.payload.approach__c : '',
              AcetabularComponentValue: action.payload.acetabular_component__c ? action.payload.acetabular_component__c : '',
              FemoralComponentHipsValue: action.payload.femoral_component__c ? action.payload.femoral_component__c : '',
              version__c: action.payload.version__c,
              comments: action.payload.surg_pref_comments__c
          }
        };
    case SET_EDIT_PREFERENCE:
      return {
        ...state,
        selectedPreference: {
          externalId: action.payload.external_id__c,
          sfid: action.payload.sfid
        }
      }
    case RESET_SURGICAL_PREFERENCE:
    return {
        ...state,
        surgicalPreferenceObj: {
          ...state.surgicalPreferenceObj,
          loanerSets: [],
        }
    };
    case RESET_CREATE_PREFERENCE:
      return initialState;
    default:
      return state;
  }
}
