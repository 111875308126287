import React, { useState, useCallback, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import CardContent from '@material-ui/core/CardContent';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setImages, deleteImages } from '../../graphql/mutations';
import { IMAGE_FETCH_URL, API } from '../../constants';
import getCaseById from "../../utils/calendar";
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import ImgNotFound from '../../images/ic_image_not_found.png'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexGrow: 1,
    },
    rootDialogueTittle: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#eaeaea',
        borderBottom: '1px solid grey'
    },
    dropzone: {
        padding: '4px',
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fff',
        outline: 'none',
    },
    imageTable: {
        padding: '4px',
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'solid',
        backgroundColor: '#fafafa',
        outline: 'none',
    },
    progressRoot: {
        width: '100%',
        marginLeft: '40px',
        marginTop: '20px'
    },

    alertMessage: {
        width: '100%',
        margin: '20px',
        marginBottom: '0px',
    },
    paper: {
        padding: theme.spacing(2),
        border: '2px solid red'
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    formControl: {
        margin: "12px",
        fontWeight: "bold",
        fontSize: "12px"
    },
    AddButton: {
        background: '#FFB500',
        boxShadow: 'none',
        color: '#000',

        "&:hover": {
            background: '#FFD271',
            boxShadow: 'none',
            color: '#000'
        },
        "&:focus": {
            background: '#FFB500!important',
            boxShadow: 'none',
            color: '#000'
        },
    },

    table: {
        minHeight: 320,
        maxHeight: 320,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%"
    },
    drapDropDiv: {
        border: "1px solid black",
        padding: "20px",
        borderStyle: "dashed",
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
}));

export default function Upload(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const GetCaseById_Set = (response) => dispatch(userCase.setCaseDetail(response));
    const [loading, setLoading] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [uploadImages, setUploadImages] = React.useState();
    const [poUploaded, setPOUploaded] = React.useState(props.caseDetails.pouploaded);
    const [isPOflag, setIsPOflag] = React.useState("");
    const [disableControl, setDisableControl] = useState(false);
    let imageArray = [],
        thumbnailImgArray = []
    useEffect(() => {
        setPOUploaded(props.caseDetails.pouploaded)
        setDisableControl(props.caseDetails.status === "Completed")
    }, [props.caseDetails]);
    useEffect(() => {
        setLoading(true)
        let images = [];
        images =
            props.caseDetails.image &&
            props.caseDetails.image.find(img => img.signature === 'Images');

        if (images) {
            imageArray = images.imageurl.split(',');
            thumbnailImgArray = images.thumbnailurl ? images.thumbnailurl?.split(',') : [];

            const attachmentNameArray = images.attachmentname
                ? images.attachmentname.split(',')
                : null;
            const attachmentIdArray = images.attachmentid
                ? images.attachmentid.split(',')
                : null;
            imageArray = imageArray.map((image, i) => ({
                uri: `${IMAGE_FETCH_URL}${image}`,
                thumbnailuri: `${thumbnailImgArray?.[i]}`,
                id: image,
                isLoading: true,
                type: getSubstring(image),
                attachmentname: attachmentNameArray ? attachmentNameArray[i] : null,
                attachmentid: attachmentIdArray ? attachmentIdArray[i] : null
            }));
        }
        const filterImageArray = imageArray.filter((f)=> !f.attachmentname.includes(props.caseDetails?.caseId) && !f.attachmentname.includes(".pdf"))
        setUploadImages(filterImageArray)
        setLoading(false)
    }, [props.caseDetails.image]);
    const onDrop = useCallback(async (acceptedFiles, e) => {
        if(props.caseDetails.status === 'Completed') return;
        setLoading(true)
        const body = new FormData();
        const extension = getSubstring(acceptedFiles[0].name);
        body.append('file', acceptedFiles[0]);
        try {
            const res = await axios({
                url: API.MASTER_DATA.UPLOAD_DOCUMENTS,
                method: 'post',
                data: body,
            })
            if (res.status != '200') {
                props.displaySnackBarMessage("error", "Request timeout we are unable to save your image. Please try again.")
            } else {
                const response = res.data.images;
                try {
                    const CaseUsageImages = [
                        {
                            case_sfid: props.caseDetails.sfId,
                            case_external_id: props.caseDetails.externalId,
                            signature_by: null,
                            attachment_name: acceptedFiles[0].name,
                            azure_image_url: response[0].imageUrl.split('/')[response[0].imageUrl.split('/').length - 1],
                            thumbnail_url: response[0].thumbnailUrl,
                            attachment_id: null,
                            isNew: true
                        }
                    ]
                    const result = await setImages(props.caseDetails.externalId,
                        props.caseDetails.sfId,
                        CaseUsageImages,
                        acceptedFiles[0].isPOUploaded ? acceptedFiles[0].isPOUploaded : props.caseDetails.pouploaded);
                    setUploadImages(result.data.addImages.image)
                    props.displaySnackBarMessage("success", "Image uploaded successfully.")
                    await getCases()
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    props.displaySnackBarMessage("error", "We are unable to save your images in Heroku, please try again.")
                }

            }
        } catch (error) {
            console.log(error);
            props.displaySnackBarMessage("error", "We are unable to save your image, please try again.")
        }
    }, [])

    const getCases = async () => {
        const res = await getCaseById(props.caseDetails.externalId, props.caseDetails.sfId)
            .then(res => {
                GetCaseById_Set(res.data);
            })
    }
    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        getFilesFromEvent: event => myCustomFileGetter(event),
    });
    const getSubstring = filename => {
        return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    };

    async function myCustomFileGetter(event) {
        const files = [];
        const file_list = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        for (var i = 0; i < file_list.length; i++) {
            const file = file_list.item(i);
            Object.defineProperty(file, 'isPOUploaded', {
                value: isPOflag,
            });
            files.push(file);
        }
        return files;
    }
    const deleteImage = async (event, data) => {
        setDeleteLoading(true)
        try {
            const CaseUsageImages = [
                {
                    case_sfid: props.caseDetails.sfId,
                    case_external_id: props.caseDetails.externalId,
                    signature_by: null,
                    attachment_name: data.attachmentname,
                    azure_image_url: data.uri.split('/')[data.uri.split('/').length - 1],
                    thumbnail_url: data.thumbnailuri,
                    attachment_id: data.attachmentid,
                    isNew: true
                }
            ]
            const result = await deleteImages(props.caseDetails.externalId,
                props.caseDetails.sfId,
                CaseUsageImages,
            );
            //setUploadImages(result.data.addImages.image)
            props.displaySnackBarMessage("success", "Image deleted successfully.")
            await getCases()
            setDeleteLoading(false)
        } catch (error) {
            setDeleteLoading(false)
            props.displaySnackBarMessage("error", "We are unable to delete images in Heroku, please try again.")
        }
    }


    const ImageUpload = async () => {
        if (props.caseDetails.pouploaded === false) {
            setIsPOflag(false)
        }
    }
    const POUpload = async () => {
        if (props.caseDetails.pouploaded === false) {
            setIsPOflag(true)
        }
    }

    return (
        loading ? <CircularProgress style={{ marginLeft: "50%", color: "gold" }} disableShrink /> :
            <div >
                <Grid container className={classes.root} justify="flex-end">
                    <label className="reqbtn" style={{ background: (poUploaded ? "#8F368D" : "#fff"), border: '1px solid #000', color: (poUploaded ? "#fff" : "#000") }}>
                        PO Uploaded
                    </label>
                </Grid>
                <Grid item xs={12} sm={12} >
                    <div className={classes.rootDialogueTittle} id="responsive-dialog-title">
                        <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{"Image Upload"}</div>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <section >
                            <div {...getRootProps({ className: classes.dropzone })}>
                                <input {...getInputProps()} disabled={disableControl}/>
                                <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr' }} onClick={() => ImageUpload()}>
                                    <FontAwesomeIcon style={{ marginInline: 'auto', fontSize: '3em' }} icon={faUpload} />
                                    <p style={{ marginInline: 'auto' }}>Upload files or Drag and Drop into the dashed region</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} >
                    <div className={classes.rootDialogueTittle} id="responsive-dialog-title">
                        <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{"PO Upload"}</div>
                    </div>
                    <div style={{ padding: '10px', backgroundColor: '#4sesse' }}>
                        <section >
                            <div {...getRootProps({ className: classes.dropzone })}>
                                <input {...getInputProps()} id="POUpload" name="POUpload" Test="abc" disabled={disableControl} />
                                <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr' }} onClick={() => POUpload()}>
                                    <FontAwesomeIcon style={{ marginInline: 'auto', fontSize: '3em' }} icon={faUpload} />
                                    <p style={{ marginInline: 'auto' }}>Upload files or Drag and Drop into the dashed region</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </Grid>
                {deleteLoading ? <CircularProgress style={{ marginLeft: "50%", color: "gold" }} disableShrink /> :
                    <div>
                        <Grid item xs={12} sm={12} >

                            <div style={{ padding: '10px' }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        {uploadImages && uploadImages.map((row, index) => (
                                            <TableHead style={{ backgroundColor: '#fafafa', border: '1px solid #EFEAEA' }}>
                                                <TableRow key={row.attachment_id}>
                                                        <TableCell width="10%">
                                                            <object data={row.thumbnailuri} width="40px" height="40px" >
                                                                <img src={ImgNotFound} alt="Just testing." height="40px" width="40px"></img>
                                                            </object>
                                                        </TableCell>
                                                    <TableCell width="80%" align="left">{row.attachmentname}</TableCell>
                                                    <TableCell width="10%" align="right">
                                                        <div class="font-icon-wrapper" >
                                                            <DeleteIcon onClick={disableControl ? null : (e) => deleteImage(e, row)} />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        ))}
                                    </Table>
                                </TableContainer>
                            </div>

                        </Grid>
                    </div>}
            </div >
    );
}