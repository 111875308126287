
import { getTPRListFilter } from '../../graphql/queries'

// import { createOrUpdateTPRFilter } from '../../graphql/mutations'
//ActionTypes

const LOAD = 'TreatmentPlanFilterGet/LOAD'
const LOAD_SUCCESS = 'TreatmentPlanFilterGet/LOAD_SUCCESS'
const LOAD_FAILURE = 'TreatmentPlanFilterGet/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'TreatmentPlanFilterGet/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    filterList: [],
    filterError: {}
}

export default function getTreatmentPlanFilter(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                filterList:action.payload,
                filterError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                filterList: {},
                filterError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                filterList: {},
                filterError: {}
            }
        default:
            return state
    }
}

export function getTprFilter() {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getTPRListFilter()
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.get_tpr_filters
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}

export function resetGetTPRFilterList() {
    return dispatch => {
        dispatch({
            type: RESET_UPLOAD_TREATMENT_PLAN
        })
    }
}