
import { getTPRCaseHistory } from '../../graphql/queries'
//ActionTypes

const LOAD = 'TPRHistory/LOAD'
const LOAD_SUCCESS = 'TPRHistory/LOAD_SUCCESS'
const LOAD_FAILURE = 'TPRHistory/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'TPRHistory/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    history: [],
    historyError: {}
}

export default function TPRHistory(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                history: action.payload
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                history: [],
                historyError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                history: [],
                historyError: {}
            }
        default:
            return state
    }
}

export function getTprCaseHistory(tprId) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        getTPRCaseHistory(tprId)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.get_tpr_history
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}

export function resetTPRHistory() {
    return dispatch => {
        dispatch({
            type: RESET_UPLOAD_TREATMENT_PLAN
        })
    }
}