
// import { getTPRListFilter } from '../../graphql/queries'

import { createOrUpdateTPRFilter } from '../../graphql/mutations'
import { getTprFilter } from '../modules/treatmentPlanFilter'
//ActionTypes

const LOAD = 'TreatmentPlanFilterSave/LOAD'
const LOAD_SUCCESS = 'TreatmentPlanFilterSave/LOAD_SUCCESS'
const LOAD_FAILURE = 'TreatmentPlanFilterSave/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'TreatmentPlanFilterSave/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    saveFilterList: {},
    saveFilterError: {}
}

export default function saveTreatmentPlanFilter(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                saveFilterList: action.payload,
                saveFilterError: {}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saveFilterList: {},
                saveFilterError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                saveFilterList: {},
                saveFilterError: {}
            }
        default:
            return state
    }
}

export function saveTprFilter(payload) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        createOrUpdateTPRFilter(payload)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data
                })
                dispatch(getTprFilter())
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}

export function resetSaveTPRFilterList() {
    return dispatch => {
        dispatch({
            type: RESET_UPLOAD_TREATMENT_PLAN
        })
    }
}