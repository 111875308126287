import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, makeStyles, MenuList, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

import { StyledCheckbox } from "../controls/StyledCheckbox";

const useStyles = makeStyles((theme) => ({
  menuList: {
    minWidth: "300px",
  },
}));

export default function PresetFilterMenu(props) {
  const classes = useStyles();

  return (
    <Paper>
      <Menu
        id="simple-menu"
        anchorEl={props.anchor}
        keepMounted
        open={Boolean(props.anchor)}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList className={classes.menuList}>
          <MenuItem disabled>
            <Typography variant="inherit">
              {props.items && props.items.length > 0
                ? "Select a preset filter"
                : "No Preset Filter Present"}
            </Typography>
          </MenuItem>
          {props.items &&
            props.items.map((item) => (
              <Grid
                container
                xs={12}
                alignItems="center"
                style={{
                  background: `${
                    item.name === props.selectedFilter && props.checkFilterName
                      ? "lightblue"
                      : ""
                  }`,
                }}
              >
                <Grid item xs={8} onClick={() => props.handleChange(item.id)}>
                  <MenuItem>
                    <Typography variant="inherit">{item.name}</Typography>
                  </MenuItem>
                </Grid>
                <Grid item xs={2} alignContent="flex-end">
                  <StyledCheckbox
                    onChange={(e) => props.handleCheckBox(item.id)}
                    checked={item.isdefault === true}
                  />
                </Grid>
                <Grid item xs={1} alignContent="flex-end">
                  <DeleteIcon
                    color="action"
                    onClick={() => props.handleDelete(item.id)}
                    fontSize="medium"
                  />
                </Grid>
              </Grid>
            ))}
        </MenuList>
      </Menu>
    </Paper>
  );
}
