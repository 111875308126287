import { Button, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Filter from "../../components/Filter";

const CaseHeader = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: "0px",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    createButton: {
      background: "#FFB500",
      boxShadow: "none",
      color: "#000",
      fontWeight: "bold",
      margin: "10px",

      "&:hover": {
        background: "#FFD271",
        boxShadow: "none",
        color: "#000",
      },
      "&:focus": {
        background: "#FFB500!important",
        boxShadow: "none",
        color: "#000",
      },
    },
  }));
  const classes = useStyles();
  let timer;
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    window.addEventListener("keyup", searchCase);
    return () => {
      window.removeEventListener("keyup", searchCase);
    };
  }, []);
  const requestSearch = async (searchedVal) => {
    setSearchText(searchedVal);
    // setIsSearching(true);
    await props.getCasesBySearch(searchedVal.trim());
    // setIsSearching(false);
  };

  const searchCase = (e) => {
    if (e.target.name === "my_search" && e.target.value !== "") {
      window.clearTimeout(timer);
      timer = window.setTimeout(() => {
        props.getCasesBySearch(e.target.value.trim());
      }, 750);
    }
  };

  const cancelSearch = async () => {
    setSearchText("");
    // setIsSearching(true);
    await props.getCasesBySearch("");
    // setIsSearching(false);
  };

  return (
    <Grid item xs={12} container className="pagetitleHead ">
      <Grid item xs={3} justify="flex-start" alignItems="center">
        <div
          style={{
            fontWeight: "bold",
            fontSize: "14px",
            padding: "20px 0px 18px 15px",
          }}
        >
          {props.pageTittle}
        </div>
      </Grid>
      <Grid item xs={6} justify="center" alignItems="center">
        <SearchBar
          style={{
            margin: "2px",
            padding: "0px",
            border: "0.5px solid lightgrey",
            width: "100%",
          }}
          name="my_search"
          placeholder="Search Case by Case ID or Patient ID"
          value={searchText}
          // onChange={requestSearch}
          onRequestSearch={requestSearch}
          onCancelSearch={cancelSearch}
        />
        {isSearching ? <LinearProgress /> : null}
      </Grid>
      <Grid item xs={3} align="right" justify="flex-end" alignItems="center">
        <Link to="/NewSurgicalCase" style={{ textDecoration: "none" }}>
          <Button
            size="medium"
            variant="contained"
            className={classes.createButton}
          >
            CREATE CASE
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default CaseHeader;
