/* eslint-disable no-use-before-define */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';

const filter = createFilterOptions();
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  inputColor: {
    border: '1px solid lightgrey',
    height: '51px',
    '&:after': {
      borderBottom: '2px solid gold',
    }
  },
  underline: {
    '&:after': {
      borderBottom: '2px solid gold',
    },
  }
}))
export default function AutoCompleteOption(props) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const classes = useStyles();
  const valueSetter = (value, options) => {
    if (options.length > 0) {
      // passing true as second param to props.fnc indicate the on load Value 
      if (props.label === "Surgeon") {
        // value could be surgeon name or surgeon id
        options.map(option => option.title == value || option.id == value ? props.fnc(option, true) : null)
      } else {
        options.map(option => option.id == value ? props.fnc(option, true) : null)
      }
    }
  }
  const name = <div className="controllabelCreate" >{props.label}
    {!props.isAstrikReq && <span style={{ color: "red" }} >*</span>}
  </div>
  const placeholder = props.page === "listing" && props.label === "Surgeon" ? "Surgeon Name" : props.isAstrikReq && props.label === "Hospital" ? "Hospital Name" : "Please Select"
  return (
    <Autocomplete
      classes={props.isSurgeon ? {inputRoot: classes.inputColor} : {}}
      value={typeof (props.value) !== 'object' ? valueSetter(props.value, props.options) : props.value}
      onChange={(event, newValue) => {
        // setValue(newValue);
        props.fnc(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="AutoComplete-Text"
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Regular option
        return option.title;
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if(props.onInputChange){ 
          // if onInputChange function passed to perform some operation on input value changes
          props.onInputChange(newInputValue)
        }
      }}
      renderOption={(option) => option.title}
      disabled={props.Isdisabled}
      renderInput={(params) => (
        <FormControl className={classes.formControl}>
          <TextField className={"CustomAutocomplete"} {...params} placeholder={placeholder} 
          InputLabelProps={{
            shrink: true,
          }}
            label={name}
            disabled={props.Isdisabled}
          />
        </FormControl>
      )}
    />
  );
}