import React, { useEffect, useState } from "react";
import SchedulerComponent from "./SchedulerComponent";
import { Grid, Chip, makeStyles } from "@material-ui/core";
import { getCalendarCases } from "../../graphql/queries";
import CaseHeader from "../../components/CaseHeader";
import Filter from "../../components/Filter";
import SimpleBackdrop from "../../components/controls/Spinner";
import moment from "moment";
import { isUserHasMPSrole } from "../../utils/calendar";
import {
  calendarCasesSuccess,
  calendarCasesReset,
  setAppliedFilters,
  resetAppliedFilters,
} from "../../redux/modules/surgicalCases";
import { useDispatch, useSelector } from "react-redux";
import Snackbars from "../../components/controls/Snackbar";
import { ROLE_CASES } from "../../constants";
import {
  getSalesTeam,
  getBranchSalesrep,
  getPresetFilters,
  getHospitals,
  getSurgeons,
  getMainBranches,
} from "../../graphql/queries";
import { API, ASYNC_STORAGE, CALENDAR_STATUS } from "../../constants";
import Modal from "./Modal"

const CaseCalendar = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: "0px",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 500,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    createButton: {
      background: "#FFB500",
      boxShadow: "none",
      color: "#000",
      fontWeight: "bold",
      margin: "10px",

      "&:hover": {
        background: "#FFD271",
        boxShadow: "none",
        color: "#000",
      },
      "&:focus": {
        background: "#FFB500!important",
        boxShadow: "none",
        color: "#000",
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const setCalendarCases = (cases, appointments) =>
    dispatch(calendarCasesSuccess(cases, appointments));
  const resetCases = () => dispatch(calendarCasesReset());
  const setFilterObj = (filters) => dispatch(setAppliedFilters(filters));
  const resetFilters = () => dispatch(resetAppliedFilters());
  const calendar = useSelector((state) => state.surgicalCases);
  const { cases, appointments } = calendar;
  const filterObj = useSelector((state) => state.surgicalCases.appliedFilters);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentViewName, setCurrentViewName] = useState("Month");
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [removeDateRange, setRemoveDateRange] = useState(false);
  const [checkFilterName, setCheckFilterName] = useState("");
  const [salesRep, setSalesRep] = useState("All");
  const [salesTeam, setSalesTeam] = useState("All");
  const [branch, setBranch] = useState("All");
  const [hospital, setHospital] = useState(null);
  const [surgeon, setSurgeon] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState([]);
  const [mako, setMako] = useState(false);
  const [as1, setAs1] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [webOpsError, setWebOpsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [division, setDivision] = useState("All");
  const [role, setRole] = useState("");
  const [banner, setBanner] = useState([]);
  const [pop, setPop] = useState("")
  const [check, setCheck] = useState(false)
  const [selectedFilterName, setSelectedFilterName] = useState("");
  const [notification, setNotification] = useState({
    severity: "",
    message: "",
  });
  const [openSnakBar, setOpenSnakBar] = useState(false);
  let initialFilter = {
    status: "",
    mako: "",
    as1: "",
    isWebOpsError: "",
    filterBranch: "",
    division: "",
    filterSalesRep: "",
    filterHospital: "",
    filterSalesTeam: "",
    filterSurgeon: "",
    searchText: "",
    startDate: "",
    endDate: "",
    surgery_date_confirmed: "",
    role: "",
  };

  const { access_token } = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.TOKEN) || "{}"
  );

  const handleCheck = (event) => {
    setCheck(event.target.checked)
  }



  const getNotification = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", access_token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    await fetch(API.MASTER_DATA.NOTIFICATIONPOP, requestOptions)
      .then(response => response.json())
      .then(result => setBanner(result))
      .catch(error => console.log('error', error));
    setPop(true)
  }

  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );
  const { branchIds, sfids } = current_user;

  const getFilterObjFromDefinition = (definition) => {
    let obj = {};
    Object.keys(definition).forEach((key) => {
      switch (key) {
        case "startDate":
        case "endDate":
          let dt = null;
          if (definition[key]) {
            dt = definition[key].includes('"')
              ? JSON.parse(definition[key])
              : JSON.stringify(definition[key]);
          }
          obj = { ...obj, [key]: dt };
          break;
        case "mako":
        case "as1":
        case "isWebOpsError":
        case "surgery_date_confirmed":
          obj = {
            ...obj,
            [key]:
              definition[key] && JSON.parse(definition[key])
                ? JSON.parse(definition[key]).value
                : false,
          };
          break;
        case "role":
          obj = {
            ...obj,
            [key]:
              definition[key] && JSON.parse(definition[key])
                ? JSON.parse(definition[key]).label
                : null,
          };
          break;
        default:
          obj = {
            ...obj,
            [key]:
              definition[key] && JSON.parse(definition[key])
                ? JSON.parse(definition[key]).value
                : "",
          };
          break;
      }
    });
    return obj;
  };

  const getFilters = async (userSfid, combinedData) => {
    let temp = localStorage.getItem("filterObj");
    let temp1 = localStorage.getItem("checkFilterName");
    let temp2 = localStorage.getItem("SelectedFilter");
    if (temp !== null) {
      initialFilter = JSON.parse(temp);
      setFilterObj(initialFilter);
      if (temp1 !== "") {
        setCheckFilterName(JSON.parse(temp1));
      }
      if (temp1 !== "") {
        setSelectedFilterName(JSON.parse(temp2));
      }
    } else {
      const res = await getPresetFilters(userSfid);
      let def = res.data.filters.find((item) => item.isdefault === true);
      if (def) {
        setCheckFilterName(def.name);
        const { __typename, ...filterDefinition } = def.definition;
        initialFilter = getFilterObjFromDefinition(filterDefinition);
      } else {
        initialFilter = {
          status: "New,Requested,Assigned,Shipped/Ready for Surgery",
          mako: "",
          as1: false,
          isWebOpsError: false,
          filterBranch: "",
          division: "",
          filterSalesRep: "",
          filterHospital: "",
          filterSalesTeam: "",
          filterSurgeon: "",
          searchText: "",
          startDate: "",
          endDate: "",
          surgery_date_confirmed: true,
          role: ROLE_CASES.COVERING_CASES
        };
        setCheckFilterName("");
      }
      setFilterObj(initialFilter);
    }

    hadleSetControls(initialFilter, combinedData[0].value, combinedData[1].value, combinedData[2].value, combinedData[3].value, combinedData[4].value);
    getCases(currentViewName, currentDate, initialFilter);
  };

  const getStatusLabel = (statusVal) => {
    const stsArr = statusVal !== "" && statusVal.split(",");
    return `${stsArr[0]} ${stsArr.length > 1 > 0 ? `(+${stsArr.length - 1})` : ""
      }`;
  };

  const getChipLabel = (key, value) => {
    const options =
      key === "filterBranch"
        ? filterObj.filterBranch
        : key === "filterSalesRep"
          ? filterObj.filterSalesRep
          : key === "filterSalesTeam"
            ? filterObj.filterSalesTeam
            : key === "filterHospital"
              ? filterObj.filterHospital
              : key === "filterSurgeon"
                ? filterObj.filterSurgeon
                : "";
    if (key === "filterSalesRep") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `salesRep( + ${str.length} ) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterSalesRep;
      return option;
    } else if (key === "filterSalesTeam") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Sales Team (+ ${str.length} ) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterSalesTeam;
      return option;
    } else if (key === "filterSurgeon") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Surgeon (+ ${str.length}) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterSurgeon;
      return option;
    } else if (key === "filterHospital") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Hospital (+ ${str.length}) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterHospital;
      return option;
    } else if (key === "filterBranch") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Branch (+ ${str.length}) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterBranch;
      return option;
    } else {
      const option =
        options &&
        options.length > 0 &&
        options.find((item) => item.id === value);
      return option ? option.title || option.label : "";
    }
  };
  const handleDelete = (name) => {
    const action = chipAction(true, name);
    const actionVal =
      name === "mako" ||
        name === "as1" ||
        name === "isWebOpsError" ||
        name === "surgery_date_confirmed"
        ? false
        : name === "status"
          ? CALENDAR_STATUS
          : "";
    action(actionVal);
    // Handle Date Range
    if (name === "startDate") {
      setEndDate("");
    }
    let obj = { ...filterObj, [name]: name === "status" ? "" : actionVal };
    setFilterObj(obj);

    if (!open) {
      getCasesByFilter(obj);
    }
  };

  const chipAction = (isDelete, key) => {
    switch (key) {
      case "mako":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"MAKO"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setMako
        );
      case "as1":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"AS-1"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setAs1
        );
      case "surgery_date_confirmed":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"Confirmed"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setConfirmed
        );
      case "isWebOpsError":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"Error"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setWebOpsError
        );
      case "status":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getStatusLabel(filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setStatus
        );
      case "filterBranch":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setBranch
        );
      case "division":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={filterObj[key]}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setDivision
        );
      case "filterSalesRep":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setSalesRep
        );
      case "filterHospital":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setHospital
        );
      case "filterSalesTeam":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setSalesTeam
        );
      case "filterSurgeon":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          setSurgeon
        );
      case "startDate":
        if (!isDelete && startDate) {
          let lbl = "Date Range Applied";
          let To =
            endDate && endDate !== ""
              ? `To ${moment(endDate).format("MM/DD/YYYY")}`
              : "";
          lbl = `${moment(startDate).format("MM/DD/YYYY")} ${To}`;
          return (
            <Chip
              label={lbl}
              onDelete={() => handleDelete(key)}
              className={classes.chip}
            />
          );
        } else {
          return setStartDate;
        }
      case "endDate":
        if (!isDelete && filterObj[key] !== "") {
          return null; // Chip rendered in Start Date as Form To
        } else {
          return setEndDate;
        }
      case "role":
        if (!isDelete && filterObj[key]) {
          return (
            <Chip
              label={filterObj[key]}
              onDelete={() => handleDelete(key)}
              className={classes.chip}
            />
          );
        } else {
          return setRole;
        }
      default:
        return null;
    }
  };

  const hadleSetControls = (appliedFilters, surgeon, branch, salesRep, salesTeam, hospital) => {
    {
      Object.keys(appliedFilters).forEach((key) => {
        const action = chipAction(true, key);
        // Need to handle based on control
        if (action) {
          if (action === setStatus) {
            const statusArr = appliedFilters[key].split(",");
            setStatus(statusArr);
          } else if (action === setHospital) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < hospital.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === hospital[i].value) {
                  strArr.push(hospital[i]);
                }
              }
            }
            setHospital([...strArr]);
          } else if (action === setSurgeon) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < surgeon.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === surgeon[i].value) {
                  strArr.push(surgeon[i]);
                }
              }
            }
            setSurgeon([...strArr]);
          } else if (action === setBranch) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < branch.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === branch[i].value) {
                  strArr.push(branch[i]);
                }
              }
            }
            setBranch([...strArr]);
          } else if (action === setSalesRep) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < salesRep.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === salesRep[i].value) {
                  strArr.push(salesRep[i]);
                }
              }
            }
            setSalesRep([...strArr]);
          } else if (action === setSalesTeam) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < salesTeam.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === salesTeam[i].value) {
                  strArr.push(salesTeam[i]);
                }
              }
            }
            setSalesTeam([...strArr]);
          } else if (action === setStartDate || action === setEndDate) {
            action(
              appliedFilters[key]
                ? moment(appliedFilters[key]).format("YYYY-MM-DD")
                : ""
            );
          } else {
            action(appliedFilters[key]);
          }
        }
      });
    }
  };

  useEffect(() => {
    const compareSurgeon = (a, b) => {
      const c1 = a.surgeonName.split(" - ");
      const c2 = b.surgeonName.split(" - ");
      var comp = 0;
      if (c1[1] > c2[1]) comp = 1;
      else if (c1[1] < c2[1]) comp = -1;
      return comp;
    };
    const compareBranch = (a, b) => {
      const c1 = a.label;
      const c2 = b.label;
      var comp = 0;
      if (c1 > c2) comp = 1;
      else if (c1 < c2) comp = -1;
      return comp;
    };
    var a = branchIds.toString();
    var c = a.split(",");

    const compare = (a, b) => {
      const c1 = a.userName;
      const c2 = b.userName;
      var comp = 0;
      if (c1 > c2) comp = 1;
      else if (c1 < c2) comp = -1;
      return comp;
    };
    const compareHospital = (a, b) => {
      const c1 = a.hospitalName.split(" - ");
      const c2 = b.hospitalName.split(" - ");
      var comp = 0;
      if (c1[1] > c2[1]) comp = 1;
      else if (c1[1] < c2[1]) comp = -1;
      return comp;
    };



    let sergeonApi = getSurgeons()
      .then((res) => {
        const sortedBySurgeonName =
          res.get_surgeons && res.get_surgeons.sort(compareSurgeon);
        return sortedBySurgeonName.map((item) => {
          return { label: item.surgeonName, value: item.surgeonID };
        });
      })
    let branchApi = getMainBranches()
      .then((res) => {
        const sortedUserByBranch = res.get_main_branches.sort(compareBranch);
        let y = sortedUserByBranch.map((item) => {
          return { label: item.branchName, value: item.branchID };
        });
        return y;
      })
    let salesRepApi = getBranchSalesrep(branchIds[0])
      .then((res) => {
        const sortedUserByBranch = res.get_user_by_branch.sort(compare);
        let y = sortedUserByBranch.map((item) => {
          item.label = item.userName;
          item.value = item.userID;
          return item;
        });
        return y;
      })
    let salesTeamApi = getSalesTeam(sfids[0])
      .then((res) => {
        return res.filter_sales_team.map((item) => {
          return { label: item.label, value: item.id };
        });
      })
    let hospitalApi = getHospitals()
      .then((res) => {
        const sortedByHospitalName =
          res.data.get_hospitals.sort(compareHospital);
        return sortedByHospitalName.map((item) => {
          return { label: item.hospitalName, value: item.hospitalId };
        });
      })

    const getData = async () => {
      let combinedData = await Promise.allSettled([sergeonApi, branchApi, salesRepApi, salesTeamApi, hospitalApi]);


      getFilters(sfids[0], combinedData);
      return {
        surgeon: combinedData[0],
        branch: combinedData[1],
        salesRep: combinedData[2],
        salesTeam: combinedData[3],
        hospital: combinedData[4],

      }
    }
    getData()
    getNotification()
    return () => {
      resetFilters();
      resetCases();
    };
  }, []);

  useEffect(() => {
    if (isSearching) {
      let filters = { ...filterObj, searchText };
      setLoading(true);
      getCases(currentViewName, currentDate, filters);
    }
  }, [searchText]);

  const onDateNavigation = async (value, event) => {
    setRemoveDateRange(true);
    // Remove Date Range from Filter
    let filters = { ...filterObj, startDate: "" };
    await getCases(currentViewName, new Date(value), filters);
  };

  const onDayChange = async (value) => {
    setCurrentDate(new Date(value));
    setCurrentViewName("Day");
    await getCases("Day", new Date(value));
  };

  const currentViewNameChange = async (displayName) => {
    await getCases(displayName, currentDate);
  };

  const getCasesByFilter = async (filters) => {
    setRemoveDateRange(false);
    await getCases(currentViewName, currentDate, filters);
  };

  const getCasesBySearch = async (searchedVal) => {
    setIsSearching(true);
    setSearchText(searchedVal);
  };

  const groupBy = (array, key) => {
    return array.reduce(function (result, curr) {
      (result[curr[key]] = result[curr[key]] || []).push(curr);
      return result;
    }, {});
  };

  const setAppoinetmentData = (
    cases,
    displayName = currentViewName,
    filterObj
  ) => {
    let appointments = [];
    cases.forEach((item, i) => {
      let surgeryDate = item.surgeryDate;
      surgeryDate =
        displayName === "Month"
          ? moment(surgeryDate).format("YYYY-MM-DD")
          : surgeryDate;
      // get all the cases from startDate in local time zone.
      let pushToView =
        moment(surgeryDate).format("YYYY-MM-DD") >=
        moment(filterObj.startDate).format("YYYY-MM-DD") &&
        moment(surgeryDate).format("YYYY-MM-DD") <=
        moment(filterObj.endDate).format("YYYY-MM-DD");
      pushToView = filterObj.searchText ? true : pushToView; // incase of search, push regardless of startDate
      if (pushToView) {
        appointments.push({
          title: item.caseId,
          startDate: surgeryDate,
          endDate: new Date(moment(surgeryDate).add(15, "m").toDate()),
          ...item,
        });
      }
    });

    if (displayName === "Month") {
      const items = groupBy(appointments, "startDate");
      let monnthlyAppointments = [];
      Object.keys(items).forEach((key, index) => {
        const total = items[key].length;
        monnthlyAppointments.push({
          title: items[key][0].title,
          startDate: items[key][0].startDate,
          endDate: new Date(
            moment(items[key][0].startDate).add(30, "m").toDate()
          ),
          otherCases: total - 1,
          totalCases: items[key],
          ...items[key][0],
        });
      });
      appointments = monnthlyAppointments;
    }
    setCalendarCases(cases, appointments);
  };

  const resetStartAndEndDates = (view, selDate, filters) => {
    let startDate = filters.startDate;
    let endDate = filters.endDate;

    if (view === "Month") {
      startDate = moment(selDate).startOf("month");
      endDate = moment(selDate).endOf("month");
    } else if (view === "Week") {
      startDate = moment(selDate).startOf("week");
      endDate = moment(selDate).endOf("week");
    } else {
      startDate = moment(selDate);
      endDate = moment(selDate);
    }

    return { ...filters, startDate, endDate };
  };

  const getCases = async (displayName, currentDate, appliedfilters = null) => {
    setLoading(true);
    let obj = appliedfilters ? appliedfilters : filterObj;
    let navDate = obj.startDate ? obj.startDate : currentDate;

    // Reset Start and End Dates.
    if (isSearching && !searchText) {
      const selDate = obj.startDate ? obj.startDate : new Date();
      obj = resetStartAndEndDates(displayName, selDate, obj);
    } else if (!obj.startDate && !searchText) {
      obj = resetStartAndEndDates(displayName, currentDate, obj);
    } else if (obj.startDate && !obj.endDate) {
      // if To range not provided so
      obj = resetStartAndEndDates(displayName, obj.startDate, obj);
    }

    // Format the start date
    obj = { ...obj, startDate: obj.startDate ? moment(obj.startDate) : null };

    // Set Search Text
    if (searchText) {
      obj = { ...obj, searchText };
    }

    // Build Filter Request Variable
    let filters = [];
    Object.keys(obj).forEach((key) =>
      filters.push({ key: [key], value: obj[key] })
    );

    try {
      const {
        data: { get_calendar_cases },
      } = await getCalendarCases(filters, "no-cache");
      setAppoinetmentData(get_calendar_cases, displayName, obj);

      if (isSearching) {
        navDate =
          get_calendar_cases.length > 0
            ? get_calendar_cases[0].surgeryDate
            : navDate;
        setIsSearching(false);
      }
      setCurrentDate(new Date(navDate));
      setCurrentViewName(displayName);
      setLoading(false);
      if (get_calendar_cases.length === 0) {
        displaySnackBarMessage("error", `No cases found`);
      }
    } catch (ex) {
      setLoading(false);
      setIsSearching(false);
      displaySnackBarMessage(
        "error",
        "Something went wrong, Please try again!"
      );
    }
  };

  const resetFilter = () => {
    setSearchText("");
  };

  const displaySnackBarMessage = (severity, errorMessage) => {
    setNotification({
      severity: severity,
      message: errorMessage,
    });
    setOpenSnakBar(true);
  };

  return (
    <Grid container style={{ background: "#fafafa" }}>
      <SimpleBackdrop open={loading} />
      <Snackbars
        severity={notification.severity}
        open={openSnakBar}
        handleClose={() => setOpenSnakBar(false)}
        message={notification.message}
      />
      <CaseHeader
        pageTittle={"Case Calendar"}
        getCasesBySearch={getCasesBySearch}
      />
      <Filter
        getSalesTeam={getSalesTeam}
        getBranchSalesrep={getBranchSalesrep}
        getPresetFilters={getPresetFilters}
        getHospitals={getHospitals}
        getSurgeons={getSurgeons}
        getMainBranches={getMainBranches}
        setSelectedFilterName={setSelectedFilterName}
        selectedFilterName={selectedFilterName}
        setOpen={setOpen}
        open={open}
        webOpsError={webOpsError}
        setWebOpsError={setWebOpsError}
        setAs1={setAs1}
        as1={as1}
        setMako={setMako}
        mako={mako}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        division={division}
        setDivision={setDivision}
        setRole={setRole}
        role={role}
        setStatus={setStatus}
        status={status}
        setEndDate={setEndDate}
        endDate={endDate}
        startDate={startDate}
        setStartDate={setStartDate}
        surgeon={surgeon}
        setSurgeon={setSurgeon}
        setHospital={setHospital}
        hospital={hospital}
        setBranch={setBranch}
        branch={branch}
        salesTeam={salesTeam}
        setSalesTeam={setSalesTeam}
        salesRep={salesRep}
        setSalesRep={setSalesRep}
        checkFilterName={checkFilterName}
        setCheckFilterName={setCheckFilterName}
        removeDateRange={removeDateRange}
        currentDate={currentDate}
        getCasesByFilter={getCasesByFilter}
        initialFilter={initialFilter}
        resetFilter={resetFilter}
      />
      {banner.length > 0 && <Modal
        pop={pop}
        setPop={setPop}
        handleCheck={handleCheck}
        banner={banner}
      />}
      <SchedulerComponent
        onChange={onDateNavigation}
        currentViewNameChange={currentViewNameChange}
        currentDate={currentDate}
        currentViewName={currentViewName}
        appointments={appointments}
      />
    </Grid>
  );
};

export default CaseCalendar;
