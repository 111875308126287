import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: 'white',
    "&$checked": {
      color: '#FFB500'
    },
    "&$checked + $track": {
      backgroundColor: '#FFD271'
    }
  },
  checked: {},
  track: {
    backgroundColor: 'grey'
  }
}));

export default function SwitchLabels(props) {
  const classes = useStyles();
  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: props.surgeonApproval,
  // });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  //   props.toggleApproval();
  // };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={props.checked}
            onChange={(event) => props.fnc(event)}
            name="checked"
            color="primary"
            disabled={props.disabled}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked
            }}
          />
        }
        label={props.label}
        labelPlacement={props.labelPlacement}
      />

    </FormGroup>
  );
}
