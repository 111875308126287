import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DialogTitle, Divider } from "@material-ui/core";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import CaseCard from "../../../../components/CaseCard";

const CardViewDialog = (props) => {
  if (props.dialogOpen == true) {
    localStorage.setItem("selectedCheckBoxes", null);
    window.localStorage.setItem("isThisInLocalStorage2", "true");
    window.dispatchEvent(new Event("storage2"));
    }
  const monthName = moment(props.title).format("MMMM");
  const dayOfMonth = moment(props.title).format("DD");
  const year = moment(props.title).format("YYYY");
  const title = `${monthName} ${dayOfMonth} ${year}`;
  return (
    <Dialog
      style={{zIndex:10000}}
      maxWidth={false}
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {title}
        <CloseIcon
          style={{ float: "right" }}
          onClick={() => props.setDialogOpen(false)}
        />
        <Divider />
      </DialogTitle>
      <DialogContent
        style={{
          // backgroundColor: "whitesmoke",
          // padding: "5px",
          // marginLeft: "10px",
          maxWidth: "1135px",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              flexDirection: "wrap",
              margin: "0px 5px",
            }}
          >
            {props.data && props.data.map((item) => <CaseCard case={item} />)}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CardViewDialog;
