import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {getCaseByCaseID} from '../../utils/surgicalCase'
import {displayStatusLabel} from '../../utils/tpr'
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';

// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowRightIcon from '@material-ui/icons/ChevronRightRounded';
import { Button } from '@material-ui/core';
import { StyledCheckbox } from '../../components/controls/StyledCheckbox';
import { TPR_STATUS } from '../../constants';
import { API, ASYNC_STORAGE } from '../../constants';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgressWithLabel from '../treatment-plan-form/CircularProgressWithLabel';
import axios from 'axios';
import fileDownload from 'js-file-download'
import { getTprPatientId } from '../../graphql/queries';
const selectedDownloadableCase = [];
const selectedDownloadableCase2 = [];

function comparator(a, b, order, orderBy) {
    var result;
    switch (orderBy) {
        case "needByDate":
            var a1 = a.needByDate !== null ? a.needByDate : a.additionalData && a.additionalData.needByDate ? a.additionalData.needByDate : '12/31/2099';
            var b1 = b.needByDate !== null ? b.needByDate : b.additionalData && b.additionalData.needByDate ? b.additionalData.needByDate : '12/31/2099';
            if (b1 < a1)
                result = -1;
            else if (b1 > a1)
                result = 1;
            else
                result = 0;
            break;
        case "tprOpened":
            if (b.isTprOpened < a.isTprOpened)
                result = -1;
            else if (b.isTprOpened > a.isTprOpened)
                result = 1;
            else
                result = 0;
            break;
        default:
            break;
    }
    return (order ==='desc') ? result : (result * -1)
}

// function getComparator(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

function stableSort(array, order, orderBy) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        return comparator(a[0], b[0], order, orderBy);
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'externalReference', numeric: false, disablePadding: true, label: 'TPR ID' },
    { id: 'patientId', numeric: true, disablePadding: false, label: 'Patient ID ' },
    { id: 'branch', numeric: true, disablePadding: false, label: 'Branch' },
    { id: 'hospital', numeric: true, disablePadding: false, label: 'Hospital No - Name' },
    { id: 'surgeon', numeric: true, disablePadding: false, label: 'Surgeon No - Name' },
    { id: 'surgery', numeric: true, disablePadding: false, label: 'Surgery Start Date' },
    { id: 'operativeSide', numeric: true, disablePadding: false, label: 'Operative Side' },
    { id: 'needByDate', numeric: true, disablePadding: false, label: 'Needed by' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'Download', numeric: true, disablePadding: false, label: 'Download' },
];
function EnhancedTableHead(props) {
    const { showTprOpened, onRequestSort, orderBy, order} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const tableHeaders = showTprOpened ? [...headCells, { id: 'tprOpened', numeric: true, disablePadding: false, label: 'TPR Opened' }]
        : [...headCells];
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">

                </TableCell>
                {tableHeaders.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                    // sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            disabled={(headCell.id === 'needByDate') || (headCell.id === 'tprOpened')? false: true}
                            active={(orderBy === headCell.id)? true: false}
                            direction={orderBy === headCell.id && order == 'asc' ? 'asc' : 'desc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null} */}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: 1000,
        border: '1px solid lightgrey'
    },
}));

export default function TreatmentPlanTable(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const GetCaseById_Set = (response) => dispatch(userCase.setCaseDetail(response));
    const [order, setOrder] = React.useState(props.orderTable);
    const [orderBy, setOrderBy] = React.useState(props.orderByTable);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState();
    const [intialPage, setIntialPage] = React.useState();
    const { cases1 } = props
    const [alertOpen, setAlertOpen] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertType, setAlertType] = React.useState('')
    const [selectedDownloadZipFileScanID, setSelectedDownloadZipFileScanID] = React.useState("");
    const [downloadZipFileProgressBarValue, setDownloadZipFileProgressBarValue] = React.useState(0);

    useEffect(() => {
        if(props.tprList.pageIndex === 0){
            setIntialPage(props.tprList.pageIndex)
        } else{
            setIntialPage(props.tprList.pageIndex)
        }
        if(props.tprList && props.tprList.content && props.tprList.content.length){
            setRowsPerPage(props.tprList && props.tprList.content && props.tprList.content.length)
        } else {
            setRowsPerPage(0)
        }
    },[props.tprList])
    const handleRequestSort = (event, property) => {
        if (orderBy === property && order === 'desc')
            setOrder('asc');
        else
            setOrder('desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleLeftArrow(e) {
        let count = intialPage - 1
        if(intialPage === 0) return
        setIntialPage(count)
        props.handleLeftArrow(count, order, orderBy)
    }

    function handleRightArrow(e) {
        let count = intialPage + 1
        if(count === props.tprList.totalPages) return
        setIntialPage(count)
        props.handleRightArrow(count, order, orderBy)

    }

    function handleDownloadZipFile2 ( scanIds) {
        let selectedCaseIndex1 = selectedDownloadableCase2.findIndex(i => i.id === scanIds.externalReference);
        return selectedDownloadableCase2[selectedCaseIndex1].value;
    }

    const handleDownloadZipFile = async (e, scanIds, requestId, scanId) => {
        selectedDownloadableCase.push(scanIds.externalReference);
        selectedDownloadableCase2.push({ id :scanIds.externalReference, value : 0});
        var l = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN));
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );

        const pateientscanFileName = `${scanIds.externalReference}.zip`
        const patientscan_id = scanId;

        setSelectedDownloadZipFileScanID(patientscan_id);
        const urlPath = API.RHS.TREATMENT_PLAN_METADATA_URI;
        let url;
        let treatmentPlanId;
        if (requestId) {
            url = encodeURI(`${urlPath}?associatedTreatmentPlanRequestId=${requestId}&latestUploadedOnly=true`)
        }
        const headers = {
            headers: {
                Authorization: "Bearer " + mako_token,
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Custom-Header": "value",
            }
        }
        try {
            const { data } = await axios.get(url, headers);
            treatmentPlanId = data[0]?.treatmentPlanId;
            if (treatmentPlanId) {
                const config = {
                    method: "GET",
                    url: `${API.RHS.TREATMENT_PLAN_URI}/${treatmentPlanId}`, // new treatment plan api call
                    // url: API.RHS.PATIENT_SCAN_URI + patientscan_id,  //older patient scan download api call
                    data: {},
                    headers: {
                        Authorization:  "Bearer " + l.mako_token,
                        "Content-Type": "application/octet-stream",
                    },
                    responseType: 'blob',

                    onDownloadProgress: (progressEvent) => {

                        const { loaded, total } = progressEvent;
                        let percentCompleted = Math.floor((loaded * 100) / total);

                        if (percentCompleted < 100) {
                            let selectedCaseIndex1 = selectedDownloadableCase2.findIndex(i => i.id === scanIds.externalReference);
                            selectedDownloadableCase2[selectedCaseIndex1].value = percentCompleted;
                            setDownloadZipFileProgressBarValue(percentCompleted);
                        }
                    }
                };

                axios(config)
                    .then(response => {
                        setDownloadZipFileProgressBarValue(100);
                        setTimeout(() => {
                            setDownloadZipFileProgressBarValue(0);
                        }, 1000)
                        // let filename = pateientscanFileName;
                        // let index1 = selectedDownloadableCase.indexOf(scanIds.externalReference);
                        // let selectedCaseIndex = selectedDownloadableCase2.findIndex(i => i.id === scanIds.externalReference);
                        // // let filename = pateientscanFileName ? pateientscanFileName.includes('.') ? pateientscanFileName : pateientscanFileName + '.zip' : patientscan_id + '.zip'
                        // fileDownload(response.data, filename);
                        // selectedDownloadableCase.splice(index1, 1);
                        // selectedDownloadableCase2.splice(selectedCaseIndex, 1);
                        let filename = data[0]?.treatmentPlanFilename
                        fileDownload(response.data, filename);
                    })
                    .catch(error => {
                        setAlertOpen(true)
                        setTimeout(function () {
                            setAlertOpen(false)
                        }, 10000);

                        setAlertMessage("Patient scan cannot be downloaded")
                        setAlertType("error")
                    })
            }
        } catch (error) {
            setAlertOpen(true)
            setTimeout(function () {
                setAlertOpen(false)
            }, 10000);
            setAlertMessage("Patient scan cannot be downloaded,issue with getting treatmentPlanId");
            setAlertType("error");
        }
    }

    /** MP: To reduce getcasebyid multiple calls */
    const getPatientIDData = (id, id2) => {
        getTprPatientId(id).then((res) => {
            if(res){
                var myDiVElem = document.getElementById(id2);
                if (myDiVElem != null) {
                    myDiVElem.innerHTML = res.get_tpr_patient_id.patientID;
                }
                return '';
            };
        }).catch(e => {
            console.log('error tpr patient scan', e);
        })
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const viewTPRDetails = (e, row) => {
        getCaseByCaseID(row.externalReference).then((res) => {
            GetCaseById_Set(res.data);
            history.push('/ViewSurgicalCase');
        })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            showTprOpened={props.showTprOpened}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.tprList.totalPages}
                            columns={headCells}
                        />
                        <TableBody>
                            {stableSort(props.tprList && props.tprList.content, order, orderBy)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            //   onClick={(event) => handleClick(event, row.name)}
                                            // role="checkbox"
                                            // aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.metadata.requestId}
                                        // selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <Link onClick={() => props.viewTPRDetails(row)} style={{ textDecoration: 'none', color: '#039be5' }}>
                                                    {row.externalReference}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="left" id={row ?row.metadata.requestId: ''}>{row ? getPatientIDData(row.externalReference, row.metadata.requestId) : '--'}</TableCell>
                                            <TableCell align="left">{row.region ? row.region : '--'}</TableCell>
                                            <TableCell align="left">{row.treatment && row.treatment.hospitalName ? row.treatment.hospitalName : row.additionalData && row.additionalData.hospitalName ? row.additionalData.hospitalName : '--'}</TableCell>
                                            <TableCell align="left">{`${row.treatment && row.treatment.surgeonName && row.treatment.surgeonName.firstName ? row.treatment.surgeonName.firstName :
                                                row.additionalData && row.additionalData.surgeonName && row.additionalData.surgeonName.firstName ? row.additionalData.surgeonName.firstName : '--'} ${row.treatment && row.treatment.surgeonName && row.treatment.surgeonName.lastName ? row.treatment.surgeonName.lastName : row.additionalData && row.additionalData.surgeonName && row.additionalData.surgeonName.lastName ? row.additionalData.surgeonName.lastName : '--'}`}</TableCell>
                                            <TableCell align="left">{row.treatment && row.treatment.surgeryDate ? moment(row.treatment.surgeryDate).format('MM/DD/YYYY') : '--'}</TableCell>

                                            <TableCell align="left">{row.treatment ? (row.treatment.operativeSide === 'left' ? 'Left' : 'Right') : '' }</TableCell>
                                            <TableCell align="left">{row.needByDate !== null ? moment(row.needByDate).format('MM/DD/YYYY') : row.additionalData && row.additionalData.needByDate ? moment(row.additionalData.needByDate).format('MM/DD/YYYY') : '--' }</TableCell>
                                            <TableCell align="left">{displayStatusLabel(row.metadata.commentedRequestState.requestState)}</TableCell>
                                            <TableCell size="small" align="center">
                                                {downloadZipFileProgressBarValue > 0 && row && row.patientScan && selectedDownloadableCase.includes(row.externalReference)
                                                    ? <CircularProgressWithLabel value={handleDownloadZipFile2(row)} />
                                                    :
                                                    (row.metadata.commentedRequestState.requestState === 'TreatmentPlanAvailable' || row.metadata.commentedRequestState.requestState === 'TreatmentPlanDelivered') && (row && row.patientScan) ?
                                                        <IconButton aria-label="download" onClick={(e) => handleDownloadZipFile(e, row, row.metadata.requestId, row.patientScan.id)}>
                                                            <ArrowDownwardIcon fontSize="inherit" />
                                                        </IconButton> : '-'
                                                }
                                            </TableCell>
                                            {props.showTprOpened ? <TableCell align="center">
                                                <StyledCheckbox disabled checked={row.isTprOpened} />
                                            </TableCell> : null}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{display: 'grid', minHeight: '52px', paddingRight: '2px', gridTemplateColumns:'2fr auto auto auto auto auto', gridGap: '30px', marginRight: '45px'}}>

                    <span style={{alignSelf: 'center', padding: '0px 50px'}}>
                        Rows Per Page: {`${rowsPerPage}`}
                    </span>
                    <span style={{alignSelf: 'center'}}>Page: {`${intialPage+1}-${props.tprList.totalPages}`}</span>
                    <span style={{alignSelf: 'center'}}>Previous</span>
                    <Button
                        disabled={intialPage+1 === 1 ? true : false}
                    >
                        <KeyboardArrowLeftIcon style={{alignSelf: 'center', cursor: 'pointer'}} onClick={(e) => handleLeftArrow(e)}/>
                    </Button>
                    {/* <span>Next</span> */}
                    <Button
                        disabled={intialPage+1 === props.tprList.totalPages ? true : false}
                    >
                        <KeyboardArrowRightIcon style={{alignSelf: 'center', cursor: 'pointer'}} onClick={(e) => handleRightArrow(e)} disable={true}/>
                    </Button>
                    <span style={{alignSelf: 'center'}}>Next</span>
                </div>
            </Paper>
        </div>
    );
}
