import React, { useState, useEffect } from 'react';
import GraphQLClient from '../../graphql/client';
import gql from 'graphql-tag';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const commentHistoryQuery = gql`
  query getFieldHistory(
    $sfid: String
    $object_name: String
    $field_name: String
  ) {
    get_field_history(
      sfid: $sfid
      object_name: $object_name
      field_name: $field_name
    ) {
      value
      old_value__c
      user_name
      updated_date
    }
  }
`;

export default function CommentHistory(props) {
    let [comments, setComments] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        const { caseItem } = props;

        if (!caseItem) {
            setLoading(false);
            return;
        }

        const loadComments = async () => {
            const results = await GraphQLClient.query({
                query: commentHistoryQuery,
                variables: {
                  sfid: caseItem.sfId,
                  object_name: 'Cases__c',
                  field_name: 'Case Comments'
                },
                fetchPolicy: 'network-only'
              });
    
            let internalComments = [];

            // history from the record_history__c table
            if (results && results.data && results.data.get_field_history) {
                internalComments = internalComments.concat(
                    results.data.get_field_history.map(historyItem => ({
                        name: historyItem.user_name
                            ? historyItem.user_name
                            : caseItem.salesRep,
                        text: historyItem.value,
                        date: moment(historyItem.updated_date),
                        oldText: historyItem.old_value__c
                    }))
                );
            }
        
            // if there has been at least one comment then a comment on
            // initial create can be retrieved from the first histories
            // old value
            if (caseItem.sfId && internalComments && internalComments.length > 0) {
                if (internalComments[internalComments.length - 1].oldText) {
                    internalComments.push({
                        name: caseItem.salesRep,
                        text: internalComments[internalComments.length - 1].oldText,
                        date: moment(caseItem.createdDate)
                    });
                }
            }
        
            // if there are no comments or if the most recent comment
            // doesn't match the internal comment then this is a new
            // unsynchronized comment
            if (
                (internalComments!=null &&
                    internalComments.length > 0 &&
                    internalComments[0].text !== caseItem.internalComment) ||
                ((internalComments == null || internalComments.length === 0) && caseItem.internalComment)
            ) {
                internalComments.splice(0, 0, {
                    name: caseItem.salesRep,
                    text: caseItem.internalComment,
                    date: moment()
                });
            }

            setComments(internalComments);
            setLoading(false);
        };
        loadComments();
    }, []);

    const items = comments.map((comment) =>
        <p>
            <strong>{comment.date.format('MMM D, YYYY [at] hh:mm A')}</strong><br />
            {comment.text || ''}
        </p>
    );

    return loading ? <CircularProgress style={{ color: "gold" }} disableShrink /> : <span>{items}</span>;
}