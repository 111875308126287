import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import AddSets from './AddSets';
import Catalog from './Catalog';
import { tabChangeToIndex } from '../../redux/modules/formChangeLog';
import { useDispatch, useSelector } from 'react-redux';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      'box-shadow': '0px !important',
      // width: 100,
    },
    camelCase: {
      textTransform: 'none',
      padding: "0px",
      'font-family': 'Arial, Helvetica, sans-serif',
      color: '#000',
      'font-size': '14px',
    },
    camelCaseTPR: {
      textTransform: 'none',
      padding: "0px",
      'font-family': 'Arial, Helvetica, sans-serif',
      color: '#000',
      'font-size': '14px',
      display: 'none',
    },

  }));

export default function AddSetsCatalog(props) {
   const classes = useStyles();
    const dispatch = useDispatch();
  const theme = useTheme();
  const setTabChangeToIndex = (index) => dispatch(tabChangeToIndex(index, 2))
  const { isFormDirty, tabChangeIndex, tabSwitchConfirm, tabLevel } = useSelector(state => state.formChangeLog)

  useEffect(() => {
    if(tabLevel === 2 && isFormDirty && tabSwitchConfirm){
      setValue(tabChangeIndex);
    }
  }, [tabSwitchConfirm])

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if(isFormDirty){
      setTabChangeToIndex(newValue)
    } else {
      setValue(newValue);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className="customTab">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs" 
          padd>
          <Tab className={classes.camelCase} label="Set Assignment" {...a11yProps(0)} />
          <Tab className={classes.camelCase} label="Catalog" {...a11yProps(1)} />
          </Tabs>
      </AppBar>
        
        
        <TabPanel  value={value} index={0} dir={theme.direction}>
        <AddSets caseDetails={props.caseDetails} displaySnackBarMessage={props.displaySnackBarMessage}/>       
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Catalog caseDetails={props.caseDetails} displaySnackBarMessage={props.displaySnackBarMessage}/>
        </TabPanel>
    </div>
  );

}