import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CaseDetils from '../../container/case-details-components/CaseDetails'
import TPRHistory from '../../container/RelatedList/TprHistory'
import TPRDetails from '../../container/treatment-plan-form/TPRDetails'
import AddSetsCatalog from '../../container/AddSets/AddSetsCatalog'
import AddPartsImagesSurgerySheet from '../../container/Usage/AddPartsImagesSurgerySheet'
import { ASYNC_STORAGE } from '../../constants';
import { ACTION_CREATORS as tab } from '../../redux/reducers/tab';
import { getSurgicalCasesErrorCodes } from '../../redux/modules/surgicalPlanErrorCodes'
import { useDispatch, useSelector } from 'react-redux';
import Snackbars from './Snackbar'
import { tabChangeToIndex } from '../../redux/modules/formChangeLog';
import tabLeavingGuard from '../hoc/tabLeavingGuard';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    'box-shadow': '0px !important',
    // width: 100,
  },
  camelCase: {
    textTransform: 'none',
    padding: "0px",
    'font-family': 'Arial, Helvetica, sans-serif',
    color: '#000',
    'font-size': '14px',
  },
  // camelCaseTPR: {
  //   textTransform: 'none',
  //   padding: "0px",
  //   'font-family': 'Arial, Helvetica, sans-serif',
  //   color: '#000',
  //   'font-size': '14px',
  //   display: 'none',
  // },
}));

function CaseTabs(props) {
  const current_user = JSON.parse(localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}");
  const { personas } = current_user;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showTPRTab, setTPRTab] = useState(false)
  const [showAddSets, setAddSets] = useState(false)

  const [notification, setNotification] = React.useState({ severity: "", message: "" })
  const [TprRhsId, setTprRhsId] = React.useState('')
  const dispatch = useDispatch();
  const SET_TAB = (response) => dispatch(tab.setTab(response));
  const setTabChangeToIndex = (index) => dispatch(tabChangeToIndex(index))
  const { isFormDirty, tabChangeIndex, tabSwitchConfirm, tabLevel } = useSelector(state => state.formChangeLog)
  //const Active_TAB = useSelector(state => state.tab.activeTab);

  useEffect(() => {
    if (props.caseDetails.mako || props.caseDetails.as1) {
      setTPRTab(true)
    }
    if(props.caseDetails.as1){
      dispatch(getSurgicalCasesErrorCodes())
    }
    if (props.caseDetails.surgery_date_confirmed__c) {
      setAddSets(true)
    }
    /**
     * unmounting the component below
     */
    return () => {
      setTPRTab(false)
    }
  }, [props.caseDetails.mako, props.caseDetails.as1])

  useEffect(() => {
    if(tabLevel === 1 && isFormDirty && tabSwitchConfirm){
      setValue(tabChangeIndex);
      SET_TAB(tabChangeIndex)
    }
    handleChange(2);
  }, [tabSwitchConfirm])

  const displaySnackBarMessage = (severity, errorMessage) => {
    setNotification({
      severity: severity, message: errorMessage
    })
    setOpen(true)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  //const tabnameValue =0 ///will pass value from table id click of related case ->index->table 
  SET_TAB(0)
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if(personas.includes('R&D') && props.caseDetails.caseId === "Case ID TBD") {
      displaySnackBarMessage("error", "Please wait until Case ID is generated.")
      setValue(0)
      SET_TAB(0)
    } else if (!personas.includes('R&D') && (props.caseDetails.caseId === "Case ID TBD" || (props.caseDetails.webops_id__c === null || props.caseDetails.webops_id__c === ""))) {
      displaySnackBarMessage("error", "Please wait until WebOps ID and Case ID is generated.")
      setValue(0)
      SET_TAB(0)
    }
    else {
      if(isFormDirty){
          setTabChangeToIndex(newValue)
      } else {
        const c = localStorage.getItem('comingFromCardView');
            if (c == 'true') {
              setValue(2);
            SET_TAB(2);
            const c11 = localStorage.getItem("selectedTprId");
            setTprRhsId(c11);
            }
            else {
              if (newValue == undefined) {
                setValue(0);
                SET_TAB(0);
                return;
              }
        setValue(newValue);
        SET_TAB(newValue)
      }
    }
    }
  };

  const getIndexToChangeTab = (index, tpr_rhs_id) => {
    setValue(index);
    SET_TAB(index)
    if (tpr_rhs_id !== '') {
      setTprRhsId(tpr_rhs_id)
    }
  }

  const handleChangeIndex = (index) => {
    setValue(index);
    SET_TAB(index)
  };

  return (
    <div className={classes.root}>
      <Snackbars severity={notification.severity} open={open} handleClose={handleClose} message={notification.message} />
      <AppBar position="static" color="default" className="customTab">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs" >
          <Tab className={classes.camelCase} label="Details" {...a11yProps(0)} />
          <Tab className={classes.camelCase} label="Related List" {...a11yProps(1)} />
          {showTPRTab ?
            <Tab
              className={classes.camelCase}
              label="Add Treatment Plan Request"
              {...a11yProps(2)} />
            : null}
          {showAddSets ? <Tab className={classes.camelCase} label="Add Sets" {...a11yProps(3)} disabled={personas.includes('R&D')}/> : null}
          <Tab className={classes.camelCase} label="Usage" disabled={personas.includes('R&D')} {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CaseDetils caseDetails={props.caseDetails} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {props.caseDetails.sfId ? <TPRHistory onPress={getIndexToChangeTab} caseDetails={props.caseDetails} caseid={props.caseDetails.sfId} /> : null}
        </TabPanel>
        {/* Next 3 tabs will be displayed dynamically */}
        <TabPanel value={value} index={2} className={showTPRTab ? 'tabpanelTreatmentPlan' : ''} dir={theme.direction}>
          {/* index 2 tab*/}
          {/* Possible Components to be rendered at index 2: TPR / AddSets / Usage */}
          {/* make sure you change in other indexs as well. bcz tab panels will be displayed dynamically */}
          {showTPRTab ?
            (TprRhsId !== '' ? <TPRDetails caseDetails={props.caseDetails} selectedRhsTprId={TprRhsId} displaySnackBarMessage={displaySnackBarMessage} /> : <TPRDetails onPress={getIndexToChangeTab} caseDetails={props.caseDetails} displaySnackBarMessage={displaySnackBarMessage} />)
            : showAddSets ?
              <AddSetsCatalog caseDetails={props.caseDetails} displaySnackBarMessage={displaySnackBarMessage} />
              : <AddPartsImagesSurgerySheet caseDetails={props.caseDetails} displaySnackBarMessage={displaySnackBarMessage} />}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {/* You will see this index panel if either TPR[2] or AddSets[2] rendered  */}
          {/* Possible Components to be rendered at index 3: AddSets / Usage */}
          {/* make sure you change in other indexs as well. bcz tab panels will be displayed dynamically */}
          {showTPRTab && showAddSets ?
            <AddSetsCatalog caseDetails={props.caseDetails} displaySnackBarMessage={displaySnackBarMessage} />
            : ((!showTPRTab && showAddSets) || (showTPRTab && !showAddSets)) && 
            <AddPartsImagesSurgerySheet caseDetails={props.caseDetails} displaySnackBarMessage={displaySnackBarMessage} />}
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          {/* You will see this index panel only if both TPR[2] and AddSets[3] are rendered */}
          {/* Possible Components to be rendered at index 4: Usage */}
          {/* make sure you change in other indexs as well. bcz tab panels will be displayed dynamically */}
          {/* {showTPRTab && showAddSets ? */}
            <AddPartsImagesSurgerySheet caseDetails={props.caseDetails} displaySnackBarMessage={displaySnackBarMessage} />
            {/* // : ((!showTPRTab && showAddSets) || (showTPRTab && !showAddSets)) && "Usage Tab"} */}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

export default tabLeavingGuard(CaseTabs);
