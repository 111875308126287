import axios from 'axios'
import { API } from '../../constants';

//ActionTypes

const LOAD = 'surgicalCAsesAttachments/LOAD'
const LOAD_SUCCESS = 'surgicalCAsesAttachments/LOAD_SUCCESS'
const LOAD_FAILURE = 'surgicalCAsesAttachments/LOAD_FAILURE'
const RESET_SURGICAL_CASES_ATTACHMENTS = 'surgicalCAsesAttachments/RESET_SURGICAL_CASES_ATTACHMENTS'

const initialState = {
    loaded: false,
    loading: false,
    surgicalCasesList: [],
    surgicalCasesListError: {}
}

export default function surgicalCasesAttachments(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                surgicalCasesList: action.payload,
                surgicalCasesListError: {}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                surgicalCasesList: [],
                surgicalCasesListError: action.payload
            }
        case RESET_SURGICAL_CASES_ATTACHMENTS:
            return {
                ...state,
                surgicalCasesList: [],
                surgicalCasesListError: {}
            }
        default:
            return state
    }
}

export function getSurgicalCasesAttachments(uploadParameters, rhsToken) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        const { associatedTreatmentPlanRequestId } = uploadParameters
        const urlPath = API.RHS.SURGICAL_CASE_ATTACHMENTS_URI;
        let url;
         if(associatedTreatmentPlanRequestId){
            url = encodeURI(`${urlPath}?associatedTreatmentPlanRequestId=${associatedTreatmentPlanRequestId}`)
        }
        const headers = {headers: {
            Authorization: "Bearer " + rhsToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Custom-Header": "value",
        }}
        axios
            .get(url, headers)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    error
                })
            })
    }
}

export function resetSurgicalCasesAttachments (){
    return dispatch => {
        dispatch({
            type: RESET_SURGICAL_CASES_ATTACHMENTS
        })
    }
}
