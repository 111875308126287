import React, { Component } from 'react';
import AutoComplete from '../controls/AutoComplete'
import { connect } from 'react-redux'


class HospitalName extends Component {
    render() {
        return <AutoComplete fnc={this.props.Hospitallocfnc} label={"Hospital"} value={this.props.value} options={this.props.Options} Isdisabled={this.props.Isdisabled} />
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(HospitalName);