import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DialogTitle, Divider, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { SETS_PARTS_STATUS } from "../../constants";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#eaeaea",
    fontWeight: 700,
    padding: "Opx",
    margin: "0px",
  },
}));

const KitsDialog = (props) => {
  const classes = useStyles();
  const [partsFromSets, setPartsFromSets] = useState([]);
  const [parts, setParts] = useState([]);

  useEffect(() => {
    if (
      props.caseDetails &&
      props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED
    ) {
      setPartsFromSets(props.caseDetails.parts);
    }
  }, []);

  useEffect(() => {
    if (props.selectedSet) {
      const selectedSetParts = partsFromSets.filter(
        (part) => part.set_id === props.selectedSet.set_id
      );
      if (selectedSetParts.length > 0) {
        setParts(selectedSetParts);
      } else {
        setParts([]);
        props.displaySnackBarMessage(
          "error",
          "Part(s) Information Not Available"
        );
      }
    }
  }, [props.selectedSet]);

  return (
    <Dialog
      open={props.dialogOpen}
      onClose={() => props.setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialog}>
        {"Parts Information"}
        <CloseIcon
          style={{ float: "right" }}
          onClick={() => props.setDialogOpen(false)}
        />
        {/* <Divider/> */}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid item xs={12} sm={12}>
            {/* <CardContent> */}
            <TableContainer
              component={Paper}
              style={{ border: "1px solid lightgrey" }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Part No - Name</TableCell>
                    <TableCell align="left">Lot #</TableCell>
                    <TableCell align="left">Actual/Expected Qty </TableCell>
                    <TableCell align="left">Complete</TableCell>
                  </TableRow>
                </TableHead>
                {parts &&
                  parts.map((row) => (
                    <TableRow key={row.product_sfid}>
                      <TableCell size="small" component="th" scope="row">
                        {row.part_name}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {row.lot_number}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {`${row.quantity__c - row.missingqty}/${row.quantity__c}`}
                      </TableCell>
                      <TableCell>
                        {row.missingqty === 0 ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <BlockIcon style={{ color: "red" }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </TableContainer>
            {/* </CardContent> */}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default KitsDialog;
