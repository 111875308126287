import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  formValueChanged,
  resetFormChangeIndicator,
} from "../../redux/modules/formChangeLog";

// Take in a component as argument WrappedComponent
export default function routeLeavingGuard(WrappedComponent) {
  // And return a new anonymous component
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);
      // this.state = {
      //   isFormDirty: false,
      // };
      this.valueChanged = this.valueChanged.bind(this);
    }

    valueChanged(name) {
      if (!this.props.isFormDirty) {
        this.props.formValueChanged(name);
      }
    }

    componentWillUnmount() {
      this.props.resetFormChangeIndicator();
    }

    render() {
      return (
        <>
          <Prompt when={this.props.isFormDirty} message={""} />
          <WrappedComponent valueChanged={this.valueChanged} {...this.props} />
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return { isFormDirty: state && state.formChangeLog.isFormDirty };
  }

  return connect(mapStateToProps, {
    formValueChanged,
    resetFormChangeIndicator,
  })(NewComponent);
}
