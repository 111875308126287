import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Buttons from "../../components/controls/Button";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { Grid } from "@material-ui/core";

export default function AlertDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center">
          <WarningRoundedIcon style={{ color: "orange" }} />
          {props.title}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons
          autoFocus
          size="medium"
          Icon="Submit"
          fnc={props.onCancel}
          name={props.cancelText ? props.cancelText : "Cancel"}
        />
        <Buttons
          size="medium"
          Icon="Submit"
          toSave
          fnc={props.onConfirm}
          name={props.confirmText ? props.confirmText : "Ok"}
        />
      </DialogActions>
    </Dialog>
  );
}
