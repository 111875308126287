export function compareHospital(a, b) {
    const c1 = a.hospitalName.split(" - ");
    const c2 = b.hospitalName.split(" - ");
    var comp = 0;
    if (c1[1] > c2[1])
        comp = 1;
    else if (c1[1] < c2[1])
        comp = -1;
    return comp;
};

export const makoProcedureType = [
    {
        id: 'Please Select',
        label: 'Please Select'
    }, {
        id: 'MCK Partial Knee',
        label: 'MCK Partial Knee'
    },
    {
        id: 'Total Hip',
        label: 'Total Hip'
    },
    {
        id: 'Triathlon Total Knee',
        label: 'Triathlon Total Knee'
    }]

export function compareSurgeon(a, b) {
    const c1 = a.surgeonName.split(" - ");
    const c2 = b.surgeonName.split(" - ");
    var comp = 0;
    if (c1[1] > c2[1])
        comp = 1;
    else if (c1[1] < c2[1])
        comp = -1;
    return comp;
}

