import React, { useEffect } from "react";
import { Grid, TablePagination, Typography } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";
import CaseCard from "../../components/CaseCard";
import SimpleBackdrop from "../../components/controls/Spinner";

const View = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  useEffect(() => {
    if (props.pageOffset === 0) {
      setPage(0);
    }
  }, [props.pageOffset]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handlePageNavigation(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };
  return (
    <Grid container style={{ padding: "10px" }}>
      <Grid container justify="center">
        {props.cases && props.cases.map((item) => (
          <CaseCard case={item} />
        ))}
      </Grid>
      {props.cases && props.cases.length > 0 && (
        <Grid container justify="center">
          {/* <Pagination count={props.totalCases} size="large"
            page={page}
            onChange={handleChangePage}
          /> */}
          <TablePagination
            component="div"
            count={props.totalCases}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default View;
