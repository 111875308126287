import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  button:{
    margin: theme.spacing(1),
    fontWeight: 'bold'
  },
  cloneButton: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    fontWeight: 'bold',
    "&:hover":{
      backgroundColor: 'white'
    }
  },
  submitButton: {
    margin: theme.spacing(1),
    backgroundColor: 'black',
    color: "#fff",
    fontWeight: 'bold',
    "&:hover":{
      backgroundColor: 'black'
    },
    "&:focus": {
      background: 'black!important',
      color: '#fff'
    },
  },
  // saveButton: {
  //   margin: theme.spacing(1),
  //   backgroundColor: '#FFB517',
  //   fontWeight: 'bold',
  //   "&:hover":{
  //     backgroundColor: '#FFB517'
  //   }
  // },
  saveButton: {
    margin: '8px',
    background: '#FFB500',
    // boxShadow: 'none',
    color: '#000',
    fontWeight: 'bold',
    "&:hover": {
      background: '#FFD271',
      // boxShadow: 'none',
      color: '#000'
    },
    "&:focus": {
      background: '#FFB500!important',
      // boxShadow: 'none',
      color: '#000'
    },
  },
  navigateButton:{
    backgroundColor: '#fbf9f9',
    fontWeight: 'bold',
    "&:hover":{
      backgroundColor: 'white'
    }
  },

  resetButton:{
    background:'#fff', 
    color:'#000', 
    boxShadow:'none', 
    border:'1px solid #E0E0E0', 
    fontWeight:'bold'
  }


}));

export default function Buttons(props) {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        autoFocus={props.autoFocus}
        className={props.toClone ? classes.cloneButton : props.toSubmit ? classes.submitButton : props.toSave ? classes.saveButton : props.toNavigate ? classes.navigateButton :classes.button}
        disabled={props.disabled}
        onClick={props.fnc}
        startIcon={<props.Icon />}>
        {props.name}

      </Button>
    </div>
  );
}

