import React, { useRef } from 'react';
import { FormControl, makeStyles } from '@material-ui/core';
import {default as ReactSelect} from 'react-select';
import { components } from 'react-select';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '97%'
    }
}));
const customStyles = {
  control: base => ({
    ...base,
    height: '51px',
    minHeight: '51px',
    border: '1px solid lightgrey',
    borderBottom:'2px solid lightgrey',
    borderRadius: 0,
    overflow:'overlay'
  })

};

const Option = (props) => {
    return (
      <div>
        <components.Option {...props} >
          <input
            type= "checkbox"
            checked= {props.isSelected}
            onChange= {()=> null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
}

export default function CustomDropdown(props) {
    const classes = useStyles();
    const theme = useTheme();
    const valueRef = useRef(props.value);
    valueRef.current = props.value;


    const selectAllOption = {
        value: "<SELECT_ALL>",
        label: "All"
      };
    
      const isSelectAllSelected = () =>
        valueRef.current.length === props.options.length;
    
      const isOptionSelected = option =>
        valueRef.current.some(({ value }) => value === option.value) ||
        isSelectAllSelected();
    
      const getOptions = () => [selectAllOption, ...props.options];
    
      const getValue = () =>
        isSelectAllSelected() ? [selectAllOption] : props.value;
    
      const onChange = (newValue, actionMeta) => {
        const { action, option, removedValue } = actionMeta;
    
        if (action === "select-option" && option.value === selectAllOption.value) {
          props.onChange(props.options, actionMeta);
        } else if (
          (action === "deselect-option" &&
            option.value === selectAllOption.value) ||
          (action === "remove-value" &&
            removedValue.value === selectAllOption.value)
        ) {
          props.onChange([], actionMeta);
        } else if (
          actionMeta.action === "deselect-option" &&
          isSelectAllSelected()
        ) {
          props.onChange(
            props.options.filter(({ value }) => value !== option.value),
            actionMeta
          );
        } else {
          props.onChange(newValue || [], actionMeta);
        }
      }
    

    return (
      <div>
        <FormControl className={classes.formControl} >
          <label style={{fontSize:12}} className="controllabelCreate" >{props.label}</label>

          <ReactSelect 
                  isOptionSelected={isOptionSelected}
                  options={getOptions()}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option
                  }}
                  onChange={onChange}
                  allowSelectAll={true}
                  value={getValue()}
                  placeholder="Select Branch"
                  styles={customStyles}
          />
        </FormControl>
      </div>
    )
}
