import React, { useState } from "react";
import { Button, NativeSelect, Typography } from "@material-ui/core"
import { QQ_CODE_OPTIONS } from "../../constants";

export const QqCode = ({ qCode, qText, handleAlert }) => {
  const [selectMenu, setSelectMenu] = useState(qCode)
  const [textarea, setTextarea] = useState(qText)

  const handleSave = (e) => {
    e.preventDefault()
    handleAlert(selectMenu, textarea)
  }


  return (<>

    <div className="qqCodeMainContainer">
      <form onSubmit={handleSave}>
        <Typography variant="h6" className="qqLabel">QQ CODE</Typography>
        <NativeSelect
          className="qqSelectField"
          label="QQ CODE"
          value={selectMenu}
          onChange={(e) => setSelectMenu(e.target.value)}
          defaultValue="SELECT QQ CODE">
          <option value="">SELECT QQ CODE</option>
          {
            QQ_CODE_OPTIONS.map((d) => (
              <option key={d.id} >{d.label}</option>
            ))
          }
        </NativeSelect>
        <Typography variant="h6" className="qqRationalLable">QQ RATIONALE</Typography>
        <textarea value={textarea} maxlength="250" onChange={(e) => setTextarea(e.target.value)} className="qqRationalField" rows="10" cols="50" placeholder="REQUESTED AS PER SURGERY ADD DNS"></textarea>
        <Button variant="contained" onClick={handleSave} className="qqSaveButton">save</Button>
      </form>
    </div>


  </>

  )
}