import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { filter } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Dropdown from '../../components/controls/Dropdown'
import Buttons from '../../components/controls/Button'
import ToggleSwtich from '../../components/controls/ToggleSwitch'
import TreatmentPlanTable from './TreatmentPlanTable';
import { getListOfTreatmentPlanRequets, resetTprList } from '../../redux/modules/listTreatmentPlanRequests'
import { getTprFilter } from '../../redux/modules/treatmentPlanFilter'
import { saveTprFilter } from '../../redux/modules/saveTreatmentPlanFilter'
import { getBranches, getMainBranchList} from '../../redux/modules/branches'
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import TPRFilterSaveDialogue from './TPRFilterSaveDialogue';
import { caseType, createdInOption, makoStatusOptions, makoStatusIHSOptions, as1StatusOptions, procedureOption } from './TreatmentPlanUtils'
import Braches from '../../components/new-case-form/Braches';
import { set } from 'date-fns';
import Snackbars from '../../components/controls/Snackbar';
import TPRDetails from '../treatment-plan-form/TPRDetails';
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import { getCaseByCaseID } from '../../utils/surgicalCase';
import SimpleBackdrop from '../../components/controls/Spinner';
import { useHistory } from 'react-router-dom';
import { getTprOpenedInfo } from '../../graphql/queries';
import { TPR_STATUS } from '../../constants';
import SearchBar from 'material-ui-search-bar';
import CustomDropdown from '../../components/controls/CustomDropdown';


function TreatmentPlans(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const { branchId, tprFilterList, tprRhsList, loading, branchList } = props
    const [tprList, setTprList] = useState([]);
    const [rows, setRows] = useState([]);
    // const [branchValue, setbranchValue] = useState()
    const [cases, setCases] = useState('MAKO');
    const [status, setStatus] = useState('');
    const [createdIn, setCreatedIn] = useState('Last 10 Days');
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [branch, setBranch] = useState('All')
    const [procedure, setProcedure] = useState('All')
    const [isIHSUser, setIsIHSUser] = useState(false)
    const [savedSearch, setSaveSearch] = useState('')
    const [myTpr, setMyTpr] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)
    const [saveObject, setSaveObject] = useState({})
    const [branchValue, setbranchValue] = React.useState('');
    const [caseOptions, setCaseOptions] = useState(caseType)
    const [selectedTprfilterlist, setSelectedTprfilterlist] = useState([])
    const [savedSearchOptions, setSavedSearchOptions] = useState([{
        id: 'None',
        label: 'None'
    }])
    const [createdInOptions, setCreatedInOptions] = useState(createdInOption)
    const [statusOptionsMAKO, setStatusOptionsMAKO] = useState([])
    const [statusOptionsAS1, setStatusOptionsAS1] = useState(as1StatusOptions)
    const [procedureOptions, setProcedureOptions] = useState(procedureOption)
    const [show, setShow] = useState(true)
    const [branchOptions, setBranchOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = React.useState({ severity: "", message: "" })
    const [showTPR, setShowTPR] = useState(false);
    const GetCaseById_Set = (response) => dispatch(userCase.setCaseDetail(response));
    const surgicalCaseDetails = useSelector(state => state.cases.currentState);
    const [loadingTPR, setLoadingTPR] = useState(false);
    const [selectedRhsTprId, setSelectedRhsTprId] = useState('');
    const [showTprOpened, setShowTprOpened] = useState(false);
    const [orderTable, setOrderTable] = React.useState('desc');
    const [orderByTable, setOrderByTable] = React.useState('needByDate');
    const [myTprSearchText,setMyTprSearchText] = useState('');
    const [optionSelected,setOptionSelected] = useState([...branchOptions]);

    useEffect(() => {
        // props.getBranches(branchId.split(','))
        props.getMainBranchList();
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        const { personas } = JSON.parse(localStorage.getItem("PU:USER") || "{}");
        if (personas.includes('In-House')) {
            setIsIHSUser(true)
            setStatusOptionsMAKO(makoStatusIHSOptions)
            setStatus('Submitted')
        } else {
            setIsIHSUser(false)
            setStatus('Created')
            setStatusOptionsMAKO(makoStatusOptions)
        }
        props.getTprFilter()
        if (cases === 'MAKO') {
            var tenDaysAgo = moment().utc().subtract(10, 'days');
            setFromDate(tenDaysAgo.utc().format())
            setToDate(moment().utc().format())
            if(personas.includes('In-House')){
                setStatus('Submitted')
            } else {
                setStatus('Created')
            }
            const finalObj = {
                caseType: 'MAKO',
                makoToken: mako_token,
                requestState: personas.includes('In-House') ? 'Submitted' : 'Created',
                dateTimeFrom: tenDaysAgo.utc().format(),
                dateTimeTo: moment().utc().format(),
                branch: branch,
                procedure: procedure,
            }
            props.getListOfTreatmentPlanRequets(finalObj)
        }
    }, [])

    useEffect(() => {
        const tenDaysAgo = moment().utc().subtract(10, 'days');
        const oneMonthsAgo = moment().utc().subtract(1, 'months');
        const threeMonthsAgo = moment().utc().subtract(3, 'months');
        const _fromDate = createdIn === 'Last 10 Days' ? tenDaysAgo.utc().format() : createdIn === 'Last 1 Month' ? oneMonthsAgo.utc().format() : createdIn === 'Last 3 Months' ? threeMonthsAgo.utc().format() : ''
        setFromDate(_fromDate);
    }, [createdIn])

    const compareBranch = (a, b) => {
        const c1 = a.label;
        const c2 = b.label;
        var comp = 0;
        if (c1 > c2)
          comp = 1;
        else if (c1 < c2)
          comp = -1;
        return comp;
      }

    useEffect(() => {
        if (branchList.length) {
            const uniqueChecker = [];
            const finalAllBranches = [];
            var x = 1
            branchList.map(item => {
                let index = uniqueChecker.indexOf(item.branchID);
                if (index === -1) {
                    item.label = item.branchName;
                    item.id = item.branchID;
                    finalAllBranches.push({ id:item.branchName, value: x , label: item.branchName });
                    uniqueChecker.push(item.id);
                    x += 1
                }
            })
            const sortedBranch = finalAllBranches.sort(compareBranch)
            setBranchOptions(sortedBranch);
            if (finalAllBranches.length === 1) {
                let event = {};
                event.target = { value: finalAllBranches[0].id }
                handleBranchChangeDropdown(event)
            }
        }
    }, [branchList])

    useEffect(()=> {
        if(selectedTprfilterlist.length){
            const { mako_token, as1_token } = JSON.parse(
                localStorage.getItem("PU:TOKEN") || "{}"
            );
            var tenDaysAgo = moment().utc().subtract(10, 'days');
            var oneMonthsAgo = moment().utc().subtract(1, 'months');
            var threeMonthsAgo = moment().utc().subtract(3, 'months');

            let contact = {}
            if(selectedTprfilterlist[0].procedureType === 'MAKO'){
                Object.assign(contact, {
                    caseType:selectedTprfilterlist[0].procedureType, 
                    makoToken: mako_token,
                    requestState: selectedTprfilterlist[0].status,
                    dateTimeFrom: selectedTprfilterlist[0].createdIn === 'Last 10 Days' ? tenDaysAgo.utc().format() : selectedTprfilterlist[0].createdIn === 'Last 1 Month' ? oneMonthsAgo.utc().format() : selectedTprfilterlist[0].createdIn === 'Last 3 Months' ? threeMonthsAgo.utc().format() : '' ,
                    dateTimeTo: moment().utc().format(),
                    branch: selectedTprfilterlist[0].branch,
                    regions: selectedTprfilterlist[0].branch,
                    procedure: selectedTprfilterlist[0].procedure,
                })
            } else if(selectedTprfilterlist[0].procedureType === 'AS-1' || selectedTprfilterlist[0].procedureType === 'AS1'){
                Object.assign(contact, {
                    caseType:selectedTprfilterlist[0].procedureType, 
                    as1Token: as1_token,
                    requestState: selectedTprfilterlist[0].status,
                    dateTimeFrom: selectedTprfilterlist[0].createdIn === 'Last 10 Days' ? tenDaysAgo.utc().format() : selectedTprfilterlist[0].createdIn === 'Last 1 Month' ? oneMonthsAgo.utc().format() : selectedTprfilterlist[0].createdIn === 'Last 3 Months' ? threeMonthsAgo.utc().format() : '' ,
                    dateTimeTo: moment().utc().format(),
                    branch: selectedTprfilterlist[0].branch,
                    regions: selectedTprfilterlist[0].branch,
                    procedure: selectedTprfilterlist[0].procedure,
                })
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(contact)

        }
    }, [savedSearch, selectedTprfilterlist])

    useEffect(() => {
        let finalArray
        let intialArray = [{ "name": 'None' }]
        if (tprFilterList.length) {
            finalArray = intialArray.concat(tprFilterList)
        }
        setSavedSearchOptions(finalArray)
    }, [tprFilterList.length])

    useEffect(() => {
        if (tprRhsList) {
            if(isIHSUser && cases === "MAKO" && status !== TPR_STATUS.CREATED 
                && status !== TPR_STATUS.REJECTED && tprRhsList.content && tprRhsList.content.length > 0)
            {
                const trpNames = tprRhsList.content.map(tpr => tpr.externalReference)
                getTprOpenedInfo(trpNames)
                .then((res) => {
                    const tprSfdcData = res.get_tprs_opened;
                    const tprListContent = tprRhsList.content.map(item => {
                        const matchedTpr = tprSfdcData.find(tpr => tpr.tpr_name === item.externalReference)
                        item.isTprOpened = matchedTpr && matchedTpr.tpr_opened || false;
                        return item;
                    })

                    const tprListFinal = {
                        ...tprRhsList,
                        content: [...tprListContent],
                    }
                    setShowTprOpened(true);
                    setTprList(tprListFinal)
                    setRows(tprListFinal)
                })
            } else {
                setShowTprOpened(false);
                setTprList(tprRhsList)
                setRows(tprRhsList)
            }
        }
    }, [tprRhsList])

    useEffect(() => {
        if (tprList && myTpr) {
            const { email } = JSON.parse(
                localStorage.getItem("PU:USER") || "{}"
            );
            const filterArray = filter(tprList.content, function (row) {
                return row.primaryContact && row.primaryContact.email && row.primaryContact.email.toLowerCase() === email && email.toLowerCase() || row.otherContacts.find(obj => obj && obj.email && obj.email.toLowerCase() === email && email.toLowerCase());
            });
            const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
            setRows(finalTprObject)
        }
        if (tprList && procedure !== 'All') {
            const { email } = JSON.parse(
                localStorage.getItem("PU:USER") || "{}"
            );
            if(tprList && procedure !== 'All' && myTpr){
                const filterArray = filter(tprList.content, function (row) {
                    return  (row.primaryContact && row.primaryContact.email && row.primaryContact.email.toLowerCase() === email && email.toLowerCase() || row.otherContacts.find(obj => obj && obj.email && obj.email.toLowerCase() === email && email.toLowerCase())) && row.treatment && row.treatment.application && row.treatment.application.applicationType === (procedure === 'Total Hip' || procedure === 'TH' ? 'TH' : procedure === 'MCK Partial Knee' || procedure === 'MCKPK' ? 'MCKPK' : procedure === 'Triathlon Total Knee' || procedure === 'TTK' ? 'TTK' : procedure);
                });
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } else {
                const filterArray = filter(tprList.content, function (row) {
                    return row.treatment && row.treatment.application && row.treatment.application.applicationType === (procedure === 'Total Hip' || procedure === 'TH' ? 'TH' : procedure === 'MCK Partial Knee' || procedure === 'MCKPK' ? 'MCKPK' : procedure === 'Triathlon Total Knee' || procedure === 'TTK' ? 'TTK' : procedure);
                });
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            }
        } 
        
    }, [tprList])

    useEffect(() => {
        if(props.tprListError){
            displaySnackBarMessage('error', props.tprListError)
        }
    }, [props.tprListError])

    const handleCaseChange = event => {
        setCases(event.target.value);
        // setLoading(true);
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        if (event.target.value === 'MAKO') {
            if (status === 'Submitted' || status === 'Created' || status === 'InDesign' || status === 'Rejected' || status === 'TreatmentPlanAvailable' || status === 'TreatmentPlanDelivered' || status === 'Cancelled') {
                const finalObj = {
                    caseType: 'MAKO',
                    makoToken: mako_token,
                    requestState: status,
                    dateTimeFrom: fromDate,
                    dateTimeTo: toDate,
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj)
            } else {
                const finalObj = {
                    caseType: 'MAKO',
                    makoToken: mako_token,
                    requestState: 'Submitted',
                    dateTimeFrom: fromDate,
                    dateTimeTo: toDate,
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj)
            }
        } else if (event.target.value === 'AS1') {
            setProcedure('All')
            if (status === 'Submitted' || status === 'Created' || status === 'InDesign' || status === 'Rejected' || status === 'TreatmentPlanAvailable' || status === 'ConditionallyApproved' || status === 'ChangeRequested' || status === 'FinalPlanAvailable' || status === 'ShippedToSales' || status === 'Completed' || status === 'Cancelled') {
                const finalObj1 = {
                    caseType: 'AS1',
                    as1Token: as1_token,
                    requestState: status,
                    dateTimeFrom: fromDate,
                    dateTimeTo: toDate,
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj1)
            } else {
                setStatus('Created')
                const finalObj1 = {
                    caseType: 'AS1',
                    as1Token: as1_token,
                    requestState: 'Created',
                    dateTimeFrom: fromDate,
                    dateTimeTo: toDate,
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj1)
            }

        }
    }
    const handleStatusChange = event => {
        setStatus(event.target.value);
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        if (cases === 'MAKO') {
            const finalObj = {
                caseType: 'MAKO',
                makoToken: mako_token,
                requestState: event.target.value,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                branch: branch,
                procedure: procedure,
                regions: branchValue
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        } else if (cases === 'AS1') {
            const finalObj1 = {
                caseType: 'AS1',
                as1Token: as1_token,
                requestState: event.target.value,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                branch: branch,
                procedure: procedure,
                regions: branchValue
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj1)
        }
    }
    const handleCreatedInOptions = event => {
        setCreatedIn(event.target.value);
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        if (event.target.value === 'Last 1 Month') {
            var oneMonthsAgo = moment().utc().subtract(1, 'months');
            setFromDate(oneMonthsAgo.utc().format())
            setToDate(moment().utc().format())
            if (cases === 'MAKO') {
                const finalObj = {
                    caseType: 'MAKO',
                    makoToken: mako_token,
                    requestState: status,
                    dateTimeFrom: oneMonthsAgo.utc().format(),
                    dateTimeTo: moment().utc().format(),
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj)
            } else if (cases === 'AS1') {
                const finalObj1 = {
                    caseType: 'AS1',
                    as1Token: as1_token,
                    requestState: status,
                    dateTimeFrom: oneMonthsAgo.utc().format(),
                    dateTimeTo: moment().utc().format(),
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj1)
            }
        } else if (event.target.value === 'Last 10 Days') {
            var tenDaysAgo = moment().utc().subtract(10, 'days');
            setFromDate(tenDaysAgo.utc().format())
            setToDate(moment().utc().format())
            if (cases === 'MAKO') {
                const finalObj = {
                    caseType: 'MAKO',
                    makoToken: mako_token,
                    requestState: status,
                    dateTimeFrom: tenDaysAgo.utc().format(),
                    dateTimeTo: moment().utc().format(),
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj)
            } else if (cases === 'AS1') {
                const finalObj1 = {
                    caseType: 'AS1',
                    as1Token: as1_token,
                    requestState: status,
                    dateTimeFrom: tenDaysAgo.utc().format(),
                    dateTimeTo: moment().utc().format(),
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj1)
            }
        } else if (event.target.value === 'Last 3 Months') {
            var threeMonthsAgo = moment().utc().subtract(3, 'months');
            setFromDate(threeMonthsAgo.utc().format())
            setToDate(moment().utc().format())
            if (cases === 'MAKO') {
                const finalObj = {
                    caseType: 'MAKO',
                    makoToken: mako_token,
                    requestState: status,
                    dateTimeFrom: threeMonthsAgo.utc().format(),
                    dateTimeTo: moment().utc().format(),
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj)
            } else if (cases === 'AS1') {
                const finalObj1 = {
                    caseType: 'AS1',
                    as1Token: as1_token,
                    requestState: status,
                    dateTimeFrom: threeMonthsAgo.utc().format(),
                    dateTimeTo: moment().utc().format(),
                    branch: branch,
                    procedure: procedure,
                    regions: branchValue
                }
                props.resetTprList()
                props.getListOfTreatmentPlanRequets(finalObj1)
            }
        }
    }

    const handleProcedureOptions = event => {
        const { email } = JSON.parse(
            localStorage.getItem("PU:USER") || "{}"
        );
        // applicationType
        setProcedure(event.target.value);
        if (event.target.value === 'MCKPK') {
            if (myTpr) {
                const filterArray = tprList.content.filter((row) => (row.treatment && row.treatment.application && row.treatment.application.applicationType === 'MCKPK') && (row.primaryContact && row.primaryContact.email && row.primaryContact.email.toLowerCase() === email && email.toLowerCase() || row.otherContacts.find(obj => obj && obj.email && obj.email.toLowerCase() === email && email.toLowerCase())))
                // setRows(filterArray)
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } else {
                const filterArray = tprList.content.filter((row) => row.treatment && row.treatment.application && row.treatment.application.applicationType === 'MCKPK')
                // setRows(filterArray)
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            }
        } else if (event.target.value === 'TH') {
            if (myTpr) {
                const filterArray = tprList.content.filter((row) => (row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TH') && (row.primaryContact && row.primaryContact.email && row.primaryContact.email.toLowerCase() === email && email.toLowerCase() || row.otherContacts.find(obj => obj && obj.email && obj.email.toLowerCase() === email && email.toLowerCase())))
                // setRows(filterArray)
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } else {
                const filterArray = tprList.content.filter((row) => row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TH')
                // setRows(filterArray)
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            }
        } else if (event.target.value === 'TTK') {
            if (myTpr) {
                const filterArray = tprList.content.filter((row) => (row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TTK') && (row.primaryContact && row.primaryContact.email && row.primaryContact.email.toLowerCase() === email && email.toLowerCase() || row.otherContacts.find(obj => obj && obj.email && obj.email.toLowerCase() === email && email.toLowerCase())))
                // setRows(filterArray)
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } else {
                const filterArray = tprList.content.filter((row) => row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TTK')
                // setRows(filterArray)
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            }
        } else if (event.target.value === 'All' && myTpr) {
            const filterArray = tprList.content.filter((row) => row.primaryContact && row.primaryContact.email && row.primaryContact.email.toLowerCase() === email.toLowerCase() || row.otherContacts.find(obj => obj && obj.email && obj.email.toLowerCase() === email && email.toLowerCase()))
            // setRows(filterArray)
            const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
            setRows(finalTprObject)
        } else {
            setRows(tprList)
        }
    }

    const handleBranchChange = event => {
        setBranch(event.target.value);
    }

    const handleSavedSearchOptions = event => {
        if (event.target.value !== 'None') {
            let filterSavedSearch = tprFilterList.filter(filterObject => filterObject.name === event.target.value);
            setSelectedTprfilterlist(filterSavedSearch)
            if (filterSavedSearch.length) {
                setMyTpr(filterSavedSearch[0].myTpr)
                setbranchValue(filterSavedSearch[0].branch)
                if(filterSavedSearch[0].procedureType === 'MAKO'){
                    setStatusOptionsMAKO([{ id: filterSavedSearch[0].status, label: filterSavedSearch[0].status === 'Submitted' ? 'Submitted' 
                    : filterSavedSearch[0].status === 'Created' ? 'Created'
                    : filterSavedSearch[0].status === 'InDesign' ? 'In Design'
                    : filterSavedSearch[0].status === 'Rejected' ? 'Rejected'
                    : filterSavedSearch[0].status === 'TreatmentPlanAvailable' ? 'Treatment Plan Available'
                    : filterSavedSearch[0].status === 'TreatmentPlanDelivered' ? 'Treatment Plan Downloaded'
                    : filterSavedSearch[0].status === 'Cancelled' ? 'Cancelled' 
                    : filterSavedSearch[0].status}])
                } else if(filterSavedSearch[0].procedureType === 'AS1' || filterSavedSearch[0].procedureType === 'AS-1'){
                    setStatusOptionsAS1([{ id: filterSavedSearch[0].status, label: filterSavedSearch[0].status === 'Created' ? 'Created-Awaiting CT Scan' 
                    : filterSavedSearch[0].status === 'InDesign' ? 'In Design' 
                    : filterSavedSearch[0].status === 'Rejected' ? 'Rejected-Rework Required'
                    : filterSavedSearch[0].status === 'Approved' ? 'Surgical Plan Approved'
                    : filterSavedSearch[0].status === 'TreatmentPlanAvailable' ? 'Surgical Plan Available'
                    : filterSavedSearch[0].status === 'ConditionallyApproved' ? 'Surgical Plan Conditionally Approved' 
                    : filterSavedSearch[0].status === 'ChangeRequested' ? 'Surgical Plan Changes Requested' 
                    : filterSavedSearch[0].status === 'FinalPlanAvailable' ? 'Final Plan Available & Manf' 
                    : filterSavedSearch[0].status === 'ShippedToSales' ? 'Shipped To Sales'
                    : filterSavedSearch[0].status === 'Completed' ? 'Completed' 
                    : filterSavedSearch[0].status === 'Cancelled' ? 'Cancelled' 
                    : filterSavedSearch[0].status === 'Submitted' ? 'Submitted'
                    : filterSavedSearch[0].status}])
                }
                // setProcedure(filterSavedSearch[0].procedure)
                setProcedure(filterSavedSearch[0].procedure === 'TH' ? 'Total Hip' : filterSavedSearch[0].procedure === 'MCKPK' ? 'MCK Partial Knee' : filterSavedSearch[0].procedure === 'TTK' ? 'Triathlon Total Knee' : filterSavedSearch[0].procedure)
                setCases(filterSavedSearch[0].procedureType === 'AS1' ? 'AS-1' : filterSavedSearch[0].procedureType)
                // setBranchOptions([{ id: filterSavedSearch[0].branch, label: filterSavedSearch[0].branch }])
                const fromRange = filterSavedSearch[0].createdIn;
                setCreatedInOptions([{ id: fromRange, label: fromRange }])
                setCreatedIn(fromRange);
                setCaseOptions([{ id: filterSavedSearch[0].procedureType, label: filterSavedSearch[0].procedureType === 'AS1' ? 'AS-1' : filterSavedSearch[0].procedureType }])
                // setProcedureOptions([{ id: filterSavedSearch[0].procedure, label: filterSavedSearch[0].procedure }])
                setProcedureOptions([{ id: filterSavedSearch[0].procedure, label: filterSavedSearch[0].procedure === 'TH' ? 'Total Hip' : filterSavedSearch[0].procedure === 'MCKPK' ? 'MCK Partial Knee' : filterSavedSearch[0].procedure === 'TTK' ? 'Triathlon Total Knee' : filterSavedSearch[0].procedure }])
            }
        } else {
            setCases('MAKO')
            setSelectedTprfilterlist([])
            setCaseOptions(caseType)
            setCreatedInOptions(createdInOption)
            setCreatedIn('Last 10 Days');
            setStatusOptionsMAKO(makoStatusOptions)
            setStatusOptionsAS1(as1StatusOptions)
            setProcedureOptions(procedureOption)
            setMyTpr(false)
        }
        setSaveSearch(event.target.value)
    }

    const onClickSave = (e) => {
        var obj = {};
        Object.assign(obj, { procedureType: cases === 'AS1' ? 'AS-1' : cases }, { status: status }, { createdIn: createdIn }, { branch: branchValue === null || branchValue === '' ? branch : branchValue}, { procedure: procedure }, { myTpr: myTpr });
        setSaveObject(obj)
        setOpenDialog(true)
    }

    const cancelSave = (e) => {
        setOpenDialog(false)
        setSaveObject({})
    }

    const callSaveService = (finalObject) => {
        props.saveTprFilter(finalObject)
        setOpenDialog(false)
    }

    const handleMyTprSwitch = (event) => {
        setMyTpr(event.target.checked)
        if (event.target.checked) {
            const { email } = JSON.parse(
                localStorage.getItem("PU:USER") || "{}"
            );
            if(procedure === 'MCKPK' || procedure === 'MCK Partial Knee'){
                const filterArray = tprList.content.filter((row) => 
                row.treatment && row.treatment.application && row.treatment.application.applicationType === 'MCKPK' && (row.primaryContact &&  row.primaryContact.email && row.primaryContact.email.toLowerCase() === email.toLowerCase() 
                || row.otherContacts.find(obj => obj.email && obj.email.toLowerCase() === email.toLowerCase())))
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } 
            else if(procedure === 'TTK' || procedure === 'Triathlon Total Knee'){
                const filterArray = tprList.content.filter((row) => 
                row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TTK' && (row.primaryContact &&  row.primaryContact.email && row.primaryContact.email.toLowerCase() === email.toLowerCase() 
                || row.otherContacts.find(obj => obj.email && obj.email.toLowerCase() === email.toLowerCase())))
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } 
            else if(procedure === 'TH' || procedure === 'Total Hip'){
                const filterArray = tprList.content.filter((row) => 
                row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TH' && (row.primaryContact &&  row.primaryContact.email && row.primaryContact.email.toLowerCase() === email.toLowerCase() 
                || row.otherContacts.find(obj => obj.email && obj.email.toLowerCase() === email.toLowerCase())))
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } 
            else {
                const filterArray = tprList.content.filter((row) => row.primaryContact &&  row.primaryContact.email && row.primaryContact.email.toLowerCase() === email.toLowerCase() 
                || row.otherContacts.find(obj => obj.email && obj.email.toLowerCase() === email.toLowerCase()))
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            }
        }  
        else {
            if (procedure === 'MCKPK' || procedure === 'MCK Partial Knee') {
                const filterArray = tprList.content.filter((row) => row.treatment && row.treatment.application && row.treatment.application.applicationType === 'MCKPK')
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })

                setRows(finalTprObject)
            } else if (procedure === 'TH' || procedure === 'Total Hip') {
                const filterArray = tprList.content.filter((row) => row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TH')
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })

                setRows(finalTprObject)
            } else if (procedure === 'TTK' || procedure === 'Triathlon Total Knee') {
                const filterArray = tprList.content.filter((row) => row.treatment && row.treatment.application && row.treatment.application.applicationType === 'TTK')
                const finalTprObject = Object.assign({}, { content: filterArray, pageIndex: tprList.pageIndex, totalPages: tprList.totalPages })
                setRows(finalTprObject)
            } else if (procedure === 'All') {
                setRows(tprList)
            }
        }
    };

    const handleLeftArrow = (pageIndex, orderTable, orderByTable) => {
        setOrderTable(orderTable)
        setOrderByTable(orderByTable)
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        if (cases === 'MAKO') {
            const finalObj = {
                caseType: 'MAKO',
                makoToken: mako_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                regions: branchValue,
                branch: branch,
                procedure: procedure,
                pageIndex: pageIndex
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        } else if (cases === 'AS1') {
            const finalObj = {
                caseType: 'AS1',
                as1Token: as1_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                regions: branchValue,
                branch: branch,
                procedure: procedure,
                pageIndex: pageIndex
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        }
    }

    const handleRightArrow = (pageIndex, orderTable, orderByTable) => {
        setOrderTable(orderTable)
        setOrderByTable(orderByTable)
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        if (cases === 'MAKO') {
            const finalObj = {
                caseType: 'MAKO',
                makoToken: mako_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                regions: branchValue,
                branch: branch,
                procedure: procedure,
                pageIndex: pageIndex
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        } else if (cases === 'AS1') {
            const finalObj = {
                caseType: 'AS1',
                as1Token: as1_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                regions: branchValue,
                branch: branch,
                procedure: procedure,
                pageIndex: pageIndex
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        }
    }

    const handleBranchChangeDropdown = (event) => {
        setShow(false)
        // setbranchValue(event.target.value.id);
        // if (!event.target.value.id)
        setbranchValue(!event.target.value.label ? event.target.value : event.target.value.label);
        if (cases === 'MAKO' && typeof event.target.value === 'string') {
            const { mako_token } = JSON.parse(
                localStorage.getItem("PU:TOKEN") || "{}"
            );
            const finalObj = {
                caseType: 'MAKO',
                makoToken: mako_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                branch: branch,
                procedure: procedure,
                regions: typeof event.target.value === 'string' ? event.target.value : event.target.value.label
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        } else if (cases === 'AS1' && typeof event.target.value === 'string') {
            const { as1_token } = JSON.parse(
                localStorage.getItem("PU:TOKEN") || "{}"
            );
            const finalObj1 = {
                caseType: 'AS1',
                as1Token: as1_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                branch: branch,
                procedure: procedure,
                regions: typeof event.target.value === 'string' ? event.target.value : event.target.value.label
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj1)
        }
    };

    const displaySnackBarMessage = (severity, errorMessage) => {
      setNotification({
        severity: severity,
        message: errorMessage,
      });
      setOpen(true);
    };
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    const handleBack = () => {
        setShowTPR(false);
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        const finalObj1 = {
            caseType: cases,
            [cases === "MAKO" ? "makoToken" : "as1Token"]: cases === "MAKO" ? mako_token : as1_token,
            requestState: status,
            dateTimeFrom: fromDate,
            dateTimeTo: toDate,
            branch: branch,
            procedure: procedure,
            regions: branchValue
        }
        props.resetTprList()
        props.getListOfTreatmentPlanRequets(finalObj1)
    }

    const viewTPR = (selectedTPR) => {
        const { externalReference, metadata } = selectedTPR
        setLoadingTPR(true);
        setSelectedRhsTprId(metadata.requestId)
        getCaseByCaseID(externalReference)
        .then(res => {
            GetCaseById_Set(res.data);
            setLoadingTPR(false);
            isIHSUser ? setShowTPR(true) : history.push('/ViewSurgicalCase')
        })
    }

    useEffect(()=> {
        const { mako_token, as1_token } = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        if (cases === 'MAKO') {
            const finalObj = {
                caseType: 'MAKO',
                makoToken: mako_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                branch: branch,
                procedure: procedure,
                regions: branchValue,
                externalReference: myTprSearchText.toUpperCase()
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj)
        } else if (cases === 'AS1') {
            const finalObj1 = {
                caseType: 'AS1',
                as1Token: as1_token,
                requestState: status,
                dateTimeFrom: fromDate,
                dateTimeTo: toDate,
                branch: branch,
                procedure: procedure,
                regions: branchValue,
                externalReference: myTprSearchText.toUpperCase()
            }
            props.resetTprList()
            props.getListOfTreatmentPlanRequets(finalObj1)
        }
    },[myTprSearchText])

    const requestSearch = (searchVal) => {
        setMyTprSearchText(searchVal.trim());
        
    }
    const cancelSearch = ()=> {
        setMyTprSearchText('');
    }
    const onTprIdPaste = (event) => {
        const text = event.clipboardData.getData('Text')
        const value = text.split(' ').join('');
        setMyTprSearchText(value);
    }
    const resetHandler = () => {
        const {mako_token} = JSON.parse(
            localStorage.getItem("PU:TOKEN") || "{}"
        );
        setCases('MAKO')
        setFromDate(moment().utc().subtract(10, 'days').utc().format())
        setToDate(moment().utc().format())
        setProcedure('All')
        let event = {}
        event.target = {value: 'None'}
        handleSavedSearchOptions(event)
        setStatus('Created')
        setOptionSelected([...branchOptions])
        const finalObj = {
            caseType : 'MAKO' ,
            makoToken: mako_token,
            requestState: status,
            dateTimeFrom: moment().utc().subtract(10, 'days').utc().format(),
            dateTimeTo: moment().utc().format(),
            branch: branch,
            procedure: procedure,
            regions: 'All'
        }
        props.resetTprList()
        props.getListOfTreatmentPlanRequets(finalObj)
    }
    


    const filterHandler = () => {
        if (tprRhsList) {
            if(tprRhsList.content && tprRhsList.content.length > 0 && optionSelected.length > 0)
            {   
                var newArray = []
                optionSelected.map(item=> {
                    const trpNames = tprRhsList.content.filter(tpr=>tpr.region.includes(item.label))
                    if(trpNames.length !==0)
                     {newArray.push(...trpNames)}
                })

                const tprListFinal = {
                        ...tprRhsList,
                        content: [...newArray],
                    }
                setTprList(tprListFinal)
                setRows(tprListFinal)

            } else {
                const tprListFinal = {
                    ...tprRhsList,
                    content: [],
                }
            setTprList(tprListFinal)
            setRows(tprListFinal)
            }
        }
    }
    const AddButton = {
        background:'#FFB500',
        boxShadow:'none',
        color:'#000',
        fontWeight:'bold',
        margin:'10px',
        "&:hover":{
          background:'#FFD271',
          boxShadow:'none',
          color:'#000'
        },
        "&:focus":{
          background:'#FFB500!important',
          boxShadow:'none',
          color:'#000'
        },
      }
    
    return (
        <div>
            <SimpleBackdrop open={loading || loadingTPR} />
            <Snackbars severity={notification.severity} open={open} handleClose={handleClose} message={notification.message} />
            <Grid container style={{ background: '#fafafa' }} className="pagetitleHead ">
                <Grid item xs={3} container >
                    <div style={{ fontWeight: 'bold', fontSize: '14px', padding: '20px 0px 18px 15px' }}>Treatment Plan Request</div>
                </Grid>
                <Grid item xs={6} justify='center' alignItems='center' classname="pagetitleHead">
                  {/* <div style={{display:'flex', flexDirection:'wrap', position:'ralative'}}> */}
                  {!showTPR && <SearchBar
                    style={{ 
                      margin:'2px', 
                      padding:'0px', 
                      border:'0.5px solid lightgrey',
                      width:'95%',
                      //boxShadow:'none'
                    }}
                    placeholder="Search Case by TPR ID"
                    value={myTprSearchText}
                    onChange={(searchVal) => {requestSearch(searchVal)}}
                    onCancelSearch={() => {cancelSearch()}}
                    onPaste = {(event) => onTprIdPaste(event)} 
                  />}
                  {/*this.state.isSearching ? <LinearProgress/> : null*/}
                      {/* <CircularProgress style={{color:'gold',width:"30px",height:'30px',verticalAlign:'canter', marginTop:'10px'}}/>
                    </div>  */}
                </Grid>
                <Grid item xs={3} className="pagetitleHead" style={{ textAlign: 'right' }}>
                    {showTPR && <Buttons Icon="Submit" fnc={handleBack} name="GO BACK" />}
                </Grid>
            </Grid>
            <div style={{ display: 'grid', gridTemplateRows: '-0.5fr 1fr', padding: '20px' }}>
            {!showTPR ?
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                    <Dropdown
                        inpage={true}
                        label={"TPR Type"}
                        ctrllabel={""}
                        value={cases}
                        fnc={(e) => handleCaseChange(e)}
                        options={caseOptions}
                    />
                    <Dropdown
                        inpage={true}
                        label={"Status"}
                        ctrllabel={""}
                        value={status}
                        fnc={(e) => handleStatusChange(e)}
                        options={cases === 'MAKO' ? statusOptionsMAKO : statusOptionsAS1}
                    />
                    <Dropdown
                        inpage={true}
                        label={"Created In"}
                        ctrllabel={""}
                        value={createdIn}
                        fnc={(e) => handleCreatedInOptions(e)}
                        options={createdInOptions}
                    />
                    {/* <Dropdown
                        inpage={true}
                        label={"Branch"}
                        ctrllabel={branchOptions.length > 1 ? "All" : ''}
                        fnc={handleBranchChangeDropdown}
                        options={branchOptions}
                        value={branchValue}
                    /> */}
                    <CustomDropdown
                        label={"Branch"}
                        onChange={setOptionSelected}
                        options={branchOptions}
                        value={optionSelected}
                    />
                    {cases === 'MAKO' ? <Dropdown
                        Isdisabled={cases !== 'MAKO' ? true : false}
                        inpage={true}
                        label={"Procedure Type"}
                        ctrllabel={""}
                        value={procedure}
                        fnc={(e) => handleProcedureOptions(e)}
                        options={procedureOptions}
                    /> : null}
                    <Dropdown
                        inpage={true}
                        label={"Saved Search"}
                        ctrllabel={""}
                        value={savedSearch}
                        fnc={(e) => handleSavedSearchOptions(e)}
                        options={savedSearchOptions}
                    />
                    {cases !== 'MAKO' ? <div></div> : null}
                    <div></div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', height: '52px', marginTop: '20px' }}>
                        <div style={{ display:'grid', gridTemplateColumns: '1fr auto'}}>
                            <ToggleSwtich
                                label="My TPR"
                                fnc={(e) => handleMyTprSwitch(e)}
                                checked={myTpr}
                            />
                            <Grid item xs={12} >
                                <Buttons Icon="submit" name="RESET" fnc={resetHandler} />
                            </Grid>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 0.5fr', textAlign: 'end' }}>
                            <Grid item xs={12} style={{ textAlign: 'right' }} >
                                <Buttons Icon= "submit" toSubmit={true} name="FILTER" fnc={(e)=> filterHandler(e)} />
                            </Grid>
                            <div class="rFloat btnSave"><Buttons Icon="Submit" toSave={true} name="SAVE" fnc={(e) => onClickSave(e)} /></div>
                        </div>
                    </div>
                                        
                </div>
                : <TPRDetails selectedRhsTprId={selectedRhsTprId} caseDetails={surgicalCaseDetails} displaySnackBarMessage={displaySnackBarMessage} /> }
                <div style={{ marginLeft: '9px' }}>
                {rows.content && !showTPR ? <TreatmentPlanTable handleLeftArrow={handleLeftArrow} handleRightArrow={handleRightArrow} tprList={rows} showTprOpened={showTprOpened} viewTPRDetails={viewTPR} orderByTable={orderByTable} orderTable={orderTable}/> : null}
                </div>
            </div>
            {openDialog ? <TPRFilterSaveDialogue open={openDialog} handleClose={cancelSave} saveObject={saveObject} callSaveService={callSaveService} /> : null}
        </div>

    )
}

function mapStateToProps(state) {
    return {
        branchId: state && state.user.branchIds && state.user.branchIds.toString(),
        tprRhsList: state && state.getAllTreatmentPlans && state.getAllTreatmentPlans.listOfAllTreatmentPlans,
        tprListError: state && state.getAllTreatmentPlans && state.getAllTreatmentPlans.listOfAllTreatmentPlansError,
        loading: state && state.getAllTreatmentPlans && state.getAllTreatmentPlans.loading,
        tprFilterList: state && state.getTprFilter && state.getTprFilter.filterList,
        // loading: state && state.getTprFilter && state.getTprFilter.loading,
        // branchList: state && state.getBranches && state.getBranches.branchList,
        branchList: state && state.getBranches && state.getBranches.mainBranchList,
        
    };
}
export default connect(mapStateToProps, { saveTprFilter, getTprFilter, getBranches, getMainBranchList, resetTprList, getListOfTreatmentPlanRequets })(TreatmentPlans)
