import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 500,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectDropDown(props) {
  const classes = useStyles();
  const theme = useTheme();
  const name = <div className="controllabelCreate"  >{props.label}<span style={{ color: "red" }} >*</span></div>
  const valueSetter = (value,options) =>{
      if(value.length == 0){
        return value
      }else if(value.length > 0 && value[0].userID){
        return value
      }else if(options.length > 0 && value.length > 0){
        let values=[];
          options.map(option=> { if(value.indexOf(option.id) > -1) { 
            values.push(option);
          }})  
          props.fnc(values);
          return values
      }
      else{
        return [];
      }
  }
  
  return (
    <div>
 
      <FormControl className={classes.formControl}>
      <InputLabel shrink htmlFor="ddl-multiple">{name}</InputLabel>
      
        <Select
          labelId="mutiple-checkbox-label"
          id="ddl-multiple"
          multiple
          value={props.value.filter(item=>item.userID).length> 0 ? props.value: valueSetter(props.value,props.options)}
          // value={typeof(props.value) !== 'object' ? valueSetter(props.value,props.options) : props.value }
          onChange={props.fnc}
          input={<Input />}
          inputProps={{
            name: props.label,
            id: 'ddl',
          }}
          renderValue={(selected) => {
            return selected.map((item) => item.userName).join(", ");
          }}
          MenuProps={MenuProps}
        >
         {props.options.length > 0 ? props.options.map((name) => (
            <MenuItem key={name.userID} value={name} data-name={name.userName} >
              <Checkbox color='primary' checked={props.value.indexOf(name) > -1} />
              <ListItemText primary={name.userName} />
            </MenuItem>
          )) : null }
        </Select>
      </FormControl>
    </div> 
  );
}
