//Actions Types
const FORM_VALUE_CHANGED = "FORM_VALUE_CHANGED";
const TAB_CHANGE_INDEX = "TAB_CHANGE_INDEX";
const TAB_SWITCH_CONFIRMATION = "TAB_SWITCH_CONFIRMATION";
const RESET_CHANGE_INDICATOR = "RESET_CHANGE_INDICATOR";

export const formValueChanged = (controlName) => ({
  type: FORM_VALUE_CHANGED,
  payload: controlName,
});

export const tabChangeToIndex = (index, tabLevel = 1) => ({
  type: TAB_CHANGE_INDEX,
  payload: {index, tabLevel}
});

export const tabSwitchConfirmation = (confirm) => ({
  type: TAB_SWITCH_CONFIRMATION,
  payload: confirm,
});

export const resetFormChangeIndicator = () => ({
  type: RESET_CHANGE_INDICATOR,
});

const initialState = {
  controlName: "",
  isFormDirty: false,
  showAlert: false,
  tabSwitchConfirm: false,
  tabChangeIndex: 0,
  tabLevel: 1
};

export default function formChangeLog(state = initialState, action = {}) {
  switch (action.type) {
    case FORM_VALUE_CHANGED:
      return {
        ...state,
        controlName: action.payload,
        isFormDirty: true,
      };
    case TAB_CHANGE_INDEX:
      return {
        ...state,
        showAlert: true,
        tabChangeIndex: action.payload.index,
        tabLevel: action.payload.tabLevel
      };
    case TAB_SWITCH_CONFIRMATION:
      return {
        ...state,
        tabSwitchConfirm: action.payload,
        showAlert: false,
      };
    case RESET_CHANGE_INDICATOR:
      return initialState;
    default:
      return state;
  }
}
