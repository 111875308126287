import React, { useEffect, useState } from 'react';
import { API, ASYNC_STORAGE, REPSUITE_CASE_STATUS } from '../../constants';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';
import axios from 'axios'
import Buttons from '../../components/controls/Button'
import CaseTabs from '../../components/controls/CaseTabs'
import { bindActionCreators } from 'redux';
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import SimpleBackdrop from '../../components/controls/Spinner'
import CancelSurgicalCase from '../SurgicalCase/CancelSurgicalCase'
import { useSelector } from 'react-redux';
import Snackbars from '../../components/controls/Snackbar'
import { cancelCase } from '../../graphql/mutations';
import { getStatusColor } from '../../utils/surgicalCase';
import routeLeavingGuard from '../../components/hoc/routeLeavingGuard';
import { getDisplayLabel } from '../../utils';
import Tooltip from "@material-ui/core/Tooltip";
import { checkForInHousePersona } from '../../utils/tpr';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12.5,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#ffb500',
        textTransform: 'none',
        padding: '7px !important',
        "&:disabled": {
            color: '#808080 !important',
            'box-shadow': 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.12)'

        },
        "&:hover": {
            backgroundColor: '#ffb500',
        },
    }

}));

const ViewSurgicalCase = ({ props, caseActions, caseDetail }) => {
    let retryCount = 0;
    const [externalId, setExternalId] = useState(caseDetail.currentState && caseDetail.currentState.externalId ? caseDetail.currentState.externalId : "")
    const [sfId, setSfId] = useState(caseDetail.currentState && caseDetail.currentState.sfId ? caseDetail.currentState.sfId : "")
    const [loading, setLoading] = useState(false)
    const surgicalCaseDetails = useSelector(state => state.cases.currentState);
    const [duration,setDuration] = useState(null)
    // const Case_Details_By_Id = useSelector(state => state.cases.currentState);
    const classes = useStyles();
    let history = useHistory();
    const [SurgicalCasedetails, setSurgicalCasedetails] = React.useState([]);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [showCancelSurgicalCaseModalPopup, setShowCancelSurgicalCaseModalPopup] = React.useState(false)
    const [notification, setNotification] = React.useState({ severity: "", message: "" })
    const [open, setOpen] = React.useState(false);
    let surgicalCase;
    const getData = async () => {
        let retryCount = 0;
        let myInterval;
        if (externalId === '') {
            setExternalId(decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent('ExternalId').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")))
        }
        if (sfId === '') {
            setSfId(decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent('SfId').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")))
        }
        const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}')
        if (externalId != '' || sfId != '') {
            try {
                let getCaseUrl = `${API.MASTER_DATA.GET_CASE_By_ID}`;
                if (externalId) {
                    getCaseUrl = getCaseUrl + `?externalId=${externalId}`;
                }
                else {
                    getCaseUrl = getCaseUrl + `?sfId=${sfId}`;
                }
                let response = await axios.get(getCaseUrl,
                    {
                        headers: { Authorization: access_token }
                    });
                let surgicalCase = {
                    response,
                };
                caseActions.setCaseDetail(response.data)
                setSurgicalCasedetails(response.data);
                clearInterval(myInterval);
                if(retryCount === 0 && Object.keys(response.data).length === 0){
                    displaySnackBarMessage("success", "Thank You! Your submission has been recorded successfully. Please DO NOT reload this page until the RepSuite Case ID has been displayed.");
                }
                
                if(Object.keys(response.data).length !== 0){
                    setOpen(false);
                }
                if(retryCount<=3 && Object.keys(response.data).length === 0){
                    myInterval =  setTimeout(()=>{
                        getData();
                    },5000)
                }
                retryCount = retryCount + 1;
                return response.data

            } catch (error) {
                console.log(Object.keys(error), error.message);
                setSurgicalCasedetails({});
            }
        } else {
            history.push('/ListSurgicalCase');
        }
    }

    useEffect(() => {
        getData();
        const current_user = JSON.parse(localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || '{}');
        const isInHouseUser = checkForInHousePersona(current_user.personas);
        if (isInHouseUser || (caseDetail &&  caseDetail.currentState && caseDetail.currentState.status === "Cancelled")) {
            setIsDisabled(true)
        }

    }, [])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const displaySnackBarMessage = (severity, errorMessage) => {
        setNotification({
            severity: severity, message: errorMessage
        })
        setOpen(true)
    }

    const handleEdit = () => {
        history.push('/EditSurgicalCase');
    }
    const handleCancelSurgicalCase = () => {
        let result = true;
        if (surgicalCaseDetails.tprId && surgicalCaseDetails.tprId.length > 0) {
            surgicalCaseDetails.tprId.map(item => {
                if (item.tpr_status !== 'Cancelled') {
                    result = false;
                }
                return result;
            });
        } else {
            result = true;
        }
        setShowCancelSurgicalCaseModalPopup(result)
        if (!result) {
            displaySnackBarMessage("error", "Please cancel all Treatment Plan(s)")
        }
    }
    const handleCaseConfirmClick = async (SurgicalCancelReason, SurgicalCancelComments) => {
        try {
            setShowCancelSurgicalCaseModalPopup(false)
            setLoading(true)

            // const result = await cancelCase(surgicalCaseDetails.externalId,
            //     surgicalCaseDetails.sfId,
            //     SurgicalCancelReason,
            //     SurgicalCancelComments);
            let caseDetail = {
                externalId:surgicalCaseDetails.externalId,
                sfId: surgicalCaseDetails.sfId,
                reason:SurgicalCancelReason,
                comment:SurgicalCancelComments
            }
            const result = await cancelSurgeryCase(caseDetail);


          
            let retryCount = 0;
            let intervalFunc = setInterval(() => {
                retryCount++;
                if (surgicalCaseDetails && surgicalCaseDetails.status != 'Cancelled' && retryCount < 3)
                    getData()
                else
                    clearInterval(intervalFunc);
            }, 7000)
            setLoading(false)
        } catch (ex) {
            displaySnackBarMessage("error", "We've had trouble cancelling the Surgical Case.")
        }
    }

    const cancelSurgeryCase = async(payload) =>{
        const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}');
        let cancelCase = `${API.MASTER_DATA.CANCELCASE}`;
        let config = {
                method: 'post',
                url: cancelCase,
                headers: { Authorization: access_token, 'Content-Type': 'application/json' },
                data: {payload:payload,query:""}
            }
        try {
            let response = await axios(config);
            if (response && !response.data.hasOwnProperty('error')) {
                setIsDisabled(true)
                displaySnackBarMessage("success", "Surgical case has been cancelled successfully")
            }else{
                displaySnackBarMessage("error", response.data.error);
            }
        }
        catch (ex) {
            displaySnackBarMessage("error", "There was an error with your request. Please try again.");

        }
    }

    const handleSurgicalCaseClose = () => {
        setShowCancelSurgicalCaseModalPopup(false);
    };

    return (
        <>
            {loading ? <SimpleBackdrop open={loading} /> :
                caseDetail.currentState ?
                    <div className={classes.root}>
                        <CancelSurgicalCase
                            showCancelSurgicalCaseModalPopup={showCancelSurgicalCaseModalPopup}
                            handleCaseConfirmClick={handleCaseConfirmClick}
                            handleSurgicalCaseClose={handleSurgicalCaseClose}
                        />

                        {}<Grid container spacing={0} className="backgroundgreycolor borderbottom">
                            <Grid item xs={12} className="pagetitleHead">
                                <Grid item sm={9} xs={12} className="lFloat">
                                    <CardContent>
                                        <Typography>
                                            Case Details
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={12} className="rFloat">
                                    <Typography>

                                        <ButtonGroup color="primary" aria-label="primary button group" disabled={isDisabled} className="margin10px">
                                            <Button className={classes.button} disabled={isDisabled || caseDetail.currentState.status === REPSUITE_CASE_STATUS.COMPLETED || caseDetail.currentState.status === REPSUITE_CASE_STATUS.CLOSED} Icon="Edit" onClick={handleEdit} name="Edit">EDIT</Button>
                                            <Button className={classes.button} disabled={isDisabled} onClick={handleCancelSurgicalCase}>CANCEL SURGICAL CASE</Button>
                                        </ButtonGroup>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <Snackbars severity={notification.severity} open={open} handleClose={handleClose} duration={duration} message={notification.message} />
                                <CardContent className="cardPadding">
                                    <label className="controllabel">  Case ID</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue"> {caseDetail.currentState.caseId} </label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel">  Sales Rep</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue">{caseDetail.currentState.salesRep}</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel"> Status</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="reqbtn" style={{background:getStatusColor(caseDetail.currentState ? caseDetail.currentState.status : "")}}> {caseDetail.currentState ? caseDetail.currentState.status : ""} </label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel">  Surgery Start Date Time</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue"> {caseDetail.currentState.surgeryDate ? moment(caseDetail.currentState.surgeryDate).format('MM/DD/yyyy, hh:mm A') : ''} </label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel"> Hospital</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue">{caseDetail.currentState.hospitalNoName}</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel"> Procedure</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue"> {caseDetail.currentState.procedures && caseDetail.currentState.procedures.length > 0 ? caseDetail.currentState.procedures[0].name : ''} </label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel">  Patient ID</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue">{caseDetail.currentState.patientId}</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel"> Surgeon No-Name</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controlvalue">{caseDetail.currentState.surgeonNoName}</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <label className="controllabel"> Covering Reps</label>
                                </CardContent>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <CardContent className="cardPadding">
                                    <LightTooltip title={caseDetail.currentState.covering_reps__c} placement="bottom">
                                        <label className="controlvalue">
                                            {getDisplayLabel(caseDetail.currentState.covering_reps__c, 3)}
                                        </label>
                                    </LightTooltip>
                                </CardContent>
                            </Grid>
                            <Grid xs={12} sm={12}>
                                <CardContent className="cardPadding">
                                </CardContent>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12}>
                            <CardContent className="cardPadding">
                            </CardContent>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CaseTabs caseDetails={caseDetail.currentState} />
                        </Grid>
                    </div > : ""}
        </>

    );


}

function mapDispatchToProps(dispatch) {
    return {
        caseActions: bindActionCreators(userCase, dispatch),
    }
}

const mapStateToProps = state => {
    return {
        caseDetail: state.cases
    }
}
//export default CaseDetailView;
export default routeLeavingGuard(connect(mapStateToProps, mapDispatchToProps)(ViewSurgicalCase));
