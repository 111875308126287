import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getHospitals, getAllHospitals, getCoveringRepsList, getSurgeons, getBranchSalesrep, getPodsMembers } from '../../graphql/queries'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Braches from '../../components/new-case-form/Braches'
import axios from 'axios'
import HospitalLocation from '../../components/new-case-form/HospitalLocation'
import HospitalName from '../../components/new-case-form/HospitalName'
import Surgeon from '../../components/new-case-form/Surgeon'
import SalesRep from '../../components/new-case-form/SalesRep'
import LoadProcedure from '../../components/new-case-form/LoadProcedure'
import CoveringReps from '../../components/new-case-form/CoveringReps'
import ToggleSwtich from '../../components/controls/ToggleSwitch'
import DropdownDivision from '../../components/controls/DropdownDivision'
import InputTextField from '../../components/controls/Input'
import MultilineTextFields from '../../components/controls/MultilineText'
import DatePickers from '../../components/controls/DatePicker'
import DateTimePicker from '../../components/controls/DateTimePicker'
import Checkbox from '@material-ui/core/Checkbox';
import Buttons from '../../components/controls/Button'
import Snackbars from '../../components/controls/Snackbar'
import { createOrUpdateCase } from '../../graphql/mutations'
import SurgicalCase, { determineCaseEditability } from '../../utils/surgicalCase'
import { useHistory, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import { ASYNC_STORAGE, REPSUITE_CASE_STATUS, USER_TYPE, API } from '../../constants';
import SubBranches from '../../components/new-case-form/SubBranches';
import SimpleBackdrop from '../../components/controls/Spinner';
import routeLeavingGuard from '../../components/hoc/routeLeavingGuard';
import { resetFormChangeIndicator } from '../../redux/modules/formChangeLog';
import Dropdown from '../../components/controls/Dropdown'
import { GENDER } from '../../constants'


const useStyles = makeStyles((theme) => ({}));

function NewSurgicalCase({ caseActions, user, caseDetail, valueChanged }) {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(location.pathname.includes('EditSurgicalCase'));
  const [loading, setLoading] = useState(true);
  const [divisionValue, setDivisionValue] = useState('');
  const [divisionDisbaled, setDivisionDisabled] = useState(true)
  const [pageTitle, setPageTitle] = useState('Create Surgical Case')
  const [fieldEditability, setFieldEditability] = useState({});
  const [surgeryDateDisabled, setSurgeryDateDisabled] = useState(false);
  const [hsptlLocationDisabled, setHsptlLoocationDisabled] = useState(false)
  const [confirmedDisabled, setConfirmedDisabled] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [CoveringRep, setCoveringRep] = React.useState([]);
  const [branchesValue, setbranchesValue] = React.useState('');
  const [branchName, setBranchName] = React.useState('');
  const [subBranchName, setSubBranchName] = React.useState('');
  const [subBranchesValue, setSubBranchesValue] = React.useState('');
  const [Hospitals, setHospitals] = React.useState({});
  const [SalesReps, setSalesReps] = React.useState([]);
  const [Surgoens, setSurgoens] = React.useState([]);
  const [hospitalName, sethospitalName] = React.useState({});
  const [isHsptlSelected, setIsHsptlSelected] = React.useState(false);
  const [SurgeonName, setSurgeonName] = React.useState({});
  // const [savedHospitalLocation, setSavedHospitalLocation] = React.useState(caseDetail.currentState && caseDetail.currentState.hospital_location__c);
  const [HospitalLocationDropdownValue, setHospitalLocationDropdownValue] = React.useState('');
  const [ProcedureValue, setProcedureValue] = React.useState('');
  const [procedureName, setProcedureName] = React.useState('');
  const [SalesRepName, setSalesRepName] = React.useState({});
  const [CoveringRepName, setCoveringRepName] = React.useState(
    user.personas.toString().includes(USER_TYPE.SALES_ASSOCIATE) ||
      user.personas.toString().includes(USER_TYPE.MPS)
      ? user.sfids : []);
  const [SurgeryStartDateTime, setSurgeryStartDateTime] = React.useState('');
  const [DeliveryDateUnix, setDeliveryDateUnix] = React.useState('');
  const [RevisionCheckboxDisabled, setRevisionCheckboxDisabled] = React.useState(false);
  const [RevisionCheckbox, setRevisionCheckbox] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [notification, setNotification] = React.useState({ severity: "", message: "" });
  const [InternalComments, setInternalComments] = React.useState("");
  const [ASCheckboxDisabled, setASCheckboxDisabled] = React.useState(false);
  const [ASCheckbox, setASCheckbox] = React.useState(false);
  const [MakoCheckboxDisabled, setMakoCheckboxDisabled] = React.useState(false);
  const [MakoCheckbox, setMakoCheckbox] = React.useState(false);
  const [OperativeSideRight, setOperativeSideRight] = React.useState(false);
  const [OperativeSideLeft, setOperativeSideLeft] = React.useState(false);
  const [DeliveryDate, setDeliveryDate] = React.useState('');
  const [PatientIDValue, setPatientIDValue] = React.useState("");
  const [SurgeryDateConfimred, setSurgeryDateConfimred] = React.useState(true);
  const [podsMembers, setPodsMembers] = React.useState(null)
  const [teamCoveringReps, setTeamCoveringReps] = React.useState([])
  const [selectedTeams, setSelectedTeams] = React.useState([])
  const [isCadaverCase, setIsCadaverCase] = React.useState(false)
  const [patientGender, setPatientGender] = React.useState();
  const [surgoenDisabled, setSurgoenDisabled] = useState(false)

  useEffect(() => {
    if (!isEdit || !(caseDetail?.currentState?.branchSfId)) {
      // get user hospitals
      getHospitals()
        .then(res => {
          const sortedByHospitalName = res.data.get_hospitals.sort(compareHospital)
          if (sortedByHospitalName.length === 1) {
            sethospitalName(sortedByHospitalName[0].hospitalId)
          }
          return sortedByHospitalName.map(item => {
            item.title = item.hospitalName;
            item.id = item.hospitalId;
            return item;
          })
        })
        .then(res => {
          setHospitals(res);
          setLoading(false);
        })
    }
    else {
      getHospitals().then(res => {
        const sortedByHospitalName = res
          .data
          .get_hospitals
          .sort(compareHospital)
          .filter(hosp => hosp.mainBranch === caseDetail.currentState.branchSfId);
        if (sortedByHospitalName.length === 1) {
          sethospitalName(sortedByHospitalName[0].hospitalId)
        }
        return sortedByHospitalName.map(item => {
          item.title = item.hospitalName;
          item.id = item.hospitalId;
          return item;
        })
      })
        .then(res => {
          setHospitals(res);
          setLoading(false);
        })
    }

    getSurgeons()
      .then(res => {
        const sortedBySurgeonName = res.get_surgeons && res.get_surgeons.sort(compareSurgeon)
        if (sortedBySurgeonName.length === 1) {
          setSurgeonName(sortedBySurgeonName[0])
        }
        return sortedBySurgeonName.map(item => {
          item.title = item.surgeonName;
          item.id = item.surgeonID;
          return item;
        })
      })
      .then(res => setSurgoens(res))

    //get pods members
    getPodsMembers()
      .then(res => {
        setPodsMembers(res && res.get_pods_members ? res.get_pods_members : [])
      })

    if (user && user.divisions.length > 1) {
      setDivisionDisabled(false)
    }
    else {
      setDivisionValue(user.divisions[0])
      setDivisionDisabled(true)
    }

  }, [user]);

  useEffect(() => {
    if (isEdit) {
      if (caseDetail.currentState != null) {
        determineCaseEditability(caseDetail.currentState)
          .then(caseFields => {
            setFieldEditability(caseFields);
          })
        if (caseDetail.currentState.mako === true) {
          setSurgoenDisabled(false)
        }
        if (caseDetail.currentState.as1 === true) {
          setSurgoenDisabled(true)
        }
        if (caseDetail.currentState.revision === true) {
          setSurgoenDisabled(false)
        }
        if (caseDetail.currentState.revision === false && caseDetail.currentState.as1 === false && caseDetail.currentState.mako === false) {
          setSurgoenDisabled(false)
        }
        setSaveDisabled(caseDetail.currentState.status === REPSUITE_CASE_STATUS.COMPLETED || caseDetail.currentState.status === REPSUITE_CASE_STATUS.CLOSED || caseDetail.currentState.status === REPSUITE_CASE_STATUS.CANCELLED)
        setSurgeryDateDisabled(caseDetail.currentState.status === REPSUITE_CASE_STATUS.ASSIGNED || caseDetail.currentState.status === REPSUITE_CASE_STATUS.SHIPPED_READY)
        setHsptlLoocationDisabled(caseDetail.currentState.status === REPSUITE_CASE_STATUS.ASSIGNED || caseDetail.currentState.status === REPSUITE_CASE_STATUS.SHIPPED_READY)
        setConfirmedDisabled(caseDetail.currentState.sets.length > 0 && caseDetail.currentState.surgery_date_confirmed__c);
        setPageTitle('Edit Surgical Case')
        setDivisionValue(caseDetail.currentState.division)
        setbranchesValue(caseDetail.currentState.branchSfId)
        setBranchName(caseDetail.currentState.branch)
        setSubBranchesValue(caseDetail.currentState.subBranch)
        setSubBranchName(caseDetail.currentState.subBranchName)
        // Cadaver check
        const isCadaverCase = caseDetail.currentState.hospSiteNo === "CADHosp" || caseDetail.currentState.hospitalName.includes("Cadaver")
        setIsCadaverCase(isCadaverCase);
        const hospObj = {
          hospitalId: caseDetail.currentState.hospitalid__c,
          hospitalName: caseDetail.currentState.hospitalName,
          accountNo: caseDetail.currentState.hospSiteNo,
          id: caseDetail.currentState.hospitalid__c,
          title: caseDetail.currentState.hospitalName
        }
        sethospitalName(hospObj)
        setHospitalLocationDropdownValue(caseDetail.currentState.hospital_location__c)
        setIsHsptlSelected(true)
        setSurgeonName(caseDetail.currentState.surgeonName)
        setSalesRepName(caseDetail.currentState.userRep)
        let reps = caseDetail.currentState.coveringRepsSFIDs.split(',')
        setCoveringRepName(reps)
        setProcedureValue(caseDetail.currentState.procedures[0].sfid)
        setProcedureName(caseDetail.currentState.procedures[0].name)
        setPatientIDValue(caseDetail.currentState.patientId)
        setInternalComments(caseDetail.currentState.internalComment)
        setMakoCheckbox(Boolean(JSON.parse(caseDetail.currentState.mako)))
        setRevisionCheckbox(Boolean(JSON.parse(caseDetail.currentState.revision)))
        setASCheckbox(Boolean(JSON.parse(caseDetail.currentState.as1)))
        setOperativeSideLeft(Boolean(JSON.parse(caseDetail.currentState.left)))
        setOperativeSideRight(Boolean(JSON.parse(caseDetail.currentState.right)))
        setPatientGender(caseDetail.currentState.patient_gender)
        setSurgeryStartDateTime(new Date(moment(caseDetail.currentState.surgeryDate).format('YYYY-MM-DD hh:mm a')))
        setDeliveryDate(moment(caseDetail.currentState.surgeryDate).subtract(2, 'days').format("YYYY-MM-DD"))
        setDeliveryDateUnix(moment(caseDetail.currentState.surgeryDate).subtract(2, 'days'))
        setSurgeryDateConfimred(caseDetail.currentState.surgery_date_confirmed__c)
        if (caseDetail.currentState.procedures[0].name.trim().includes("MAKO")) {
          // setMakoCheckbox(true)
          // setASCheckbox(false)
          setASCheckboxDisabled(true)
          setRevisionCheckboxDisabled(false)
          setMakoCheckboxDisabled(false)
        }
        else if (caseDetail.currentState.procedures[0].name.trim().includes("AS-1")) {
          // setASCheckbox(true)
          // setMakoCheckbox(false)
          setASCheckboxDisabled(false)
          // setRevisionCheckbox(false)
          setMakoCheckboxDisabled(true)
          setRevisionCheckboxDisabled(true)
        }
        else {
          // setASCheckbox(false)
          // setMakoCheckbox(false)
          // setRevisionCheckbox(false)
          setMakoCheckboxDisabled(false)
          setRevisionCheckboxDisabled(false)
          setASCheckboxDisabled(false)
        }
      } else {
        history.push('/NewSurgicalCase');
      }

    }
    else {
      let result = null;
      caseActions.setCaseDetail(result)

    }

  }, [])

  const getDivisions = divisions => {
    if (divisions && divisions.length > 0) {

      return divisions.map(division => ({
        id: division,
        label: division !== 'Trauma' ? division : 'Trauma & Extremities'
      }));
    }
    return [];
  }

  const compareHospital = (a, b) => {
    const c1 = a.hospitalName.split(" - ");
    const c2 = b.hospitalName.split(" - ");
    var comp = 0;
    if (c1[1] > c2[1])
      comp = 1;
    else if (c1[1] < c2[1])
      comp = -1;
    return comp;
  }
  //Load Surgeon 
  const compareSurgeon = (a, b) => {
    const c1 = a.surgeonName.split(" - ");
    const c2 = b.surgeonName.split(" - ");
    var comp = 0;
    if (c1[1] > c2[1])
      comp = 1;
    else if (c1[1] < c2[1])
      comp = -1;
    return comp;
  }

  //Load Sales Rep 
  const compare = (a, b) => {
    const c1 = a.userName;
    const c2 = b.userName;
    var comp = 0;
    if (c1 > c2)
      comp = 1;
    else if (c1 < c2)
      comp = -1;
    return comp;
  }

  useEffect(() => {

    if (subBranchesValue) {
      setLoading(true);

      getBranchSalesrep(subBranchesValue)
        .then(res => {
          const sortedUserByBranch = res.get_user_by_branch.sort(compare)
          // debugger
          // Default Sales Rep to logged in user
          if (!SalesRepName || Object.keys(SalesRepName).length === 0) {
            const useObj = sortedUserByBranch.find(users => users.userID === user.sfids.toString())
            setSalesRepName(useObj && user.personas.toString().includes(USER_TYPE.SALES_REP)
              // && user.personas.toString().includes("R&D")
              ? useObj : {})
          }
          return sortedUserByBranch.map(item => {
            item.title = item.userName;
            item.id = item.userID;
            return item;
          }).filter(salesRep => salesRep.multiplePersona.indexOf('Sales Rep') > -1 || salesRep.multiplePersona.indexOf('R&D') > -1)
        })
        .then(res => {
          const current_user = JSON.parse(localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}");
          const { personas } = current_user;
          if (personas.includes('R&D')) {
            const RAndDUser = res.filter(salesRep =>
              salesRep.multiplePersona === 'Sales Rep;R&D'
            )
            setSalesReps(RAndDUser)
            if (RAndDUser.length === 1) {
              onChangeSalesRep(RAndDUser[0], true)
            }
          } else {
            setSalesReps(res.filter(salesRep => salesRep.multiplePersona.indexOf('Sales Rep') > -1 || salesRep.multiplePersona.indexOf('R&D') > -1))
          }
        })

      getCoveringReps()
        .then(res => {
          setCoveringRep(res)
          // Default Sales Rep to logged in user
          if (!CoveringRepName || CoveringRepName.length === 0) {
            setCoveringRepName(user.personas.toString().includes(USER_TYPE.SALES_ASSOCIATE) ||
              user.personas.toString().includes(USER_TYPE.MPS)
              ? user.sfids : [])
          }
          setLoading(false);
        })
    }

  }, [subBranchesValue, hospitalName]);


  const getCoveringReps = async () => {
    const result = await getCoveringRepsList()
    
    let teams = [];
    if (!podsMembers) {
      const res = await getPodsMembers()
      teams = res && res.get_pods_members;
    } else {
      teams = podsMembers
    }

    let coveringReps = [];
    let coveringRepTeams = [];

    if (result) {
      coveringReps = result.get_covering_reps.map(item => {
        item.title = item.userName;
        item.id = item.userID;
        return item;
      })
    }

    if (teams && teams.length > 0) {
      coveringRepTeams = teams && teams.map(item => {
        let teamMembers = [];
        item.title = `${item.name} TEAM`;
        item.id = item.sfid;
        item.members.split(",").forEach((m) => {
          const rep = coveringReps.find((item) => item.id === m);
          if (rep) {
            teamMembers.push(rep);
          }
        });
        item.coveringReps = teamMembers;
        return item
      })
      setTeamCoveringReps(coveringRepTeams)
    }
    return [...coveringRepTeams, ...coveringReps]
  }

  let setDivision = getDivisions(user.divisions);
  const handleDivisionDropdown = (event) => {
    valueChanged("Diviion")
    setDivisionValue(event.target.value);
  };
  //Branches Dropdown Dropdown
  const handleBranchChangeDropdown = async (event) => {
    if (event.target.value.id) { // Auto Set
      setbranchesValue(event.target.value.id);
    } else { // on change
      valueChanged("Branch")
      setbranchesValue(event.target.value);
      setSubBranchesValue("");
    }
  };
  // Sub Branches Dropdown
  const handleSubBranchChangeDropdown = async (event) => {
    if (event.target.value.id) {
      setSubBranchesValue(event.target.value.id);
    } else { // on change
      valueChanged("SubBranch")
      setSubBranchesValue(event.target.value);
    }
  };
  //Hospital Name
  const Hospitallocationfunc = (hospitalName, initialRender) => {
    sethospitalName(hospitalName)

    setIsHsptlSelected(hospitalName && hospitalName.hospitalId)
    const isCadaverHospSelected = hospitalName && (hospitalName.accountNo === "CADHosp" || hospitalName.hospitalName.includes("Cadaver"));
    setIsCadaverCase(isCadaverHospSelected);

    if (!initialRender) { // set/reset onChange only

      setHospitalLocationDropdownValue('');
      setbranchesValue(hospitalName ? hospitalName.mainBranch : '')
      setBranchName(hospitalName ? hospitalName.mainBranchName : '')
      setSubBranchesValue(hospitalName ? hospitalName.branchID : '')
      setSubBranchName(hospitalName ? hospitalName.branchName : '')

      setSalesRepName({})
      setSalesReps([])
      setCoveringRepName([])
      setCoveringRep([])

      if (isCadaverHospSelected) {
        // Auto Select Cadaver Surgeon
        const cadeverSurgeon = Surgoens && Surgoens.find(item => item.surgeonName.toLowerCase().includes("cadaver"));
        setSurgeonName(cadeverSurgeon);
      } else if (isCadaverCase && !hospitalName) {
        // Cleared Cadaver Hosp - so clear out Cadaver Surgeon Selection
        setSurgeonName({});
      }
    }

    // if (savedHospitalLocation) {
    //   setHospitalLocationDropdownValue(savedHospitalLocation);
    //   setSavedHospitalLocation(null);
    // }
    // else {
    //   setHospitalLocationDropdownValue('');
    // }

  };
  //Hospital Location
  const handleHospitalLocChangeDropdown = (event, calledOnFirstRender = false) => {
    if (!calledOnFirstRender) {
      valueChanged("HospitalLoc")
    }
    setHospitalLocationDropdownValue(event.target.value);
  };

  // const pay = () => {
  //   setSavedHospitalLocation('')
  // }

  // const myFunction = (e, calledOnFirstRender = false) => {
  //   if(!calledOnFirstRender){
  //     valueChanged("HospitalLoc")
  //   }
  //   pay();
  //   handleHospitalLocChangeDropdown(e);
  // }

  //Surgeon
  const onChangeSurgeonName = (SurgeonName, calledOnFirstRender = false) => {
    if (!calledOnFirstRender) {
      valueChanged("Surgeon")
    }
    setSurgeonName(SurgeonName)
  };
  // Sales Rep
  const onChangeSalesRep = (SalesRepName, calledOnFirstRender = false) => {
    if (!calledOnFirstRender) {
      valueChanged("SalesRep")
    }
    setSalesRepName(SalesRepName)
  };

  //Covering Rep
  const onChangeCoveringRep = (event, selOptions) => {
    let selecetdOptions = selOptions ? selOptions : event

    let options = [];
    selecetdOptions.forEach((item) => {
      if (item.members) {
        options.push(...item.coveringReps)
      } else {
        options.push(item)
      }
    });

    if (event.target) {
      valueChanged("CoveringRep")
      if (event.target.innerText || event.target.name) {
        const val = event.target.innerText || event.target.name;
        const teamOption = teamCoveringReps.find(item => item.title === val)
        if (teamOption) {
          // Clicked on Team Option
          const clickedTeamOption = selectedTeams.find(item => item.id === teamOption.id)
          if (clickedTeamOption) { // Means unselected a team
            const remainingTeams = selectedTeams.filter(item => item.id !== clickedTeamOption.id)
            // remove selected Team
            setSelectedTeams([...remainingTeams])
            // remove teams covering reps from selected options
            options = options.filter((item) => !teamOption.coveringReps.find(rep => rep.id === item.id))
          } else {
            // New Team Selected - set teams
            setSelectedTeams([...selectedTeams, teamOption])
          }
        } else {
          // Normal Option - handle removing team
          const selectedRep = selecetdOptions.find(item => item.title === event.target.innerText)
          if (!selectedRep) {
            const teamToBeUnchecked = selectedTeams.find(item => item.coveringReps.find(i => i.title === event.target.innerText))
            if (teamToBeUnchecked) {
              const remainingTeams = selectedTeams.filter(item => item.id !== teamToBeUnchecked.id)
              setSelectedTeams([...remainingTeams])
            }
          }
        }
      } else {
        // Removed chip
        const removedItem = CoveringRepName.find(item => !item.members && !selecetdOptions.find(rep => rep.id === item.id))
        if (removedItem) {
          // Check if it from teams
          const teamToBeUnchecked = selectedTeams.find(item => item.coveringReps.find(i => i.id === removedItem.id))
          if (teamToBeUnchecked && selectedTeams.find(item => item.id === teamToBeUnchecked.id)) {
            const remainingTeams = selectedTeams.filter(item => item.id !== teamToBeUnchecked.id)
            setSelectedTeams([...remainingTeams])
          }
        }
      }
    }
    options = options.filter((value, index) => options.indexOf(value) === index);
    setCoveringRepName(options)
    if (options.length === 0) {
      setSelectedTeams([])
    }
  };
  //Procedure
  const onChangeProcedureValue = (event) => {
    valueChanged("Procedure")
    setProcedureValue(event.target.value);
    setProcedureName(event.target.selectedOptions[0].label);
    if (event.target.selectedOptions[0].innerHTML.trim().includes("MAKO")) {
      setMakoCheckbox(true)
      setASCheckbox(false)
      setASCheckboxDisabled(true)
      setRevisionCheckboxDisabled(false)
      setMakoCheckboxDisabled(false)
    }
    else if (event.target.selectedOptions[0].innerHTML.trim().includes("AS-1")) {
      setASCheckbox(true)
      setMakoCheckbox(false)
      setASCheckboxDisabled(false)
      setRevisionCheckbox(false)
      setMakoCheckboxDisabled(true)
      setRevisionCheckboxDisabled(true)
    } else if (
      event.target.selectedOptions[0].innerHTML.trim().includes("Revision")
    ) {
      setASCheckbox(false);
      setMakoCheckbox(false);
      setASCheckboxDisabled(false);
      setRevisionCheckbox(true);
      setMakoCheckboxDisabled(false);
      setRevisionCheckboxDisabled(false);
    } else {
      setASCheckbox(false)
      setMakoCheckbox(false)
      setRevisionCheckbox(false)
      setMakoCheckboxDisabled(false)
      setRevisionCheckboxDisabled(false)
      setASCheckboxDisabled(false)
    }
  };
  //Surgery Start Date Time
  const onChangeSurgeryStartDateTime = (date) => {
    valueChanged("SurgeryDate")
    const SSDate = date
    setSurgeryStartDateTime(SSDate);
    const DDate = moment(date).subtract(2, 'days').format("YYYY-MM-DD")
    const DDateUnix = moment(date).subtract(2, 'days')
    setDeliveryDateUnix(DDateUnix)
    setDeliveryDate(DDate)
  };
  //Deliery Datehand
  const onChangesetDeliveryDate = (event) => {

  };

  //Patient ID
  const onChangesetPatientIDValue = (event) => {
    valueChanged("PatientID")
    setPatientIDValue(event.target.value);
  };

  const onChangePatientGender = (event) => {
    valueChanged("Gender")
    setPatientGender(event.target.value);
  };

  const onChangeSurgeryDateConfirmed = (event) => {
    valueChanged("SurgeryDateConfirmed")
    setSurgeryDateConfimred(event.target.checked)
  };
  //Operative Side Left

  const onChangeOperativeSideLeft = (event) => {
    valueChanged("OPSideLeft")
    setOperativeSideLeft(!OperativeSideLeft);
    if (!OperativeSideLeft) {
      (setOperativeSideRight(false))
    }
  };

  //Operative Side right

  const onChangeOperativeSideRight = (event) => {
    valueChanged("OPSideLeft")
    setOperativeSideRight(!OperativeSideRight);
    if (!OperativeSideRight) {
      (setOperativeSideLeft(false))
    }
  };
  //Mako Checkbox right
  const onChangeMakoCheckbox = (event) => {
    valueChanged("Mako")
    setMakoCheckbox(!MakoCheckbox);
  };
  //Revision Checkbox

  const onChangeRevisionCheckbox = (event) => {
    valueChanged("Revision")
    setRevisionCheckbox(!RevisionCheckbox);
  };
  //AS-1 Checkbox
  const onChangeASCheckbox = (event) => {
    valueChanged("As1")
    setASCheckbox(!ASCheckbox);
  };
  //Internal Comments
  const onChangeComments = (event) => {
    valueChanged("InternalComments")
    setInternalComments(event.target.value);
  };
  // Snackbar
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  // validation 
  const ValidationSurgicalCase = () => {
    let errorMessage = '';
    if (!divisionValue) {
      errorMessage += "Division,"
    }
    if (!branchesValue) {
      errorMessage += "Branch,"
    }
    if (!subBranchesValue) {
      errorMessage += "Sub Branch,"
    }
    if (!hospitalName || typeof hospitalName == "undefined" || !hospitalName.hospitalId) {
      errorMessage += "Hospital,"
    }
    if (!HospitalLocationDropdownValue || typeof HospitalLocationDropdownValue == "undefined") {
      errorMessage += "Hospital Location,"
    }
    if (!SurgeonName || typeof SurgeonName == "undefined" || !SurgeonName.surgeonID) {
      errorMessage += "Surgeon,"
    }
    if (!SalesRepName || typeof SalesRepName == "undefined" || !SalesRepName.userID) {
      errorMessage += "Sales Rep,"
    }
    if (CoveringRepName.length <= 0) {
      errorMessage += "Covering Rep,"
    }
    if (!ProcedureValue) {
      errorMessage += "Procedure,"
    }
    if (!SurgeryStartDateTime) {
      errorMessage += "Surgery Start Date Time,"
    }
    errorMessage = errorMessage.replace(/.$/, ".")
    return errorMessage
  }
  const displaySnackBarMessage = (severity, errorMessage) => {
    setNotification({
      severity: severity, message: errorMessage
    })
    setOpen(true)
  }
  //OnCancel Button click
  const handleCancel = () => {
    // dispatch(resetFormChangeIndicator());
    history.goBack();
  }
  //OnSubmit Button click
  const handleSubmit = async () => {
    let errorMessage = ValidationSurgicalCase()
    if (errorMessage) {
      displaySnackBarMessage("error", "Please fill out or select the following required fields:" + errorMessage);
    }
    else {
      try {
        let result = null;
        const covering_reps__c = CoveringRepName.map(repId => repId.id).join(',')
        const coveringRepsName = CoveringRepName.map(repUserName => repUserName.title).join(',')
        const payload = {
          status__c: caseDetail.currentState ? caseDetail.currentState.status : null,
          external_id__c: caseDetail.currentState ? caseDetail.currentState.externalId : null,
          sfid: caseDetail.currentState ? caseDetail.currentState.sfId : null,
          division__c: divisionValue ? divisionValue : '',
          branchid__c: branchesValue ? branchesValue : '',
          subbranch: subBranchesValue ? subBranchesValue : '',
          hospitalid__c: hospitalName ? hospitalName.hospitalId : '',
          hospital_staffid__c: SurgeonName ? SurgeonName.surgeonID : null,
          patient_info__c: PatientIDValue || '',
          cap_price__c: caseDetail.currentState?.capPrice ? 1 : 0,
          revisions__c: RevisionCheckbox ? RevisionCheckbox : false,
          as1__c: ASCheckbox ? ASCheckbox : false,
          favourite__c: false,
          procedureid__c: ProcedureValue || '',
          procedure_list__c: procedureName || '',
          kit_assigner__c: SalesRepName.userID || '',
          covering_reps__c: covering_reps__c || '',
          coveringRepsName: coveringRepsName || '',
          kitdelivery_date__c: DeliveryDate ? Number(moment(DeliveryDateUnix).unix() * 1000) : null,
          left__c: OperativeSideLeft || false,
          right__c: OperativeSideRight || false,
          mako__c: MakoCheckbox || false,
          hospital_location__c: HospitalLocationDropdownValue ? HospitalLocationDropdownValue : null,
          surgery_date_time: SurgeryStartDateTime ? Number(moment(SurgeryStartDateTime).unix() * 1000) : '',
          case_coverage__c: InternalComments || '',
          surgery_date_confirmed__c: SurgeryDateConfimred,
          patient_gender__c: patientGender || ''
        }
        //result = await upsertCaseRequest(payload, true);
        if(caseDetail.currentState && isEdit){
          result = await upsertCaseRequest(payload, true);
        } else {
          result = await createOrUpdateTestCase(payload);
          result = {
            externalId: result.data.External_Id__c,
            sfId: result.data.Id,
            status: result.data.status__c
          }
        }
        caseActions.setCaseDetail(result)
        if (result) {
          dispatch(resetFormChangeIndicator());

          setLoading(false);
          if (caseDetail.currentState != null) {
            displaySnackBarMessage("success", 'Case details successfully updated');
          } else {
            displaySnackBarMessage("success", 'New case request successfully saved');
          }

          history.push('/ViewSurgicalCase')

        }
      } catch (error) {
        setLoading(false);
        displaySnackBarMessage("error", "There was an error with your request. Please try again.");
      }
    }
  }


  const upsertCaseRequest = async (payload) => {
    setLoading(true);
    let createdCase;
    try {
      const results = await createOrUpdateCase(payload);
      if (results) {
        createdCase = results.data.createOrUpdateCase;
      }
    } catch (ex) {
      setLoading(false);
      displaySnackBarMessage("error", "We've had trouble saving your case to Salesforce.");
    }
    return createdCase;
  };

  const createOrUpdateTestCase = async (payload) => {
    setLoading(true);
    delete payload.sfid;
    let createdCase;
    const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}');
    let createOrUpdateCaseUrl = `${API.MASTER_DATA.CREATEORUPDATECASE}`;
    let config = {
      method: 'post',
      url: createOrUpdateCaseUrl,
      headers: { Authorization: access_token, 'Content-Type': 'application/json' },
      data: { payload: payload, query: "" }
    }
    try {
      let response = await axios(config);
      setLoading(false);
      if (response && !response.data.hasOwnProperty('error')) {
        createdCase = response;
        return createdCase;
      } else {
        displaySnackBarMessage("error", response.data.error);
        setTimeout(() => history.push('/NewSurgicalCase'), 3000);
      }
    }
    catch (ex) {
      // alert("We've had trouble getting the Case Id.");
      setLoading(false);
      displaySnackBarMessage("error", "There was an error with your request. Please try again.");

    }
  }

  return (
    <div className={classes.root}>
      <SimpleBackdrop open={loading} />
      <Grid container spacing={0}>
        <Grid item xs={12} className="pagetitleHead pagetitleHeadPadding">
          <CardContent>
            <Typography >
              {pageTitle}
              <Snackbars severity={notification.severity} open={open} handleClose={handleClose} message={notification.message} />
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} className="backgroundgreycolor" >
          <CardContent >
            <Typography>
              <HospitalName
                Hospitallocfnc={Hospitallocationfunc}
                Options={Hospitals}
                value={hospitalName}
                Isdisabled={fieldEditability.Hospital} />
            </Typography>
            <Typography>
              <HospitalLocation hospitalName={hospitalName}
                label={"Hospital Location"}
                value={HospitalLocationDropdownValue}
                Isdisabled={hsptlLocationDisabled || !isHsptlSelected || fieldEditability.HospitalLocation}
                handleHospitalLocChangeDropdown={handleHospitalLocChangeDropdown} />
            </Typography>
            <Typography>
              {isCadaverCase ? <Braches show={false} isAstrikReq={true} handleBranchChangeDropdown={handleBranchChangeDropdown} value={branchesValue} Isdisabled={!isHsptlSelected || fieldEditability.Branch} />
                : <InputTextField label={"Branch"} isdisabled value={branchName} isAstrikReq />}
            </Typography>
            <Typography>
              {isCadaverCase ? <SubBranches handleSubBranchChangeDropdown={handleSubBranchChangeDropdown} branchesValue={branchesValue} value={subBranchesValue} Isdisabled={!branchesValue || fieldEditability.SubBranch} />
                : <InputTextField label={"Sub Branch/Agency"} isdisabled value={subBranchName} isAstrikReq />}
            </Typography>
            <Typography>
              <DropdownDivision label={"Division"} ctrllabel={"Please Select"} Isdisabled={divisionDisbaled || fieldEditability.Division} value={divisionValue} options={setDivision} fnc={handleDivisionDropdown} />
            </Typography>
            <Typography>
              <Surgeon onChangeSurgeonName={onChangeSurgeonName} options={Surgoens} value={SurgeonName} Isdisabled={surgoenDisabled || !isHsptlSelected || fieldEditability.Surgeon} />
            </Typography>
            <Typography>
              <SalesRep label={"Sales Rep"} isAstrikReq={false} onChangeSalesRep={onChangeSalesRep} options={SalesReps} value={SalesRepName} Isdisabled={!isHsptlSelected || fieldEditability.SalesRep} />
            </Typography>
            <Typography>
              <CoveringReps selectedTeams={selectedTeams} onChangeCoveringRep={onChangeCoveringRep} coveringRepVal={CoveringRepName} options={CoveringRep} disabled={!isHsptlSelected || fieldEditability.CoveringReps} />
            </Typography>
            <Typography >
              <LoadProcedure isAstrikReq={true} onChangeProcedureValue={onChangeProcedureValue} ProcedureValue={ProcedureValue} Isdisabled={fieldEditability.Procedure} />
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} className="backgroundgreycolor">
          <CardContent>
            <Typography style={{ marginLeft: '9px' }}>
              <DateTimePicker label="Surgery Start Date Time" value={SurgeryStartDateTime} disabled={surgeryDateDisabled || fieldEditability.SurgeryStartDateTime} fnc={onChangeSurgeryStartDateTime} />
            </Typography>
            <Typography className="chkheight">
              <div style={{ marginLeft: '10px' }}>
                <ToggleSwtich label="Surgery Date Confirmed?"
                  fnc={onChangeSurgeryDateConfirmed}
                  checked={SurgeryDateConfimred}
                  disabled={confirmedDisabled || fieldEditability.SurgeryDateConfirmed} />
              </div>
            </Typography>
            <Typography>
              <DatePickers label="Delivery Date" isAstrikReq={true} fnc={onChangesetDeliveryDate} isdisabled={true} value={DeliveryDate} />
            </Typography>
            <Typography>
              <InputTextField maxLength={30} label="Patient ID" value={PatientIDValue} fnc={onChangesetPatientIDValue} isdisabled={fieldEditability.PatientID} />
            </Typography>
            <Typography className="chkheight">
              <Dropdown isAstrikReq={false} label={"Gender"} fnc={onChangePatientGender} options={GENDER} value={patientGender ? patientGender.toLowerCase() : null} isdisabled={false} />
            </Typography>
            <Typography className="chkheight">
              <div>
                <Checkbox
                  color="default"
                  onClick={onChangeOperativeSideLeft}
                  value="Left"
                  disabled={fieldEditability.Left}
                  checked={OperativeSideLeft}
                  inputProps={{ 'aria-label': 'checkbox with default color' }} />
                <label className="chklabel">Left</label>
              </div>
              <div>
                <Checkbox
                  color="default"
                  onClick={onChangeOperativeSideRight}
                  checked={OperativeSideRight}
                  value="Right"
                  disabled={fieldEditability.Right}
                  inputProps={{ 'aria-label': 'checkbox with default color' }} />
                <label className="chklabel">Right</label>
              </div>
            </Typography>
            <Typography className="chkheight">
              <div>
                <Checkbox
                  color="default"
                  onClick={onChangeMakoCheckbox}
                  checked={MakoCheckbox}
                  disabled={MakoCheckboxDisabled || fieldEditability.Mako}
                  inputProps={{ 'aria-label': 'checkbox with default color' }} />
                <label className="chklabel">Mako</label>
              </div>
              <div>
                <Checkbox
                  color="default"
                  onClick={onChangeASCheckbox}
                  checked={ASCheckbox}
                  disabled={ASCheckboxDisabled || fieldEditability.AS1}
                  inputProps={{ 'aria-label': 'checkbox with default color' }} />
                <label className="chklabel"> AS-1</label>
              </div>
              <div>
                <Checkbox
                  color="default"
                  onClick={onChangeRevisionCheckbox}
                  checked={RevisionCheckbox}
                  disabled={RevisionCheckboxDisabled || fieldEditability.Revision} />
                <label className="chklabel"> Revision</label>
              </div>
            </Typography>
            <Typography>
              <MultilineTextFields disabled={fieldEditability.InternalComments} fnc={onChangeComments} label="Internal Comments" value={InternalComments} />
              <span class="internalComment">Please do not enter complaints in the text box.</span>
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={12}>
          <CardContent>
            <Typography>
              <div class="rFloat btnSaveCancel"><Buttons Icon="Submit" toSave={true} fnc={handleSubmit} name="Save" disabled={saveDisabled} /></div>
              <div class="rFloat grey-color btnSaveCancel"><Buttons Icon="Cancel" fnc={handleCancel} name="Cancel" /></div>
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    caseActions: bindActionCreators(userCase, dispatch),
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    caseDetail: state.cases
  };
}
export default routeLeavingGuard(connect(mapStateToProps, mapDispatchToProps)(NewSurgicalCase));