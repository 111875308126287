import React, { useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  WeekView,
  MonthView,
  DayView,
  Appointments,
  Toolbar,
  TodayButton,
  ViewSwitcher,
  DateNavigator,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import MonthViewComponent from "./AppointmentView/MonthViewComponent";
import WeekViewComponent from "./AppointmentView/WeekViewComponent";
import DayViewComponent from "./AppointmentView/DayViewComponent";
import { makeStyles } from "@material-ui/core/styles";
import getCaseById from "../../utils/calendar";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ACTION_CREATORS as userCase } from "../../redux/reducers/cases";
import SimpleBackdrop from "../../components/controls/Spinner";
import { FormControlLabel } from "@material-ui/core";
import { StyledCheckbox } from "../../components/controls/StyledCheckbox";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    flexGrow: 1,
  },
  schedular: {
    position: "relative",
    margin: "0px 15px",
    border: "0.5px solid lightgray",
  },
  hrsCheckBox: {
    position: "absolute",
    right: "120px",
    top: "10px",
  },
  line: {
    position: "absolute",
    left: 0,
    top: ({ top }) => top,
    background: "#ffb400",
    zIndex: 1,
    height: "2px",
    width: "100%",
    transform: "translate(0, -1px)",
  },
  circle: {
    width: theme.spacing(1.5),
    position: "absolute",
    left: 0,
    top: ({ top }) => top,
    background: "#ffb400",
    zIndex: 1,
    height: theme.spacing(1.5),
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
  },
  checkBoxBase: {
    color: "white",
    "&$checked": {
      color: "#FFB500",
    },
    "&$checked + $track": {
      backgroundColor: "#FFD271",
    },
  },
}));

const SchedulerComponent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const GetCaseById_Set = (response) =>
    dispatch(userCase.setCaseDetail(response));
  const [viewName, currentViewChange] = React.useState("Month");
  const [currentDate, changeDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [is24hours, set24hours] = React.useState(false);
  const [day, setDay] = React.useState({ startHour: 6, endHour: 18 });
  // const timeRef = useRef();

  useEffect(() => {
    changeDate(props.currentDate);
  }, [props.currentDate]);

  useEffect(() => {
    currentViewChange(props.currentViewName);
    // if (props.currentViewName !== "Month") {
    //   timeRef.current && timeRef.current.scrollIntoView({ block: "center" });
    //   window.scrollTo({ top: 0 });
    // }
  }, [props.currentViewName]);

  const Appointment = ({ children, style, ...restProps }) => {
    const classes = useStyles({ style });
    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          display: "inline",
          lineHeight: "0.9em",
          marginTop: "1px",
          height: "95%",
          backgroundColor: "white",
        }}
      >
        {props.currentViewName === "Month" ? (
          <MonthViewComponent
            data={restProps.data}
            viewSurgicalCase={(externalId, sfId) =>
              viewSurgicalCase(externalId, sfId)
            }
          />
        ) : props.currentViewName === "Week" ? (
          <WeekViewComponent
            data={restProps?.data}
            viewSurgicalCase={(externalId, sfId) =>
              viewSurgicalCase(externalId, sfId)
            }
          />
        ) : (
          <DayViewComponent
            data={restProps.data}
            viewSurgicalCase={(externalId, sfId) =>
              viewSurgicalCase(externalId, sfId)
            }
          />
        )}
      </Appointments.Appointment>
    );
  };

  const TimeIndicator = ({ top, ...restProps }) => {
    const classes = useStyles({ top });
    return (
      // <div {...restProps} ref={timeRef}>
      <div {...restProps}>
        <div className={classes.circle} />
        <div className={classes.line} />
      </div>
    );
  };

  const viewSurgicalCase = (externalId, sfId) => {
    setLoading(true);
    getCaseById(externalId, sfId).then((res) => {
      GetCaseById_Set(res.data);
      setLoading(false);
      history.push("/ViewSurgicalCase");
    });
  };

  const onChange24Hours = (is24hrs) => {
    const startHour = is24hrs ? 0 : 6;
    const endHour = is24hrs ? 24 : 18;
    set24hours(is24hrs);
    setDay({ startHour, endHour });
  };

  return (
    <Paper className={classes.schedular}>
      {viewName !== "Month" && (
        <div className={classes.hrsCheckBox}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={is24hours}
                onChange={() => onChange24Hours(!is24hours)}
                name={"24hours"}
              />
            }
            label={"24 Hours"}
          />
        </div>
      )}
      <Scheduler height={700} data={props.appointments}>
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={(value, event) => {
            changeDate(new Date(value));
            props.onChange(value);
          }}
          currentViewName={viewName}
          onCurrentViewNameChange={(displayName) => {
            currentViewChange(displayName);
            props.currentViewNameChange(displayName);
          }}
        />
        <MonthView displayName="Month" />
        <WeekView
          startDayHour={day.startHour}
          endDayHour={day.endHour}
          displayName="Week"
          cellDuration={15}
        />
        <DayView
          displayName="Day"
          startDate={new Date(currentDate)}
          today={false}
          startDayHour={day.startHour}
          endDayHour={day.endHour}
          cellDuration={15}
        />
        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <ViewSwitcher />
        <Appointments
          appointmentComponent={Appointment}
          appointmentType="vertical"
        />
        <CurrentTimeIndicator indicatorComponent={TimeIndicator} />
      </Scheduler>
      <SimpleBackdrop open={loading} />
    </Paper>
  );
};

export default SchedulerComponent;
