import moment from 'moment';
import axios from 'axios';
import React, { Component } from 'react';
import { CALENDAR_STATUS, REPSUITE_CASE_STATUS, TPR_STATUS, API } from '../constants';
import { get_tpr_history } from '../graphql/queries';

class surgicalCase extends Component {
    form = {};
    static assignSurgicalCaseValue = (data) => {
        this.form = data;
    }
    static getSurgicalCaseValue = () => this.form;
    static sresetSurgicalCaseValue = () => this.form = {};
}

export const getStatusColor = (status) => {
    const caseStatus = CALENDAR_STATUS.find(item => item.label === status);
    return caseStatus ? caseStatus.color : '#c52c02';
}

export const getDiffHours = (createdDate, surgeryDate) => {
    const diffHours = moment(createdDate).diff(surgeryDate, 'hours');
    return Math.abs(diffHours) < 48;
};

export const determineCaseEditability = async (caseDetails) => {
    const { status, as1, mako, sfId, revision, sets } = caseDetails;
    const caseFields = {
        Division: true,
        Branch: true,
        SubBranch: true,
        Hospital: true,
        HospitalLocation: true,
        Surgeon: true,
        SalesRep: true,
        CoveringReps: true,
        Procedure: true,
        SurgeryStartDateTime: true,
        SurgeryDateConfirmed: true,
        PatientID: true,
        Left: true,
        Right: true,
        Mako: true,
        AS1: true,
        Revision: true,
        InternalComments: true
    }

    // Enable fields based on Case Status ( Note: make it false to enable field ) 
    // New: Enable all fields
    if (status === REPSUITE_CASE_STATUS.NEW) {
        return {}
    }
    // Completed, Closed, Cancelled: Disable all fields
    if (status === REPSUITE_CASE_STATUS.CLOSED || status === REPSUITE_CASE_STATUS.CANCELLED || status === REPSUITE_CASE_STATUS.COMPLETED) {
        return caseFields;
    } else {
        // Requested, Assigned, Shipped (check for TPR association and its status)
        let tprlist;
        if (sfId != null && (as1 || mako)) {
            const response = await get_tpr_history(sfId);
            tprlist = response && response.data && response.data.get_tpr_list;
        }
        if (tprlist) {
            // Check for submitted or beyond status TPRs if any
            const OtherStatusTprs = tprlist.filter(tpr => tpr.tpr_status !== TPR_STATUS.CREATED && tpr.tpr_status !== TPR_STATUS.CREATED_AWAITING_CTSCAN);
            if (OtherStatusTprs && OtherStatusTprs.length > 0) {
                // one or more tprs in submotted or beyond status
                return {
                    ...caseFields,
                    Hospital: sets && sets.length > 0 ? true : false,
                    HospitalLocation: sets && sets.length > 0 ? true : false,
                    Surgeon: (revision === true && as1 === true ? true : false || as1 === true ? true : false),
                    SalesRep: false,
                    CoveringReps: false,
                    SurgeryStartDateTime: false,
                    SurgeryDateConfirmed: false,
                    PatientID: false,
                    Revision: false,
                    InternalComments: false
                }
            } else {
                // all tprs in created state
                return {
                    ...caseFields,
                    Hospital: sets && sets.length > 0 ? true : false,
                    HospitalLocation: sets && sets.length > 0 ? true : false,
                    Surgeon: (revision === true && as1 === true ? true : false || as1 === true ? true : false),
                    SalesRep: false,
                    CoveringReps: false,
                    SurgeryStartDateTime: false,
                    SurgeryDateConfirmed: false,
                    PatientID: false,
                    Revision: false,
                    InternalComments: false
                }
            }
        } else {
            // Non As1/Mako case (or) As1/Mako case with no TPR
            switch (status) {
                case REPSUITE_CASE_STATUS.REQUESTED:
                    return {
                        ...caseFields,
                        Hospital: sets && sets.length > 0 ? true : false,
                        HospitalLocation: sets && sets.length > 0 ? true : false,
                        Surgeon: false,
                        SalesRep: false,
                        CoveringReps: false,
                        SurgeryStartDateTime: false,
                        PatientID: false,
                        Mako: false,
                        AS1: false,
                        Revision: false,
                        InternalComments: false
                    }
                case REPSUITE_CASE_STATUS.ASSIGNED:
                case REPSUITE_CASE_STATUS.SHIPPED_READY:
                    return {
                        ...caseFields,
                        Surgeon: false,
                        SalesRep: false,
                        CoveringReps: false,
                        SurgeryStartDateTime: false,
                        PatientID: false,
                        Mako: false,
                        AS1: false,
                        Revision: false,
                        InternalComments: false
                    }
                default: return caseFields
            }
        }
    }
}

export const getCaseByCaseID = async (caseId) => {
    const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}')
    try {
        let response = await axios.get(`${API.MASTER_DATA.GET_CASE_By_ID
            }?name=${caseId}`,
            {
                headers: { Authorization: access_token }
            });
        return response;

    } catch (error) {
        console.log(Object.keys(error), error.message);
    }
}

export default surgicalCase;