import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Buttons from "../../components/controls/Button";
import { Grid } from "@material-ui/core";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#eaeaea",
    borderBottom: "1px solid grey",
  },
  buttonroot: {
    borderColor: "orange",
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightgrey",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "gold",
      },
    },
  },
})(TextField);

function SaveFilterDialog(props) {
  const {
    open,
    handleClose,
    classes,
    handleSave,
    handleUpdate,
    selectedFilter,
  } = props;
  const [name, setName] = React.useState();
  const [disable, setDisable] = React.useState(true);
  const [createNew, setCreateNew] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setCreateNew(!selectedFilter);
  }, [selectedFilter]);

  const handleTextBox = (e) => {
    if (e.target.value) {
      setName(e.target.value);
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const onCloseDialog = () => {
    handleClose()
    setTimeout(() => {
      setDisable(true)
      setCreateNew(!selectedFilter)
    }, 1000)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.root} id="responsive-dialog-title">
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>
            {"Save Surgical Case Filter"}
            <CloseIcon
              style={{ float: "right" }}
              onClick={onCloseDialog}
            />
          </div>
        </DialogTitle>
        <DialogContent style={{ display: "grid", gridGap: "20px" }}>
          {createNew ? (
            <>
              {"Please provide a name to save the filter criteria."}
              <CssTextField
                className={classes.margin}
                label="Filter Name"
                variant="outlined"
                id="custom-css-outlined-input"
                onChange={(e) => handleTextBox(e)}
              />
            </>
          ) : (
            <>{`Do you want to create new filter criteria or update existing filter "${selectedFilter}"?`}</>
          )}
        </DialogContent>
        <DialogActions>
          {createNew ? (
            <Buttons
              Icon="Submit"
              toSave={true}
              fnc={() => handleSave(name)}
              disabled={disable}
              name="Save"
            />
          ) : (
            <Grid container justify="flex-end">
              <Buttons
                Icon="Submit"
                toClone={true}
                fnc={() => setCreateNew(true)}
                name="CREATE"
              />
              <Buttons
                Icon="Submit"
                toSave={true}
                fnc={handleUpdate}
                name="UPDATE"
              />
            </Grid>
          )}
          {/* <Buttons Icon="Submit" fnc={handleClose} name="CANCEL" /> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(SaveFilterDialog);
