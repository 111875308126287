import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '97%',
      'text-align': 'right'
    },
  },
}));


export default function MultilineTextFields(props) {
  const classes = useStyles();
  const name = <div className="controllabelCreate"  >{props.label}</div>
  return (
    <form className={classes.root} noValidate autoComplete="off" >
      <TextField
        id={props.label}
        multiline
        placeholder={"Comments"}
        rows={4}
        label={name}
        disabled={props.disabled}
        className={"multilineHeight"}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {props.isError ? <FontAwesomeIcon style={{ marginInline: 'auto', marginBottom: '50px', marginLeft: '10px', fontSize: '14px', color: 'red' }} icon={faExclamationTriangle} /> : null}
            </InputAdornment>
          ),
        }}
        onChange={props.fnc}
        value={props.value}
      />
    </form>
  );
}
