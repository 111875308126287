import { createOrUpdateTPR } from '../../graphql/mutations'
/***************************action types*******************/

export const TreatmentPlanRequest_RESETDATA = 'TreatmentPlanRequest_RESETDATA';
export const TreatmentPlanRequest_INITIATE = 'TreatmentPlanRequest_INITIATE';
export const TreatmentPlanRequest_SEARCH = 'TreatmentPlanRequest_SEARCH';
export const TreatmentPlanRequest_UPDATE = 'TreatmentPlanRequest_UPDATE';
export const TreatmentPlanRequest_STATUS = 'TreatmentPlanRequest_STATUS';
export const TreatmentPlanRequest_SCANS = 'TreatmentPlanRequest_SCANS';
export const TreatmentPlanRequest_CREATEORUPDATETPR_GRAPHQL = 'TreatmentPlanRequest_CREATEORUPDATETPR_GRAPHQL';

export const patientinfo_SAVE = 'patientinfo_SAVE';

export const GET_CASE_BY_ID = 'GET_CASE_BY_ID';

/***************************action and thunks*******************/

export const tpr_reset_initiate = () => {
  return {
    type: TreatmentPlanRequest_INITIATE,
    payload: null
  }
};
export const tpr_reset_search = () => {
  return {
    type: TreatmentPlanRequest_SEARCH,
    payload: null
  }
};
export const tpr_reset_update = () => {
  return {
    type: TreatmentPlanRequest_UPDATE,
    payload: null
  }
};

export const tpr_intial_set = (response) => {
  return {
    type: TreatmentPlanRequest_INITIATE,
    payload: response
  }
};

export const tpr_search_set = (response) => {
  return {
    type: TreatmentPlanRequest_SEARCH,
    payload: response
  }
};

export const tpr_patient_scans_set = (response) => {
  return {
    type: TreatmentPlanRequest_SCANS,
    payload: response
  }
};

export const tpr_status_set = (response) => {
  return {
    type: TreatmentPlanRequest_STATUS,
    payload: response
  }
};

export const dispatchpatientInfo_set = (response) => {
  return {
    type: patientinfo_SAVE,
    payload: response
  }
};
export const dispatchpatientinfo_SAVE = (response) => ({
  type: patientinfo_SAVE,
  payload: response
})

export const dispatchpatientinfo_GET = (response) => {
  return {
    type: patientinfo_SAVE,
    payload: response
  }
};

export const dispatchpatientinfo_UPDATE = (response) => ({
  type: patientinfo_SAVE,
  payload: response
})


/***************************reducer*******************/

//import * as actionTypes from './actionTypes';
const axiosPayLoad = {
  payload: null,
  TreatmentPlanRequest_Intial: null,
  TreatmentPlanRequest_Search: null,
  TreatmentPlanRequest_Status: null,
  TreatmentPlanRequest_PatientScans: null,
  TreatmentPlanRequest_TPRCreateorUpdateGraphql: null,
  patientinfo: null,
  CaseById: null,
};

export const STORE = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case TreatmentPlanRequest_RESETDATA:
      return axiosPayLoad;
    case TreatmentPlanRequest_INITIATE:
      return { ...state, TreatmentPlanRequest_Intial: action.payload }
    case TreatmentPlanRequest_SEARCH:
      return { ...state, TreatmentPlanRequest_Search: action.payload }
    case TreatmentPlanRequest_STATUS:
      return { ...state, TreatmentPlanRequest_Status: action.payload }
      case TreatmentPlanRequest_SCANS:
        return { ...state, TreatmentPlanRequest_PatientScans: action.payload }
    case TreatmentPlanRequest_CREATEORUPDATETPR_GRAPHQL:
      return { ...state, TreatmentPlanRequest_TPRCreateorUpdateGraphql: action.payload }
    case patientinfo_SAVE:
      return { ...state, patientinfo: action.payload }
    case GET_CASE_BY_ID:
        return { ...state, CaseById: action.payload }
    default: return state;
  }
};
