import React, { useEffect, useCallback, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getProductSystems, getSubBranches, getSurgeons,getCatalogSets } from "../../graphql/queries";
import { buildSurgnPreferencePayload, createOrUpdatePrefrences, setCaseProducts, updateSetCatalogCategory, setWebCaseProducts } from "../../graphql/mutations";
import { FormControlLabel, InputLabel } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { API, ASYNC_STORAGE, SETS_PARTS_STATUS } from "../../constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CREATORS as userCase } from "../../redux/reducers/cases";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { getSurgeonPreferences } from '../../redux/modules/getSurgeonPreferences'
import { getSurgeonPreferenceByFilter } from '../../graphql/queries';
import Buttons from "../../components/controls/Button";
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import KitsDialog from "./KitsDialog";
import { formValueChanged, resetFormChangeIndicator } from "../../redux/modules/formChangeLog";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    flexGrow: 1,
    background: "#FAFAFA",
  },
  formControl: {
    margin: "25px 15px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  dot: {
    height: "25px",
    width: "25px",
    padding: "10px 14px",
    borderRadius: "89%",
    backgroundColor: "#FFD271",
    fontSize: "16px",
    fontWeight: "700"
  },
  textField: { marginTop: "12px" },
  selectEmpty: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: "12px",
    minWidth: 250,
  },
  AddButton: {
    background: "#FFB500",
    boxShadow: "none",
    color: "#000",
    fontWeight: "bold",

    "&:hover": {
      background: "#FFD271",
      boxShadow: "none",
      color: "#000",
    },
    "&:focus": {
      background: "#FFB500!important",
      boxShadow: "none",
      color: "#000",
    },
  },

  DeleteButton: {
    background: "#000",
    boxShadow: "none",
    color: "#fff",
    fontWeight: "bold",

    "&:hover": {
      background: "#000",
      boxShadow: "none",
      color: "#fff",
    },
    "&:focus": {
      background: "#000!important",
      boxShadow: "none",
      color: "#fff",
    },
  },

  ResetBtn: {
    background: "#fff",
    color: "#000",
    boxShadow: "none",
    border: "1px solid #E0E0E0",
    fontWeight: "bold",

    "&:hover": {
      background: "#fff",
      boxShadow: "none",
      color: "#000",
    },
    "&:focus": {
      background: "#fff!important",
      boxShadow: "none",
      color: "#000",
    },
  },

  Custometable: {
    background: "#FFF",
    margin: "0",
    padding: "0",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AddSets(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_user = JSON.parse(localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || '{}');
  const [showForm, setshowForm] = React.useState(false);
  const [savedSets, setSavedSets] = React.useState([]);
  const [rows, setrows] = React.useState([]);
  const [rows5, setrows5] = React.useState([]);
  const [duplicateFlag, setDuplicateFlag] = React.useState(false);
  const GetCaseById_Set = (response) =>
    dispatch(userCase.setCaseDetail(response));
  let Case_Data = null;
  const setsChanged = () => dispatch(formValueChanged('sets'))
  const resetChanges = () => dispatch(resetFormChangeIndicator())
  const [options, setOptions] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState(rows && rows.slice().fill(false));
  const [isChecked2, setIsChecked2] = React.useState([]);
  const [setvalues, setSetValue] = React.useState([]);
  const [loaderOpen, setLoaderOpen] = React.useState(true);
  const [isDataSynced, setIsDataSynced] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [subBranchesOptions, setSubBranchesOptions] = React.useState([])
  const [subBranchesValue, setSubBranchesValue] = React.useState(props.caseDetails.subBranch)
  const [subBranchName, setSubBranchName] = React.useState(props.caseDetails.subBranchName)
  const [selectedPreference, setSelectedPreference] = React.useState('')
  const [surgeonSetsPreferences, setSurgeonSetsPreferences] = React.useState();
  const [surgeonID, setSurgeonID] = useState('');
  const [isPrefLoading, setIsPrefLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [selectedSet, setSelectedSet] = useState(null)
  const [rows2, setrows2] = React.useState([]);
  const [newSetsValue, setNewSetsValue] = React.useState([]);
  const [catalogSets, setCatalogSets] = React.useState([]);
  const [productToAdd, setProductToAdd] = React.useState([]);
  const [selectedProductToDelete, setSelectedProductToDelete] = React.useState([]);
  const [addedProducts, setAddedProducts] = React.useState([]);
  const [rows4, setrows4] = React.useState([]);
  
  // const surgeonSetsPreferences = useSelector(state => state.getSurgeonPreferences && state.getSurgeonPreferences.surgeonList && state.getSurgeonPreferences.surgeonList.preferences);
  useEffect(() => {
    GetCaseById();
    
      // New Territory Design Query
      getSurgeons()
      .then(Surgeons => {
          const surgn = Surgeons.get_surgeons.find(item => item.surgeonName === props.caseDetails.surgeonName);
          setSurgeonID(surgn ? surgn.surgeonID : '');
      })

  }, []);

  useEffect(() => {
   getCatalogSetsFromCatalogSets();  
  }, [])

  useEffect(() => {
    if (props.caseDetails) {
      setIsDisabled(
        props.caseDetails.status === SETS_PARTS_STATUS.ASSIGNED ||
          props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED ||
          props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED_READY ? true : false
      )
    }
  }, [props.caseDetails])

  useEffect(() => {
    if(subBranchesValue){
      setSurgeonSetsPreferences();
      setIsPrefLoading(true);
      const hospitalId = props.caseDetails.hospitalid__c;
      const procedureId = props.caseDetails.procedureid__c;
      const filterSurgeonId = props.caseDetails.hospital_staffid__c;
      const filterObject = Object.assign({},{ procedure: procedureId, filterHospital: hospitalId, filterSurgeon: filterSurgeonId });
      getSurgeonPreferenceByFilter(subBranchesValue, filterObject)
        .then((response) => {
          const surgnPreferences = response.data.get_surgeon_pref.preferences;
          const surgnPreferencesSets = response.data.get_surgeon_pref.preferencesSets;
          let t1 = [];
          let t2 = surgnPreferencesSets;
          let t3 = [];
          for (let i = 0; i < t2.length; i++) {
          let check = false;
          t2[i].category_name  =  t2[i].category_name === null ? '' : t2[i].category_name;
          for (let j = 0; j < t3.length; j++) {
             if (t2[i].category_name == t3[j]) {
              check = true;
              break;
             }
          }

          // t2.includes(t2[i].category);

          if (check == false) {
            t3.push(t2[i].category_name);
            t1.push({
              "category" : t2[i].category_name,
              "requested_set_name" : [
              {
                "category__c":  t2[i].category__c,
                "external_id":  t2[i].external_id,
                "is_preference_product__c": t2[i].is_preference_product__c,
                "kit_no__c": t2[i].kit_no__c,
                "loaner__c": t2[i].loaner__c,
                "productSfid": t2[i].productSfid,
                "productsystemname__c": t2[i].productsystemname__c,
                "quantity__c": t2[i].quantity__c,
                "requested_set_name": t2[i].requested_set_name,
                "setType": t2[i].setType,
                "set_id": t2[i].set_id,
                "set_name": t2[i].set_name,
                "status__c": t2[i].status__c,
                "itemsValid": t2[i].isPrefItemsValid
              }
              ],
              "status__c" : t2[i].status__c
            })
          }
          else {
            let index = -1;

            for (let k = 0; k < t1.length; k++) {
              if (t1[k].category == t2[i].category_name) {
              index = k;
               break;
              }
           }
           t1[index].requested_set_name.push({
            "category__c":  t2[i].category__c,
            "external_id":  t2[i].external_id,
            "is_preference_product__c": t2[i].is_preference_product__c,
            "kit_no__c": t2[i].kit_no__c,
            "loaner__c": t2[i].loaner__c,
            "productSfid": t2[i].productSfid,
            "productsystemname__c": t2[i].productsystemname__c,
            "quantity__c": t2[i].quantity__c,
            "requested_set_name": t2[i].requested_set_name,
            "setType": t2[i].setType,
            "set_id": t2[i].set_id,
            "set_name": t2[i].set_name,
            "status__c": t2[i].status__c,
            "itemsValid": t2[i].isPrefItemsValid
          })     
          }
          }
          // setrows2(t1);
          return (
            surgnPreferences &&
            surgnPreferences.length > 0 &&
            surgnPreferences.filter(
              (item) => item.surgeonName === props.caseDetails.surgeonName
            )
          );
        })
        .then((surgeon) => {
          setSurgeonSetsPreferences(surgeon);
          setIsPrefLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsPrefLoading(false);
        });
    }
  }, [subBranchesValue])

  useEffect(() => {
    if(!isDataSynced){
      setsChanged()
    }
    return () => {
      resetChanges()
    }
  }, [isDataSynced])

  const handleAddRow = () => {
    let addRow = rows5;
    addRow.push('Row Added');
    setrows5(addRow);
    setshowForm(true);
    setIsDataSynced(false);
    const min = 1;
    const max = 1000;
    const rand = min + Math.random() * (max - min);
    const item = {
      set_id: "",
      set_name: "",
      status__c: "Requested",
      input: ""
    };
    setrows([...rows, item]);
    setOptions([]);
    setIsChecked([...isChecked, false])
    const values = [...setvalues];
    values.push({
      id: "",
      type: "loaner",
      quantity: 1,
      is_preference_product__c: true,
      status__c: "Requested",
    });
    setSetValue(values);
  };

  const handleResetClick = () => {
    let savedSets = [];
    let displayRows = [];
    rows.forEach((item, index) => {
      savedSets.push({
        id: item.set_name !== "" ? item.set_id : "",
        type: "loaner",
        quantity: 1,
        is_preference_product__c: true,
        status__c: item.set_name !== "" ? item.status__c : "Requested",
      });

      if (item.set_name !== "") {
        displayRows.push(item);
      } else {
        // clear all entered input values
        displayRows.push({
          set_id: "",
          set_name: "",
          status__c: "Requested",
          input: ""
        });
      }
    });
    setrows(displayRows);
    setSetValue(savedSets);
    setOptions([]);
    setSelectedPreference('');
  };

  const handleProductChange = (index, event) => {
    if (props.caseDetails && event.target.value.length >= 2) {
      getProductSystems(
        event.target.value,
        props.caseDetails.procedures[0].sfid,
        subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
        props.caseDetails.storageExist,
        props.caseDetails.userRep
      )
        .then((res) => {
          let prevSetId = '';
          return res.get_parts_in_sets.filter((item, index) => {
            let _prevSetId = prevSetId;
            prevSetId = item.set_id;
            item.catalog_category = item.catalog_category;
            item.set_id = item.set_id;
            item.catalog_sfid = item.catalog_sfid;
            return _prevSetId !== item.set_id; // to get unique set
          });
        })
        .then((res) => {
          setOptions(res);
        });
    }
  };

  const saveClick = () => {
    handlesaveClick(setvalues);
  };

  const handlesaveClick = async (setvalues) => {
    // const setIdArr = rows2.map((item) => { return item.id });
    // const isDuplicateProductExist = setIdArr.some((item, idx) => { 
    //     return setIdArr.indexOf(item) != idx 
    // });

    // if (isDuplicateProductExist) {
    //   props.displaySnackBarMessage(
    //     "error", "Sets/Parts cannot be saved as you have duplicate product(s)."
    //   );
    //   return
    // }
    const payload = {
      case__c: props.caseDetails.sfId,
      externalId: props.caseDetails.externalId,
      sets: setvalues,
      parts: props.caseDetails.parts,
      is_hospital_owned: props.caseDetails.is_hospital_owned,
      division: props.caseDetails.division,
      is_submit: true,
      case_current_status: "Requested",
      branch: subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
    };
    try {
      setLoaderOpen(true);
      // return setCaseProducts(payload).then((response) => {
         return setWebCaseProducts(payload).then((response) => {
        // let setsCount = response.data.setCaseProducts.sets.length;
        let setsCount = response.data.setWebCaseProducts.sets.length;
        if (setsCount != setvalues.length) {
          let retryCount = 0;
          let intervalFunc = setInterval(() => {
            retryCount++
            if (retryCount < 2) {
              // settingSetId();
              setrows5([]);
              GetCaseById()
              setLoaderOpen(false);
              props.displaySnackBarMessage(
                "success",
                "Sets/Parts Details are saved successfully"
              );
            }
            else {
              clearInterval(intervalFunc);
            }
          }, 7000)
        } else {
          // settingSetId();
          setrows5([]);
          GetCaseById();
          setLoaderOpen(false);
          props.displaySnackBarMessage(
            "success",
            "Sets/Parts Details are saved successfully"
          );
        }
      });
    } catch (ex) {
      console.log(`Error ${ex}`);
    }
  };

  // const handleCheckBoxClick = (index) => {
  //   setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  // };

  const handleCheckBoxClick = (index, idx, item) => {
    // setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
    let e1 = [];
    if (selectedProductToDelete.includes(item)) {
      let index = selectedProductToDelete.indexOf(item);
    selectedProductToDelete.splice(index, 1);
    e1 = selectedProductToDelete;
    }
  else {
    for (let i = 0; i < selectedProductToDelete.length; i++) {
      e1.push(selectedProductToDelete[i]);
    }
    e1.push(item);
  }
    setSelectedProductToDelete(e1);
    let countIndex = 0;
    for (let i = 0; i < idx; i++) {
      countIndex = countIndex + rows2[i].requested_set_name.length;
    }
    countIndex = countIndex + index;
    setIsChecked(isChecked.map((v, i) => (i ===   countIndex) ? !v : v));
    setIsChecked2(isChecked2.map((v, i) => (i === countIndex) ? !v : v));
  }


  const handleRemoveRow = () => {
    
    // setDialogOpen(false);
    // setLoading(true);
    setData(-1);
    const activeRows = [];
    isChecked2.map((r, idx) => {
      if (r === false) {
        activeRows.push(rows4[idx])
      }
    })
    // setrows2(activeRows);
    setIsChecked(isChecked.filter(i => i === false));
    setIsChecked2(isChecked2.filter(i => i === false));
    const setvalues = buildSetValues(activeRows)
    // Save(setvalues);
    setLoaderOpen(true);
    Save1(setvalues);
  };

  const buildSetValues = (rows) => {
    const setvalues = [];
    rows.map(r => {
      const itm = {
        "catalog_sfid": r.category_sfid,
        "id": r.set_id,
        "type": "loaner",
        "quantity": 1,
        "is_preference_product__c": true,
        "status__c": "Requested"
      }
      setvalues.push(itm);
    });

    return setvalues;
  }

  const Save1 = async (setvalues) => {
    setSelectedProductToDelete([]);
    const payload = {
      "case__c": props.caseDetails.sfId,
      "externalId": props.caseDetails.externalId,
      "sets": setvalues,
      "parts": props.caseDetails.parts,
      "is_hospital_owned": props.caseDetails.is_hospital_owned,
      "division": props.caseDetails.division,
      "is_submit": true,
      "case_current_status": "Requested",
      "branch": subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
    }
    try {
      // return setCaseProducts(payload)
      return setWebCaseProducts(payload)
        .then(response => {
          // let setsCount = response.data.setCaseProducts.sets.length;
          let setsCount = response.data.setWebCaseProducts.sets.length;
          if(setsCount != setvalues.length){
            let retryCount = 0;
            let intervalFunc = setInterval(() => {
              retryCount++;
             if (retryCount<2){
              GetCaseById()
              
               props.displaySnackBarMessage("success", 'Sets/Parts Details are saved successfully');
             }
             else{
              clearInterval(intervalFunc);
             }
           }, 7000)
          }
          else {
            GetCaseById()
            props.displaySnackBarMessage("success", 'Sets/Parts Details are saved successfully');
          }
        })
    } catch (ex) {
      console.log(`Error ${ex}`);
    }
  }

  const handleRemoveRow1 = () => {
    setIsDataSynced(false);
    const activeRows = [];
    let activeSets = [];
    isChecked.map((r, idx) => {
      if (r === false) {
        activeRows.push(rows[idx])
        activeSets.push(setvalues[idx])
      }
    })
    // const setIdArr = activeSets.map((item) => { return item.id });
    // const isDuplicateProductExist = setIdArr.some((item, idx) => { 
    //     return setIdArr.indexOf(item) != idx 
    // });
    // setDuplicateFlag(isDuplicateProductExist);
    setrows(activeRows);
    setSetValue(activeSets);
    setIsChecked(isChecked.filter(i => i === false));
  };

  const handleSubBranchChange = async (event) => {
    const val = event.target.value.id ? event.target.value.id : event.target.value;
    setSubBranchesValue(val);
  };

  const handlePreferenceChange = (event) => {
    if (event.target.value) {
      setSelectedPreference(event.target.value)
    } else {
      setSelectedPreference('')
    }
  }

  const GetCaseById = useCallback(async () => {
    const { access_token } = JSON.parse(
      localStorage.getItem("PU:TOKEN") || "{}"
    );
    let url = `${API.MASTER_DATA.GET_CASE_By_ID}`;
    if (props.caseDetails.externalId) {
      url = url + `?externalId=${props.caseDetails.externalId}`;
    }
    else {
      url = url + `?sfId=${props.caseDetails.sfId}`;
    }
    var config = {
      method: "get",
      url: url,
      headers: { Authorization: access_token },
      data: {},
    };
    try {
      let response = await axios(config);
      if (response.data.sets.length > 0) {
        const values = [];
        catalogueDisplay(response.data.sets);
        setNewSetsValue(response.data.sets);
        response.data.sets.forEach((item, index) => {
          values.push({
            catalog_sfid: item.category_sfid,
            id: item.set_id,
            type: "loaner",
            quantity: 1,
            is_preference_product__c: true,
            status__c: item.status__c,
          });
        });
        setSavedSets(response.data.sets);
        setSetValue(values);
        setrows(response.data.sets);
        // setrows2(response.data.sets);
        setshowForm(true);
        setIsChecked(response.data.sets && (response.data.sets.slice().fill(false)))
        // setIsChecked2(response.data.sets && (response.data.sets.slice().fill(false)))
        setSelectedProductToDelete([]);
      } else {
        setrows([]);
        setSetValue([]);
      }
      setIsDataSynced(true);
      setLoaderOpen(false);
      Case_Data = response.data;
      GetCaseById_Set(response.data);
    } catch (ex) {
      console.log(`Error ${ex}`);
      props.displaySnackBarMessage("error", "We've had trouble getting the Case Sets")
    }
  }, []);

  const getSetsPreference = () => {
    if (selectedPreference && surgeonSetsPreferences) {
      const finalSets = surgeonSetsPreferences.filter((set) => set.sfid === selectedPreference)
      setProductToAdd(finalSets[0] && finalSets[0].sets);
      localStorage.setItem("test88", JSON.stringify(finalSets[0] && finalSets[0].sets));
      assignSets(finalSets[0] && finalSets[0].sets)
    }
  }

  function catalogueDisplay (e1) {
    let t1 = [];
    let t2 = e1;
    let t3 = [];
    let t4 = [];
    for (let i = 0; i < t2.length; i++) {
    let check = false;
    t2[i].category_name  =  t2[i].category_name === null ? '' : t2[i].category_name;
    for (let j = 0; j < t3.length; j++) {
       if (t2[i].category_name == t3[j]) {
        check = true;
        break;
       }
    }

    // t2.includes(t2[i].category);

    if (check == false) {
      t4.push(t2[i]);
      t3.push(t2[i].category_name);
      t1.push({
        "category" : t2[i].category_name,
        "requested_set_name" : [
        {
          "category__c":  t2[i].category__c,
          "external_id":  t2[i].external_id,
          "is_preference_product__c": t2[i].is_preference_product__c,
          "kit_no__c": t2[i].kit_no__c,
          "loaner__c": t2[i].loaner__c,
          "productSfid": t2[i].productSfid,
          "productsystemname__c": t2[i].productsystemname__c,
          "quantity__c": t2[i].quantity__c,
          "requested_set_name": t2[i].requested_set_name,
          "setType": t2[i].setType,
          "set_id": t2[i].set_id,
          "set_name": t2[i].set_name,
          "status__c": t2[i].status__c,
          "itemsValid": t2[i].isPrefItemsValid
        }
        ],
        "status__c" : t2[i].status__c
      })
    }
    else {
      let index = -1;

      for (let k = 0; k < t1.length; k++) {
        if (t1[k].category == t2[i].category_name) {
        index = k;
         break;
        }
     }
     t4.push(t2[i]);
     t1[index].requested_set_name.push({
      "category__c":  t2[i].category__c,
      "external_id":  t2[i].external_id,
      "is_preference_product__c": t2[i].is_preference_product__c,
      "kit_no__c": t2[i].kit_no__c,
      "loaner__c": t2[i].loaner__c,
      "productSfid": t2[i].productSfid,
      "productsystemname__c": t2[i].productsystemname__c,
      "quantity__c": t2[i].quantity__c,
      "requested_set_name": t2[i].requested_set_name,
      "setType": t2[i].setType,
      "set_id": t2[i].set_id,
      "set_name": t2[i].set_name,
      "status__c": t2[i].status__c,
      "itemsValid": t2[i].isPrefItemsValid
    })     
    }
    }
    t4.sort((a, b) => {
      let fa = a.category_name && a.category_name.toLowerCase(),
          fb = b.category_name && b.category_name.toLowerCase();
  
      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
  });

  t1.sort((a, b) => {
    let fa = a.category && a.category.toLowerCase(),
        fb = b.category && b.category.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
});
    setrows2(t1);
    setrows4(t4);
    setIsChecked2(t4 && (t4.slice().fill(false)))
  }

  function getCatalogSetsFromCatalogSets () {
    getCatalogSets(
      props.caseDetails.procedures[0].sfid,
      subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
      props.caseDetails.storageExist,
      props.caseDetails.userRep)
      .then(res => {
        setCatalogSets(res.get_catalog_in_sets);
        })
  }

   function settingSetId () {
    try {
      let newSetValues2 = [];
      let existingSets = newSetsValue;
      for (let i = 0; i < existingSets.length; i++) {
        newSetValues2.push({
          "sfid": existingSets[i].set_id,
          "category":existingSets[i].category_sfid
    })
      }
      let tt =JSON.parse(localStorage.getItem("test88"));
      for (let i = 0; i < tt.length; i++) {
      let category = tt[i].category;
      let check = false;
        for (let j = 0; j < catalogSets.length; j++) {
          if ( catalogSets[j].catalog_category == category) {
            for (let k = 0; k < catalogSets[j].caseProducts.length; k++) {
                if (catalogSets[j].caseProducts[k].includes(tt[i].set_name)) {
               let z = catalogSets[j].caseProducts[k];
                     let e1 = z.split(", ");
                         newSetValues2.push({
                           "sfid": e1[e1.length - 1],
                           "category":catalogSets[j].catalog_sfid 
                     })
              //  check = true;
               break;
              }
            }
          }
        }
      }
      return updateSetCatalogCategory(props.caseDetails.externalId, newSetValues2)
            .then(response => {
              GetCaseById();
            })
  } catch (ex) {
    GetCaseById();
        console.log(`Error ${ex}`);
      }
}

  const assignSets = (sets) => {
      setshowForm(true);
      setIsDataSynced(false);
      const array1Display= []
      const array2RequestObject = []
      let e2 = [];
      for (let i = 0; i < sets.length; i++) {
        let duplicateProduct = false;
        for (let j = 0; j < newSetsValue.length; j++) {
        if (newSetsValue[j].set_id === sets[i].set_id) {
          duplicateProduct = true;
          break;
        }
        }
        if (duplicateProduct === false) {
          sets[i]["catalog_sfid"] = sets[i].category_sfid;
          sets[i]["category_name"] = sets[i].category;
          e2.push(sets[i]);
        }
      }
      e2.forEach(element => {
        array1Display.push( {
          catalog_sfid: element.category_sfid,
          set_id: element.set_id,
          set_name: '',
          status__c: "Requested",
          input: element.set_name
        })
        array2RequestObject.push({
          catalog_sfid: element.category_sfid,
          id: element.set_id,
          type: "loaner",
          quantity: 1,
          is_preference_product__c: true,
          status__c: "Requested",
        })
      });
      setrows([...rows, ...array1Display]);
      setOptions([]);
      setIsChecked([...isChecked, ...(array1Display && array1Display.slice().fill(false))])
      const setValArr = [...setvalues, ...array2RequestObject];
      setAddedProducts(e2);
      let e1 = [...e2, ...newSetsValue];
      setSetValue(setValArr);
      catalogueDisplay(e1);

      const setIdArr = setValArr.map((item) => { return item.id });
      const isDuplicateProductExist = setIdArr.some((item, idx) => { 
          return setIdArr.indexOf(item) != idx 
      });

      // if (isDuplicateProductExist) {
      //   props.displaySnackBarMessage(
      //     "error", "Selected preference set(s) are already exist. Please delete duplicate set(s)."
      //   );
      // }
  }

  const savePreference = () => {
    const setIdArr = setvalues.map((item) => { return item.id });
    const isDuplicateProductExist = setIdArr.some((item, idx) => { 
        return setIdArr.indexOf(item) != idx 
    });

    if (isDuplicateProductExist) {
      props.displaySnackBarMessage(
        "error", "Duplicate set(s) added"
      );
      return
    }

    setLoaderOpen(true);
    if (selectedPreference) { 
        // Existing Preference
        const selectedPref = surgeonSetsPreferences.find((set) => set.sfid === selectedPreference)
        const { external_id__c, sfid } = selectedPref;
        saveSurgeonPreference(external_id__c, sfid);
    } else {
        // New Preference
        checkUnqPrefAndSave();
    }
  }

  const checkUnqPrefAndSave = () => {
    // Get User Preference for branch
    const hospitalId = props.caseDetails.hospitalid__c
    const procedureId = props.caseDetails.procedureid__c
    const filterObject = Object.assign({}, { procedure: procedureId, filterHospital: hospitalId, filterSalesRep: `${current_user.sfids[0]}`})
    getSurgeonPreferenceByFilter(subBranchesValue, filterObject)
    .then(response => {  
      const surgnPreferences = response.data.get_surgeon_pref.preferences;
      const userPref = surgnPreferences && surgnPreferences.length > 0 &&
        surgnPreferences.filter(item => item.surgeonName === props.caseDetails.surgeonName)
      return userPref && userPref.length > 0 ? false : true ;
    })
    .then(isUnqCombo => {
        if(!isUnqCombo){
          setLoaderOpen(false);
          props.displaySnackBarMessage("error", "Surgeon Preference Already Exists")
        } else {
          saveSurgeonPreference();
        }
    })
}

const saveSurgeonPreference = (externalId = null, sfid = null) => {
    const preferenceObj = {
      combination: {
        branch: subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
        hospital: {
          hospitalId: props.caseDetails.hospitalid__c,
          hospitalName: props.caseDetails.hospitalName
        },
        surgeon: {
          surgeonID: surgeonID,
          surgeonName: props.caseDetails.surgeonName
        },
        procedure: {
          id: props.caseDetails.procedureid__c,
          label: props.caseDetails.procedure_list__c
        },
      },
      surgicalPreferenceObj: {
        loanerSets: rows,
      },
    }
    const payload = buildSurgnPreferencePayload(preferenceObj, current_user, false);
    createOrUpdatePrefrences(payload, externalId, sfid)
    .then(result => {
        setLoaderOpen(false);
        props.displaySnackBarMessage("Success", `Surgeon preference has been ${selectedPreference ? "updated" : "created"} successfully.`)
    })
    .catch( res => {
        setLoaderOpen(false);
        props.displaySnackBarMessage("error", "Something went wrong! Please try again.")
    })
}

let [selectedId, setData] = useState('-1');
const handleCheckBoxClicks = (index, item) => {

  setData(index == selectedId ? -1 :  index);

//  checks(item);
}

const showKits = (selSet) => {
  setSelectedSet(selSet)
  setOpen(true);
}

  return loaderOpen ? (
    <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
  ) : (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} container>
          <Grid item xs={2} alignItems="center" className={classes.root}>
            <Typography className={classes.formControl}>
              Requested Sets{" "}
            </Typography>
          </Grid>

          <Grid
            item
            xs={10}
            container
            justify="flex-end"
            alignItems="baseline"
            className={classes.root}
          >
            <div style={{display:"flex"}}>
              <Typography className={classes.formControl}>
                Sub Branch/Agency
              </Typography>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                placeholder={"Sub Branch/Agency"}
                disabled
                value={subBranchName}
              />
            </div>
            {/* <Typography className={classes.formControl}>
              Sub Branch/Agency
            </Typography>
            <FormControl className={classes.selectEmpty}>
              <NativeSelect
                labelId="dropdown-filled-label"
                // disabled={isDisabled || rows.length > 0}
                disabled={true}
                className={subBranchesValue ? null : 'dropdownPlaceholder'}
                value={subBranchesValue}
                name="subBranch"
                onChange={(e) => handleSubBranchChange(e)}
                inputProps={{ "aria-label": "subBranch" }}
              >
                <option value=""> Please Select </option>
                {subBranchesOptions.length > 0 && subBranchesOptions.map(item =>
                  <option value={item.id}>{item.label}</option>
                )}
              </NativeSelect>
            </FormControl> */}
            <Typography className={classes.formControl}>
              Preferences{" "}
            </Typography>
            <FormControl className={classes.selectEmpty}>
              <NativeSelect
                value={selectedPreference}
                onChange={(e) => handlePreferenceChange(e)}
                id="name-native-disabled"
                className={selectedPreference ? null : 'dropdownPlaceholder'}
                inputProps={{ "aria-label": "age" }}
                disabled={isDisabled || !subBranchesValue}
              >
                <option value="">{isPrefLoading ?'Loading Preferences...' : 'Select Preference'}</option>
                {(surgeonSetsPreferences && surgeonSetsPreferences.length > 0) && surgeonSetsPreferences && surgeonSetsPreferences.map(item =>
                  <option value={item.sfid}>{item.name}</option>
                )}
              </NativeSelect>
            </FormControl>
            <Button size="medium" variant="contained" className={classes.AddButton} onClick={getSetsPreference} disabled={isDisabled || !selectedPreference} >
              ADD
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} container className={classes.root}>
          <Grid
            container
            className={classes.root}
            justify="flex-end"
            style={{ background: "#fff", margin: "0" }}
          >
            <Button
              size="small"
              variant="contained"
              disabled={isDisabled || duplicateFlag || !subBranchesValue || rows.length > 0 && rows.findIndex(r => r.set_id === '') > -1}
              className={classes.AddButton}
              onClick={(e) => handleAddRow(e)}
            >
              ADD
            </Button>
            <Button
              size="small"
              variant="contained"
              className={classes.DeleteButton}
              disabled={isDisabled || rows2.length === 0 || !isChecked2.find(i => i === true)}
              onClick={() => handleRemoveRow()}
            >
              DELETE
            </Button>

            {showForm && rows5.length !== 0 && (
              <>
                <table className="addsetsControl" id="tab_logic">
                  <thead>
                    <tr>
                      <th className="text-center"> </th>
                      <th className="text-center"> Product System</th>
                      <th className="text-center"> Set Name </th>
                      <th className="text-center"> Status </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((item, idx) => (
                      <>
                      {idx >= (rows.length - rows5.length) ?
                      <tr id="addr0" key={idx}>
                        <td></td>
                        {/* <td>
                          {item.status__c == SETS_PARTS_STATUS.ASSIGNED ||
                            item.status__c == SETS_PARTS_STATUS.SHIPPED ||
                            item.status__c == SETS_PARTS_STATUS.SHIPPED_READY ||
                            isDisabled ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={idx}
                                  disabled
                                  color="default"
                                  checked={isChecked[idx]}
                                  onChange={e => handleCheckBoxClick(idx)}
                                  name={item.set_id}
                                  value="left"
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                />
                              }
                            />
                          ) : (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={idx}
                                  color="default"
                                  checked={isChecked[idx]}
                                  onChange={e => handleCheckBoxClick(idx)}
                                  name={item.set_id}
                                  value="left"
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                />
                              }
                            />
                          )}
                        </td> */}
                        <td>
                          {item.set_name != "" ? (
                            <FormControl className={classes.formControl}>
                              <Tooltip title={item.requested_set_name != "" ? item.requested_set_name : item.set_name} placement="top">
                                <TextField
                                  style={{ width: 280 }}
                                  disabled
                                  variant="outlined"
                                  fullWidth
                                  name="id"
                                  value={item.requested_set_name != "" ? item.requested_set_name : item.set_name}
                                  onChange={(event) =>
                                    handleProductChange(idx, event)
                                  }
                                />
                              </Tooltip>
                            </FormControl>
                          )
                           : (
                            <Autocomplete
                              id="google-map-demo"
                              getOptionLabel={(option) => {
                                return typeof option === "string"
                                  ? option
                                  : option.set_name;
                              }}
                              filterOptions={(x) => {
                                return x;
                              }}
                              options={options}
                              value={rows[idx].input}
                              autoComplete
                              includeInputInList
                              freeSolo
                              disableOpenOnFocus
                              onChange={(e, newInputValue) => {
                                const rws = [...rows];
                                const sets = [...setvalues]
                                rws[idx].input = newInputValue ? newInputValue.set_name : '';
                                rws[idx].set_id = newInputValue ? newInputValue.set_id : '';
                                sets[idx].id = '';
                                const selectedOp = newInputValue ? options.findIndex(op => op.set_name === newInputValue.set_name) : -1;
                                if (selectedOp > -1) {
                                  const isDuplicateProduct = sets.findIndex(item => item.id === newInputValue.set_id) > -1
                                  if (isDuplicateProduct) {
                                    setDuplicateFlag(true);
                                    props.displaySnackBarMessage(
                                      "error", "Selected product already exists. Please select different product"
                                    );
                                  } else {
                                    setDuplicateFlag(false);
                                  }
                                  sets[idx].id = options[selectedOp].set_id;
                                  sets[idx].catalog_sfid = options[selectedOp].catalog_sfid;
                                }
                                setSetValue(sets);
                                setrows(rws);
                              }}
                              onInputChange={(e, newInputValue) => {
                                const rws = [...rows];
                                rws[idx].input = newInputValue ? newInputValue : '';
                              }}
                              renderInput={(params) => (
                                <FormControl className={classes.formControl}>
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    style={{ width: 280 }}
                                    name="id"
                                    value={rows[idx].input}
                                    onChange={(event) =>
                                      handleProductChange(idx, event)
                                    }
                                  />
                                </FormControl>
                              )}
                            />
                          )}
                        </td>
                        <td>
                          <Tooltip title={item.status__c !== SETS_PARTS_STATUS.REQUESTED && item.set_name ? item.set_name : ''} placement="top">
                            <TextField
                              type="text"
                              disabled
                              name="mobile"
                              id="outlined-basic"
                              variant="outlined"
                              style={{ width: 280 }}
                              value={item.status__c !== SETS_PARTS_STATUS.REQUESTED && rows[idx].set_name ? rows[idx].set_name : ''}
                              className="form-control"
                            />
                          </Tooltip>
                        </td>
                        <td>
                          <TextField
                            type="text"
                            disabled
                            name="mobile"
                            id="outlined-basic"
                            variant="outlined"
                            value={
                              rows[idx] && rows[idx].status__c
                                ?  rows[idx] && rows[idx].status__c
                                : "Requested"
                            }
                            className="form-control"
                          />
                        </td>
                        {
                          props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED && item.status__c === SETS_PARTS_STATUS.SHIPPED &&
                          <td>
                            <Tooltip title="Show Kit BOM's" placement='top'>
                              <ChevronRightOutlinedIcon size="large" onClick={() =>  showKits(item)} />
                            </Tooltip>
                          </td>
                        }
                      </tr> : ''
                      }
                      </>
                      ))}
                  </tbody>
                </table>
              </>
            )}


            {showForm && rows.length !== 0 && (
              <>
              
                <table className="addsetsControl" id="tab_logic">
                  <thead>
                    <tr>
                      <th className="text-center"> </th>
                      <th className="text-center"> PRODUCT CATEGORY</th>
                      <th className="text-center"> </th>
                      <th className="text-center"> SURGEON IS LOANER </th>
                      <th className="text-center"> Active </th>
                      {/* <th className="text-center"> Status </th> */}

                      <th />
                    </tr>
                  </thead>
                  <tbody>
                  {rows2.map((item, idx) => {
                        return  ( 


                          
                          <>


<TableRow key={rows2.set_id} hover>
                    <TableCell align="left">
                    {idx == selectedId ? 
                         <td style={{fontSize: '25px'}}   onClick={() => handleCheckBoxClicks(idx, item)}> -</td> : <td style={{fontSize: '25px'}}   onClick={() => handleCheckBoxClicks(idx, item)}> +</td>
                        
                        }
                    </TableCell>
                    <TableCell align="left" style={{width:"30%"}}>
                     { item.category}
                    
                    
                    </TableCell>
                    <TableCell align="left">
                    <span className={classes.dot}> {item.requested_set_name.length} </span>
                    
                    </TableCell>
                    <TableCell align="left">

                      {
                      /* {row.isPrefItemsValid || row.isPrefItemsValid.toString().toLowerCase() === 'true' ? "True" : "False"} */}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ cursor: "pointer" }}>
                       
                      </div>
                    </TableCell>
                    <TableCell align="left">
                    </TableCell>
                  </TableRow>




                  

{idx == selectedId ? 
  <>
{item.requested_set_name.map((item2, idx2) => {
  return  ( 
<tr>
<td style={{width:"6%"}}>
     
    </td>
    <td style={{width:"32%"}}> { item2.set_name} </td> 
    <td style={{width:"6%"}}>
     
    </td>
    <td >
    <FormControlLabel
        control={
          <Checkbox
            key={idx2}
            disabled={isDisabled || item.status__c == SETS_PARTS_STATUS.ASSIGNED || 
              item2.status__c == SETS_PARTS_STATUS.SHIPPED || 
              item2.status__c == SETS_PARTS_STATUS.SHIPPED_READY }
            color="default"
            checked={selectedProductToDelete.includes(item2.set_name)}
            onChange={() => handleCheckBoxClick(idx2, idx, item2.set_name)}
            name={item2.name}
            value="left"
            inputProps={{ 'aria-label': 'checkbox with default color' }} />
        }
      />
                       </td>
                      True
                      {/* <td> {item2.itemsValid || item2.itemsValid.toString().toLowerCase() === 'true' ? "True" : "False"}  </td> */}
</tr>
)})}
</> : ''}
</>
                        )})}
                  </tbody>
                </table>
              </>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          className={classes.root}
          justify="flex-end"
          alignItems="baseline"
        >
          <Button
            size="medium"
            variant="contained"
            disabled={isDisabled || isDataSynced || duplicateFlag || (setvalues.length > 0 && setvalues.findIndex(r => r.id === '') > -1)}
            className={classes.AddButton}
            onClick={saveClick}
          >
            SAVE
          </Button>
          <Buttons size="medium" Icon="Submit" disabled={isDisabled || rows.length === 0 || (rows.length > 0 && rows.findIndex(r => r.set_id === '') > -1)} toSubmit={true} fnc={savePreference} name="SAVE PREFERENCE"/>
          <Button
            size="medium"
            variant="contained"
            disabled={isDisabled}
            onClick={handleResetClick}
            className={classes.ResetBtn}
          >
            RESET
          </Button>
        </Grid>
      </Grid>
      <KitsDialog
        dialogOpen={open}
        selectedSet={selectedSet}
        setDialogOpen={() => setOpen(false)}
        displaySnackBarMessage={props.displaySnackBarMessage}
        caseDetails={props.caseDetails}
      />
    </div>
  );
}
