
import { searchAction } from '../../redux/reducers/relatedCase'
import TPRHistoryTable from './Table'
import CircularProgress from "@material-ui/core/CircularProgress";

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

  export default function CaseDetils(props) {
  
  const dispatch = useDispatch();
  const TreatmentPlanRequest_SEARCH = searchvalues => dispatch(searchAction(searchvalues));
  const TreatmentPlanRequest_SEARCH_DATA = useSelector(state => state.relatedCase);

  useEffect(() => {
    TreatmentPlanRequest_SEARCH(props.caseid);
  }, [])

  return (
    <div>
      {props.caseDetails.mako || props.caseDetails.as1 ?
        TreatmentPlanRequest_SEARCH_DATA 
        && TreatmentPlanRequest_SEARCH_DATA.TreatmentPlanRequest 
        && (typeof TreatmentPlanRequest_SEARCH_DATA.TreatmentPlanRequest.get_tpr_list !== 'undefined' && TreatmentPlanRequest_SEARCH_DATA.TreatmentPlanRequest.get_tpr_list!=null)
        && (typeof TreatmentPlanRequest_SEARCH_DATA.TreatmentPlanRequest.get_tpr_list.length !== 'undefined' && TreatmentPlanRequest_SEARCH_DATA.TreatmentPlanRequest.get_tpr_list.length != null) ? 
          <TPRHistoryTable caseDetails={props.caseDetails} onPress={props.onPress} relatedListHistory={TreatmentPlanRequest_SEARCH_DATA ? TreatmentPlanRequest_SEARCH_DATA : []} /> 
          : <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
        : null}
    </div>
  );
}

