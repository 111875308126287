import React, { Component } from 'react';
import Dropdown from '../controls/Dropdown'
import { getSubBranches2, getSubBranches } from '../../graphql/queries'
import { connect } from 'react-redux'

class SubBranches extends Component {
    state = {
        subBraches: [],
    }
    async componentDidMount() {
        if(this.props.branchesValue)
            await this.getSubBranchesOfBranch();
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.branchesValue && this.props.branchesValue !== prevProps.branchesValue) {
            await this.getSubBranchesOfBranch();
        }
    }
    getSubBranchesOfBranch = async () => {
        let subBranches = await getSubBranches2(this.props.branchesValue);
        const uniqueChecker = [];
        const finalSubBranches = [];
        subBranches.get_sub_branches.map(item => {
            let index = uniqueChecker.indexOf(item.branchID);
            if (index === -1) {
                item.label = item.branchName;
                item.id = item.branchID;
                finalSubBranches.push({ id: item.branchID, label: item.branchName});
                uniqueChecker.push(item.id);
            }
        })
        if(finalSubBranches.length === 1){
            let event={};
            event.target={value:finalSubBranches[0]}
            this.props.handleSubBranchChangeDropdown(event)
        }
        this.setState({
            loading: false,
            subBraches: finalSubBranches,
        });
    };
    render() {
        const { subBraches } = this.state;
        return <Dropdown label={"Sub Branch/Agency"} isAstrikReq={true} options={subBraches} ctrllabel={"Please Select"} fnc={this.props.handleSubBranchChangeDropdown} value={this.props.value} Isdisabled={this.props.Isdisabled}/> 
    }
}
const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(SubBranches);