import React from "react";
import axios from "axios";
import moment from "moment";
import {
  API,
  BUS_SEG_CODE,
  PROCEDURE_TYPE,
} from "../../constants";

/**MP: requestBody for calling the API **/
export const requestBody = (caseDetails, capBtn, isContract) => {
  if ((capBtn || caseDetails.capPrice) && isContract) {
    return caseDetails;
  } else if (capBtn || caseDetails.capPrice) {
    const items = [];
    caseDetails.usages.map((parts, index) => {
      const obj = {};
      obj.pid = parts.catalog_number;
      obj.qty = parts.quantity__c > 0 ? parts.quantity__c : 1;
      obj.pusg = parts.wasted ? "Explanted" : "Implanted";
      items.push(obj);
      return items;
    });

    const caseTwo = {
      hospital_id: caseDetails.hospitalid__c,
      procedureType: caseDetails.revision
        ? `${PROCEDURE_TYPE} Revision`
        : `${PROCEDURE_TYPE} Primary`,
      pd: moment(caseDetails.surgeryDate).format("MM/DD/YYYY"),
      items: items,
    };
    return caseTwo;
  } else {
    const partsNumbers = caseDetails.usages;
    const contextPricing = [];
    const primary = [];
    const wasted = [];
    const revision = [];
    const wastedRevision = [];

    partsNumbers &&
      partsNumbers.map((data) => {
        if (data.wasted && (data.revisions__c || caseDetails.revision)) {
          wastedRevision.push(data.catalog_number);
        } else if (data.revisions__c || caseDetails.revision) {
          revision.push(data.catalog_number);
        } else if (data.wasted) {
          wasted.push(data.catalog_number);
        } else {
          primary.push(data.catalog_number);
        }
      });

    contextPricing.push({
      busSegCode: BUS_SEG_CODE[0],
      productNumbers: primary,
    });

    contextPricing.push({
      busSegCode: BUS_SEG_CODE[1],
      productNumbers: wasted,
    });

    contextPricing.push({
      busSegCode: BUS_SEG_CODE[2],
      productNumbers: revision,
    });

    contextPricing.push({
      busSegCode: BUS_SEG_CODE[3],
      productNumbers: wastedRevision,
    });

    const caseOne = {
      hospital_id: caseDetails.hospitalid__c,
      pricingDate: moment(caseDetails.surgeryDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      contextPricing: contextPricing,
    };

    return caseOne;
  }
};

/**MP: Calling the API **/
export const axiosReq = async (url, reqBody) => {
  try {
    const response = await axios
      .post(url, reqBody);
    return response;
  } catch (err) {
    console.log("err", err);
  }
};

/**MP: To check pricing for usages in surgerysheet **/
export const checkPricingForUsages = (caseData) => {
  const nonPriceProducts = [];
  caseData.usages.map((parts) => {
    const filteredprices = caseData.usages.filter(
      (item) =>
        parts.catalog_number === item.catalog_number &&
        parts.wasted === item.wasted  &&
        parts.usg_price__c === null
    );
    if (filteredprices[0]) nonPriceProducts.push(filteredprices[0].catalog_number);
  });
  return nonPriceProducts;
};

/**MP: Main function to get parts data and return modelNPricing for selected parts **/
export const ModelNPricing = async (
  caseDetails,
  capBtn = "",
  isContract = "",
  saveType,
  isPartTab
) => {
  let reqBody = {};
  if (saveType === "autoSave") {
    const user = JSON.parse(localStorage["PU:USER"]);
    reqBody = requestBody(caseDetails, capBtn, isContract);
    reqBody.externalId = caseDetails.externalId;
    reqBody.firstName = user.first_name;
    reqBody.lastName = user.last_name;
    reqBody.isPartTab = isPartTab;
    reqBody.caseId = caseDetails.caseId;
  } else {
    reqBody = requestBody(caseDetails, capBtn, isContract);
  }

  const URL = capBtn
    ? isContract
      ? API.MODELN_CONSTRUCT_PRICE_URL
      : API.MODELN_CONSTRUCT_RESOLVE_URL
    : API.MODELN_PRICING_URL;
  return axiosReq(URL, reqBody);
};

/**MP: To check lineitem parts pricing if null**/
export const checkPriceNonCap = async (modelNData) => {
  if (modelNData?.data) {
    const resolvedData = modelNData.data.resolvedPrices
      ? modelNData.data.resolvedPrices
      : modelNData.data;
    let allData = [];
    resolvedData.map((resolvedPrices) => {
      resolvedPrices.sykResolvedPrices.map((item) => {
        allData.push(item);
      });
    });
    return allData;
  }
};

/**MP: To check capItems parts pricing if null**/
export const checkPriceCap = async (modelNData) => {
  if (modelNData?.data) {
    const constructPrice = modelNData?.data?.constructPrice;
    if(constructPrice){
      const constructData = [...constructPrice.implantedProducts , ...constructPrice.explantedProducts]
      return constructData;
    }
  }
};

export const transfromContractPricesToPayload = (
  caseDetailsObj,
  selectedParts,
  qqCodeStatus,
  qqCode,
  qqRationale,
  freightCharge
) => {
  const {
    externalId,
    sfId,
    capPrice,
    cap_contract_id,
    cap_construct_name,
    total_cap_price,
    upcharge_total,
    cap_contractId,
    cap_contract_name
  } = caseDetailsObj;

  let transformedPart = {
    sfid: sfId,
    external_id: externalId,
    quickquote_code: qqCode,
    quickquote_rationale: qqRationale,
    usage_override: qqCodeStatus,
    cap_price: capPrice,
    cap_contract_id: cap_contract_id,
    cap_construct_name: cap_construct_name,
    total_cap_price__c: total_cap_price,
    upcharge_total:upcharge_total,
    cap_contractId:cap_contractId,
    cap_contract_name:cap_contract_name
  };

  let usagePriceContracts = selectedParts.map((item, index) => ({
    sfid: item.sfid,
    external_id: item.external_id__c ? item.external_id__c : item.external_id,
    price_override: item.price_override,
    usg_price_override: item.price_override ? item.usg_price_override : null,
    pricelistcontract: item.contract_name,
    usg_total_amount: Number(item.price_override ? item.usg_price_override : item.usg_price__c)*Number(item.quantity),
    wasted: item.wasted
  }));
  transformedPart["usagePriceContracts"] = usagePriceContracts;

  return transformedPart;
};

export const createPayloadForAPI = async(caseData, res) => {
  const items = [];
  caseData.usages.map(parts => {
      const obj = {};
      obj.pid = parts.catalog_number;
      obj.qty = parts.quantity__c > 0 ? parts.quantity__c : parts.cap_quantity ;
      obj.pusg = parts.wasted ? 'Explanted' : 'Implanted';
      items.push(obj);
      return items;
  })

  const user = JSON.parse(localStorage["PU:USER"]);
  const reqBody = {
    procedureType: caseData.revision
      ? `${PROCEDURE_TYPE} Revision`
      : `${PROCEDURE_TYPE} Primary`,
    hospital_id: caseData.hospitalid__c,
    capPrice: caseData.capPrice,
    pd:moment(caseData.surgeryDate).format("MM/DD/YYYY"),
    externalId: caseData.externalId,
    items: items,
    firstName: user.first_name, 
    lastName: user.last_name, 
    isPartTab: true, 
    caseId: caseData.caseId,
    responseData: [res]
  };

  return axiosReq(API.MODELN_PRICE_SAVE_URL, reqBody);

}

