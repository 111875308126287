import React, { Component } from 'react';
import "../../styles/style.css"
import appLogo from '../../images/ic_app_logo_new.png'

import LogoutMenu from '../controls/LogoutMenu' 
import { connect } from 'react-redux'

class TopHeader extends Component {
     render() {
        
        return (
                    <div className="header container-fluid" style={{ display: 'grid', gridTemplateColumns: '175px auto'}}>
                            <a href="/">  <img src={appLogo}
                                alt="Logo" />
                            </a>
                            <div style={{textAlign: 'end'}}>
                            <LogoutMenu loginName={this.props.user.first_name} />
                        </div>
                    </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(TopHeader);