import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql/client";
import Layout from "./Layout";
import { BrowserRouter } from "react-router-dom";
import AlertDialog from "./components/controls/AlertDialog";
import { refreshJwt } from "./graphql/mutations";
import { ASYNC_STORAGE } from './constants';
import IdleLogout from './components/controls/IdleLogout'

function App() {
  const [confirm, setConfirm] = React.useState(false);
  const [confirmCallback, setConfirmCallback] = React.useState(null);
  useEffect(() => {
    // Refresh Jwt Token
    const refreshToken = async () => {
      const { refresh_token } = JSON.parse(
        localStorage.getItem(ASYNC_STORAGE.TOKEN) || "{}"
      );
      const results = await refreshJwt(refresh_token);
      if (results.data.refreshJwt) {
        const { access_token, refresh_token, mako_token, as1_token, expires_in, dips_token } = results.data.refreshJwt;
        localStorage.setItem(
          ASYNC_STORAGE.TOKEN,
          JSON.stringify({
            access_token,
            refresh_token,
            mako_token,
            as1_token,
            expires_in,
            dips_token,
          })
        );
        // Store Refreshed Time
        localStorage.setItem('Refreshed At', JSON.stringify(new Date()));
      }
    };
    // Set time interval of 59 mins to refresh Jwt
    const refreshInterval = 59 * 60 * 1000;
    const interval = setInterval(() => refreshToken(), refreshInterval);
    return () => {
      // On unmount: Clear refresh interval
      clearInterval(interval);
    };
  }, []);
  const getConfirmation = (message, callback) => {
    setConfirmCallback(() => callback);
    setConfirm(true);
  };

  const allowTransition = () => {
    setConfirm(false);
    confirmCallback(true);
  };

  const blockTransition = () => {
    setConfirm(false);
    confirmCallback(false);
  };

  return (
    <ApolloProvider client={client}>
      <BrowserRouter getUserConfirmation={getConfirmation}>
        <Layout />
        <IdleLogout></IdleLogout>
        <AlertDialog
          open={confirm}
          onConfirm={allowTransition}
          onCancel={blockTransition}
          title="Warning!"
          message="You are leaving without saving. Are you sure you want to proceed?"
          confirmText="Yes"
          cancelText="No"
        />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
