import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dropdown from '../../components/controls/Dropdown'
import Buttons from '../../components/controls/Button';
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ToggleSwtich from '../../components/controls/ToggleSwitch'
import SurgeonPreferencesTable from './SurgeonPreferencesTable';
import { getSurgeonPreferences } from '../../redux/modules/getSurgeonPreferences'
import { deleteSurgeonPreferences, resetDeleteSurgeonPreferences } from '../../redux/modules/deleteSurgeonPreferences'
import { getBranches } from '../../redux/modules/branches'
import { getMainAndSubBranches } from '../../redux/modules/MainAndSubBranches'
import { getHospitals } from '../../redux/modules/hospitals'
import { getSurgeons } from '../../redux/modules/surgeons'
import { compareHospital, makoProcedureType, compareSurgeon } from './SurgeonPreferenceUtils'
import AutoComplete from '../../components/controls/AutoComplete'
import LoadProcedure from '../../components/new-case-form/LoadProcedure'
import Snackbars from '../../components/controls/Snackbar'
import CreateOrUpdatePreference from './CreateOrUpdatePreference';
import { resetCreatePreference, setSelectedPreference, setSurgicalPrefData, setTprPreferenceData } from '../../redux/modules/createSurgeonPreference';
import { ASYNC_STORAGE } from '../../constants';
import { buildSurgnPreferencePayload, createOrUpdatePrefrences } from '../../graphql/mutations';
import SimpleBackdrop from '../../components/controls/Spinner';
import Braches from '../../components/new-case-form/Braches';
import { getSurgeonPreferenceByFilter } from '../../graphql/queries';
import routeLeavingGuard from '../../components/hoc/routeLeavingGuard';
import { resetFormChangeIndicator } from '../../redux/modules/formChangeLog';

function SurgeonPreferences(props) {

    const { loading, salesRespId, preferences, branchList, hospitalList, surgeonsList, deletedSurgeonList, createPreference, resetFormChangeIndicator } = props

    const current_user = JSON.parse(localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || '{}');
    const [showPreference, setShowPreference] = useState(false);
    const [edit, setEdit] = useState(false);
    const [saving, setSaving] = useState(false);
    const [surgicalPreference, setSurgicalPreference] = useState(false);
    const [tprPreference, setTprPreference] = useState(false);
    const [branchValue, setbranchValue] = useState()
    const [hospitalName, sethospitalName] = useState([]);
    const [hospitalValue, setHospitalValue] = useState({})
    const [surgeonValue, setSurgeonValue] = useState({})
    const [surgeonName, setSurgeonName] = useState([]);
    const [procedureValue, setProcedureValue] = useState();
    const [reset, setReset] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [procedureTypeValue, setProcedureTypeValue] = useState({})
    const [notification, setNotification] = useState({ severity: "", message: "" })
    const [openSnakBar, setOpenSnakBar] = useState(false);
    const [severity, setSeverity] = useState('');
    const [myPreference, setMyPreference] = useState(false)
    const [procedureTypeOptions, setProcedureTypeOptions] = useState(makoProcedureType);
    const [show, setShow] = useState(true)
    const [disableTPR, setDisableTPR] = useState(false)
    const [disableProcedure, setDisableProcedure] = useState(false)
    const [branchOptions, setBranchOptions] = useState([]);
    const [branchId, setBranchIds] = useState('')
    const [searchNameText,setSearchNameText] = useState('');
    const [searchNickname,setSearchNickname] = useState(true);
    const [filterPref,setFilterPref] = useState(null);

    useEffect(() => {
        props.getBranches()
        // props.getMainAndSubBranches(branchId.split(','))
        props.getHospitals()
        props.getSurgeons()

        return () => {
            props.resetCreatePreference();
        }

    }, [])

    useEffect(() => {
        if (branchList.length) {
            const _branchIds = branchList.map(i => i.branchID).toString()
            setBranchIds(_branchIds);
            props.getSurgeonPreferences(_branchIds)
        }    
    }, [branchList])

    const compareBranch = (a, b) => {
        const c1 = a.label;
        const c2 = b.label;
        var comp = 0;
        if (c1 > c2)
            comp = 1;
        else if (c1 < c2)
            comp = -1;
        return comp;
    }

    useEffect(() => {
        if (branchList.length) {
            const uniqueChecker = [];
            const finalAllBranches = [];
            branchList.map(item => {
                let index = uniqueChecker.indexOf(item.branchID);
                if (index === -1) {
                    item.label = item.branchName;
                    item.id = item.branchID;
                    finalAllBranches.push({ id: item.branchID, label: item.branchName, mainBranch: item.mainBranch });
                    uniqueChecker.push(item.id);
                }
            })
            const sortedBranch = finalAllBranches.sort(compareBranch)
            setBranchOptions(sortedBranch);
            if (finalAllBranches.length === 1) {
                let event = {};
                event.target = { value: finalAllBranches[0] }
                handleBranchChangeDropdown(event)
            }
        }
        if (hospitalList.length) {
            const finalHosp = []
            const sortedByHospitalName = hospitalList.sort(compareHospital)
            sortedByHospitalName.map(item => {
                item.title = item.hospitalName;
                item.id = item.hospitalId;
                finalHosp.push(item);
            })
            sethospitalName(finalHosp)
        }
        if (surgeonsList.length) {
            const finalSurgeon = []
            const sortedBySurgeonName = surgeonsList.sort(compareSurgeon)
            sortedBySurgeonName.map(item => {
                item.title = item.surgeonName;
                item.id = item.surgeonID;
                finalSurgeon.push(item);
            })
            setSurgeonName(finalSurgeon)
        }
        if (deletedSurgeonList && deletedSurgeonList.preferences && deletedSurgeonList.preferences.length) {
            // setOpenSnakBar(true)
            // setSeverity('Success')
            displaySnackBarMessage("Success", "Successfully Deleted the Surgeon Preference");
            setDeleteLoading(false)
        }
    }, [branchList, surgeonsList, hospitalList, deletedSurgeonList])

    const handleBranchChangeDropdown = (event) => {
        let val = !event.target.value.id ? event.target.value : event.target.value.id;
        setbranchValue(val);
        // if (!event.target.value.id){
        //     setbranchValue(event.target.value);
        //     props.getSurgeonPreferences(event.target.value)
        //     let _branchId;
        //     if(event.target.value){
        //         // if selected branch is Main Branch -> mainBranch = null
        //         // if selected branch is Sub Branch -> pass mainBranch to get Hospitals and Surgeons
        //         const { mainBranch } = branchOptions.find(item => item.id === event.target.value)
        //         _branchId = mainBranch ? mainBranch : event.target.value;
        //     }
        //     props.getHospitals(_branchId)
        //     props.getSurgeons(_branchId)
        // }   
    };

    const handleHospitalChangeDropdown = (hospitalName) => {
        setHospitalValue(hospitalName)
    }

    const handleSurgeonChangeDropdown = (surgeonName) => {
        setSurgeonValue(surgeonName)
    }

    const handleProcedureChangeDropdown = (procedureName) => {
        if (procedureName.target.value) {
            setProcedureValue(procedureName.target.value)
            setDisableTPR(true)
        } else {
            setProcedureValue()
            setDisableTPR(false)
        }
    }

    const handleProcedureTypeChangeDropdown = (event) => {
        if (event.target.value !== 'Please Select') {
            setProcedureTypeValue(event.target.value)
            setProcedureValue(event.target.value)
            setDisableProcedure(true)
        } else {
            setProcedureValue()
            setDisableProcedure(false)
        }
    }

    const filterSurgeonList = (e) => {
        let secondObject = {}
        if (hospitalValue && hospitalValue.hospitalId) {
            Object.assign(secondObject, { filterHospital: `${hospitalValue.hospitalId}` })
        }
        if (surgeonValue && surgeonValue.surgeonID) {
            Object.assign(secondObject, { filterSurgeon: `${surgeonValue.surgeonID}` })
        }
        if (procedureValue) {
            Object.assign(secondObject, { procedure: `${procedureValue}` })
        }
        if (myPreference) {
            Object.assign(secondObject, { filterSalesRep: `${myPreference ? salesRespId : ''}` })
        }
        props.getSurgeonPreferences(branchValue ? branchValue : branchId, secondObject)
        setSearchNameText('')
    }

    const deleteSurgeon = (row) => {
        const finalObject = Object.assign({}, { sfid: row.sfid }, { external_id__c: row.external_id__c })
        props.deleteSurgeonPreferences(row.branchid__c, finalObject)
        setDeleteLoading(true)
    }

    const closeSnackbar = (e) => {
        setOpenSnakBar(false)
        props.resetDeleteSurgeonPreferences()
    }

    const resetSurgeonList = () => {
        setDisableProcedure(false)
        setDisableTPR(false)
        setHospitalValue({})
        setbranchValue('');
        setSurgeonValue({})
        setProcedureValue()
        setProcedureTypeValue({})
        setProcedureTypeOptions([])
        setReset(true)
        setMyPreference(false)
        setSearchNameText('')
        setTimeout(() => {
            setReset(false)
            setProcedureTypeOptions(makoProcedureType);
        }, 500)
        props.getSurgeonPreferences(branchId)
    }

    const handleMyPreferenceSwitch = (e) => {
        setMyPreference(e.target.checked)
    }

    const handleAddForJR = () => {
        setEdit(false);
        setShowPreference(true);
        setSurgicalPreference(true);
        setTprPreference(false);
        setSearchNameText('');
    }

    const handleAddForMako = () => {
        setEdit(false);
        setShowPreference(true);
        setTprPreference(true);
        setSurgicalPreference(false);
        setSearchNameText('');
    }

    const handleSave = () => {
        const validationMsg = requiredFeildValidations()
        if (validationMsg) {
            displaySnackBarMessage("error", "Please fill out or select the required fields. \n" + validationMsg)
        } else {
            setSaving(true);
            if (edit) {
                // Existing Preference
                const { externalId, sfid } = createPreference.selectedPreference;
                saveSurgeonPreference(externalId, sfid);
            } else {
                // New Preference
                if(surgicalPreference){ // Skip Uniq Check fr ADD FOR JR
                    saveSurgeonPreference();
                } else {
                    checkUnqPrefAndSave();
                }
            }
        }
    }

    const checkUnqPrefAndSave = () => {
        const { procedure, branch, hospital, surgeon } = createPreference.combination;
        let filterObj = {
            filterHospital: `${hospital.hospitalId}`,
            filterSurgeon: `${surgeon.surgeonID}`,
            procedure: `${procedure.id}`,
        }
        // check user preference for JR only
        if(surgicalPreference){
            filterObj = {
                ...filterObj,
                filterSalesRep: `${current_user.sfids[0]}`
            }
        }
        getSurgeonPreferenceByFilter(branch, filterObj)
        .then(response => {  
            const surgnPreferences = response.data.get_surgeon_pref.preferences;
            return surgnPreferences && surgnPreferences.length > 0 ? false : true ;
            // const userPref = surgnPreferences && surgnPreferences.length > 0 &&
            // surgnPreferences.filter(item => (item.procedureName === procedure.label || item.procedureid__c === procedure.id))
            // return userPref && userPref.length > 0 ? false : true ;
        })
        .then(isUnqCombo => {
            if(!isUnqCombo){
                setSaving(false);
                displaySnackBarMessage("error", "Surgeon Preference Already Exists. Please Select Different Combination")
            } else {
                saveSurgeonPreference();
            }
        })
    }

    const saveSurgeonPreference = (externalId = null, sfid = null) => {
        resetFormChangeIndicator()
        const payload = buildSurgnPreferencePayload(createPreference, current_user, tprPreference);
        createOrUpdatePrefrences(payload, externalId, sfid)
        .then(result => {
            displaySnackBarMessage("Success", `Surgeon preference has been ${edit ? "updated" : "created"} successfully.`)
            setShowPreference(false);
            if(edit){
                setEdit(false);
            }
            props.resetCreatePreference();;
            setSaving(false);
            props.getSurgeonPreferences(branchValue ? branchValue : branchId);
        })
        .catch( res => {
            setSaving(false);
            displaySnackBarMessage("error", "Something went wrong! Please try again.")
        })
    }


    const requiredFeildValidations = () => {
        let errMsg = ''
        const { combination, tprPreferenceObj } = createPreference

        if (combination.branch === '') {
            errMsg += "Branch,"
        }
        if (!combination.hospital.hospitalId) {
            errMsg += "Hospital,"
        }
        if (!combination.surgeon.surgeonID) {
            errMsg += "Surgeon,"
        }
        if (combination.procedure.id === '') {
            errMsg += "Procedure,"
        }

        if (surgicalPreference) {
            if (createPreference.surgicalPreferenceObj.loanerSets.length === 0) {
                errMsg += "Set(s),"
            }
        } else { // Mako
            const { FemoralComponentValue, InsertTypeValue, BasePlateValue, ApproachValue, AcetabularComponentValue, FemoralComponentHipsValue, version__c } = tprPreferenceObj;
            if (version__c === '') {
                errMsg += "Version,"
            }
            if (combination.procedure.label === 'Total Hip') {
                if (ApproachValue === '') {
                    errMsg += "Approach,"
                }
                if (AcetabularComponentValue === '') {
                    errMsg += "Acetabular Component,"
                }
                if (FemoralComponentHipsValue === '') {
                    errMsg += "Femoral Component,"
                }
            } else {
                if (FemoralComponentValue === '') {
                    errMsg += "Femoral Component,"
                }
                if(BasePlateValue === ''){
                    errMsg += "BasePlate Value,"
                }
                if (InsertTypeValue === '') {
                    errMsg += "Insert Type,"
                }
                if (BasePlateValue === '') {
                    errMsg += "Base Plate,"
                }
            }
        }

        return errMsg && errMsg.replace(/.$/, ".");
    }

    const isUniqComboOfPreference = () => {
        const { procedure, branch, hospital, surgeon } = createPreference.combination;

        if (edit) {
            const { editCombination } = createPreference;
            let isSameCombo =
                editCombination &&
                editCombination.branch === branch &&
                editCombination.hospital.hospitalId === hospital.hospitalId &&
                editCombination.surgeon.surgeonID === surgeon.surgeonID &&
                (editCombination.procedure.label === procedure.label ||
                    editCombination.procedure.id === procedure.id);

            if (isSameCombo) {
                //skip uniq check
                return true;
            }
        }

        // User Preferences - need to get based on the branch and verify
        const surgnPreferences = preferences && preferences.length > 0 &&
            preferences.filter(item => item.created_user_sfid === current_user.sfids[0])

        let userPref = surgnPreferences && surgnPreferences.length > 0 &&
            surgnPreferences.filter(item => item.branchid__c === branch &&
                item.hospitalid__c === hospital.hospitalId && item.contactid__c === surgeon.surgeonID
                && (item.procedureName === procedure.label || item.procedureid__c === procedure.id)
            )

        return userPref && userPref.length > 0 ? false : true;
    }

    const handleCancel = () => {
        setEdit(false);
        setShowPreference(false);
        setSurgicalPreference(false);
        setTprPreference(false);
        setSearchNameText('');
        props.resetCreatePreference();
        resetFormChangeIndicator();
    }

    const editPreference = (preference) => {
        const _prefType = preference.name.substring(0,2);
        props.setSelectedPreference(preference);
        _prefType === 'TP' ? props.setTprPreferenceData(preference) : props.setSurgicalPrefData(preference);
        setEdit(true);
        setShowPreference(true);
        if(_prefType === 'TP'){
            setTprPreference(true);
            setSurgicalPreference(false);
        } else {
            setTprPreference(false);
            setSurgicalPreference(true);
        }
    }

    const displaySnackBarMessage = (severity, errorMessage) => {
        setNotification({
            severity: severity, message: errorMessage
        })
        setOpenSnakBar(true);
    }

     //Filter Surgeon Preference basis on Nickname searchbar

     useEffect(()=>{
        if(searchNameText.length >0) {
            //const { procedure, branch, hospital, surgeon } = createPreference.combination;
            let secondObject = {}
            if (hospitalValue && hospitalValue.hospitalId) {
                Object.assign(secondObject, { filterHospital: `${hospitalValue.hospitalId}` })
            }
            if (surgeonValue && surgeonValue.surgeonID) {
                Object.assign(secondObject, { filterSurgeon: `${surgeonValue.surgeonID}` })
            }
            if (procedureValue) {
                Object.assign(secondObject, { procedure: `${procedureValue}` })
            }
            if (myPreference) {
                Object.assign(secondObject, { filterSalesRep: `${myPreference ? salesRespId : ''}` })
            }
            getSurgeonPreferenceByFilter(branchValue? branchValue : branchId, secondObject)
            .then(response => {  
                const surgnPreferences = response.data.get_surgeon_pref.preferences;
                const filt = surgnPreferences && surgnPreferences.length > 0 ?  surgnPreferences.filter(pre=> pre.nick_name__c !== null && pre.nick_name__c.toLowerCase().includes(searchNameText.toLowerCase())) : null;
                if(filt!=null) {
                    setFilterPref(filt)
                    setSearchNickname(false)
                }
                else {
                    setSearchNickname(true)
                }
            })
                // if(preferences && preferences.length > 0 && searchNameText.length > 0 ) {
                // const filteredPref = preferences && preferences.filter(pre=> pre.nick_name__c != null && pre.nick_name__c.toLowerCase().includes(searchNameText.toLowerCase()))
                //console.log(filteredPref)
                // setFilterPref(filteredPref)  
        }
            else {
                setFilterPref(null)
                setSearchNickname(true)
            }
    }, [searchNameText]);

    const requestSearch = (searchVal) => {
        setSearchNameText(searchVal.trim());
        setSearchNickname(false);
    };

    const cancelSearch = () => {
        setSearchNameText('');
        setSearchNickname(true);
    };

    // const onNicknamePaste = (event) => {
    //     // console.log(event.target.value.trim())
    //     // const text = event.clipboardData.getData('Text')
    //     // const value = text.split(' ').join('');
    //     // setSearchNameText(e);
    //     // setSample(false);
    // };

    return (
        <div>
            <SimpleBackdrop open={saving} />
            <Snackbars
                severity={notification.severity}
                open={openSnakBar}
                handleClose={(e) => { closeSnackbar(e) }}
                message={notification.message}
            />
            <Grid container style={{ background: '#fafafa', display: 'grid', gridTemplateColumns: '1fr 2fr 1fr Auto' }}>
                <Grid item xs={12} className="pagetitleHead ">
                    <div style={{ display: 'grid', fontWeight: 'bold', fontSize: '14px', padding: '20px 0px 18px 15px' }}>Surgeon Preferences</div>
                </Grid>
                {!showPreference ? <Grid item xs={12} justify='center' alignItems='center' className="pagetitleHead">
                    <SearchBar 
                        style={{ 
                            margin:'2px', 
                            padding:'0px', 
                            border:'0.5px solid lightgrey',
                            width:'95%'
                        }}
                        placeholder="Search by Nickname"
                        value = {searchNameText}
                        onChange = {(searchVal) => {requestSearch(searchVal)}}
                        onCancelSearch={() => {cancelSearch()}}
                        //onPaste = {(event) => onNicknamePaste(event)} 
                    />
                </Grid>: <Grid item xs={12} className="pagetitleHead"></Grid>}
                <Grid item xs={12} className="pagetitleHead" style={{ textAlign: 'right' }}>
                {!showPreference ? (
                    <Buttons
                        Icon="Submit"
                        toSave={true}
                        fnc={handleAddForJR}
                        name="ADD FOR JR"
                    />
                    ) : createPreference.combination.procedure.id !== '' ? (
                        <Buttons Icon="Submit" toSave={true} fnc={handleSave} name="SAVE" />
                    ) : null}
                </Grid>
                <Grid item xs={12} className="pagetitleHead ">
                    <div style={{ marginRight: '11px' }}>
                        {!showPreference ? (
                            <Buttons
                                Icon="Submit"
                                toSave={true}
                                fnc={handleAddForMako}
                                name="ADD FOR MAKO"
                            />
                        ) : (
                            <Buttons Icon="Submit" fnc={handleCancel} name="CANCEL" />
                        )}
                    </div>
                </Grid>
            </Grid>
            {!showPreference ?
            <div style={{ display: 'grid', gridTemplateRows: '-0.5fr 1fr', padding: '20px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '20px' }}>
                        <AutoComplete
                            isAstrikReq={true}
                            isSurgeon={true}
                            fnc={handleHospitalChangeDropdown}
                            label={"Hospital"}
                            value={hospitalValue}
                            options={hospitalName}
                        />
                        <Dropdown
                            inpage={true}
                            label={"Branch"}
                            ctrllabel={"Please Select"}
                            fnc={handleBranchChangeDropdown}
                            options={branchOptions}
                            value={branchValue}
                        />
                        <AutoComplete
                            isAstrikReq={true}
                            page={"listing"}
                            isSurgeon={true}
                            fnc={handleSurgeonChangeDropdown}
                            label={"Surgeon"}
                            value={surgeonValue}
                            options={surgeonName}
                        />
                        <LoadProcedure
                            Isdisabled={disableProcedure}
                            isSurgeon={true}
                            reset={reset}
                            isAstrikReq={false}
                            onChangeProcedureValue={handleProcedureChangeDropdown}
                            inpage={true}
                            procedureValue={procedureValue}
                        />
                        <Dropdown
                            Isdisabled={disableTPR}
                            inpage={true}
                            label={"Procedure Type for TPR"}
                            ctrllabel={""}
                            fnc={handleProcedureTypeChangeDropdown}
                            options={procedureTypeOptions}
                        />
                        <div style={{ height: '52px', marginTop: '23px', fontWeight: 'bold', display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
                            <ToggleSwtich
                                fnc={(e) => handleMyPreferenceSwitch(e)}
                                checked={myPreference}
                            />
                            <div style={{ marginTop: '16px' }}>My Preferences</div>
                            {/* <div class="rFloat btnSave"><Buttons Icon="Submit" toSave={true} name="SAVE" fnc={(e) => onClickSave(e)} /></div> */}
                        </div>
                        <div></div>
                        <div></div>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Buttons Icon="Submit" toSubmit={true} name="FILTER" fnc={(e) => { filterSurgeonList(e) }} />
                            </Grid>
                            <Grid item xs={12} >
                                <Buttons Icon="Submit" name="RESET" fnc={resetSurgeonList} />
                            </Grid>
                        </div>

                    </div>
                    <div style={{ marginLeft: '5px' }}>
                    {/* {props.loading || deleteLoading ? <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
                        : preferences ? <SurgeonPreferencesTable preferencesList={preferences} deleteSurgeon={deleteSurgeon} editPreference={editPreference} sample={searchNickname} text={searchNameText}/> : <CircularProgress style={{ marginLeft: "50%", color: "gold" }} /> } */}
                    { searchNickname ?
                        <div>{props.loading || deleteLoading ? <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
                        : preferences ? <SurgeonPreferencesTable preferencesList={preferences} deleteSurgeon={deleteSurgeon} editPreference={editPreference} /> : <CircularProgress style={{ marginLeft: "50%", color: "gold" }} /> }</div>
                    : <div>{props.loading || deleteLoading ? <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
                        : filterPref ? <SurgeonPreferencesTable preferencesList={filterPref} deleteSurgeon={deleteSurgeon} editPreference={editPreference} /> : <CircularProgress style={{ marginLeft: "50%", color: "gold" }} /> }</div>
                    }
                    </div>
            </div>
            : <CreateOrUpdatePreference
                isUpdate={edit}
                surgicalPreference={surgicalPreference}
                tprPreference={tprPreference}
                displaySnackBarMessage={displaySnackBarMessage}
            />}
        </div>

    )
}

function mapStateToProps(state) {
    return {
        // branchId: state && state.user.branchIds && state.user.branchIds.toString(),
        salesRespId: state && state.user.branchIds && state.user.sfids.toString(),
        preferences: state && state.getSurgeonPreferences.surgeonList && state.getSurgeonPreferences.surgeonList.preferences,
        loading: state && state.getSurgeonPreferences.surgeonList && state.getSurgeonPreferences.loading,
        branchList: state && state.getBranches && state.getBranches.branchList,
        // branchList: state && state.mainAndSubBranches && state.mainAndSubBranches.branchList,
        hospitalList: state && state.getHospitals && state.getHospitals.hospitalList,
        surgeonsList: state && state.getSurgeons && state.getSurgeons.surgeonsList,
        deletedSurgeonList: state && state.deleteSurgeonPreferences && state.deleteSurgeonPreferences.deletedSurgeonList,
        createPreference: state && state.createSurgeonPreference,
        deletedSurgeonLoading: state && state.deleteSurgeonPreferences && state.deleteSurgeonPreferences.loading,
    };
}

const SurgeonPrefComponent = routeLeavingGuard(connect(mapStateToProps, { getSurgeonPreferences, deleteSurgeonPreferences, getBranches, getMainAndSubBranches, getHospitals, getSurgeons, resetDeleteSurgeonPreferences, resetCreatePreference,  setSurgicalPrefData, setTprPreferenceData, setSelectedPreference, resetFormChangeIndicator})(SurgeonPreferences))
export default SurgeonPrefComponent
