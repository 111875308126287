import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  formValueChanged,
  resetFormChangeIndicator,
  tabSwitchConfirmation,
} from "../../redux/modules/formChangeLog";
import AlertDialog from "../controls/AlertDialog";

// Take in a component as argument WrappedComponent
export default function tabLeavingGuard(WrappedComponent) {
  // And return a new anonymous component
  class NewComponent extends React.Component {
    constructor(props) {
      super(props);
      this.valueChanged = this.valueChanged.bind(this);
      this.onConfirm = this.onConfirm.bind(this);
      this.onCancel = this.onCancel.bind(this);
    }

    valueChanged(name) {
      if (!this.props.changeLogs.isFormDirty) {
        this.props.formValueChanged(name);
      }
    }

    onConfirm() {
      this.props.tabSwitchConfirmation(true);
    }

    onCancel() {
      this.props.tabSwitchConfirmation(false);
    }

    componentWillUnmount() {
      this.props.resetFormChangeIndicator();
    }

    render() {
      return (
        <>
          <AlertDialog
            open={this.props.changeLogs.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            title="Warning!"
            message="You are leaving without saving. Are you sure you want to proceed?"
            confirmText="Yes"
            cancelText="No"
          />
          <WrappedComponent valueChanged={this.valueChanged} {...this.props} />
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return { changeLogs: state && state.formChangeLog };
  }

  return connect(mapStateToProps, {
    formValueChanged,
    resetFormChangeIndicator,
    tabSwitchConfirmation,
  })(NewComponent);
}
