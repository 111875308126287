import { ApolloClient } from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { HttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ASYNC_STORAGE, API,operationCaseStatus } from '../constants';
import GraphQLClient from '../graphql/client';
import gql from 'graphql-tag';

import {
  REACT_APP_GRAPHQL_HTTP_URI,
  REACT_APP_GRAPHQL_WS_URI
} from 'dotenv';
console.log(process.env.REACT_APP_GRAPHQL_HTTP_URI)
// Create an http link:
const httpLink = new HttpLink({  
  uri: process.env.REACT_APP_GRAPHQL_HTTP_URI
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 2000,
    jitter: false
  },
  attempts: async (count, operation, error) => {
    if (
      operation &&
      operation.operationName &&
      operation.operationName === 'refreshJwt'
    ) {
      return false;
    }

    if (operation &&
      operation.operationName &&
      error &&
      error.statusCode === 500 &&
      operation.operationName === operationCaseStatus[operation.operationName]) {
      alert("Case is already submitted")
      window.location.reload(false);
    }

    if (
      error &&
      (error.statusCode === 401 ||
        error.statusCode === 403 ||
        error.name === 'TokenExpiredError' ||
        ('' + error).indexOf('Received status code 401') > 0)
    ) {
      try {
        const mutation = gql`
          mutation refreshJwt($refreshToken: String) {
            refreshJwt(refresh_token: $refreshToken) {
              access_token
              refresh_token
              mako_token
              as1_token
              expires_in
              dips_token
            }
          }
        `;
        const token = localStorage.getItem(ASYNC_STORAGE.TOKEN)
        const tokenPayload = JSON.parse(token || '{}');
        const refreshToken = tokenPayload.refresh_token;
        const results = await GraphQLClient.mutate({
          mutation,
          variables: {
            refreshToken
          }
        });

        if (results.data.refreshJwt) {
          const {
            access_token,
            refresh_token,
            mako_token,
            as1_token,
            expires_in,
            dips_token
          } = results.data.refreshJwt;
          localStorage.setItem(
            ASYNC_STORAGE.TOKEN,
            JSON.stringify({
              access_token,
              refresh_token,
              mako_token,
              as1_token,
              expires_in,
              dips_token
            })
          );
        }
      } catch (err) {
        console.log(
          `[Reauth error]: Error: Could not reauth; ${err}`
        );
        return false;
      }
    }

    if (count == 3 && !!error) {
      console.log(
        `[Network error]: Error: ${error}, Operation: ${
          operation && operation.operationName
            ? operation.operationName
            : 'unknown'
        }`
      );
    }
    return !!error && count <= 3;
  }
});

// JWT's
const authLink = setContext(async (_, { headers }) => {
  const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}') 
  return {
    headers: {
      ...headers,
      authorization: access_token ? `Bearer ${access_token}` : "",
    }
  }


  // if (tokenPayload) {
  //   const payload = JSON.parse(tokenPayload || '{}');
  //   context.headers.authorization = `Bearer ${payload.access_token}`;
  // }
  // return context;
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: REACT_APP_GRAPHQL_WS_URI,
  options: {
    reconnect: true,
    lazy: true,
    inactivityTimeout: 30000
  }
});

/*const cache = new InMemoryCache();
persistCache({
  cache,
  storage: AsyncStorage,
  maxSize: false
});*/

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
      
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([retryLink, authLink, linkError, httpLink])
);

export default new ApolloClient({
  
  link,
  cache: new InMemoryCache({
    // defining dataIdFromObject helps to manage data change. If any data changes either from api or in graphql cache,
    // dataIdFromObject helps to update the same data object.
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'CaseCard': // when fetching cases in chunk
          return object.caseId;
        case 'Case': // when editing/inserting case
          return object.caseId;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  })
  // defaultOptions
});
