import axios from 'axios'
import { API } from '../../constants';

//ActionTypes

const LOAD = 'uploadTreatmentPlan/LOAD'
const LOAD_SUCCESS = 'uploadTreatmentPlan/LOAD_SUCCESS'
const LOAD_FAILURE = 'uploadTreatmentPlan/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'uploadTreatmentPlan/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    treatmentPlanUploaded: [],
    treatmentPlanError: {}
}

export default function uploadTreatmentPlan(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: false
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                treatmentPlanUploaded: action.payload,
                treatmentPlanError: {}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                treatmentPlanUploaded: [],
                treatmentPlanError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                treatmentPlanUploaded: [],
                treatmentPlanError: {}
            }
        default:
            return state
    }
}

export function getUploadedTreatmentPlan(uploadParameters, rhsToken) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        const { treatmentPlanId, associatedTreatmentPlanRequestId } = uploadParameters
        const urlPath = API.RHS.TREATMENT_PLAN_METADATA_URI;
        let url;
        // if(treatmentPlanId && associatedTreatmentPlanRequestId){
        //     url = encodeURI(`${urlPath}?treatmentPlanId=${treatmentPlanId}&associatedTreatmentPlanRequestId=${associatedTreatmentPlanRequestId}&latestUploadedOnly=true`)
        // } else
         if(associatedTreatmentPlanRequestId){
            url = encodeURI(`${urlPath}?associatedTreatmentPlanRequestId=${associatedTreatmentPlanRequestId}&latestUploadedOnly=true`)
        }
        const headers = {headers: {
            Authorization: "Bearer " + rhsToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Custom-Header": "value",
        }}
        axios
            .get(url, headers)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    error
                })
            })
    }
}

export function resetUploadedTreatmentPlanResponse (){
    return dispatch => {
        dispatch({
            type: RESET_UPLOAD_TREATMENT_PLAN
        })
    }
}
