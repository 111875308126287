import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getAllHospitalLocations } from '../../graphql/queries'

import NativeSelect from '@material-ui/core/NativeSelect';


const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '97%',
    },
}));

export default function Dropdown(props) {

    const classes = useStyles();
    const [HospitalLocations, setHospitalLocation] = React.useState([]);
    const name = <div className="controllabelCreate"  >{props.label}<span style={{ color: "red" }} >*</span></div>
    // const [value, setValue] = React.useState('');
    useEffect(() => {
        get_AllHospitalLocations()
    }, [props.hospitalName])
    const compare = (a, b) => {
        const c1 = a.address1;
        const c2 = b.address1;
        var comp = 0;
        if (c1 > c2)
            comp = 1;
        else if (c1 < c2)
            comp = -1;
        return comp;
    }
    const get_AllHospitalLocations = async () => {
        if (props.hospitalName != undefined) {
            const HospitalLocations = await getAllHospitalLocations(props.hospitalName.accountNo)
            const sortedHospitalLocations = HospitalLocations.sort(compare)
            const HospitalLocationsNew = sortedHospitalLocations.map(item => {
                const name = item.inventoryName ? (item.inventoryName + ' ' + item.inventoryDescription) : item.address1
                if (item.primary) {
                    item.title = '*' + item.siteNo + ' - ' + name
                } else {
                    item.title = item.siteNo + ' - ' + name
                }
                item.id = item.sfid
                return item;
            })
            if(HospitalLocationsNew.length === 1){
                let event = {};
                event.target = { value: HospitalLocationsNew[0].id }
                // passing true as second param to indicate the on load Value 
                props.handleHospitalLocChangeDropdown(event, true)
            }     
            setHospitalLocation(HospitalLocationsNew);
        } else { setHospitalLocation([]) }
    }
    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="ddl">{name}</InputLabel>
                <NativeSelect
                    labelId="simple-select-filled-label"
                    id="simple-select-filled"
                    value={props.value}
                    disabled={props.Isdisabled}
                    className={props.value ? null : props.label === 'Hospital Location' ? '' : 'dropdownPlaceholder'}
                    inputProps={{
                        name: props.label,
                        id: 'ddl',
                    }}
                    onChange={(event) => props.handleHospitalLocChangeDropdown(event)}>
                    {HospitalLocations.length > 1 ? <option value="" selected>{""}</option> : null} 
                    {HospitalLocations.map(option => <option value={option.id} data-name={option.label} key={option.id}> {option.title} </option>)}
                </NativeSelect>
            </FormControl>

        </div>
    );
}
