import React, { useEffect, useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ToggleSwtich from '../../components/controls/ToggleSwitch'
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EMAIL_NOTIFICATION_LABEL_INFO } from '../../constants';
import { getNotificationSettings } from '../../graphql/queries';
import { appSettingNotification } from '../../graphql/mutations';
import { ASYNC_STORAGE } from '../../constants';
import Buttons from '../../components/controls/Button'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Snackbars from '../../components/controls/Snackbar'
import { formValueChanged, resetFormChangeIndicator } from '../../redux/modules/formChangeLog';
import routeLeavingGuard from '../../components/hoc/routeLeavingGuard';
const useStyles = makeStyles((theme) => ({
    switchBase: {
        color: 'white',
        "&$checked": {
            color: '#FFB500'
        },
        "&$checked + $track": {
            backgroundColor: '#FFD271'
        }
    },
    checked: {},
    track: {
        backgroundColor: 'grey'
    }

}));
function EmailNotification(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    /**
   * handle the Accordian Panel to open and close
   * @param {panelValues} panel
   */
    const valueChanged = (name) => dispatch(formValueChanged(name))
    const resetChangeIndicator = () => dispatch(resetFormChangeIndicator())
    const { isFormDirty } = useSelector(state => state.formChangeLog)
    const surgicalCaseDetails = useSelector(state => state.cases.currentState);
    const [expanded, setExpanded] = React.useState('panel0');
    const [notificationResult, setnotificationResult] = React.useState([])
    const [isLoading, setisLoading] = React.useState(true)
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = React.useState({ severity: "", message: "" })
    const [notificationData, setnotificationData] = React.useState({
        ship_ready_for_surgery_sets_notification__c: false,
        send_email_invite_notification__c: false,
        requested_sets_notification__c: false,
        oracle_order_notify__c: false,
        new_patient_scan_available__c: false,
        tpr_creation_notification__c: false,
        tpr_indesign_notification__c: false,
        tpr_as1_indesign_notification__c: false,
        tpr_rejection_notification__c: false,
        tpr_as1_rejected_notification__c: false,
        tpr_as1_signed_off_notification__c: false,
        tpr_submission_notification__c: false,
        tpr_as1_submmision_notification__c: false,
        tpr_treatmentplanavailable_notification__c: false,
        treatment_plan_downloaded__c: false,
        tpr_cancelled__c: false,
        tpr_as1_cancelled_notification__c: false,
        tpr_created_awaiting_ct_scan__c: false,
        tpr_surgical_plan_available_notiify__c: false,
        tpr_completed_notification__c: false,
        tpr_surgicalplan_conditionally_approved__c: false,
        tpr_surgicalplan_change_requested__c: false,
        tpr_surgical_plan_approved__c: false,
        tpr_finalplanavail_manf__c: false,
        tpr_shiped_to_sales_notification__c: false
    })
    const current_user = JSON.parse(localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || '{}');
    const getNotificationDet = useCallback(async () => {
        setisLoading(true)
        const result = await getNotificationSettings(current_user.sfids.toString());
        setnotificationResult(result.data.get_app_settings)
        setnotificationData(prevState => ({
            ...prevState, "oracle_order_notify__c": result.data.get_app_settings.oracle_order_notify__c,
            "send_email_invite_notification__c": result.data.get_app_settings.send_email_invite_notification__c,
            "requested_sets_notification__c": result.data.get_app_settings.requested_sets_notification__c,
            "ship_ready_for_surgery_sets_notification__c": result.data.get_app_settings.ship_ready_for_surgery_sets_notification__c,
            "new_patient_scan_available__c": result.data.get_app_settings.new_patient_scan_available__c,
            "tpr_creation_notification__c": result.data.get_app_settings.tpr_creation_notification__c,
            "tpr_indesign_notification__c": result.data.get_app_settings.tpr_indesign_notification__c,
            "tpr_as1_indesign_notification__c": result.data.get_app_settings.tpr_as1_indesign_notification__c,
            "tpr_rejection_notification__c": result.data.get_app_settings.tpr_rejection_notification__c,
            "tpr_as1_rejected_notification__c": result.data.get_app_settings.tpr_as1_rejected_notification__c,
            "tpr_as1_signed_off_notification__c": result.data.get_app_settings.tpr_as1_signed_off_notification__c,
            "tpr_submission_notification__c": result.data.get_app_settings.tpr_submission_notification__c,
            "tpr_as1_submmision_notification__c": result.data.get_app_settings.tpr_as1_submmision_notification__c,
            "tpr_treatmentplanavailable_notification__c": result.data.get_app_settings.tpr_treatmentplanavailable_notification__c,
            "treatment_plan_downloaded__c": result.data.get_app_settings.treatment_plan_downloaded__c,
            "tpr_cancelled__c": result.data.get_app_settings.tpr_cancelled__c,
            "tpr_as1_cancelled_notification__c": result.data.get_app_settings.tpr_as1_cancelled_notification__c,
            "tpr_created_awaiting_ct_scan__c": result.data.get_app_settings.tpr_created_awaiting_ct_scan__c,
            "tpr_surgical_plan_available_notiify__c": result.data.get_app_settings.tpr_surgical_plan_available_notiify__c,
            "tpr_completed_notification__c": result.data.get_app_settings.tpr_completed_notification__c,
            "tpr_surgicalplan_conditionally_approved__c": result.data.get_app_settings.tpr_surgicalplan_conditionally_approved__c,
            "tpr_surgicalplan_change_requested__c": result.data.get_app_settings.tpr_surgicalplan_change_requested__c,
            "tpr_surgical_plan_approved__c": result.data.get_app_settings.tpr_surgical_plan_approved__c,
            "tpr_finalplanavail_manf__c": result.data.get_app_settings.tpr_finalplanavail_manf__c,
            "tpr_shiped_to_sales_notification__c": result.data.get_app_settings.tpr_shiped_to_sales_notification__c  
        }))
        setisLoading(false)
    })
    useEffect(() => {
        getNotificationDet();
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleNotificationCancelAll = () => {
        setnotificationData(prevState => ({
            ...prevState, "oracle_order_notify__c": false,
            "send_email_invite_notification__c": false,
            "requested_sets_notification__c": false,
            "ship_ready_for_surgery_sets_notification__c": false,
            "new_patient_scan_available__c": false,
            "tpr_creation_notification__c": false,
            "tpr_indesign_notification__c": false,
            "tpr_as1_indesign_notification__c": false,
            "tpr_rejection_notification__c": false,
            "tpr_as1_rejected_notification__c": false,
            "tpr_as1_signed_off_notification__c": false,
            "tpr_submission_notification__c": false,
            "tpr_as1_submmision_notification__c": false,
            "tpr_treatmentplanavailable_notification__c": false,
            "treatment_plan_downloaded__c": false,
            "tpr_cancelled__c": false,
            "tpr_as1_cancelled_notification__c": false,
            "tpr_created_awaiting_ct_scan__c": false,
            "tpr_surgical_plan_available_notiify__c": false,
            "tpr_completed_notification__c": false,
            "tpr_surgicalplan_conditionally_approved__c": false,
            "tpr_surgicalplan_change_requested__c": false,
            "tpr_surgical_plan_approved__c": false,
            "tpr_finalplanavail_manf__c": false,
            "tpr_shiped_to_sales_notification__c": false
        }))

        if(!isFormDirty){
            valueChanged("CancelAll")
        }
    }
    const handleNotificationApplyAll = () => {
        setnotificationData(prevState => ({
            ...prevState, "oracle_order_notify__c": true,
            "send_email_invite_notification__c": true,
            "requested_sets_notification__c": true,
            "ship_ready_for_surgery_sets_notification__c": true,
            "new_patient_scan_available__c": true,
            "tpr_creation_notification__c": true,
            "tpr_indesign_notification__c": true,
            "tpr_as1_indesign_notification__c": true,
            "tpr_rejection_notification__c": true,
            "tpr_as1_rejected_notification__c": true,
            "tpr_as1_signed_off_notification__c": true,
            "tpr_submission_notification__c": true,
            "tpr_as1_submmision_notification__c": true,
            "tpr_treatmentplanavailable_notification__c": true,
            "treatment_plan_downloaded__c": true,
            "tpr_cancelled__c": true,
            "tpr_as1_cancelled_notification__c": true,
            "tpr_created_awaiting_ct_scan__c": true,
            "tpr_surgical_plan_available_notiify__c": true,
            "tpr_completed_notification__c": true,
            "tpr_surgicalplan_conditionally_approved__c": true,
            "tpr_surgicalplan_change_requested__c": true,
            "tpr_surgical_plan_approved__c": true,
            "tpr_finalplanavail_manf__c": true,
            "tpr_shiped_to_sales_notification__c": true
        }))

        if(!isFormDirty){
            valueChanged("ApplyAll")
        }
    }
    const handleNotificationReset = () => {
        window.location.reload(true);
    }

    const handleNotificationSave = async () => {
        setisLoading(true)
        const payload = {
            ship_ready_for_surgery_sets_notification__c: notificationData.ship_ready_for_surgery_sets_notification__c ? notificationData.ship_ready_for_surgery_sets_notification__c : false,
            oracle_order_notify__c: notificationData.oracle_order_notify__c ? notificationData.oracle_order_notify__c : false,
            send_email_invite_notification__c: notificationData.send_email_invite_notification__c ? notificationData.send_email_invite_notification__c : false,
            requested_sets_notification__c: notificationData.requested_sets_notification__c ? notificationData.requested_sets_notification__c : false,
            sets_cancelled_removed_notification__c: false,
            case_cancelled_notification__c: false,
            new_patient_scan_available__c: notificationData.new_patient_scan_available__c,
            tpr_creation_notification__c: notificationData.tpr_creation_notification__c,
            tpr_indesign_notification__c: notificationData.tpr_indesign_notification__c,
            tpr_as1_indesign_notification__c: notificationData.tpr_as1_indesign_notification__c,
            tpr_rejection_notification__c: notificationData.tpr_rejection_notification__c,
            tpr_as1_rejected_notification__c: notificationData.tpr_as1_rejected_notification__c,
            tpr_as1_signed_off_notification__c: notificationData.tpr_as1_signed_off_notification__c,
            tpr_submission_notification__c: notificationData.tpr_submission_notification__c,
            tpr_as1_submmision_notification__c: notificationData.tpr_as1_submmision_notification__c,
            tpr_treatmentplanavailable_notification__c: notificationData.tpr_treatmentplanavailable_notification__c,
            treatment_plan_downloaded__c: notificationData.treatment_plan_downloaded__c,
            tpr_cancelled__c: notificationData.tpr_cancelled__c,
            tpr_as1_cancelled_notification__c: notificationData.tpr_as1_cancelled_notification__c,
            external_id__c: "",
            sfid: "",
            branch__c: "",
            tpr_created_awaiting_ct_scan__c: notificationData.tpr_created_awaiting_ct_scan__c,
            tpr_surgical_plan_available_notiify__c: notificationData.tpr_surgical_plan_available_notiify__c,
            tpr_completed_notification__c: notificationData.tpr_completed_notification__c,
            tpr_surgicalplan_conditionally_approved__c: notificationData.tpr_surgicalplan_conditionally_approved__c,
            tpr_surgicalplan_change_requested__c: notificationData.tpr_surgicalplan_change_requested__c,
            tpr_surgical_plan_approved__c: notificationData.tpr_surgical_plan_approved__c,
            tpr_finalplanavail_manf__c: notificationData.tpr_finalplanavail_manf__c,
            tpr_shiped_to_sales_notification__c: notificationData.tpr_shiped_to_sales_notification__c
        }
        const result = await appSettingNotification(payload, true)
        setisLoading(false)
        displaySnackBarMessage("success", "Notification settings saved Successfully")
        resetChangeIndicator();
    }
    const handletoggleChange = name => (event) => {
        if(!isFormDirty){
            valueChanged(name)
        }
        setnotificationData({ ...notificationData, [name]: event.target.checked });
    };

    const label = (labelMessage, infoMessage) => {
        return (
            <React.Fragment>
                <div className="emailnotification">{labelMessage}   <Tooltip title={infoMessage}>
                    <IconButton aria-label={infoMessage}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
                </div>
            </React.Fragment>
        )
    }
    const displaySnackBarMessage = (severity, errorMessage) => {
        setNotification({
            severity: severity, message: errorMessage
        })
        setOpen(true)
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (


        <Grid item sm={12} xs={12}>
            { isLoading ? <div className="NotificationProgress"><CircularProgress style={{ color: 'gold' }} disableShrink /> </div> :
                <div>
                    <Grid container spacing={0} xs={12} className="backgroundgreycolor borderbottom pagetitleHead">
                        <CardContent>
                            <Typography>
                                Notification
                            </Typography>
                        </CardContent>
                    </Grid>
                    <div className="tprCaseDetailsPanel">
                        <Accordion expanded={expanded === 'panel0'} >
                            <AccordionSummary style={{ backgroundColor: '#eaeaea', fontWeight: 'bold', fontSize: '14px' }} expandIcon={<ExpandMore />}>
                                <CardContent>
                                    Surgical Case Notification
                                </CardContent>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: '#fafafa' }}>
                                <Grid item sm={6} xs={12}>
                                    <Snackbars severity={notification.severity} open={open} handleClose={handleClose} message={notification.message} />
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.ship_ready_for_surgery_sets_notification__c}
                                                    onChange={handletoggleChange("ship_ready_for_surgery_sets_notification__c")}
                                                    color="primary" classes={{
                                                        root: classes.root,
                                                        switchBase: classes.switchBase,
                                                        thumb: classes.thumb,
                                                        track: classes.track,
                                                        checked: classes.checked
                                                    }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.SHIP_READY_FOR_SURGERY_SETS_NOT_LABEL, EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.SHIP_READY_FOR_SURGERY_SETS_NOT_INFO)}
                                                labelPlacement="start"

                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.send_email_invite_notification__c} onChange={handletoggleChange("send_email_invite_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.SEND_EMAIL_INVITE_NOT_LABEL, EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.SEND_EMAIL_INVITE_NOT_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>

                                            <FormControlLabel
                                                control={<Switch checked={notificationData.requested_sets_notification__c} onChange={handletoggleChange("requested_sets_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.NO_SETS_ON_CASE_NOT_LABEL, EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.NO_SETS_ON_CASE_NOT_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.oracle_order_notify__c} onChange={handletoggleChange("oracle_order_notify__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.ORACLE_ORDER_NOTIFY_LABEL, EMAIL_NOTIFICATION_LABEL_INFO.SURGICAL_CASE_NOTIFICATION.ORACLE_ORDER_NOTIFY_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="tprCaseDetailsPanel">
                        <Accordion expanded={expanded === 'panel0'} >
                            <AccordionSummary style={{ backgroundColor: '#eaeaea', fontWeight: 'bold', fontSize: '14px' }} expandIcon={<ExpandMore />}>
                                <CardContent>
                                    MAKO - Treatment Plan Notification
                                </CardContent>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: '#fafafa' }}>
                                <Grid item sm={6} xs={12}>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_creation_notification__c} onChange={handletoggleChange("tpr_creation_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_CREATION_LABEL,
                                                    EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_CREATION_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_indesign_notification__c} onChange={handletoggleChange("tpr_indesign_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_INDESIGN_LABEL,
                                                    EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_INDESIGN_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_rejection_notification__c} onChange={handletoggleChange("tpr_rejection_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_REJECTION_LABEL,
                                                    EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_REJECTION_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_submission_notification__c} onChange={handletoggleChange("tpr_submission_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_SUBMISSION_LABEL,
                                                    EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_SUBMISSION_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_treatmentplanavailable_notification__c} onChange={handletoggleChange("tpr_treatmentplanavailable_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TREATMENT_PLAN_AVAILABLE_LABEL,
                                                    EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TREATMENT_PLAN_AVAILABLE_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.treatment_plan_downloaded__c} onChange={handletoggleChange("treatment_plan_downloaded__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TREATMENT_PLAN_DOWNLOADED_LABEL,
                                                    EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TREATMENT_PLAN_DOWNLOADED_INFO)}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Grid item sm={11} xs={12}>
                                            <Typography>
                                                <FormControlLabel
                                                    control={<Switch checked={notificationData.tpr_cancelled__c} onChange={handletoggleChange("tpr_cancelled__c")} color="primary" classes={{
                                                        root: classes.root,
                                                        switchBase: classes.switchBase,
                                                        thumb: classes.thumb,
                                                        track: classes.track,
                                                        checked: classes.checked
                                                    }} />}
                                                    label={label(EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_CANCELLED_LABEL,
                                                        EMAIL_NOTIFICATION_LABEL_INFO.MAKO_TPR_NOTIFICATION.TPR_CANCELLED_INFO)}
                                                    labelPlacement="start"
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>

                        </Accordion>
                    </div>
                    <div className="tprCaseDetailsPanel">
                        <Accordion expanded={expanded === 'panel0'} >
                            <AccordionSummary style={{ backgroundColor: '#eaeaea', fontWeight: 'bold', fontSize: '14px' }} expandIcon={<ExpandMore />}>
                                <CardContent>
                                    AS-1 - Treatment Plan Notification
                                </CardContent>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: '#fafafa' }}>
                                <Grid item sm={6} xs={12}>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_created_awaiting_ct_scan__c} onChange={handletoggleChange("tpr_created_awaiting_ct_scan__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Created-Awaiting CT Scan", "Created-Awaiting CT Scan")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_as1_submmision_notification__c} onChange={handletoggleChange("tpr_as1_submmision_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Submitted", "Submitted")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_as1_indesign_notification__c} onChange={handletoggleChange("tpr_as1_indesign_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("In Design", "In Design")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_as1_rejected_notification__c} onChange={handletoggleChange("tpr_as1_rejected_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Rejected-Rework Required", "Rejected-Rework Required")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_completed_notification__c} onChange={handletoggleChange("tpr_completed_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Completed", "Completed")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid> */}
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_as1_signed_off_notification__c} onChange={handletoggleChange("tpr_as1_signed_off_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Signed Off", "Signed Off")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_as1_cancelled_notification__c} onChange={handletoggleChange("tpr_as1_cancelled_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Cancelled", "Cancelled")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_surgical_plan_available_notiify__c} onChange={handletoggleChange("tpr_surgical_plan_available_notiify__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Surgical Plan Available", "Surgical Plan Available")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_surgicalplan_conditionally_approved__c} onChange={handletoggleChange("tpr_surgicalplan_conditionally_approved__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Surgical Plan Conditionally Approved", "Surgical Plan Conditionally Approved")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_surgicalplan_change_requested__c} onChange={handletoggleChange("tpr_surgicalplan_change_requested__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Surgical Plan Change Requested", "Surgical Plan Change Requested")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_surgical_plan_approved__c} onChange={handletoggleChange("tpr_surgical_plan_approved__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Surgical Plan Approved", "Surgical Plan Approved")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_finalplanavail_manf__c} onChange={handletoggleChange("tpr_finalplanavail_manf__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("FinalPlanAvail&Manf", "FinalPlanAvail&Manf")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.tpr_shiped_to_sales_notification__c} onChange={handletoggleChange("tpr_shiped_to_sales_notification__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("Shipped to Sales", "Shipped to Sales")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="tprCaseDetailsPanel">
                        <Accordion expanded={expanded === 'panel0'} >
                            <AccordionSummary style={{ backgroundColor: '#eaeaea', fontWeight: 'bold', fontSize: '14px' }} expandIcon={<ExpandMore />}>
                                <CardContent>
                                    Patient Scan Notification
                                </CardContent>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: '#fafafa' }}>
                                <Grid item sm={6} xs={12}>
                                    <Grid item sm={11} xs={12}>
                                        <Typography>
                                            <FormControlLabel
                                                control={<Switch checked={notificationData.new_patient_scan_available__c} onChange={handletoggleChange("new_patient_scan_available__c")} color="primary" classes={{
                                                    root: classes.root,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked
                                                }} />}
                                                label={label("New Patient Scan Available", "New Patient Scan Available")}
                                                labelPlacement="start"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>

                        </Accordion>
                    </div>

                    <Grid item xs={12} sm={12} style={{ padding: '9px 1px 9px 1px' }}>
                        <div class="rFloat btnSave"><Buttons Icon="Submit" toSave={true} fnc={handleNotificationSave} name="SAVE" /></div>
                        <div class="rFloat btnCancel"><Buttons Icon="Submit" toClone={true} fnc={handleNotificationReset} name="RESET" /></div>
                        <div class="rFloat btnCancel"><Buttons Icon="Submit" toSave={true} fnc={handleNotificationApplyAll} name="APPLY ALL" /></div>
                        <div class="rFloat btnCancel"><Buttons Icon="Submit" fnc={handleNotificationCancelAll} name="CANCEL ALL" /></div>
                    </Grid>
                </div>
            }

        </Grid>
    )
}

export default routeLeavingGuard(EmailNotification)