import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ACTION_CREATORS as CasesActionCreators } from '../reducers/cases';
import { ACTION_CREATORS as TabActionCreators } from '../reducers/tab';
import { ACTION_CREATORS as UserActionCreators } from '../reducers/user';
import {
  ACTION_CREATORS as FiltersActionCreators,
  SELECTORS as FiltersSelectors
} from '../reducers/filters';
// import { ACTION_CREATORS as StatusActionCreators } from '../reducers/status';
// import { ACTION_CREATORS as CalendarTabActionCreators } from '../reducers/calendarTab';
// import { ACTION_CREATORS as CaseUsageInfoActionCreators } from '../reducers/caseUsageInfo';
// import { ACTION_CREATORS as AddSetsCreators } from '../reducers/addSetsReducer';

export default function defaultConnector(WrappedComponent) {
  class ConnectedComponent extends React.Component {
    render() {
      return (
        <WrappedComponent
          componentName={WrappedComponent.name}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    let {
      cases,
      user,
      tab
      // filters,
      // status,
      // calendarTab,
      // caseUsageInfo,
      // addSetsReducer
    } = state;
    return {
      userReducer: user,
      casesReducer: cases,
      tabReducer: tab,
      // filtersReducer: filters,
      // statusReducer: status,
      // calendarTabReducer: calendarTab,
      // myCases: FiltersSelectors.getCasesByMyCasesFilter(state),
      // myCasesFilters: FiltersSelectors.getMyCasesFilterParams(state),
      // myCasesFilterName: FiltersSelectors.getMyCasesFilterName(state),
      // myCasesSearchText: FiltersSelectors.getMyCasesSearchText(state),
      // calendarCases: FiltersSelectors.getCasesByCalendarFilter(state),
      // calendarFilters: FiltersSelectors.getCalendarFilterParams(state),
      // calendarMonth: FiltersSelectors.getCalendarMonthView(state),
      // myOrdersFilters: FiltersSelectors.getMyOrdersFilterParams(state),
      // myOrdersSearchText: FiltersSelectors.getMyOrdersSearchText(state),
      //myPreferencesFilters: FiltersSelectors.getMyPreferencesFilterParams(state),
      //caseUsageInfo,
      //addSetsReducer: addSetsReducer
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      userActions: bindActionCreators(UserActionCreators, dispatch),
      tabActions: bindActionCreators(TabActionCreators, dispatch),
//      statusActions: bindActionCreators(StatusActionCreators, dispatch),
      casesActions: bindActionCreators(CasesActionCreators, dispatch)
      // filtersActions: bindActionCreators(FiltersActionCreators, dispatch),
      
      
      // calendarTabActions: bindActionCreators(
      //   CalendarTabActionCreators,
      //   dispatch
      // ),
      // caseUsageInfoToggleAction: bindActionCreators(
      //   CaseUsageInfoActionCreators,
      //   dispatch
      // ),
      //AddSetsActions: bindActionCreators(AddSetsCreators, dispatch)
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectedComponent);
}
