import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Dialog,
  TextField,
  NativeSelect,
  Box,
  FormControlLabel
} from "@material-ui/core";
import ButtonControl from "../../components/controls/ButtonControl";
import "./../../styles/style.css";
import { QqCode } from "./QqCode";
import MuiAlert from "@material-ui/lab/Alert";
import { setContractPrices } from "../../graphql/mutations";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { ACTION_CREATORS as userCase } from "../../redux/reducers/cases";
import getCaseById from "../../utils/calendar";
import {
  ModelNPricing,
  transfromContractPricesToPayload,
  checkPriceNonCap,
  checkPriceCap,
  createPayloadForAPI
} from "./ModelNPricing";
import moment from "moment";

import { PROCEDURE_TYPE, API } from "../../constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Pricing(props) {
  const dispatch = useDispatch();
  const GetCaseById_Set = (response) =>
    dispatch(userCase.setCaseDetail(response));
  const [open, setOpen] = useState(false);
  const [allContracts, setAllContracts] = useState([]);
  const [isMultipleContract, setIsMultipleContract] = useState(false);
  const [selectedContract, setSelectedContract] = useState("");
  const [code, setCode] = useState([]);
  const [alert, setAlert] = useState(false);
  const [capPrice, setCapPrice] = useState([]);
  const [nonCapPrice, setNonCapPrice] = useState([]);
  const [lineTotal, setLineTotal] = useState(0);
  const [loader, setLoader] = React.useState(true);
  const [qqCode, setQqCode] = useState(props.caseDetails.QuickQuoteCode);
  const [qqText, setQqText] = useState("");
  const [qqCodeButton, setQqCodeButton] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [capTotal, setCapTotal] = useState({
    capPriceTotal: 0,
    totalUpcharge: 0,
    totalPrice: 0,
  });
  const [wastedTotal, setWastedTotal] = useState({
    wastedPriceTotal: 0,
    wastedTotalUpcharge: 0
  });
  const [noPriceText, setNoPriceText] = useState("");
  const [caseDetailsWithPrice, setCaseDetailsWithPrice] = useState([]);

  const PricingGrid = (props) => {
    const { headeritems, bodyitems, type, mainprops } = props;

    return (
      <TableContainer component={Paper}>
        <Table aria-label="pricing table">
          <TableHead>
            <TableRow>
              {headeritems.map((item, index) => {
                return (<TableCell key={index} align={item.align}>{item.label}</TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyitems.map((part, index) => {
              return (
                <TableRow key={index}>
                  {
                    part.cap_quantity ?
                      <TableCell align="center">
                        {part.pricelistcontract
                          ? `${caseDetailsWithPrice.cap_contractId
                            ? caseDetailsWithPrice.cap_contractId + " - "
                            : ""
                          } ${part.pricelistcontract}`
                          : "NA"}
                      </TableCell>
                      :
                      <TableCell align="center">
                        {part.pricelistcontract
                          ? `${part.pricelistcontractId
                            ? part.pricelistcontractId + " - "
                            : ""
                          } ${part.pricelistcontract} ${part.tierIndex !== null
                            ? " - Tier " + part.tierIndex
                            : ""
                          }`
                          : "NA"}
                      </TableCell>
                  }
                  <TableCell align="center">{part.part_name}</TableCell>
                  {type === 'capItem' ?
                    <TableCell align="center">{part.cap_quantity}</TableCell>
                    : <></>
                  }
                  {type === 'lineItem' ?
                    <TableCell align="center">{part.quantity__c}</TableCell>
                    : <></>
                  }
                  <TableCell align="center"><span className={part.cap_quantity && part.wasted ? 'MuiTabs-indicator' : ''}>${numFormat(part.usg_price__c)}</span></TableCell>
                  {type === 'capItem' ?
                    <TableCell align="center">${part.upcharge ? part.upcharge : "0"}</TableCell>
                    : <></>}
                  {type === 'lineItem' ?
                    <TableCell size="small" align="left">
                      <input
                        onBlur={codeChange}
                        className="overrideInput"
                        defaultValue={
                          code[
                            part.external_id__c
                              ? part.external_id__c
                              : part.external_id
                          ] || code[
                          part.external_id__c
                            ? part.external_id__c
                            : part.external_id
                          ] == "" ? code[
                          part.external_id__c
                            ? part.external_id__c
                            : part.external_id
                          ]
                            :
                            part.price_override === true ||
                              part.override_price__c === true
                              ? part.usg_price_override
                                ? Number(part.usg_price_override)
                                : Number(part.price_override__c)
                              : ""
                        }
                        name={
                          part.external_id__c
                            ? part.external_id__c
                            : part.external_id
                        }
                        type="number"
                        max={999999}
                        min={0}
                        disabled={
                          mainprops.caseDetails.status === "Completed" || part.cap_quantity
                            ? true
                            : false
                        }
                      />
                    </TableCell>
                    : <></>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const numFormat = (num) => {
    if (num) {
      num = Number(num);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    } else {
      return 0;
    }
  };

  /**MP: To get data from getCaseById API */
  const getAndSetUsageData = async (currentContract) => {
    let count = 0;
    const cd = setInterval(async () => {
      count++;
      const caseData = await getLiveCases();
    if (count > 7 || (caseData.usages.length > 0 && caseData.usages[0].usg_price__c !== null)) {
        clearInterval(cd);
        setCaseDetailsWithPrice(caseData)
        setCaseDetailsValues(caseData);
      }
    }, 6000);
  };

  const pricingValidate = async(modelNData,capPrice) => {
    if(!capPrice){
      const modelNPrice = await checkPriceNonCap(modelNData);
      const priceFilter = [];
      modelNPrice.map(item => {
        if(item.resolvedPrice === null){
          priceFilter.push(item.productNumber)
        }
      });
      if(priceFilter.length > 0){
        props.displaySnackBarMessage('error',`Pricing not available for part/s - ${priceFilter.toString()}. Please go to parts tab and delete the invalid part/s to proceed.`)
        setNoPriceText(" ");
        setLoader(false);
        return false;
      }
    }else{
      if(modelNData.data.resolvedPrices.length > 0){
        const modelNPrice = await checkPriceNonCap(modelNData);
        
        const priceFilter = [];
        modelNPrice.map(item => {
          if(item.resolvedPrice === null){
            priceFilter.push(item.productNumber)
          }
        });
      
        if(priceFilter.length > 0){
          props.displaySnackBarMessage('error',`Pricing not available for part/s - ${priceFilter.toString()}. Please go to parts tab and delete the invalid part/s to proceed.`)
          setNoPriceText(" ");
          setLoader(false);
          return false;
        }
      }

      if(modelNData.data.constructPrice.length > 0){
        const modelNPrice = await checkPriceCap(modelNData);
        
        const priceFilters = [];
        modelNPrice.map(item => {
          if(item.prc === null){
            priceFilters.push(item.pid)
          }
        });
      
        if(priceFilters.length > 0){
          props.displaySnackBarMessage('error',`Pricing not available for part/s - ${priceFilters.toString()}. Please go to parts tab and delete the invalid part/s to proceed.`)
          setNoPriceText(" ");
          setLoader(false);
          return false;
        }
    }
  }
}
  
  /**MP: Added logic according to capBtn value **/
  const getModelNData = async (data) => {
    if (data.status === "E") {
      setNoPriceText(" ");
      props.displaySnackBarMessage("error", data.resolvedPrices?.[0]?.message);
      setLoader(false);
      return;
    } else {
      if (props.caseDetails.capPrice) {
        if (data.constructPrograms.length > 1) {
          setIsMultipleContract(true);
          setAllContracts(data.constructPrograms);
        } else if (data.constructPrograms.length === 1) {
          getCapPriceData(
            data.constructPrograms[0].priceProgramId,
            data.constructPrograms
          );
        } else if (
          data.resolvedPrices?.length > 0 &&
          data.constructPrice.length === 0
        ) {
          props.displaySnackBarMessage('error',`There is no cap data found, List price is applied.`)
          getAndSetUsageData();
        } else if (
          data.resolvedPrices?.length > 0 &&
          (data.constructPrice.implantedProducts.length > 0 ||
            data.constructPrice.explantedProducts.length > 0)
        ) {
          getAndSetUsageData();
        } else if (
          data.constructPrice.implantedProducts.length > 0 ||
          data.constructPrice.explantedProducts.length > 0
        ) {
          getAndSetUsageData();
        }
        setLoader(false);
      } else {
        getAndSetUsageData();
        setLoader(false);
      }
    }
  };

  /**MP: Set Updated casedetails value to component**/
  const setCaseDetailsValues = async (casesData) => {
    setQqCode(casesData.QuickQuoteCode);
    setQqText(casesData.QuickQuoteRationale);
    const capData = casesData.usages.filter(
      (item) => item.cap_quantity !== null
    );
    const nonCapData = casesData.usages.filter(
      (item) => item.cap_quantity === null
    );
    const wastedData = capData.filter(
      (item) => item.wasted
    );
    if (wastedData.length > 0) {
      let wastedPrice = 0;
      let wastedUpcharge = 0;
      wastedData.map((priceVal) => {
        if (priceVal.override_price__c && (priceVal.usg_price_override >= 0 || priceVal.price_override__c >= 0)) {
          setQqCodeButton(true);
          let op = priceVal.usg_price_override
            ? priceVal.usg_price_override
            : priceVal.price_override__c;
          wastedPrice += Number(
            Number(op) * Number(priceVal.quantity__c)
          );
        } else {
          wastedPrice += Number(
            Number(priceVal.listPrice || priceVal.usg_price__c) *
            Number(priceVal.quantity__c || priceVal.cap_quantity)
          );
          wastedUpcharge += Number(priceVal.upcharge ? priceVal.upcharge : 0);
        }
      })
      const wastedTotalVal = {
        wastedPriceTotal: wastedPrice,
        wastedTotalUpcharge: wastedUpcharge,
      };
      setWastedTotal(wastedTotalVal);
    }
    if (capData.length > 0) {
      setCapPrice(capData);
      const capTotalVal = {
        capPriceTotal: casesData.total_cap_price,
        totalUpcharge: casesData.upcharge_total,
      };
      setCapTotal(capTotalVal);
    }

    if (nonCapData.length > 0) {
      let nonCapPriceTotals = 0;
      [...nonCapData].map((priceVal) => {
        if (priceVal.override_price__c  && (priceVal.usg_price_override >= 0 || priceVal.price_override__c >= 0)) {
          setQqCodeButton(true);
          let op = priceVal.usg_price_override
            ? priceVal.usg_price_override
            : priceVal.price_override__c;
          nonCapPriceTotals += Number(
            Number(op) * Number(priceVal.quantity__c)
          );
        } else {
          nonCapPriceTotals += Number(
            Number(priceVal.listPrice || priceVal.usg_price__c) *
            Number(priceVal.quantity__c)
          );
        }
      });
      setLineTotal(nonCapPriceTotals);
      setNonCapPrice(nonCapData);
    }
    setLoader(false);
    window.onbeforeunload = function () {
      return;
    };
  };

  /**MP: Get Pricing from modelN API */
  const getPricing = async (usageParts) => {
    const modelNData = await ModelNPricing(
      usageParts,
      usageParts.capPrice,
      "",
      "autoSave",
      true
    );

    if (modelNData?.data?.[0]?.status === "E") {
      setNoPriceText(" ");
      props.displaySnackBarMessage("error", modelNData?.data?.[0].message);
      setLoader(false);
      return;
    } else if (modelNData.data) {
      const validP = await pricingValidate(modelNData,usageParts.capPrice);
      if(validP === false){
        return false;
      }else{
        if(props.caseDetails.capPrice && modelNData.data?.constructPrograms.length > 1){
          getModelNData(modelNData.data);
        }else{
          await createPayloadForAPI(usageParts,modelNData.data);
          getModelNData(modelNData.data);
        }
      }
    } else {
      setNoPriceText("Please add products to get pricing");
    }
  };

  const handleContract = (e) => {
    if (e.target.value) {
      setLoader(true);
      setSelectedContract(e.target.value);
      getCapPriceData(e.target.value);
    }
  };

  /**MP: To get Selected Contract price **/
  const getCapPriceData = async (priceProgramId, contracts = "") => {
    const filterContract = allContracts.filter(
      (contract) => contract.priceProgramId === priceProgramId
    );
    const currentContract =
      filterContract.length > 0 ? filterContract : contracts;

    const items = [];
    props.caseDetails.usages.map((parts,index)=>{
        const obj = {};
        obj.pid = parts.catalog_number;
        obj.qty = parts.quantity__c > 0 ? parts.quantity__c : parts.cap_quantity ;
        obj.pusg = parts.wasted ? 'Explanted' : 'Implanted';
        items.push(obj);
        return items;
    })

    const reqBody = {
      procedureType: props.caseDetails.revision
        ? `${PROCEDURE_TYPE} Revision`
        : `${PROCEDURE_TYPE} Primary`,
      hospital_id: props.caseDetails.hospitalid__c,
      pd:moment(props.caseDetails.surgeryDate).format("MM/DD/YYYY"),
      constructId: currentContract[0].id,
      priceProgramId: currentContract[0].priceProgramId,
      referenceKey: currentContract[0].referenceKey,
      externalId: props.caseDetails.externalId,
      items: items
    };

    const modelNData = await ModelNPricing(reqBody, true, true, "autoSave");
    if (modelNData?.data?.[0]?.status === "E") {
      setNoPriceText(modelNData.data[0].message);
      props.displaySnackBarMessage("error", modelNData?.data?.[0].message);
      setLoader(false);
      return;
    } else if (modelNData.data) {
        await createPayloadForAPI(props.caseDetails,modelNData.data);
        getAndSetUsageData(currentContract);
    }
  };

  const handleDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAlert = (qqCodes, qqTexts) => {
    setSaveButton(true);
    setQqCode(qqCodes);
    setQqText(qqTexts);
    setOpen(false);
    setAlert(true);

    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };

  const codeChange = (e) => {
    if (code) {
      setQqCodeButton(true);
      setSaveButton(true);
    } else {
      setQqCodeButton(false);
      setSaveButton(false);
    }

    let name = e.target.name;
    let value = e.target.value;
    if (value > 999999 || value < 0) {
      value = "";
      props.displaySnackBarMessage("error", "Please enter value 0 to 999999");
    }
    const newValues = {
      ...code,
      [name]: value,
    };
    setCode(newValues);
  };

  /**MP: Calling getCaseById API */
  const getLiveCases = async () => {
    const res = await getCaseById(
      props.caseDetails.externalId,
      props.caseDetails.sfId,
      true
    ).then((res) => {
      getPartsLiveCases();
      return res.data;
    });

    return res;
  };

  const getPartsLiveCases = async () => {
    const res = await getCaseById(
      props.caseDetails.externalId,
      props.caseDetails.sfId,
      false
    ).then((res) => {
      GetCaseById_Set(res.data);
      userCase.setCaseDetail(res.data);
      return res.data;
    });
    return res;
  };

/**MP: Save price override value along with qqcode values. */
  const autoSavePricing = async (currentSlectedContract) => {
    const filterContract =
      allContracts.length > 0 &&
      allContracts.filter(
        (contract) => contract.priceProgramId === currentSlectedContract
      );
    const currentContract =
      filterContract.length > 0 ? filterContract : currentSlectedContract;

    const allPriceData = [...nonCapPrice];
    setLoader(true);

    const apiPayload = [];
    const caseData = await getLiveCases();
    const setPricing = caseData.usages.filter(part => part.cap_quantity === null).map((price, index) => {
      let usageData = [];

      usageData = allPriceData.filter(
        (item, idx) =>
          price.catalog_number === item.catalog_number &&
          item.wasted === price.wasted
      );
      const usageDataVal = usageData?.[0];
      if (usageDataVal && (
        price.external_id__c ||
        usageDataVal.external_id ||
        usageDataVal.external_id__c
      )
      ) {
        let priceObj = {};
        const ext_id = usageDataVal.external_id
          ? usageDataVal.external_id
          : usageDataVal.external_id__c;
        if (
          code[price.external_id__c ? price.external_id__c : ext_id] ||
          code[price.external_id__c ? price.external_id__c : ext_id] === ""
        ) {
          priceObj.sfid = usageDataVal.sfid ? usageDataVal.sfid : price.sfid;
          priceObj.external_id = price.external_id__c
            ? price.external_id__c
            : ext_id;
          priceObj.quantity =
            usageDataVal.cap_quantity === null
              ? usageDataVal.quantity__c
              : null;
          priceObj.contract_name = usageDataVal.pricelistcontract;
          priceObj.price_override = code[
            price.external_id__c ? price.external_id__c : ext_id
          ]
            ? true
            : false;
          priceObj.override_price__c = code[
            price.external_id__c ? price.external_id__c : ext_id
          ]
            ? true
            : false;
          priceObj.usg_price_override =
            code[price.external_id__c ? price.external_id__c : ext_id];
          priceObj.usg_price__c = usageDataVal.usg_price__c;
          priceObj.wasted = price.wasted;
          apiPayload.push(priceObj);
        }
      }
    });

    const { externalId, sfId, capPrice, freightCharges } = props.caseDetails;
    const caseDetailsObj = {};
    caseDetailsObj.externalId = externalId;
    caseDetailsObj.sfId = sfId;
    caseDetailsObj.capPrice = capPrice;
    caseDetailsObj.cap_contract_id = currentContract && currentContract.length > 0 ? currentContract[0].id : (caseData.cap_contract_id ? caseData.cap_contract_id : null);
    caseDetailsObj.cap_construct_name = currentContract && currentContract.length > 0 ? currentContract[0].name : (caseData.cap_construct_name ? caseData.cap_construct_name : null);
    caseDetailsObj.total_cap_price = capTotal.capPriceTotal;
    caseDetailsObj.upcharge_total = capTotal.totalUpcharge;
    caseDetailsObj.cap_contractId = caseData.cap_contractId;
    caseDetailsObj.cap_contract_name = caseData.cap_contract_name;


    //to check override price exist or not
    let codeVal = apiPayload.filter((item) => item.usg_price_override);
    let usageVal = caseData.usages.filter(item => item.override_price__c);

    if (codeVal.length > 0 || (apiPayload.length === 0 && usageVal.length > 0) || (usageVal.length > apiPayload.length)) {
      codeVal = true;
    } else {
      codeVal = false;
      setQqCode('');
      setQqText('');
    }

    const contractPayload = transfromContractPricesToPayload(
      caseDetailsObj,
      apiPayload,
      codeVal,
      codeVal ? qqCode : "",
      codeVal ? qqText : "",
      freightCharges
    );

    await setContractPrices(contractPayload);
  };

  const saveContractPricing = async () => {
    if (!qqCode) {
      let codeVal = "";
      for (const key in code) {
        codeVal += code[key];
      }
      if (codeVal.length > 0 && nonCapPrice.length > 0) {
        props.displaySnackBarMessage("error", "Please select QQ code.");
        return;
      }
    }

    if (props.caseDetails.capPrice) {
      await autoSavePricing(selectedContract);
      await getAndSetUsageData();
      setSaveButton(false);
      setQqCodeButton(false);

      props.displaySnackBarMessage(
        "success",
        "Contract price saved successfully."
      );
    } else {
      await autoSavePricing();
      await getAndSetUsageData();
      setSaveButton(false);
      setQqCodeButton(false);

      props.displaySnackBarMessage(
        "success",
        "Contract price saved successfully."
      );
    }
  };

  const getAndSetModelNPricing = async () => {
    if (props.caseDetails.usages.length > 0) {
      await getPricing(props.caseDetails);
    } else {
      setLoader(false);
      setNoPriceText("Please add products in parts tab.");
    }
  };

  useEffect(() => {
    if (props.caseDetails.status === "Completed") {
      if(props.caseDetails.usages.length > 0){
        setLoader(true);
        getCaseById(
          props.caseDetails.externalId,
          props.caseDetails.sfId,
          true
        ).then((res) => {
          if(res.data){
            setCaseDetailsWithPrice(res.data)
            setCaseDetailsValues(res.data);
          }
        });
        setLoader(false);
      }else{
        setNoPriceText('Please add products in parts tab.')
      }
      setLoader(false);
    } else {
      window.onbeforeunload = function () {
        return false;
      };
      getAndSetModelNPricing();
    }
  }, []);

  return noPriceText || loader ? (
    loader ? (
      <CircularProgress component="div" style={{ marginLeft: "50%", color: "gold" }} />
    ) : (
      <p>
        <center> {noPriceText}</center>
      </p>
    )
  ) : (
    <>
      {isMultipleContract ? (
        <div>
            <NativeSelect
              onChange={handleContract}
              className="contractBtn"
              disabled={props.caseDetails.status === "Completed"}
            >
              <option value="">Please Select Contract</option>
              {allContracts.map((c, i) => (
                <option
                  key={i}
                  value={c.priceProgramId}
                  selected={selectedContract === c.priceProgramId}
                >
                   {`$${(c.totalConstructPrice - c.totalUpcharge).toFixed(2)} - ${c.contractName} - ${c.contractId}`}
                </option>
              ))}
            </NativeSelect>
          
          {nonCapPrice.length == 0 && capPrice.length == 0 ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item className="multipleContractBox">
                <Box
                  bgcolor="lightgrey"
                  className="multipleContractMsg"
                >
                  Multiple cap contracts found. Please select one contract to
                  continue.
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </div>
      ) : (
        caseDetailsWithPrice.cap_contract_id ?
          <NativeSelect
            className="contractBtn"
            disabled={true}
            title={`$${caseDetailsWithPrice.total_cap_price.toFixed(2)} ${caseDetailsWithPrice.cap_contract_name ? ' - ' + caseDetailsWithPrice.cap_contract_name : ''} ${caseDetailsWithPrice.cap_contractId ? ' - ' + caseDetailsWithPrice.cap_contractId : ''}`}
          >
            <option value="">{`$${caseDetailsWithPrice.total_cap_price.toFixed(2)} ${caseDetailsWithPrice.cap_contract_name ? ' - ' + caseDetailsWithPrice.cap_contract_name : ''} ${caseDetailsWithPrice.cap_contractId ? ' - ' + caseDetailsWithPrice.cap_contractId : ''}`}</option>
          </NativeSelect>
          : <></>
      )}
      <br />
      {(selectedContract && isMultipleContract) || !isMultipleContract ? (
        <>
          <Grid container spacing={2} >
            <Grid item xs={9}>
              {capPrice.length > 0 ? (
                <PricingGrid
                  headeritems={[{ label: 'CONTRACT', align: 'center' }, { label: 'PARTS NO/NAME', align: 'center' }, { label: 'QUANTITY', align: 'center' }, { label: 'PRICE PER ITEM', align: 'center' }, { label: 'UPCHARGE', align: 'center' }]}
                  bodyitems={capPrice}
                  type={'capItem'}
                  mainprops={props}
                />
              ) : (
                <></>
              )}

              <br />

              {nonCapPrice.length > 0 ? (
                <PricingGrid
                  headeritems={[{ label: 'CONTRACT', align: 'center' }, { label: 'PARTS NO/NAME', align: 'center' }, { label: 'QUANTITY', align: 'center' }, { label: 'PRICE PER ITEM', align: 'center' }, { label: 'PRICE OVERRIDE', align: 'left' }]}
                  bodyitems={nonCapPrice}
                  type={'lineItem'}
                  mainprops={props}
                />
              ) : capPrice.length === 0 ? (
                <center>
                  {noPriceText
                    ? noPriceText
                    : "Please wait while price data is loading..."}
                </center>
              ) : (
                <></>
              )}

            </Grid>
            <Grid item xs={3}>
              {!props.caseDetails.capPrice && nonCapPrice.length > 0 ? (
                <div className="pricing">
                  <TableBody>
                    <TableRow>
                      <TableCell align="right">LINE PRICE TOTAL</TableCell>
                      <TableCell align="right">
                        ${numFormat(lineTotal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">OVERALL PRICE</TableCell>
                      <TableCell align="right">
                        ${numFormat(Number(lineTotal))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </div>
              ) : capPrice.length > 0 || nonCapPrice.length > 0 ? (
                <div className="pricing">
                  <TableBody>
                    <TableRow>
                      <TableCell align="right">CAP PRICE TOTAL</TableCell>
                      <TableCell align="right">
                        ${numFormat(capTotal.capPriceTotal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">UPCHARGE TOTAL</TableCell>
                      <TableCell align="right">
                        {capTotal.totalUpcharge
                          ? `$${numFormat(capTotal.totalUpcharge)}`
                          : "$0"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">CAP WASTED TOTAL</TableCell>
                      <TableCell align="right">
                        ${numFormat(wastedTotal.wastedPriceTotal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">LINE PRICE TOTAL</TableCell>
                      <TableCell align="right">
                        ${numFormat(lineTotal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">OVERALL PRICE</TableCell>
                      <TableCell align="right">
                        $
                        {numFormat(
                          capTotal.capPriceTotal +
                          capTotal.totalUpcharge +
                          wastedTotal.wastedPriceTotal +
                          lineTotal
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <div className="qqCodeSaveMsgContainer">
        {alert && (
          <Alert
            severity="success"
            onClose={handleClose}
            className="qqCodeSaveMsg"
          >
            QQ code saved succesfully
          </Alert>
        )}
      </div>{" "}
      <Grid container style={{ paddingTop: "20px" }}>
        {nonCapPrice.length > 0 || capPrice.length > 0 ? (
          <Grid item xs={4}>
            <ButtonControl
              className="button"
              Icon="SAVE"
              name="SAVE"
              toSave={true}
              disabled={
                !saveButton || props.caseDetails.status === "Completed"
                  ? true
                  : false
              }
              fnc={saveContractPricing}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid xs={2}></Grid>
        <Grid item xs={4}>
          {nonCapPrice.length > 0 ? (
            <ButtonControl
              style={{ padding: "30px", width: "auto" }}
              Icon="save"
              name={qqCode ? qqCode : "ENTER QQ CODE"}
              toSave={true}
              fnc={handleDialog}
              disabled={
                !qqCodeButton || props.caseDetails.status === "Completed"
                  ? true
                  : false
              }
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <QqCode handleAlert={handleAlert} qCode={qqCode} qText={qqText} />
      </Dialog>
    </>
  );
}
