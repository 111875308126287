import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Buttons from '../../components/controls/Button';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#eaeaea',
    borderBottom: '1px solid grey'
  },
  buttonroot: {
    borderColor: 'orange'
  }
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'lightgrey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gold',
      },
    },
  },
})(TextField);

function TPRFilterSaveDialogue(props) {
  const { open, classes, handleClose, saveObject, callSaveService } = props
  const [open1, setOpen1] = React.useState(false);
  const [name, setName] = React.useState();
  const [disable, setDisable] = React.useState(true)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleTextBox = (e) => {
    if (e.target.value) {
      setName(e.target.value)
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const callSaveService1 = (e) => {
    const finalObject = Object.assign(saveObject, { name: name })
    callSaveService(finalObject)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.root} id="responsive-dialog-title">
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{"Save Treatment Plan Filter Criteria"}</div>
        </DialogTitle>
        <DialogContent style={{ display: 'grid', gridGap: '20px' }}>
          {/* <DialogContentText> */}
            Please provide a name to save the filter criteria.
          {/* </DialogContentText> */}
          {/* <DialogContentText> */}
          {/* <TextField id="outlined-search" label="Name" variant="outlined"/> */}
          <CssTextField
            className={classes.margin}
            label="Filter Name"
            variant="outlined"
            id="custom-css-outlined-input"
            onChange={(e) => handleTextBox(e)}
          />
          {/* </DialogContentText> */}

        </DialogContent>
        <DialogActions>
          <Buttons Icon="Submit" fnc={(e) => { handleClose(e) }} name="CANCEL" />
          <Buttons Icon="Submit" toSave={true} fnc={(e) => { callSaveService1(e) }} disabled={disable} name="ACCEPT" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(TPRFilterSaveDialogue)