import axios from 'axios'
import { API } from '../../constants';

//ActionTypes

const LOAD = 'patientScans/LOAD'
const LOAD_SUCCESS = 'patientScans/LOAD_SUCCESS'
const LOAD_FAILURE = 'patientScans/LOAD_FAILURE'
const RESET_UPLOAD_TREATMENT_PLAN = 'patientScans/RESET_UPLOAD_TREATMENT_PLAN'

const initialState = {
    loaded: false,
    loading: false,
    patientScansMetadata: [],
    patientScansMetadataError: ''
}

export default function patientScans(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                patientScansMetadata: action.payload,
                patientScansMetadataError: ''
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                patientScansMetadata: {},
                patientScansMetadataError: action.payload
            }
        case RESET_UPLOAD_TREATMENT_PLAN:
            return {
                ...state,
                patientScansMetadata: {},
                patientScansMetadataError: ''
            }
        default:
            return state
    }
}

export function getPatientScans(patientScans) {
    const dicomTags = '(0008,1030)';
    return dispatch => {
        dispatch({
            type: LOAD
        })
        const { caseType, makoToken, as1Token, patientScanStatus,date, pageIndex } = patientScans
        let url
        const urlPath = API.REACT_APP_MIB_METADATA_REQUEST;
        if(patientScanStatus) {
            let assigned=null;
            if(patientScanStatus=="Assigned"){
                assigned=true;
            }
            url = encodeURI(`${urlPath}?additionalDataFilter={ "jointReplacement.tprhs.assigned":${assigned}}${date!=''?`&dateTimeFrom=${date}`:``}&pageSize=${-1}&dicomTags=${dicomTags}`)
        }
        const headers = {headers: {
            Authorization: caseType === 'MAKO' ? "Bearer " + makoToken : "Bearer " + as1Token,
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Custom-Header": "value",
        }}
        axios
            .get(url, headers)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                let errMsg = "We've had trouble while fetching the Patient scan list."
                if (error.response.status === 401 || error.response.status === 403) {
                    errMsg = `Patient scan list cannot be fetched; ${error.response.status}:Client cannot be authenticated`;
                }
                dispatch({
                    type: LOAD_FAILURE,
                    payload: errMsg
                })
            })
    }
}


