import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Snackbars(props) {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  const classes = useStyles();
  return (
    <div>
      <Snackbar open={props.open} autoHideDuration={(props.duration && props.duration === null) ? props.duration :6000} onClose={props.handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        style={{zIndex:10001}}
        className="MuiSnackbar-anchorOrigintopCenter">
        <Alert onClose={props.handleClose} severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}