import PropTypes from 'prop-types';

export const Value = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.object
]);

export const Option = PropTypes.shape({
  label: PropTypes.string,
  value: Value
});

export const Options = PropTypes.arrayOf(Option);

export const Address = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string
});

export const Query = PropTypes.shape({
  source: PropTypes.func,
  labelField: PropTypes.string,
  valueField: Value,
  params: PropTypes.shape({
    user_sfid: PropTypes.string
  })
});

export const DeviceOrientationInfo = PropTypes.shape({
  deviceType: PropTypes.oneOf(['Tablet', 'Phone']),
  orientation: PropTypes.oneOf(['Portrait', 'Landscape']),
  dim: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  })
});

export const Set = PropTypes.shape({
  __typename: PropTypes.string,
  kit_no__c: PropTypes.string,
  loaner__c: PropTypes.string,
  productName: PropTypes.string,
  set_id: PropTypes.string,
  set_name: PropTypes.string,
  type: PropTypes.string
});

export const Part = PropTypes.shape({
  __typename: PropTypes.string,
  expiration_date: PropTypes.string,
  loaner__c: PropTypes.string,
  lot_number: PropTypes.string,
  lot_serial_control_code__c: PropTypes.string,
  part_desc: PropTypes.string,
  part_ipc_sfid: PropTypes.string,
  part_name: PropTypes.string,
  product_sfid: PropTypes.string,
  serial: PropTypes.string,
  unit: PropTypes.string
});

export const Case = PropTypes.shape({
  QuickQuoteCode: PropTypes.string,
  QuickQuoteRationale: PropTypes.string,
  additionalFees: PropTypes.array,
  address1__c: PropTypes.string,
  address2__c: PropTypes.string,
  address_name: PropTypes.string,
  address_site_number: PropTypes.string,
  billingToAddress: PropTypes.string,
  branch: PropTypes.string,
  cancelComment: PropTypes.string,
  cancelReason: PropTypes.string,
  capPrice: PropTypes.bool,
  caseBranchId: PropTypes.string,
  caseId: PropTypes.string,
  city__c: PropTypes.string,
  communityCase: PropTypes.bool,
  consignment_assignment_count: PropTypes.number,
  coveringRep: PropTypes.string,
  coveringRepsSFIDs: PropTypes.string,
  createdDate: PropTypes.string,
  deliveryDate: PropTypes.object,
  deliveryOption: PropTypes.string,
  division: PropTypes.string,
  externalComments: PropTypes.string,
  externalId: PropTypes.string,
  favoriteCase: PropTypes.bool,
  hospitalName: PropTypes.string,
  hospitalNoName: PropTypes.string,
  hospital_staffid__c: PropTypes.string,
  hospitalid__c: PropTypes.string,
  image: PropTypes.arrayOf(PropTypes.string),
  internalComment: PropTypes.string,
  is_hospital_owned: PropTypes.bool,
  lastModifiedByUser: PropTypes.string,
  lastModifiedDate: PropTypes.string,
  left: PropTypes.bool,
  usgreject_comments__c: PropTypes.string,
  mako: PropTypes.bool,
  orderType: PropTypes.string,
  parts: PropTypes.arrayOf(Part),
  patientId: PropTypes.string,
  postalcode__c: PropTypes.string,
  pouploaded: PropTypes.bool,
  procedure: PropTypes.string,
  procedureSfids: PropTypes.string,
  procedures: Options,
  returnDate: PropTypes.string,
  revision: PropTypes.bool,
  right: PropTypes.bool,
  salesRep: PropTypes.string,
  salesTeamsSFIDs: PropTypes.string,
  sets: PropTypes.arrayOf(Set),
  sfid: PropTypes.string,
  shipTo: PropTypes.string,
  ship_toaddress__r__external_id__c: PropTypes.string,
  ship_toaddress__c: PropTypes.string,
  shippingAddress: PropTypes.string,
  shippingDate: PropTypes.string,
  signatureHospitalRepName: PropTypes.string,
  signatureSalesRepName: PropTypes.string,
  signature_source__c: PropTypes.string,
  state_province__c: PropTypes.string,
  status: PropTypes.string,
  surgeonLastName: PropTypes.string,
  surgeonName: PropTypes.string,
  surgeonNoName: PropTypes.string,
  surgeryDate: PropTypes.string,
  urgentreplenish: PropTypes.bool,
  usages: PropTypes.array,
  userRep: PropTypes.string
});

export const EditCaseForm = PropTypes.shape({
  branch: Option,
  cap_price__c: PropTypes.bool,
  caseId: PropTypes.string,
  deliveryDate: PropTypes.string,
  division: Option,
  externalId: PropTypes.string,
  favourite__c: PropTypes.bool,
  hospital: Option,
  internalComment: PropTypes.string,
  isJointReplacementAndTrauma: PropTypes.bool,
  is_hospital_owned: PropTypes.bool,
  left: PropTypes.bool,
  mako: PropTypes.bool,
  offlineId: PropTypes.string,
  patientId: PropTypes.string,
  procedures: Options,
  recordHistories: PropTypes.array,
  revisions__c: PropTypes.bool,
  right: PropTypes.bool,
  selectedParts: PropTypes.arrayOf(Part),
  selectedSets: PropTypes.arrayOf(Set),
  sfid: PropTypes.string,
  status__c: PropTypes.string,
  surg_pref_comments__c: PropTypes.string,
  surgeon: Option,
  surgeryDate: PropTypes.string,
  surgeryTime: PropTypes.string
});

export const Contract = PropTypes.shape({
  comments: PropTypes.string,
  description: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  oracleId: PropTypes.string,
  startDate: PropTypes.string
});

export const PricingRow = PropTypes.shape({
  C0: PropTypes.string,
  C1: PropTypes.string,
  C2: PropTypes.string,
  partName: PropTypes.string,
  productOracleId: PropTypes.string
});

export const Preference = PropTypes.shape({
  __typename: PropTypes.string,
  sfid: PropTypes.string,
  branch: PropTypes.string,
  branchid__c: PropTypes.string,
  contactid__c: PropTypes.string,
  external_id__c: PropTypes.string,
  hospitalName: PropTypes.string,
  hospitalid__c: PropTypes.string,
  name: PropTypes.string,
  nick_name__c : PropTypes.string,
  procedureName: PropTypes.string,
  procedureid__c: PropTypes.string,
  surgeonName: PropTypes.string,
  surg_pref_comments__c: PropTypes.string,
  parts: PropTypes.arrayOf(Part),
  sets: PropTypes.arrayOf(Set)
});

export const EditPreferenceForm = PropTypes.shape({
  sfid: PropTypes.string,
  branchid__c: PropTypes.string,
  contactid__c: PropTypes.string,
  external_id__c: PropTypes.string,
  surg_pref_comments__c: PropTypes.string,
  name: PropTypes.string,
  nick_name__c : PropTypes.string,
  hospital: Option,
  procedure: Option,
  surgeon: Option,
  selectedParts: PropTypes.arrayOf(Part),
  selectedSets: PropTypes.arrayOf(Set)
});

export const User = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  sales_rep_email: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  preferences: PropTypes.arrayOf(Preference),
  branchIds: PropTypes.arrayOf(PropTypes.string),
  screens: PropTypes.arrayOf(PropTypes.string),
  screen_permissions: PropTypes.arrayOf(PropTypes.string),
  sfids: PropTypes.arrayOf(PropTypes.string),
  teammate_sfids: PropTypes.string
});

export const Navigation = PropTypes.shape({
  dispatch: PropTypes.func,
  navigate: PropTypes.func,
  closeDrawer: PropTypes.func,
  goBack: PropTypes.func,
  state: PropTypes.object,
  toggleDrawer: PropTypes.func
});

export const Order = PropTypes.shape({
  caseId: PropTypes.string,
  orderType: PropTypes.string,
  orderNumber: PropTypes.string,
  hospitalName: PropTypes.string,
  surgeonName: PropTypes.string,
  capPrice: PropTypes.string,
  surgeryDate: PropTypes.string,
  usageSubmittedDate: PropTypes.string,
  erpCreatedDate: PropTypes.string,
  erpNumber: PropTypes.string,
  erpOrderStatus: PropTypes.string,
  billedAmount: PropTypes.string,
  poUploaded: PropTypes.string,
  branch: PropTypes.string,
  externalId: PropTypes.string,
  sfid: PropTypes.string,
  hospitalSurgeonCombination: PropTypes.string,
  orderTypeNumberCombination: PropTypes.string,
  status: PropTypes.string,
  integrationStatus: PropTypes.string,
  orderSfid: PropTypes.string
});
