import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CALENDAR_STATUS, ASYNC_STORAGE, API } from "../../constants";
import axios from "axios";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import "../../styles/style.css";
import getCaseById from "../../utils/calendar";
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import SimpleBackdrop from "../../components/controls/Spinner";
import { getDiffHours } from "../../utils/surgicalCase";

const CaseCard = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const GetCaseById_Set = (response) => dispatch(userCase.setCaseDetail(response));
  const [loading, setLoading] = React.useState(false);

  let allCaseStatus = ['All', 'New', 'Assigned', 'Shipped/Ready for Surgery', 'Completed', 'Closed', 'Cancelled', 'Requested'];
  
  const itemColor =
  props.case &&
CALENDAR_STATUS.find((itm) => {
if(props.case && allCaseStatus.indexOf(props.case.status)!=-1){
 return itm.label == props.case.status
}else {
 return  {
  id: "Default",
  label: "Default",
  color: '#ffff00',
}
 }
});
  
  
  // const itemColor1 = props.case && CALENDAR_STATUS.find(
  //   (itm) => {
  //     console.log(itm);
  //     console.log("11111111111111111111");
  //     let check = arr5.indexOf(props.case.status);
  //     console.log(check);
     
  //  if (itm && itm.label == props.case.status) {
  //   console.log(itm.label);
  //   console.log(itm.color);
  //   console.log(props.case.status);
  //  console.log("test44");
  //   return itm.color;
  //  }
  //   }  
  // );
  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      flexGrow: 1,
    },
    card: {
      margin: "7px",
      borderBottom: `8px solid ${itemColor && itemColor.color ? itemColor.color : ""}`,
      boxShadow: "2px 2px 5px grey",
      borderRadius: "10px",
      padding: "0px 10px",
      backgroundColor: "white",
      lineHeight: "1.1em",
      width: "355px",
      cursor: "pointer",
      "&:hover": {
        boxShadow: `0px 0px 5px ${itemColor && itemColor.color ? itemColor.color : ""}`,
      },
      "&:focus": {},
    },
    detail: {
      marginTop: "-5px",
      marginBottom: "5px",
      padding: "0px 10px",
      backgroundColor: "white",
      width: "350px",
      cursor: "pointer",
    },
  }));

  const arr = [];

  const myFunction = (data) => {

    let checkCase = false;
    for (let i = 0; i < data.length; i++) {
  if (data[i].tpr_status === 'InDesign' || data[i].tpr_status === 'Rejected' ||
  data[i].tpr_status === 'Treatment Plan Downloaded' ||
  data[i].tpr_status === 'Treatment Plan Available' || 
  data[i].tpr_status === 'In Design' ||
  data[i].tpr_status === 'Rejected-Rework Required' ||
  data[i].tpr_status === 'Surgical Plan Available'||
  data[i].tpr_status === 'Final Plan Available and Product Manufacturing'||
  data[i].tpr_status === 'SignedOff'||
  data[i].tpr_status === 'Shipped To Sales') {
checkCase = true;
  }
    }
  
    return checkCase;
  }

  const viewSurgicalCase = (externalId, sfId, tpr_id) =>(event) => {
    event.stopPropagation();
    localStorage.setItem("selectedTprId",tpr_id);
    setLoading(true)
    getCaseById(externalId, sfId)
    .then(res => {
      GetCaseById_Set(res.data);
      setLoading(false)
      localStorage.setItem("comingFromCardView",true);
      history.push('/ViewSurgicalCase');
     
    })
  }

  async function getImplantSize (id, event) {
    var myDiVElem2 = document.getElementById(id.caseId);
    if (id.procedures.length > 0 ) {
    let checkMakoExist = id.procedures[0].name.includes("MAKO");
     if (!props.case.mako) {
      return;
     }
    }
    else {
      return;
    }
    if (myDiVElem2 != undefined) {
      myDiVElem2.innerHTML = '';
    }
    let tt = [];
    let tt2 = [];
    for (let i = 0; i < id.tprs.length; i++) {
      if (id.tprs[i].tpr_status === 'Treatment Plan Available' || id.tprs[i].tpr_status === 'Treatment Plan Downloaded') {
        let l = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN));
        const header =  {
         Authorization: "Bearer " + l.mako_token,
         "Content-Type": "application/json",
     };
     let id11 = id.tprs[i].tpr_id;
     let c7 = true;
     let cx = API.RHS.TREATMENT_PLAN_METADATA_URI;
     var url = `${cx}?associatedTreatmentPlanRequestId=${id11}&latestUploadedOnly=${c7}`
     var TPRPatientScans = {
         method: 'get',
         url: url,
         headers: header,
     };
     let result;
    let p = 'p' + i;
    tt2.push(p);
      
     try{
          const response = await axios(TPRPatientScans).then(res => {
          // resolve();
          let myDiVElem = document.getElementById(id.caseId);
          if (res && res.data) {
          
               if (myDiVElem != null && res && res.data && true) {
                if (res.data[0] && (res.data[0].implantInformationParameters != undefined)) {
                // alert(res.data[0] && res.data[0].implantInformationParameters[0]);
                let ui1 = res.data[0].implantInformationParameters;
                let str = '';
             
                for (let i = 0; i < ui1.length; i++) {
                str = str + ui1[i].value;
                if (i+1 !== ui1.length) {
                  str = str + ',';
                }
                }
                let ui = str;
                let cq = res.data[0].implantInformationParameters[0].key + res.data[0].implantInformationParameters[1].key;
                if (str != ',' && str != ',,') {
                tt.push(ui);
                }
            
                }
               }
              }
             
          })
             
     }
     catch (e) {
       
         console.log('error tpr patient scan', e);
     }
    
    } 
  }
    Promise.allSettled(tt2).then(res => {
      tt2 = [];
      if (tt.length == 1) {
        let myDiVElem5 = document.getElementById(id.caseId);
        let myDiVElem7 = document.getElementById('caseCard-Detail');
        let caseelem = false;
        if (myDiVElem7 !== null) {
         caseelem = myDiVElem5.querySelector('.caseCard-Details');

        }
            if (caseelem) {
              
        caseelem.getElementsByTagName("p")[8].innerHTML = 'Implant Size: ' + tt[0];
        return;
            //myDiVElem5.innerHTML = 'Implant Size: ' + tt[0];
            }else{
              if (myDiVElem5) {
              myDiVElem5.innerHTML = 'Implant Size: ' + tt[0];
              }
            }
          }
    })
    // 
      }

  const getChckeboxValue = (tprs, data, data2) => (event) => { 
    event.stopPropagation();
    const value = event.target.checked;

    let arr2 = [];
    let arr3 = localStorage.getItem("selectedCheckBoxes");
    if ( arr3 == "null" || arr3 == "undefined") {
      arr3 = [];
    } else {
      arr2 = JSON.parse(localStorage.getItem("selectedCheckBoxes"));
    }

    if (event.target.checked === true) {
      let u = { 
        tprs: tprs,
        requestId: data.tpr_id,
        sfId: data2.sfId,
        external_id: data.external_id,
        caseId: data2.caseId 

      };
      arr.push(u);
      arr2.push(u);
    }
    else {
       arr2.filter((value, index) => {
        if (value.tprs === tprs) {
       arr2.splice(index,1);
          return;
        }
       })
     
    }
    localStorage.setItem("selectedCheckBoxes",JSON.stringify(arr2));
  }


  const classes = useStyles();
  const procedure = props.case &&
    props.case.procedures.length > 0 ? props.case.procedures[0].name : "";

  const viewCase = (externalId, sfId) => {
    setLoading(true);
    getCaseById(externalId, sfId)
    .then(res => {
      GetCaseById_Set(res.data);
      setLoading(false);
      history.push('/ViewSurgicalCase');
    })
  }
  return (
    props.case && 
    <Grid item className={props.detailOnly ? classes.detail : classes.card} onClick={() => viewCase(props.case.externalId, props.case.sfId)}>
      <div className="caseCard">
        <div
          style={{
            display: props.detailOnly ? "none" : "block",
            marginTop:'-2px',
            float: "right",
          }}
        >
          {props.case.isWebOpsError ? (
            <div
              style={{
                border: '1px solid red',
                display: "inline",
                borderRadius: "10px",
                padding: "1px 10px 1px 30px",
                fontSize: "14px",
                color: "red",
                margin: "-5px 3px 0px 0px",
                position: "relative",
              }}
            >
              <WarningIcon
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  marginTop: "1px",
                  marginLeft: "-20px",
                }}
              />
              {"Error"}
            </div>
          ) : null}
          {/* Show Add-On if Diff is less than 48 hrs */}
          {getDiffHours(moment(props.case.createdDate), moment(props.case.surgeryDate)) ? (
            <div
              style={{
                backgroundColor: "black",
                display: "inline",
                borderRadius: "10px",
                padding: "2px 10px 2px 30px",
                fontSize: "14px",
                color: "white",
                margin: "-5px 3px 0px 0px",
                position: "relative",
              }}
            >
              <WarningIcon
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  marginTop: "1px",
                  marginLeft: "-20px",
                }}
              />
              {"Add-On"}
            </div>
          ) : null}
          <div
            style={{
              backgroundColor: itemColor.color,
              display: "inline",
              borderRadius: "10px",
              padding: "2px 10px",
              color: "white",
              fontSize: "14px",
              marginTop: "-5px 3px 0px 0px",
            }}
          >
            {props.case.status === 'Shipped/Ready for Surgery' ? 'Shipped' : props.case.status}
          </div>
        </div>
        <h5>{props.case.caseId}</h5>
        <h5 style={{ marginTop: "-10px" }}>
          {props.case.hospitalName.split("-")[1].trim()}
        </h5>
        <div id="caseCard-Detail" className="caseCard-Details">
          <p>
            Surgeon: <span id="caseDetail">{props.case.surgeonName}</span>
          </p>
          <p>
            Procedure:{" "}
            <strong>
              <span>{procedure}</span>
            </strong>
          </p>
          <p>
            Sales Rep: <span>{props.case.salesRep}</span>
          </p>
          {/* <div style={{position:"relative"}}> */}
          <p>
            Covering Rep:{" "}
            <span
              style={{
                whiteSpace: "nowrap",
                marginTop: "-12.9px",
                marginLeft: "94px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {props.case.covering_reps__c}
            </span>
          </p>
          {/* </div> */}
          <p>
            Patient ID: <span>{props.case.patientId}</span>
          </p>
        
          <p onLoad={ getImplantSize(props.case)} >  </p>
          <div style={{ marginBottom: "15px", margingTop: "-2px" }}>
            <p>
              Surgery Date:{" "}
              <strong>
                <span>
                  {moment(props.case.surgeryDate).format("MM/DD/YYYY")}
                </span>
              </strong>
            </p>
            <p
              style={{
                float: "right",
                marginTop: "-5px",
              }}
            >
              <strong>
                <span>{moment(props.case.surgeryDate).format("hh:mm A")}</span>
              </strong>
            </p>
          </div>
      
          <p id={props.case.caseId} style={{marginBottom: "4px"}}>  </p>
        
          <div style={{ marginBottom: "15px", margingTop: "-2px", color:"grey", fontSize:"13.5px" }}>
          { props.case.tprs.length > 0 && ((props.case.mako == true) || 
            (props.case.as1 == true)) && myFunction(props.case.tprs) ? 
          <table style={{  fontSize: "12px", width: "95%" }}>
              <th style= {{ textAlign: "start" }}> TPR ID</th>
              <th style={{  marginLeft: "1px", fontSize: "12px", textAlign: "start"  }}> STATUS</th>
              <th style={{  paddingLeft: "1px", textAlign: "start"  }}> SELECT</th>
                  {props.case.tprs.map(data => {
               return  ( 
                <>
                 {data.tpr_status === 'InDesign' || data.tpr_status === 'Rejected' ||
                    data.tpr_status === 'Treatment Plan Downloaded' ||
                    data.tpr_status === 'Treatment Plan Available' || 
                    data.tpr_status === 'In Design' ||
                    data.tpr_status === 'Rejected-Rework Required' ||
                    data.tpr_status === 'Surgical Plan Available'||
                    data.tpr_status === 'Final Plan Available and Product Manufacturing'||
                    data.tpr_status === 'SignedOff'||
                    data.tpr_status === 'Shipped To Sales' ?
                  <tr>
               <td>  {data.tpr_name} </td>
                  <td onClick={viewSurgicalCase(props.case.externalId, props.case.sfId, data.tpr_id)} style={{ textDecoration: 'none', color: '#039be5' }}>  {data.tpr_status} </td>
                  <td><input type="checkbox" onClick={getChckeboxValue(data.tpr_id, data, props.case)} disabled={data.tpr_status !== 'Treatment Plan Downloaded' && data.tpr_status !== 'Treatment Plan Available'  ? true : false} style={{  marginLeft: "18px" }}/></td> 
                  </tr> : 
                  '' }
                  </>
                  ) 
                  })}
              
              </table>
                   : ''
                }
          </div>
        </div>
      </div>
      <SimpleBackdrop open={loading} />
    </Grid>
  );
};
export default CaseCard;
