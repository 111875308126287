import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const Modal = (props) => {
    const handleSavePopup = () => {
        props.setPop(false);
    }

    // const message = props.rob === null || props.rob?.label === "" || props.rob?.value === ""
    //     ? "Please enter ROB # and MICS Handpiece info before submitting."
    //     : "Please enter Serial Number associated with MICS Handpiece before submitting.";

    const message = props.rob === null
    ? "Please enter ROB # before submitting."
    : "Please enter ROB # before submitting.";


    return (
        <div>
            <Dialog open={props.pop}>
                <DialogContent style={{ width: "auto", backgroundColor: "purple" }}>
                    <div style={{ textAlign: "center", padding: "5px" }}>
                        <span style={{ display: "inline-block", margin: "0 auto", marginTop: "15px", color: "white" }}>{message}</span>
                        <Button type="submit" variant="contained" style={{ backgroundColor: "#ffb500", color: "black", fontWeight: "bold", display: "block", margin: "0 auto", marginTop: "60px", marginBottom: "10px" }} onClick={handleSavePopup}>Dismiss</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Modal;
