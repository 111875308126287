import React from "react";
import { CALENDAR_STATUS } from "../../../constants";
import CaseDeatilsTooltip from "./Dialogs/CaseDetailsTootip";

const DayViewComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const itemColor = CALENDAR_STATUS.find(
    (item) => item.label == props.data.status
  ).color;
  const onHover = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setOpen(true);
  };
  const onHoverOut = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setOpen(false);
  };
  return (
    <>
      <div
        onClick={() => {props.viewSurgicalCase(props.data.externalId, props.data.sfId)}}
        onMouseOver={(e) => onHover(e)}
        onMouseOut={(e) => onHoverOut(e)}
        style={{
          height: "40px",
          padding: "0px",
          width:'50px',
          whiteSpace: "nowrap",
          borderRadius: "3px",
          borderLeft: `3px solid ${itemColor ? itemColor : ""}`,
          backgroundColor: "white",
        }}
      >
        <p style={{ margin: "5px" }}>{props.data.title}</p>
        <strong style={{ marginTop: "6px" }}>{props.data.hospitalName}</strong>
      </div>
      <CaseDeatilsTooltip
        itemColor={itemColor}
        data={props.data}
        open={open}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default DayViewComponent;
