import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

export const StyledRadioButton = withStyles({
    root: {
        '&$checked': {
            color: "#FFB500",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);