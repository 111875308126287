import axios from 'axios';
import { get_tpr_history } from '../../graphql/queries'
/***************************action types*******************/

export const Related_RESETDATA = 'Related_RESETDATA';
export const TreatmentPlanRequest_SEARCH_DATA = 'TreatmentPlanRequest_SEARCH_DATA';
export const SurgicalCaseHistory_SEARCH_DATA = 'SurgicalCaseHistory_SEARCH_DATA';
export const CaseActionTracking_SEARCH_DATA = 'CaseActionTracking_SEARCH_DATA';

/***************************action and thunks*******************/

export const resetCaseSearch = () => ({
  type: Related_RESETDATA,
  resetData: []
});

export const dispatchSearch = (response) => ({
  type: TreatmentPlanRequest_SEARCH_DATA,
  payload: response
})
export const searchAction = values => dispatch => {
  return get_tpr_history(values)
    .then(response => {
      dispatch(dispatchSearch(response.data));
    })
    .catch(error => {
      dispatch(dispatchSearch(error));
    })
}


/***************************reducer*******************/

//import * as actionTypes from './actionTypes';
const axiosPayLoad = {
  payload: null,
  TreatmentPlanRequest: null,
  SurgicalCaseHistory: null,
  CaseActionTracking: null,

  // type:'Add',
  // adjudicationControlDetails:null,
  // createData:null,
  // updateData:null,
  // deleteData:null
};

export const STORE = (state = axiosPayLoad, action) => {
  switch (action.type) {
    case Related_RESETDATA:
      return axiosPayLoad;
    case TreatmentPlanRequest_SEARCH_DATA:
      return { ...state, TreatmentPlanRequest: action.payload }
    default: return state;
  }
};
