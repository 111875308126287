import { ASYNC_STORAGE, CASES_LIMIT } from './../../constants';
//import { getLocalCaseStatus } from './../../utils';
import { fetchingCasesFromRestEndpoint } from '../../graphql/queries';

// Actions
export const ACTION_TYPES = {
  BEGIN_LOAD_CASES: 'BEGIN_LOAD_CASES',
  LOAD_CASES: 'LOAD_CASES',
  LOAD_MORE_CASES: 'LOAD_MORE_CASES',
  END_LOAD_CASES: 'END_LOAD_CASES',
  BEGIN_REFRESH_CASES: 'BEGIN_REFRESH_CASES',
  REFRESH_CASES: 'REFRESH_CASES',
  REFETCH_CASES_AFTER_FILTER: 'REFETCH_CASES_AFTER_FILTER',
  END_REFRESH_CASES: 'END_REFRESH_CASES',
  REMOVE_OFFLINE_CASE: 'REMOVE_OFFLINE_CASE',
  EDIT_CASE_DETAIL: 'EDIT_CASE_DETAIL',
  REFRESH_CASE_VIEW: 'REFRESH_CASE_VIEW',
  RESTORE_CASE: 'RESTORE_CASE',
  APP_CASE: 'APP_CASE',
  CURRENT_CASE: 'CURRENT_CASE',
  GET_CASE_BY_ID: 'GET_CASE_BY_ID'
};

async function loadCases(pageOffset, appCases) {
  try {
    const tokenPayload = localStorage.getItem(ASYNC_STORAGE.TOKEN);
    let bearerToken = '';
    if (tokenPayload) {
      const payload = JSON.parse(tokenPayload || '{}');
      bearerToken = `Bearer ${payload.access_token}`;
    }
   let results;
    return results;
  } catch (ex) {
    return [];
  }
}

// Action Creators
export const ACTION_CREATORS = {
  
  setCaseDetail: (payload) => {
    return async (dispatch, store) => {
      dispatch({
        type: ACTION_TYPES.CURRENT_CASE,
        payload
      });
    }
  },
  resetCaseDetail: (payload) => {
    return async (dispatch, store) => {
      dispatch({
        type: ACTION_TYPES.CURRENT_CASE,
        payload
      });
  }
  },
  editCaseDetail: (cases, caseObj, appCases) => {
    // when case is edited
    return async (dispatch, store) => {
      dispatch({
        type: ACTION_TYPES.BEGIN_LOAD_CASES
      });

      // const status = getLocalCaseStatus(appCases, caseObj);
      // if (status) {
      //   caseObj.status = status;
      // }

      dispatch({
        type: ACTION_TYPES.END_LOAD_CASES
      });
      dispatch({
        type: ACTION_TYPES.REFRESH_CASE_VIEW
      });
    };
  },
  removeOfflineCase: offlineId => {
    return {
      type: ACTION_TYPES.REMOVE_OFFLINE_CASE,
      offlineId
    };
  },
  loadCases: user => {
    return async (dispatch, store) => {
      dispatch({
        type: ACTION_TYPES.BEGIN_LOAD_CASES
      });
      await loadCases(0); // args - pageOffset
      dispatch({
        type: ACTION_TYPES.END_LOAD_CASES
      });
    };
  },

  reloadCases: (user, appCases) => {
    return {
      type: ACTION_TYPES.REFRESH_CASE_VIEW
    };
  },

  refreshCaseView: () => {
    return {
      type: ACTION_TYPES.REFRESH_CASE_VIEW
    };
  },

  // refetchCasesAfterFilter: () => {
  //   // action to refetch cases. Used when user applies filter
  //   return {
  //     type: ACTION_TYPES.REFETCH_CASES_AFTER_FILTER
  //   };
  // },

  restoreCase: () => {
    return {
      type: ACTION_TYPES.RESTORE_CASE
    };
  },
  currentCase: () => {
    return {
      type: ACTION_TYPES.CURRENT_CASE
    };
  },
  
  appCreatedCase: appCase => {
    return {
      type: ACTION_TYPES.APP_CASE,
      appCase
    };
  }
};

// Selectors

export const SELECTORS = {};

// Store
const initialState = {
  loading: false, // First load
  refreshing: false, // Pull down to refresh
  source: [],
  refreshToggle: false,
  // refetchCasesAfterFilter: false, // to refetch cases after filter is applied
  appCases: []
};

export const STORE = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.BEGIN_REFRESH_CASES:
    case ACTION_TYPES.END_REFRESH_CASES:
      return Object.assign({}, state, {
        refreshing: action.type === ACTION_TYPES.BEGIN_REFRESH_CASES
      });
    case ACTION_TYPES.BEGIN_LOAD_CASES:
    case ACTION_TYPES.END_LOAD_CASES:
      return Object.assign({}, state, {
        loading: action.type === ACTION_TYPES.BEGIN_LOAD_CASES
      });
    case ACTION_TYPES.REFRESH_CASE_VIEW:
      return Object.assign({}, state, {
        refreshToggle: !state.refreshToggle
      });
    // case ACTION_TYPES.REFETCH_CASES_AFTER_FILTER:
    //   return Object.assign({}, state, {
    //     refetchCasesAfterFilter: !state.refetchCasesAfterFilter
    //   });
    case ACTION_TYPES.LOAD_CASES:
      return Object.assign({}, state, {
        source: action.cases
      });
    case ACTION_TYPES.REMOVE_OFFLINE_CASE:
      return Object.assign({}, state, {
        source: state.source.filter(
          myCase => myCase.offlineId !== action.offlineId
        )
      });
    case ACTION_TYPES.EDIT_CASE_DETAIL:
      return Object.assign({}, state, {
        source: action.cases
      });
    case ACTION_TYPES.APP_CASE:
      let appCases = [];
      const cases = [...state.appCases].filter(
        obj => obj.externalId === action.appCase.externalId
      );
      if (cases.length > 0) {
        appCases = [...state.appCases].map(obj => {
          if (obj.externalId === action.appCase.externalId) {
            obj.status = action.appCase.status;
          }
          return obj;
        });
      } else {
        appCases = [...state.appCases, action.appCase];
      }

      return Object.assign({}, state, {
        appCases
      });
    case ACTION_TYPES.RESTORE_CASE:
      return initialState;
    case ACTION_TYPES.CURRENT_CASE:
        return {...state, 
          currentState :  
            action.payload
        };
    default:
      return state;
  }
};

export const calculateCaseTypeFormulaValue = item => {
  let { is_hospital_owned } = item;
  let sets = item && item.sets ? item.sets : [];
  let parts = item && item.parts ? item.parts : [];

  is_hospital_owned = is_hospital_owned ? JSON.parse(is_hospital_owned) : false;

  let loaner_assignment_count =
    sets.filter(set => {
      const loaner__c =
        typeof set.loaner__c === 'boolean'
          ? set.loaner__c
          : JSON.parse(set.loaner__c);
      return (
        loaner__c === true || set.type === 'Loaner' || set.setType === 'Loaner'
      );
    }).length +
    parts.filter(part => {
      const loaner_part__c =
        typeof part.loaner__c === 'boolean'
          ? part.loaner__c
          : JSON.parse(part.loaner__c);
      return loaner_part__c === true;
    }).length;

  let consignment_assignment_count =
    sets.filter(set => {
      const loaner__c =
        typeof set.loaner__c === 'boolean'
          ? set.loaner__c
          : JSON.parse(set.loaner__c);
      return (
        loaner__c === false &&
        (set.type === 'Consignment' || set.setType === 'Consignment')
      );
    }).length +
    parts.filter(part => {
      const loaner_part__c =
        typeof part.loaner__c === 'boolean'
          ? part.loaner__c
          : JSON.parse(part.loaner__c);
      return loaner_part__c === false;
    }).length;
  if (
    is_hospital_owned === true &&
    loaner_assignment_count === 0 &&
    consignment_assignment_count === 0
  ) {
    return 'Hospital Owned';
  } else if (loaner_assignment_count > 0 && consignment_assignment_count > 0)
    return 'Combination';
  else if (
    (is_hospital_owned === true &&
      loaner_assignment_count > 0 &&
      consignment_assignment_count == 0) ||
    (is_hospital_owned === true &&
      loaner_assignment_count == 0 &&
      consignment_assignment_count > 0)
  ) {
    return 'Combination';
  } else if (loaner_assignment_count > 0 && consignment_assignment_count == 0) {
    return 'Loaner';
  } else if (loaner_assignment_count == 0 && consignment_assignment_count > 0) {
    return 'Consignment';
  } else {
    return '';
  }
};
