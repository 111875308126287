import React, { Component } from 'react';
import { getSurgeons } from '../../graphql/queries'
import Autocomplete from '../../components/controls/AutoComplete'
import { connect } from 'react-redux'

class Surgeon extends Component {
    state = {
        Surgeons: [],
    }
    async componentDidMount() {
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {
    }
    get_AllSurgeon = async () => {
        const Surgeons = await getSurgeons()
        const Surgeons1 = Surgeons.get_surgeons.map(item => {
            item.title = item.surgeonName;
            item.id = item.surgeonID;
            return item;
        })
        this.setState({
            loading: false,
            Surgeons: Surgeons1,
        });
    };
    render() {
        const { Surgeons } = this.state;
        return <Autocomplete label={"Surgeon"} options={this.props.options} 
        page={this.props.page}
        fnc={this.props.onChangeSurgeonName}
        Isdisabled={this.props.Isdisabled}
        value={this.props.value} /> 
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
}
export default connect(mapStateToProps, null)(Surgeon);