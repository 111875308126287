import GraphQLClient from "./client";
import gql from "graphql-tag";
import axios from "axios";
import { ASYNC_STORAGE, API } from "../constants";
import { transformSurgPrefData } from "./mutations";

export function fromSetOptions(caseDetails) {
  return [
    ...caseDetails.sets
      .filter(
        (set) =>
          set.setType &&
          set.setType.toLowerCase() === "consignment" &&
          (set.status__c.toLowerCase().indexOf("shipped") > -1 ||
            ((set.loaner__c === "true" || set.loaner__c === true) &&
              set.status__c === "Assigned"))
      )
      .map((set) => ({
        label: set.kit_no__c
          ? set.kit_no__c.concat(" - ").concat(set.set_name)
          : set.set_name,
        value: set,
      })),
    {
      label: "Rep/Hospital Consigned",
      value: "Rep/Hospital Consigned",
    },
    {
      label: "Branch Loaner",
      value: "Branch Loaner",
    },
    {
      label: "Regional Loaner",
      value: "Regional Loaner",
    },
    {
      label: "National Loaner",
      value: "National Loaner",
    },
    {
      label: "Hospital Owned",
      value: "Hospital Owned",
    },
  ];
}

// to get calendar screen day wise cases
const GET_CALENDAR_CASES = gql`
  query get_calendar_cases($filters: CaseFilter!) {
    get_calendar_cases(filters: $filters) {
      as1
      mako
      externalId
      sfId
      caseId
      salesRep
      createdDate
      surgeryDate
      patientId
      surgeonName
      status
      isWebOpsError
      hospitalName
      favoriteCase
      covering_reps__c
      downloadable
      tprs {
        external_id
        sfid
        tpr_id
        tpr_name
        tpr_status
        tpr_procedure
        surgery_date
      }
      procedures {
        name
      }
    }
  }
`;

const GET_TPR_PATIENT_IDS = gql`
query get_tpr_patient_id($tpr_name: String) {
  get_tpr_patient_id(tpr_name: $tpr_name) {
    patientID
    __typename
  }
}`;

export async function getTprPatientId(tpr_name) {
  const query = GET_TPR_PATIENT_IDS;
  const results = await executeQuery(query,{'tpr_name':tpr_name});
  return results.data;
}

const GET_MPS_USERS = gql`
query get_mps_user($email:String) {
   get_mps_user(email: $email)
  {
    email
    federationidentifier
  }
}`;

export async function getMpsUser() {
  const query = GET_MPS_USERS;
  const results = await executeQuery(query);
  return results.data;
}

const GET_CASES_IN_CHUNK = gql`
  query get_cases($filters: CaseFilter!) {
    get_cases(filters: $filters) {
      cases {
        as1
        mako
        externalId
        sfId
        caseId
        salesRep
        createdDate
        surgeryDate
        patientId
        surgeonName
        status
        hospitalName
        favoriteCase
        isWebOpsError
        covering_reps__c
        downloadable
        tprs {
          external_id
          sfid
          tpr_id
          tpr_name
          tpr_status
          tpr_procedure
          surgery_date
        }
        procedures {
          name
        }
      }
      total
    }
  }
`;


const GET_SHIP_TO_VALUES = gql`
query get_ship_to_values($type:String!,$name:String!,$refresh_cache:Boolean) {
  get_ship_to_values(type:$type,name:$name,refresh_cache:$refresh_cache) {
    ship_method_values{
    id
    label
 }
    ship_priority_values{
    id
    label
}
 __typename
    }
  }
`;



const GET_SUB_INVENTORY = gql`
query get_Sub_Inventory($accountId:String,$branchId:String) {
    get_Sub_Inventory(accountId:$accountId,branchId:$branchId) {
      subinvnameofHospital
      subinvdescofHospital
      addressnameofHospital
      acountsfid
      nameofbranch
      subinvnameofbranch
      subinvdescofbranch
      nameofSubbranch
      subinvnameofSubbranch
      subinvdescofSubbranch
      oraclesitenumber
      AddressERPCode
      ERPCodeofSubBranch
    }
  }
`;

// query to get cases count with status by date
const GET_CASES_COUNT_BY_DATE = gql`
  query get_cases_count_by_date($filters: CaseFilter!) {
    get_cases_count_by_date(filters: $filters) {
      surgeryDate
      status
      count
    }
  }
`;

// query to get parts from consignment sets
const GET_PARTS_FROM_CONSIGNMENT_SETS = gql`
  query get_parts_from_consignment_sets(
    $sfid: String
    $externalId: String
    $searchText: String
  ) {
    get_parts_from_consignment_sets(
      sfid: $sfid
      externalId: $externalId
      searchText: $searchText
    ) {
      set_id
      set_name
      part_name
      product_sfid
      part_desc
      lot_serial_control_code__c
      is_lot_controlled
      productOracleId
      gtin
      catalog_number
    }
  }
`;

const GET_PARTS_FROM_USAGE = gql`
  query get_parts_for_usage($searchText: String) {
    get_parts_for_usage(searchText: $searchText) {
      part_name
      product_sfid
      part_desc
      lot_serial_control_code__c
      is_lot_controlled
      productOracleId
      gtin
      catalog_number
    }
  }
`;

const GQL_GET_CONSIGNMENT_SETS_BY_USER = gql`
  query getConsignmentSetsByUser($user_sfid: String!) {
    get_non_loaner_sets(user_sfid: $user_sfid) {
      part_ipc_sfid
      product_sfid
      part_id
      part_desc
      part_name
      set_id
      set_name
      inventory_type
      oracle_sub_inventory_location
      label
      kit_no__c
      Surgery_date__c
      Case_Number__c
      Status__c
      Category__c
      hospital_No_Name
      invlocation_id
      hospital_name_case
      parts {
        product_sfid
        part_id
        part_desc
        set_id
        part_name
        lot_serial_control_code__c
        unit
        lot_number
        serial
        expiration_date
        productOracleId
        partsearch__c
        part_search_withouthyphen__c
        pricebookName
        gtin
        catalog_number
      }
      originating_loc_branchid__c
      current_loc_branchid__c
    }
  }
`;

export const GQL_GET_CASE_ORDERS = gql`
  query getOrderDetails($case_id: String) {
    get_order_details(case_id: $case_id) {
      sfid
      OrderNumber
      aroNo
      erpNumber
      SalesrepName
      PoNumber
      PoDate
      Status
      OrderBookedDate
      InvoiceDate
      InvoiceNumber
      TotalAmount
      finalbillamount
      lineItems {
        lineStatus
        partNoName
        AROStatus
        AROShipTo
        AROShipmentMethod
        ShippedQty
        BackorderQty
        Price
        Requested
        orderid
      }
    }
  }
`;

export const GQL_GET_CASES_FROM_SETS = gql`
  query getCasesFromSets($set_id: String!) {
    get_cases_from_sets(set_id: $set_id) {
      sfId
      caseId
      salesRep
      hospitalName
      surgeryDate
    }
  }
`;

export const GQL_GET_ORDERS = gql`
  query getOrders($user_sfid: String!) {
    my_orders(user_sfid: $user_sfid) {
      caseId
      orderType
      orderNumber
      hospitalName
      surgeonName
      capPrice
      surgeryDate
      usageSubmittedDate
      erpCreatedDate
      erpNumber
      erpOrderStatus
      billedAmount
      poUploaded
      branch
      externalId
      sfid
      hospitalSurgeonCombination
      orderTypeNumberCombination
    }
  }
`;

const GQL_GET_CASE_RECORD_HISTORY = gql`
  query getCaseRecordHistory($caseid: String, $case_external_id: String) {
    get_case_record_history(
      caseid: $caseid
      case_external_id: $case_external_id
    ) {
      parent_name__c
      updated_by__c
      old_value__c
      name
      parent_record_id__c
      new_value__c
      changed_field__c
      parent_object_type__c
    }
  }
`;

const GQL_GET_SURGEON_PREFERENCE_RECORD_HISTORY = gql`
  query getSurgeonPreferenceRecordHistory(
    $surgeon_preference_id: String
    $surgeon_preference_external_id: String
  ) {
    get_surgeon_preference_record_history(
      surgeon_preference_id: $surgeon_preference_id
      surgeon_preference_external_id: $surgeon_preference_external_id
    ) {
      user_name
      value
      updated_date
      old_value
    }
  }
`;

// Start - New Territory Design Graphql queries
const GQL_GET_HOSPITALS = gql`
  query get_hospitals($searchText: String) {
    get_hospitals(searchText: $searchText) {
      branchID
      branchName
      mainBranch
      mainBranchName
      hospitalId
      hospitalName
      accountNo
    }
  }
`;

const GQL_GET_SURGEONS = gql`
  query get_surgeons($searchText: String) {
    get_surgeons(searchText: $searchText) {
      surgeonID
      surgeonName
    }
  }
`;

const GQL_GET_BRANCHES2 = gql`
  query get_branches2 {
    get_branches2 {
      branchID
      branchName
      branchDivision
      operatingUnit
      mainBranch
      inventoryLocation
    }
  }
`;

const GQL_GET_MAIN_BRANCHES2 = gql`
  query get_main_branches2 {
    get_main_branches2 {
      branchID
      branchName
      branchDivision
      operatingUnit
      mainBranch
      inventoryLocation
    }
  }
`;

const GQL_GET_SUB_BRANCHES2 = gql`
  query get_sub_branches2($branchId: String) {
    get_sub_branches2(branchId: $branchId) {
      branchID
      branchName
      branchDivision
      operatingUnit
      mainBranch
      inventoryLocation
    }
  }
`;

// End - New Territory Design Graphql queries

const GQL_GET_ALL_HOSPITAL_LOCATIONS = gql`
  query get_hospital_locations($accountNo: String) {
    get_hospital_locations(accountNo: $accountNo) {
      address1
      sfid
      siteNo
      primary
      inventoryName
      inventoryDescription
    }
  }
`;

export const GQL_GET_ALL_SALESREP = gql`
  query get_user_by_branch($branchIds: [String]) {
    get_user_by_branch(branchIds: $branchIds) {
      branchID
      branchName
      userID
      userName
      multiplePersona
    }
  }
`;

export const GET_SURGEON_PREF = gql`
  query get_surgeon_pref($userBranchId: String, $filters: PreferenceFilter) {
    get_surgeon_pref(userBranchId: $userBranchId, filters: $filters) {
      preferences {
        name
        nick_name__c
        hospitalid__c
        branchid__c
        contactid__c
        procedureid__c
        procedureName
        acetabular_component__c
        approach__c
        femoral_component__c
        insert_type__c
        baseplate__c
        version__c
        external_id__c
        sfid
        hospitalName
        surgeonName
        branch
        surg_pref_comments__c
        storageExist
        createdDate
        user_name
        storageExist
        created_user_sfid
        isactive__c
      }
      preferencesSets {
        category
        isPrefItemsValid
        set_id
        set_name
        kit_no__c
        loaner__c
        productName
        type
        isPrefItemsValid
        category_sfid
        surgeon_preference__r__external_id__c
        surgeon_preference__c
      }
    }
  }
`;

export const GQL_GET_ALL_BRANCHES = gql`
  query get_branches2 {
    get_branches2 {
      branchID
      branchName
      branchDivision
      operatingUnit
      mainBranch
    }
  }
`;

export const GQL_GET_SUB_BRANCHES = gql`
  query get_sub_branches($branchId: String) {
    get_sub_branches(branchId: $branchId) {
      branchID
      branchName
      branchDivision
      operatingUnit
      mainBranch
      inventoryLocation
    }
  }
`;

export const GQL_GET_BUH = gql`
  query getBUHRecord(
    $hospital_sfid: String
    $surgeon_sfid: String
    $branch_sfid: String
    $sales_rep_sfid: String
  ) {
    get_buh_record(
      hospital_sfid: $hospital_sfid
      surgeon_sfid: $surgeon_sfid
      branch_sfid: $branch_sfid
      sales_rep_sfid: $sales_rep_sfid
    ) {
      buh_available
    }
  }
`;

// Preset Filter Query (procedure, favorite => add in future if req)
const GET_PRESET_FILTERS = gql`
  query filters {
    filters {
      id
      name
      isdefault
      definition {
        division
        filterBranch
        filterSalesRep
        status
        filterHospital
        filterSurgeon
        isWebOpsError
        startDate
        endDate
        filterSalesTeam
        as1
        mako
        surgery_date_confirmed
        role
      }
    }
  }
`;

const GET_PODS_MEMBERS = gql`
  query get_pods_members {
    get_pods_members {
      sfid
      name
      members
    }
  }
`;

const GET_FACILITY_NAMES = gql`
  query get_facility_names($customerIds: [String]) {
    get_facility_names(customerIds: $customerIds) {
      sfid
      customerId
      customerName
    }
  }
`;

const GET_TPR_OPENED_INFO = gql`
  query get_tprs_opened($tprNames: [String]) {
    get_tprs_opened(tprNames: $tprNames) {
      tpr_rhs_id
      tpr_sfid
      external_id
      tpr_name
      tpr_opened
    }
  }
`;

export async function executeAxios(config) {
  let response = await axios(config);
  return response.data;
}

function executeQuery(
  query,
  variables = {},
  fromCache = false,
  cachePolicy = "no-cache"
) {
  const options = {
    query,
    variables,
    fetchPolicy: cachePolicy, // not storing in cache
  };

  return GraphQLClient.query(options);
}

// Start - New Territory Design
// Get User Hospitals
export async function getHospitals(searchText = "") {
  const query = GQL_GET_HOSPITALS;
  const results = await executeQuery(
    query,
    {
      searchText: searchText,
    },
    true
  );
  return results;
}

export async function getShipToValues(type, name,refresh_cache) {
  const query = GET_SHIP_TO_VALUES;
  const results = await executeQuery(query, {
    type:type, name:name, refresh_cache:refresh_cache
  });
  return results.data;
}

export async function getSubInventory(accountId, branchId) {
  const query = GET_SUB_INVENTORY;
  const results = await executeQuery(query, {
    accountId:accountId, branchId:branchId
  });
  return results.data;
}

// Get User Surgeons
export async function getSurgeons(searchText = "") {
  const query = GQL_GET_SURGEONS;
  const results = await executeQuery(
    query,
    {
      searchText: searchText,
    },
    true
  );
  return results.data;
}

/*
 * these all mirror the existing queries with the same name minus the 2 at the end
 * When account updated with branches for most records
 * rename these to drop the 2 and the original services deleted.
 */

// Get All Branches of User
export async function getAllBranches2() {
  const query = GQL_GET_BRANCHES2;
  const results = await executeQuery(query, {}, true);
  if (results.data) {
    return {
      get_branches: results.data.get_branches2,
    };
  }
  return null;
}

export async function getMainBranches() {
  const query = GQL_GET_MAIN_BRANCHES2;
  const results = await executeQuery(query, {}, true);
  if (results.data) {
    return {
      get_main_branches: results.data.get_main_branches2,
    };
  }
  return null;
}

export async function getSubBranches2(branchId = "") {
  const query = GQL_GET_SUB_BRANCHES2;
  const results = await executeQuery(
    query,
    {
      branchId: branchId,
    },
    true
  );
  if (results.data) {
    return {
      get_sub_branches: results.data.get_sub_branches2,
    };
  }
  return null;
}

// End - New Territory Design

export async function getAllHospitalLocations(text = "") {
  if (!text) {
    return [];
  }
  const query = GQL_GET_ALL_HOSPITAL_LOCATIONS;
  const results = await executeQuery(
    query,
    {
      accountNo: text,
    },
    true
  );
  if (results && results.data && results.data.get_hospital_locations) {
    return results.data.get_hospital_locations;
  }
  return [];
}

export async function getBranchSalesrep(branchIds = []) {
  const query = GQL_GET_ALL_SALESREP;
  const results = await executeQuery(
    query,
    {
      branchIds: branchIds,
    },
    true
  );
  return results.data;
}
// fetch All Branches of User
export async function getAllBranches(branchIds = []) {
  const query = GQL_GET_ALL_BRANCHES;
  const results = await executeQuery(query, {}, true);
  if (results.data && results.data.get_branches2) {
    return {
      get_branches: results.data.get_branches2.filter(
        (x) => x.mainBranch == null
      ),
    };
  }
  return null;
}
// fetch Sub Branches for a given Branch
export async function getSubBranches(branchIds = []) {
  const query = GQL_GET_SUB_BRANCHES;
  const results = await executeQuery(
    query,
    {
      branchIds: branchIds,
    },
    true
  );
  if (results.data && results.data.get_sub_branches) {
    return {
      get_sub_branches: results.data.get_sub_branches.filter(
        (x) => x.branchID == branchIds || x.mainBranch == branchIds
      ),
    };
  }
  return null;
}
// fetch All Sub Branches for User
export async function getAllSubBranches() {
  const query = GQL_GET_SUB_BRANCHES;
  const results = await executeQuery(
    query,
    {
      branchIds: "",
    },
    true
  );
  if (results.data && results.data.get_sub_branches) {
    return {
      get_sub_branches: results.data.get_sub_branches,
    };
  }
  return null;
}
// fetch Both Branches + Sub Branches
export async function getBranchesAndSubBranches(branchIds = []) {
  const query = GQL_GET_ALL_BRANCHES;
  const results = await executeQuery(query, {}, true);
  if (results.data && results.data.get_branches2) {
    return {
      get_branches: results.data.get_branches2,
    };
  }
  return null;
}

export async function getCasesFromSets(set_id) {
  const query = GQL_GET_CASES_FROM_SETS;
  const results = await executeQuery(query, {
    set_id,
  });
  return results;
}

export async function getSetsDataFromCache(user_sfid) {
  const results = await executeQuery(GQL_GET_CONSIGNMENT_SETS_BY_USER, {
    user_sfid,
  });
  return results.data.get_non_loaner_sets.map((set) => {
    if (
      !set.Case_Number__c &&
      (set.hospital_name_case || set.Surgery_date__c)
    ) {
      Object.assign(set, { hospital_name_case: "", Surgery_date__c: "" });
    }
    return set;
  });
}

export const GQL_GET_ALL_PicklistValues = gql`
  query getPicklistValues(
    $type: String
    $name: String!
    $field_name: String
    $parentPicklistValue: String
    $refresh_cache: Boolean
  ) {
    get_picklist_values(
      type: $type
      name: $name
      field_name: $field_name
      parentPicklistValue: $parentPicklistValue
      refresh_cache: $refresh_cache
    ) {
      id
      label
    }
  }
`;

export async function getFieldPicklistValues(
  type,
  name,
  field_name,
  parentPicklistValue,
  refresh_cache = false
) {
  const query = GQL_GET_ALL_PicklistValues;
  const results = await executeQuery(
    query,
    {
      type,
      name,
      field_name,
      parentPicklistValue,
      refresh_cache,
    },
    true
  );
  return results.data;
}

const GET_CONTRACTS = gql`
  query getContracts($hospital_id: String!, $date: String, $division: String) {
    get_contracts(hospital_id: $hospital_id, date: $date, division: $division) {
      id
      name
      sfid
      description
      comments
      contractNumber
      contractEndDate
      contractStartDate
      priceListHeaderId
    }
  }
`;

export async function getContracts(hospital_id) {
  const query = GET_CONTRACTS;
  return await executeQuery(query, {
    hospital_id,
  });
}

const GET_PRICE_FROM_ORACLE = gql`
  query getPriceFromOracle(
    $hospital_id: String!
    $contractAndProducts: [ContractAndProducts!]
  ) {
    get_price_from_oracle(
      hospital_id: $hospital_id
      contractAndProducts: $contractAndProducts
    ) {
      contractNumber
      productId
      price
    }
  }
`;

export async function getPriceFromOracle(hospital_id, contractAndProducts) {
  const query = GET_PRICE_FROM_ORACLE;
  return await executeQuery(query, {
    hospital_id,
    contractAndProducts,
  });
}

export const getNotificationSettings = (user_sfid) => {
  const query = gql`
    query get_app_settings($user_sfid: String!) {
      get_app_settings(user_sfid: $user_sfid) {
        requested_sets_notification__c
        oracle_order_notify__c
        ship_ready_for_surgery_sets_notification__c
        sets_cancelled_removed_notification__c
        send_email_invite_notification__c
        case_cancelled_notification__c
        new_patient_scan_available__c
        tpr_cancelled__c
        tpr_creation_notification__c
        tpr_indesign_notification__c
        tpr_rejection_notification__c
        tpr_submission_notification__c
        tpr_treatmentplanavailable_notification__c
        treatment_plan_downloaded__c
        tpr_created_awaiting_ct_scan__c
        tpr_surgical_plan_available_notiify__c
        tpr_completed_notification__c
        tpr_surgicalplan_conditionally_approved__c
        tpr_surgicalplan_change_requested__c
        tpr_surgical_plan_approved__c
        tpr_finalplanavail_manf__c
        tpr_shiped_to_sales_notification__c
        tpr_as1_submmision_notification__c
        tpr_as1_indesign_notification__c
        tpr_as1_cancelled_notification__c
        tpr_as1_rejected_notification__c
        tpr_as1_signed_off_notification__c
        sfid
        external_id__c
      }
    }
  `;
  return executeQuery(query, {
    user_sfid,
  });
};

// fetching calendar cases
export const getCasesCountByDate = async (caseFilters, fetchPolicy) => {
  let variables = {};
  for (let i in caseFilters) {
    variables[caseFilters[i].key] = caseFilters[i].value;
  }

  const query = GET_CASES_COUNT_BY_DATE;
  return await executeQuery(
    query,
    {
      filters: variables,
    },
    false,
    fetchPolicy
  );
};

// fetching cases
export const getCalendarCases = async (calendarFilters, fetchPolicy) => {
  let variables = {};
  for (let i in calendarFilters) {
    variables[calendarFilters[i].key] = calendarFilters[i].value;
  }
  const query = GET_CALENDAR_CASES;
  return await executeQuery(
    query,
    {
      filters: variables,
    },
    false,
    fetchPolicy
  );
};

// fetching cases
export const getCasesInChunk = async (
  pageOffset,
  caseFilters,
  searchText,
  fetchPolicy
) => {
  // adding pageOffset and other filters if present
  let variables = { pageOffset: pageOffset };
  for (let i in caseFilters) {
    variables[caseFilters[i].key] = caseFilters[i].value;
  }
  //variables['role'] =  "My Cases";
  if (searchText) {
    variables["searchText"] = searchText;
  }
  const query = GET_CASES_IN_CHUNK;
  return await executeQuery(
    query,
    {
      filters: variables,
    },
    false,
    fetchPolicy
  );
};

// clearing apollo cache
export const clearCache = async () => {
  return await GraphQLClient.clearStore();
};

export const getOrderDetails = (case_id) =>
  executeQuery(GQL_GET_CASE_ORDERS, {
    case_id,
  });

export const getOrders = (user, fromCache = false) => {
  const query = GQL_GET_ORDERS;
  const { sfids } = user;

  return executeQuery(
    query,
    {
      user_sfid: sfids[0],
    },
    fromCache
  );
};

export async function getCaseRecordHistory(caseid, case_external_id) {
  const query = GQL_GET_CASE_RECORD_HISTORY;
  const results = await executeQuery(query, {
    caseid,
    case_external_id,
  });
  return results;
}

export const GQL_GET_ORACLE_PRICE_FOR_PARTS = gql`
  query get_price_from_oracle(
    $hospital_id: String!
    $contractAndProducts: [ContractAndProducts!]
  ) {
    get_price_from_oracle(
      hospital_id: $hospital_id
      contractAndProducts: $contractAndProducts
    ) {
      productId
      price
      contractNumber
    }
  }
`;

export async function getPartPriceFromOracle(hospital_id, contractAndProducts) {
  const query = GQL_GET_ORACLE_PRICE_FOR_PARTS;
  return await executeQuery(query, {
    hospital_id,
    contractAndProducts,
  });
}

export async function getSurgeonPreferenceRecordHistory(
  surgeon_preference_id,
  surgeon_preference_external_id
) {
  const query = GQL_GET_SURGEON_PREFERENCE_RECORD_HISTORY;
  const results = await executeQuery(query, {
    surgeon_preference_id,
    surgeon_preference_external_id,
  });
  return results;
}

export async function getRepSuiteAppSettings() {
  const query = gql`
    query getRepSuiteAppSettings {
      get_rep_suite_app_settings {
        branch__c
        hospital__c
        delivery_lead_time__c
        division__c
      }
    }
  `;
  let results = await executeQuery(query, {}, false);
  if (results && results.data && results.data.get_rep_suite_app_settings) {
    const sqliteInserts = results.data.get_rep_suite_app_settings.map(
      (result) => {
        const { branch__c, delivery_lead_time__c, hospital__c, division__c } =
          result;
        return {
          hospital__c,
          branch__c,
          delivery_lead_time__c,
          division__c,
          json: JSON.stringify(result),
        };
      }
    );
    // await cleanAppSettings();
    // const length = sqliteInserts.length;
    // await bulkInsert('app_settings', sqliteInserts);
    // return length;
  }
  return 0;
}

export const getRepSuiteAppSettingsFromCache = async (connectivity) => {
  if (connectivity !== "none") {
    await getRepSuiteAppSettings().catch((error) => {});
  }
};

//query to get tpr     ******* history
const GQL_GET_TPR_HISTORY = gql`
  query get_tpr_list($caseId: String) {
    get_tpr_list(caseId: $caseId) {
      tpr_name
      tpr_status
      tpr_procedure
      surgery_date
      tpr_id
    }
  }
`;

export const get_tpr_history = async (caseId) => {
  const results = await executeQuery(GQL_GET_TPR_HISTORY, {
    caseId,
  });
  return results;
};

const GQL_GET_TPR_CASE_HISTORY = gql`
  query get_tpr_history($tpr_id: String!) {
    get_tpr_history(tpr_id: $tpr_id) {
      createddate
      oldvalue
      newvalue
      name
    }
  }
`;

export async function getTPRCaseHistory(tprId) {
  if (!tprId) return [];
  const query = GQL_GET_TPR_CASE_HISTORY;
  const results = await executeQuery(query, {
    tpr_id: tprId,
  });
  return results.data;
}

const GQL_GET_SURGICAL_CASES_ERROR_CODES = gql`
  query get_surgical_plan_codes {
    get_surgical_plan_codes {
      label
      statusCode {
        surgicalCode
        description
        ewtype
      }
    }
  }
`;

export async function getSurgicalErrorCodes() {
  const query = GQL_GET_SURGICAL_CASES_ERROR_CODES;
  const results = await executeQuery(query);
  return results.data;
}

const GQL_GET_TPR_LIST_FILTER = gql`
  query get_tpr_filters {
    get_tpr_filters {
      procedureType
      status
      createdIn
      branch
      procedure
      myTpr
      name
    }
  }
`;

export async function getTPRListFilter() {
  const query = GQL_GET_TPR_LIST_FILTER;
  const results = await executeQuery(query);
  return results.data;
}

export async function getProductSystems(
  searchText,
  procedure_id,
  case_branch_sfid,
  storageExist,
  case_sales_rep
) {
  const query = GQL_GET_ALL_PRODUCTS;
  const results = await executeQuery(
    query,
    {
      searchText,
      procedure_id,
      case_branch_sfid,
      storageExist,
      case_sales_rep,
    },
    true
  );
  return results.data;
}

export async function getPartsfromUsage(searchText) {
  const query = GET_PARTS_FROM_USAGE;
  const results = await executeQuery(
    query,
    {
      searchText,
    },
    true
  );
  return results.data;
}

export async function getPartsfromSets(sfid, externalId, searchText) {
  const query = GET_PARTS_FROM_CONSIGNMENT_SETS;
  const results = await executeQuery(
    query,
    {
      sfid,
      externalId,
      searchText,
    },
    true
  );
  return results.data;
}

export async function getCatalogSets(
  procedure_id,
  case_branch_sfid,
  storageExist,
  case_sales_rep
) {
  const query = GQL_GET_CATALOG;
  const results = await executeQuery(
    query,
    {
      procedure_id,
      case_branch_sfid,
      storageExist,
      case_sales_rep,
    },
    true
  );
  return results.data;
}

export async function setAddSets(
  searchText,
  procedure_id,
  case_branch_sfid,
  storageExist,
  case_sales_rep
) {
  const query = GQL_GET_ALL_PRODUCTS;
  const results = await executeQuery(
    query,
    {
      searchText,
      procedure_id,
      case_branch_sfid,
      storageExist,
      case_sales_rep,
    },
    true
  );
  return results.data;
}

export const GQL_GET_ALL_PRODUCTS = gql`
  query get_parts_in_sets(
    $searchText: String
    $procedure_id: String
    $case_branch_sfid: String
    $storageExist: Boolean
    $case_sales_rep: String
  ) {
    get_parts_in_sets(
      searchText: $searchText
      procedure_id: $procedure_id
      case_branch_sfid: $case_branch_sfid
      storageExist: $storageExist
      case_sales_rep: $case_sales_rep
    ) {
      set_id
      set_name
      catalog_category
      catalog_sfid
    }
  }
`;

export const GQL_GET_CATALOG = gql`
  query get_catalog_in_sets(
    $procedure_id: String
    $case_branch_sfid: String
    $storageExist: Boolean
    $case_sales_rep: String
  ) {
    get_catalog_in_sets(
      procedure_id: $procedure_id
      case_branch_sfid: $case_branch_sfid
      storageExist: $storageExist
      case_sales_rep: $case_sales_rep
    ) {
      catalog_category
      caseProducts
      catalog_sfid
    }
  }
`;

const GET_CATALOG_MAPPING = gql`
  query get_catalog_mapping(
    $femoralComponent: String
    $insertType: String
    $baseplate: String
  ) {
    get_catalog_mapping(
      femoralComponent: $femoralComponent
      insertType: $insertType
      baseplate: $baseplate
    ) {
      catalogNumber
      catalogDescription
    }
  }
`;

export async function getSalesTeam(user_sfid) {
  const query = GET_SALES_TEAM;
  const results = await executeQuery(
    query,
    {
      user_sfid,
    },
    true
  );
  return results.data;
}

const GET_SALES_TEAM = gql`
  query filter_sales_team($user_sfid: String!) {
    filter_sales_team(user_sfid: $user_sfid) {
      id
      label
      branch_sfid
      division
      branch
    }
  }
`;

export async function getCatalogMapping(
  femoralComponent,
  insertType,
  baseplate
) {
  const query = GET_CATALOG_MAPPING;
  const results = await executeQuery(query, {
    femoralComponent,
    insertType,
    baseplate,
  });
  return results;
}

export async function getTPR(tprId, isMako) {
  const { as1_token, mako_token } = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.TOKEN) || "{}"
  );
  var header = {
    Authorization: `Bearer ${isMako ? mako_token : as1_token}`,
    "Content-Type": "application/json",
  };

  var geturl = `${API.RHS.TPR_URI}/${tprId}`;
  var TPRGetConfig = {
    method: "get",
    url: geturl,
    headers: header,
    data: {},
  };
  let result;
  try {
    await axios(TPRGetConfig)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        // alert("Get TPR: We've had trouble getting the TPR Get Response.");
        return false;
      });
  } catch (ex) {
    // alert("Get TPR: We've had trouble getting the TPR Get.");
  }
  return result;
}

export async function getSurgeonPreference(userBranchId, filters = {}) {
  const query = GET_SURGEON_PREF;
  const results = await executeQuery(query, {
    userBranchId: userBranchId,
  });
  transformSurgPrefData(results.data.get_surgeon_pref);
  return results;
}

export async function getSurgeonPreferenceByFilter(userBranchId, filters) {
  const query = GET_SURGEON_PREF;
  const results = await executeQuery(query, {
    userBranchId: userBranchId,
    filters: filters,
  });
  transformSurgPrefData(results.data.get_surgeon_pref);
  return results;
}

// Get Case Preset Filters
export async function getPresetFilters(userSFID) {
  const query = GET_PRESET_FILTERS;
  const results = await executeQuery(query, {
    user_sfid: userSFID,
  });
  return results;
}

export async function getPodsMembers() {
  const query = GET_PODS_MEMBERS;
  const results = await executeQuery(query, {});
  return results.data;
}

// Get Account Names for ids
export async function getFacilityNames(customerIds = []) {
  const query = GET_FACILITY_NAMES;
  const results = await executeQuery(query, {
    customerIds,
  });
  return results.data;
}

// Get TPR Details(Opened flag) from SFDC
export async function getTprOpenedInfo(tprNames = []) {
  const query = GET_TPR_OPENED_INFO;
  const results = await executeQuery(query, {
    tprNames,
  });
  return results.data;
}

// Get Procedure PickList Values
export const GQL_GET_PROCEDURE_PicklistValues = gql`
  query getProcedurePicklistValues(
    $procedure__c: String
    $version__c: String
    $approach__c: String
    $femoral__c: String
    $acetabular__c: String
    $baseplate__c: String
    $insert_type__c: String
  ) {
    get_procedure_picklist_values(
      procedure__c: $procedure__c
      version__c: $version__c
      approach__c: $approach__c
      femoral__c: $femoral__c
      acetabular__c: $acetabular__c
      baseplate__c: $baseplate__c
      insert_type__c: $insert_type__c
    ) {
      id
      label
      acetabular{
        id
        label
      }
    }
  }
`;

const GET_UPLOADEDBY = gql`
query UploadedBy {
  UploadedBy {
    user_sfid
    name
  }
}
`;

export async function getProcedurePicklistValues(
  procedure__c,
  version__c,
  approach__c,
  femoral__c,
  acetabular__c,
  baseplate__c,
  insert_type__c
) {
  const query = GQL_GET_PROCEDURE_PicklistValues;
  const results = await executeQuery(
    query,
    {
      procedure__c,
      version__c,
      approach__c,
      femoral__c,
      acetabular__c,
      baseplate__c,
      insert_type__c
    },
    true
  );
  return results.data;
}

export async function getUploadedBy() {
  const query = GET_UPLOADEDBY;
  const results = await executeQuery(query);
  return results.data;
}
const GET_ROBOS_LIST = gql`
  query get_robos_list($robo: String) {
    get_robos_list(robo: $robo) {
      id
      sfid
      svmxc__serial_lot_number__c
      __typename
    }
  }
`;

export async function getRobosList() {
  const query = GET_ROBOS_LIST;
  const results = await executeQuery(query);
  return results.data;
}

const GET_COVERING_REPS = gql`
query get_covering_reps {
  get_covering_reps {
    userID
    userName
    __typename
  }
}
`
export async function getCoveringRepsList() {
  const query = GET_COVERING_REPS;
  const results = await executeQuery(query);
  return results.data;
}