import React, { useRef } from "react";
import { FormControl, makeStyles } from "@material-ui/core";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "97%",
  },
}));
const customStyles = {
  control: (base) => ({
    ...base,
    height: "47px",
    minHeight: "47px",
    border: "1px solid lightgrey",
    borderRadius: 0,
    overflow: "overlay",
  }),
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default function CheckBoxMenu(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div>
      <FormControl className={classes.formControl}>
        <label style={{ fontSize: 13 }} className="controllabelCreate">
          {props.label}
        </label>

        <ReactSelect
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
          }}
          paddingBottom={8}
          options={props.options}
          value={props.value}
          onChange={props.onChange}
          placeholder={"select"}
          styles={customStyles}
          allowSelectAll={props.allowSelectAll}
        />
      </FormControl>
    </div>
  );
}
