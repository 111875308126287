import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Link } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 350,
        border: '1px solid lightgrey'
    },
});

export default function SurgeonPreferencesTable(props) {
    const classes = useStyles();
    const scans = [{ "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }, { "name": "Jason", "branch": "NORTH TEXAS-DALLAS", "hospital": "Live Oak Surg CTR", "surgeon": "JASON SOMOGYI", "procedure": "Knee-Rivison", "active": "true" }]
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Nickname</TableCell>
                                <TableCell align="left">Hospital No - Name</TableCell>                                
                                <TableCell align="left">Branch</TableCell>
                                <TableCell align="left">Surgeon No - Name</TableCell>
                                <TableCell align="left">Procedure</TableCell>
                                <TableCell align="left">Active</TableCell>
                                <TableCell align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: 'auto', height: '150px' }}>
                            {props && props.preferencesList.map((row) => (
                                <TableRow key={row.name} hover>
                                    <TableCell align="left" >
                                        {!row.isactive__c ? <div style={{display: 'grid', gridTemplateColumns: '21px auto' }}>
                                        <Tooltip title="Invalid">
                                            <WarningIcon fontSize="small" style={{color: 'red'}}/>
                                        </Tooltip>
                                        <Link onClick={() => props.editPreference(row)} style={{ textDecoration: 'none', color: '#039be5' }}>{row.name ? row.name : '--'}</Link>
                                        </div> : <Link onClick={() => props.editPreference(row)} style={{ textDecoration: 'none', color: '#039be5' }}>{row.name ? row.name : '--'}</Link>}
                                    </TableCell>
                                    <TableCell align="left">{row.nick_name__c ? row.nick_name__c : '--'}</TableCell>
                                    <TableCell align="left">{row.hospitalName ? row.hospitalName : '--'}</TableCell>
                                    <TableCell align="left">{row.branch ? row.branch : '--'}</TableCell>
                                    <TableCell align="left">{row.surgeonName ? row.surgeonName : '--'}</TableCell>
                                    <TableCell align="left">{row.procedureName ? row.procedureName : '--'}</TableCell>
                                    <TableCell align="left">{row.isactive__c ? 'True'  : 'False'}</TableCell>
                                    <TableCell align="left">
                                        <div style={{cursor: 'pointer'}}>
                                            <DeleteRoundedIcon onClick={() => {props.deleteSurgeon(row)}}/>
                                        </div>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'grid', minHeight: '52px', paddingRight: '2px', gridTemplateColumns: '2fr auto auto auto', gridGap: '30px' }}>

                    <span style={{ alignSelf: 'center', padding: '0px 15px' }}>
                        Total Records: {`${props.preferencesList.length}`}
                    </span>
                </div>
            </Paper>
        </div>
    );
}
