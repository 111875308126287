import axios from 'axios'
import { USER_TYPE, API } from '../../src/constants';

export const getPatientScansAPI = async (token, status,date, pageIndex) => {
    var header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
    const dicomTags = '(0008,1030)';
    var url = `${API.MIB.PATIENT_SCAN_METADATA_URI}?patientScanStatus=${status}&pageSize=${-1}&dicomTags=${dicomTags}`;
    if(status) {
        let assigned=null;
        if(status=="Assigned"){
            assigned=true;
        }
        url =`${API.MIB.PATIENT_SCAN_METADATA_URI}?additionalDataFilter={ "jointReplacement.tprhs.assigned":${assigned}}${date!=''?`&dateTimeFrom=${date}`:``}&pageSize=${-1}&dicomTags=${dicomTags}`;
    }
    
    var TPRPatientScans = {
        method: 'get',
        url: url,
        headers: header,
    };
    let result;
    try{
         const response = await axios(TPRPatientScans)
         result = response.data
    }
    catch (e) {
        // alert(e);
        console.log('error tpr patient scan', e);
    }
    return result;
}

export const setRHSStatus = async (requestId, status, token, reason = null) => {
    var header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
    var puturl = `${API.RHS.TPR_URI}/${requestId}/state?requestState=${status}`

    if(reason){
        puturl = `${puturl}&requestStateComment=${reason}`
    }
    
    var TPRSatusConfig = {
        method: 'put',
        url: puturl,
        headers: header,
    };
    return await axios(TPRSatusConfig);
}

export const setRHSCanel = async (requestId, status, token, cancellationReason) => {
    var header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
    var puturl = `${API.RHS.TPR_URI}/${requestId}/state?requestState=${status}&requestStateComment=${cancellationReason}`;
    var TPRSatusConfig = {
        method: 'put',
        url: puturl,
        headers: header,
    };
    return await axios(TPRSatusConfig);
}

export const checkUserIntrnlCommentsAccess = (personas) => {
    return personas.length > 0 && (personas.indexOf(USER_TYPE.MPS) > -1 ||
    personas.indexOf(USER_TYPE.MAKO_MPS) > -1 ||
    personas.indexOf(USER_TYPE.SALES_REP) > -1 ||
    personas.indexOf(USER_TYPE.SALES_ASSOCIATE) > -1 ||
    personas.indexOf(USER_TYPE.ADMIN) > -1 ||
    personas.indexOf(USER_TYPE.IHS) > -1 ||
    personas.indexOf(USER_TYPE.InHouse) > -1 ||
    personas.indexOf(USER_TYPE.R_and_D) > -1 ||
    personas.indexOf(USER_TYPE.CLINICAL_APP_SUPPORT) > -1)
}

export const checkForInHousePersona = (personas) => {
    return personas.length > 0 && (personas.indexOf(USER_TYPE.IHS) > -1 ||
    personas.indexOf(USER_TYPE.InHouse) > -1)
}

export const displayStatusLabel = (status) => {
    return status === 'InDesign' ? 'In Design' 
    : status === 'TreatmentPlanAvailable' ? 'Treatment Plan Available' 
    : status === 'TreatmentPlanDelivered' ? 'Treatment Plan Downloaded'
    : status === 'ConditionallyApproved' ? 'Conditionally Approved'
    : status === 'ChangeRequested' ? 'Change Requested'
    : status === 'FinalPlanAvailable' ? 'Final Plan Available'
    : status === 'ShippedToSales' ? 'Shipped To Sales'
    : status === 'FinalPlanAvailable' ? 'Final Plan Available'
    : status === 'SignedOff' ? 'Signed Off'
    : status
}