import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Chip,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Buttons from "../../components/controls/Button";
import { useRef } from "react";

const EmailDialog = (props) => {
  const useStyles = makeStyles((theme) => ({
    chip: {
      margin: theme.spacing(0.5),
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: "97%",
    },
    toAddress: {
      color: "#000",
      fontWeight: 600,
      marginLeft: "5px",
      fontSize: "16px",
      display: "grid",
      gridTemplateColumns: "1fr auto",
    },
  }));
  const classes = useStyles();
  const [emailAddresses, setEmailAddresses] = useState([...props.defaultTo]);
  const emailAddressesRef = useRef(emailAddresses);

  const [inputAddress, setInputAddress] = useState("");

  useEffect(() => {
    window.addEventListener("keyup", addEmailAddress);
    return () => {
      window.removeEventListener("keyup", addEmailAddress);
    };
  }, []);

  const addEmailAddress = (e) => {
    if (
      e.target.name === "ss_email" &&
      e.target.value &&
      (e.key === "Enter" || e.keyCode === 13)
    ) {
      const values = e.target.value.split(/[\s, ;]+/);
      let arr = [...emailAddressesRef.current, ...values];
      setEmailAddressesFnc(arr);
      setInputAddress("");
    }
  };

  const setEmailAddressesFnc = (emails) => {
    emailAddressesRef.current = emails;
    setEmailAddresses(emails);
  };

  const onChangeEmail = (event) => {
    setInputAddress(event.target.value);
  };

  const onSendEmail = () => {
    const validEmails = emailAddresses.every((email) => isValideEmail(email));
    if (validEmails) {
      const emailsTo = [ ...emailAddresses];
      props.onSend(emailsTo.join(";"));
    } else {
      props.displaySnackBarMessage(
        "error",
        "Invalid Email(s) exist(s). Please remove to proceed"
      );
    }
  };

  const handleDelete = (index) => {
    let addrss = [...emailAddresses];
    addrss.splice(index, 1);
    setEmailAddressesFnc(addrss);
  };

  const isValideEmail = (email) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return email.match(validRegex);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {"Email Surgery Sheet"}
        <CloseIcon
          style={{ float: "right", cursor: "pointer" }}
          onClick={props.onClose}
        />
        <Divider />
      </DialogTitle>
      <DialogContent style={{ minWidth: "550px" }}>
        <DialogContentText id="alert-dialog-description">
          <Grid container xs={12}>
            <Grid item xs={11} align="left">
              <Typography className={classes.toAddress}>{"To: "}</Typography>
            </Grid>
            <Grid item xs={11} align="left">
              {emailAddresses.map((val, index) => (
                <Chip
                  label={val}
                  onDelete={() => handleDelete(index)}
                  color={isValideEmail(val) ? "primary" : "secondary"}
                  className={classes.chip}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ marginTop: "10px" }}>
            <Grid item xs={12} align="left">
              <TextField
                className={classes.textField}
                name="ss_email"
                placeholder={"Type email address and then press Enter"}
                label={
                  <div className="controllabelCreate">{"Add Recipients"}</div>
                }
                onChange={onChangeEmail}
                helperText="Note: Paste comma or semicolon separated email addresses and then press Enter"
                value={inputAddress}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
        <DialogActions>
          <Grid container xs={12} justify="flex-end">
            <Buttons
              Icon="Submit"
              toSave={true}
              fnc={onSendEmail}
              name="SEND"
            />
            <Buttons Icon="Submit" fnc={props.onClose} name="CANCEL" />
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EmailDialog;
