import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { REACT_APP_AUTH_API } from 'dotenv';
import defaultConnector from '../../redux/connectors/defaultConnector';
import { ASYNC_STORAGE, USER_TYPE, ACCESS_FOR } from '../../constants';
import GraphQLClient from '../../graphql/client';
import gql from 'graphql-tag';
import { Navigation } from '../../propTypes';
import SimpleBackdrop from '../../components/controls/Spinner'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import NotAuthorized from './NotAuthorized';
import NoUserProfile from './NoUserProfile';


const profileQuery = gql`
  query profile($accessToken: String!) {
    current_user(access_token: $accessToken) {
      sfids
      first_name
      last_name
      screens
      divisions
      branchIds
      teammate_sfids
      email
      sales_rep_email
      personas
      storageExist
    }
  }
`;
export default function Authenticate(WrappedComponent) {
  class connectedComp extends React.Component {

    state = {
      loading: true,
      userProfileExists: false,
      hasScreenPermisson: true,
      // result: {}
    };

    async componentDidMount() {
      await this.authorizeUser();
    }


    getAsyncStorageToken = () => {
      return (localStorage.getItem(ASYNC_STORAGE.TOKEN)) || '{}';
    }

    storeAsyncStorageToken = async (response, loginDate) => {
      //const { access_token, refresh_token, expires_in } = ;
      const access_token = response.access_token;
      const refresh_token = response.refresh_token;
      const mako_token = response.mako_token;
      const as1_token = response.as1_token;
      const expires_in = response.expires_in;
      const dips_token = response.dips_token;
      localStorage.setItem(
        ASYNC_STORAGE.TOKEN,
        JSON.stringify({
          access_token,
          refresh_token,
          mako_token,
          as1_token,
          expires_in,
          dips_token
        })
      );
      // loginDate type is object. Converting to string to store it in async storage.
      localStorage.setItem('Login Time', JSON.stringify(loginDate));
    };

    getAsyncStorageCurrentUser = async () =>
      (localStorage.getItem(ASYNC_STORAGE.CURRENT_USER)) || '{}';

    storeAsyncStorageCurrentUser = async response => {
      localStorage.setItem(
        ASYNC_STORAGE.CURRENT_USER,
        JSON.stringify(response)
      );
    };

    authorizeUser = async () => {
      try {
        let params = new URL(window.location.href).searchParams;
        let finalParam = {
          access_token: params.get("access_token"),
          refresh_token: params.get("refresh_token")
        }
        if (params.get('access_token')) {
          await this.storeAsyncStorageToken(finalParam, new Date())
        }
        const { access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
        if (!access_token) {
          await this.signIn();
        } else {
          await this.verifyUser(access_token);
        }
      } catch (error) {
        this.setState({
          loading: false,
          result: {
            type: 'error',
            ...error
          }
        });
      }
    };

    getQueryStringValue(key) {
      return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    hasScreenAccess = (screens) => {
      const {pathname} = window.location;
      switch(pathname){
          case "/ListSurgicalCase":
          case "/EditSurgicalCase":
          case "/NewSurgicalCase": 
          case "/ViewSurgicalCase":
              return screens.indexOf(ACCESS_FOR.CASES) > -1
          case "/":              
          case "/CaseCalendar":
          case "/CaseCardView":
              return screens.indexOf(ACCESS_FOR.CALENDAR) > -1
          case "/Notification":
              return true // screens.indexOf("Notification") > -1
          case "/PatientScans":
              return true
          case "/TreatmentPlanRequest":
              return true
          case "/SurgeonPreferences":
              return true
          case "/Support":
              return true
          default:
              return false                         
      }
    }

    signIn = async () => {
      
      // this.setState({ result: {}, loading: true });
      const redirect_uri = window.location.href;
      let canvas = false
      let REACT_APP_AUTH_API;
      canvas = this.getQueryStringValue("canvas");
      REACT_APP_AUTH_API = process.env.REACT_APP_AUTH_API;
     
      const options = {
        authUrl: `${REACT_APP_AUTH_API}?redirect_uri=${redirect_uri}`
      };
      if (Boolean(canvas)) {
        window.location.href = options.authUrl+`&popup=true`;
      } else {
        window.location.href = options.authUrl;
      }

      this.setState({ loading: false });
    };
    navigateToMain = async (current_user, screen_permissions) => {
      const { navigation, userActions } = this.props;
      // this.setState({ loading: false });
      let user = {
        ...current_user,
        screen_permissions
      };
      await userActions.setUser(user);
      //navigation.navigate('Main', user);
    };

    verifyUser = async accessToken => {
      const { refresh_token, expires_in } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
      const current_user = JSON.parse(await this.getAsyncStorageCurrentUser() || '{}');
      try {
        // if token is expired, renew silently
        if (!expires_in || Date.now().toString() >= expires_in) {
          await this.getRefreshToken(refresh_token);
        }

        // if user profile is not available, try to fetch
        if (!current_user || Object.keys(current_user).length === 0) {
          await this.props.userActions.setUser({});
          const query = profileQuery;
          let results;
          results = await GraphQLClient.query({
            query,
            //fetchPolicy: 'network-only',
            variables: {
              accessToken
            }
          });
          const { current_user } = results.data;
          let userProfileExists = current_user ? true : false;
          await this.storeAsyncStorageCurrentUser(current_user);
          await this.props.userActions.setUser(current_user);
          const hasScreenPermisson = this.hasScreenAccess(current_user.screens);
          this.setState({hasScreenPermisson, loading: false, userProfileExists})
        } else {              
          const { personas, screens } = current_user;
          const hasScreenPermisson = this.hasScreenAccess(screens);
          this.setState({hasScreenPermisson, loading: false, userProfileExists: true})
        }

      } catch (error) {
        if (error.message === 'Network error: Network request failed') {
          const {
            current_user
          } = JSON.parse(
            await this.getAsyncStorageCurrentUser()
          );
          const screen_permissions = current_user.screens;
          await this.props.userActions.setUser({
            ...current_user,
            screen_permissions
          });
        } else {
          try {
            // const { refresh_token } = JSON.parse(
            //   await this.getAsyncStorageToken()
            // );
            const {
              refresh_token
            } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
            if (!refresh_token) {
              throw new Error(
                `Your refresh token is expired or invalid. Please login again.`
              );
            }
            await this.getRefreshToken(refresh_token);
          } catch (error) {
            this.setState({
              loading: false,
              result: {
                type: 'error',
                ...error
              }
            });
          }
        }
      }
    };

    getRefreshToken = async refreshToken => {
      const mutation = gql`
          mutation refreshJwt($refreshToken: String) {
            refreshJwt(refresh_token: $refreshToken) {
              access_token
              refresh_token
              mako_token
              as1_token
              expires_in
              dips_token
            }
          }
        `;

      try {
        const results = await GraphQLClient.mutate({
          mutation,
          variables: {
            refreshToken
          }
        });
        if (!results.data.refreshJwt) {
          await this.reauthorizeUser();
        } else {
          await this.storeAsyncStorageToken(results.data.refreshJwt, new Date());
          const { access_token } = results.data.refreshJwt;
          let userResults = await GraphQLClient.query({
            query: profileQuery,
            //fetchPolicy: 'network-only',
            variables: {
              accessToken: access_token
            }
          });

          const { current_user } = userResults.data;
          const screen_permissions = current_user.screens;
          this.navigateToMain(current_user, screen_permissions);
        }
      } catch (error) {
        await this.reauthorizeUser();
      }
    };

    reauthorizeUser = async () => {
      const asyncStorageKeys = localStorage.getAllKeys();
      const removeKeys = asyncStorageKeys.map(key => {
        return localStorage.removeItem(key);
      });
      await Promise.all(removeKeys);
      await this.authorizeUser();
    };

    render() {
      const { access_token } = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN) || '{}')
      const {  loading, userProfileExists, hasScreenPermisson } = this.state;

      return (
        loading || !access_token ? <SimpleBackdrop open={true} /> 
        : !userProfileExists ? <NoUserProfile/> 
        : hasScreenPermisson ?
          <WrappedComponent
            componentName={WrappedComponent.name}
            {...this.props}
          /> 
        : <NotAuthorized/>
      )
    }
  }

  Authenticate.propTypes = {
    navigation: Navigation,
    userActions: PropTypes.objectOf(PropTypes.func)
  };

  Authenticate.defaultProps = {
    navigation: {
      navigate: () => { }
    },
    userActions: {
      setUser: () => { }
    }
  };
  return defaultConnector(connectedComp);
}

// export default defaultConnector(Authenticate);