import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '97%',
  },
}));

const Input = ({ onChange, placeholder, value, isSecure, id, onClick, disabled }) => (
  <input
    disabled={disabled}
    onkeypress="return false"
    style={{
      height: '50px', height: '38px',
      width: '98%',
      // borderBottom: '1px solid black',
      // border: '1px solid lightgrey'
      border: 'none',
      borderBottom: '1px solid lightgrey',
    }}
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    isSecure={isSecure}
    placeholder="mm/dd/yyyy, hh:mm"
    id={id}
    onClick={onClick}
  />
);

const NoClickInput = ({ onClick, ...props }) => <Input {...props} />;

export default function DateAndTimePickers(props) {
  // const [startDate, setStartDate] = useState();
  return (
    <div style={{ display: 'grid' }}>
      <span style={{
        marginTop: '9px',
        fontWeight: 600,
        fontSize: '11px'
      }}>{props.label}<span style={{ color: "red" }} >*</span></span>
      <DatePicker
        customInput={<Input disabled={props.disabled} />}
        disabled={props.disabled}
        selected={props.value}
        onChange={(event) => props.fnc(event)}
        showTimeSelect
        timeFormat="p"
        timeIntervals={15}
        // timeCaption="time"
        placeholder="MM/dd/yyyy, hh:mm aa"
        dateFormat="MM/dd/yyyy, hh:mm aa"
      />
    </div>

  );
};
//   const classes = useStyles();
// const name = <div className="controllabelCreate"  >{props.label}<span style={{ color: "red" }} >*</span></div>
//   return (
//     <FormControl className={classes.container}>
//       <TextField
//         id="datetime-local"
//         label={name}
//         type="datetime-local"
//         className={props.value?  classes.textField : classes.textField + ' datetimePlaceholder'} 
//         disabled={props.disabled}
//         margin="normal"
//         InputLabelProps={{
//           shrink: true,
//         }}
//         value={props.value}
//         onChange={(event) => props.fnc(event)}
//         inputProps={{
//           step: 900, // 15 min increments
//         }}
//       />
//     </FormControl>
//   );
// }
