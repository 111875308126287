import React, { useEffect , useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Buttons from '../../components/controls/Button'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import { getCatalogSets, getSubBranches } from '../../graphql/queries';
import { FormControlLabel, ListItemSecondaryAction, TextField } from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import { useDispatch } from 'react-redux';
import { ACTION_CREATORS as userCase } from '../../redux/reducers/cases';
import { API, SETS_PARTS_STATUS } from '../../constants';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setCaseProducts, updateSetCatalogCategory, setWebCaseProducts } from '../../graphql/mutations';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import KitsDialog from "./KitsDialog";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    flexGrow: 1,
    background:'#FAFAFA',
  },
  formControl: {
    margin: "25px 15px",
    fontWeight:"bold",
    fontSize:"14px"
  },
  dot: {
    height: "25px",
    width: "25px",
    padding: "10px 14px",
    borderRadius: "89%",
    backgroundColor: "#FFD271",
    fontSize: "16px",
    fontWeight: "700"
  },
  label:{
    margin: "5px 5px",
    fontWeight:"bold",
    fontSize:"13px"
  },
  selectEmpty: {
    marginLeft: 10,
    marginRight:10 ,
    fontSize:"12px",
    minWidth: 250
  },
  AddButton: {
    background:'#FFB500',
    boxShadow:'none',
    color:'#000',
    fontWeight:'bold',
    "&:hover":{
      background:'#FFD271',
      boxShadow:'none',
      color:'#000'
    },
    "&:focus":{
      background:'#FFB500!important',
      boxShadow:'none',
      color:'#000'
    },
  },

  DeleteButton: {
    background:'#000',
    boxShadow:'none',
    color:'#fff',
    fontWeight:'bold',
    "&:hover":{
      background:'#000',
      boxShadow:'none',
      color:'#fff'
    },
    "&:focus":{
      background:'#000!important',
      boxShadow:'none',
      color:'#fff'
    },
  },
  ResetBtn: {
    background: "#fff",
    color: "#000",
    boxShadow: "none",
    border: "1px solid #E0E0E0",
    fontWeight: "bold",

    "&:hover": {
      background: "#fff",
      boxShadow: "none",
      color: "#000",
    },
    "&:focus": {
      background: "#fff!important",
      boxShadow: "none",
      color: "#000",
    },
  },
  Custometable: {
    background: '#FFF',
    margin: '0',
    padding: '0'
  },
}));

  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 1;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 18 + ITEM_PADDING_TOP,
        width: 450,
      },
    },
  };
export default function Catalog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const GetCaseById_Set = (response) => dispatch(userCase.setCaseDetail(response));
  const [showForm, setshowForm] = React.useState(false);
  const [rows, setrows] = React.useState([]);
  const [rows2, setrows2] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState([]);
  const [isChecked2, setIsChecked2] = React.useState([]);
  const [catalog, setCatalog] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [productSystem, setProductSystem] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [selectedProduct, setselectedProduct] = React.useState([]);
  const [selectedProductId, setselectedProductId] = React.useState([]);
  const [selectedProductItem, setselectedProductItem] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [notification, setNotification] = React.useState({ severity: "", message: "" })
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [subBranchesOptions, setSubBranchesOptions] = React.useState([])
  const [subBranchesValue, setSubBranchesValue] = React.useState(props.caseDetails.subBranch)
  const [subBranchName, setSubBranchName] = React.useState(props.caseDetails.subBranchName)
  const [open, setOpen] = React.useState(false)
  const [selectedSet, setSelectedSet] = React.useState(null)
  const [catalogValue, setCatalogValue] = React.useState([]);
  const [productSystemValue, setProductSystemValue] = React.useState([]);
  const [selectedProductToDelete, setSelectedProductToDelete] = React.useState([]);
  const [selectedProcedureCategoryId, setSelectedProcedureCategoryId] = React.useState('');
  const [rows4, setrows4] = React.useState([]);  


  const getCaseById = () => {
    const { access_token } = JSON.parse(localStorage.getItem('PU:TOKEN') || '{}');
    let url = `${API.MASTER_DATA.GET_CASE_By_ID}`;
    if (props.caseDetails.externalId) {
      url = url + `?externalId=${props.caseDetails.externalId}`;
    }
    else {
      url = url + `?sfId=${props.caseDetails.sfId}`;
    }
    var config = {
      method: 'get',
      url: url,
      headers: { Authorization: access_token },
      data: {}
    }
    try {
      let response = axios(config)
        .then(res => {
          GetCaseById_Set(res.data)
          setrows(res.data.sets);
          let t1 = [];
          let t2 = res.data.sets;
          let t3 = [];
          let t4 = [];
          for (let i = 0; i < t2.length; i++) {
          let check = false;
          t2[i].category_name  =  t2[i].category_name === null ? '' : t2[i].category_name;
         
          for (let j = 0; j < t3.length; j++) {
             if (t2[i].category_name == t3[j]) {
              check = true;
              break;
             }
          }

          // t2.includes(t2[i].category);

          if (check == false) {
            t4.push(t2[i]);
            t3.push(t2[i].category_name);
            t1.push({
              "category" : t2[i].category_name,
              "requested_set_name" : [
              {
                "category__c":  t2[i].category__c,
                "external_id":  t2[i].external_id,
                "is_preference_product__c": t2[i].is_preference_product__c,
                "kit_no__c": t2[i].kit_no__c,
                "loaner__c": t2[i].loaner__c,
                "productSfid": t2[i].productSfid,
                "productsystemname__c": t2[i].productsystemname__c,
                "quantity__c": t2[i].quantity__c,
                "requested_set_name": t2[i].requested_set_name,
                "setType": t2[i].setType,
                "set_id": t2[i].set_id,
                "set_name": t2[i].set_name,
                "status__c": t2[i].status__c
              }
              ],
              "status__c" : t2[i].status__c
            })
          }
          else {
            let index = -1;

            for (let k = 0; k < t1.length; k++) {
              if (t1[k].category == t2[i].category_name) {
              index = k;
               break;
              }
           }
           t4.push(t2[i]);
           t1[index].requested_set_name.push({
            "category__c":  t2[i].category__c,
            "external_id":  t2[i].external_id,
            "is_preference_product__c": t2[i].is_preference_product__c,
            "kit_no__c": t2[i].kit_no__c,
            "loaner__c": t2[i].loaner__c,
            "productSfid": t2[i].productSfid,
            "productsystemname__c": t2[i].productsystemname__c,
            "quantity__c": t2[i].quantity__c,
            "requested_set_name": t2[i].requested_set_name,
            "setType": t2[i].setType,
            "set_id": t2[i].set_id,
            "set_name": t2[i].set_name,
            "status__c": t2[i].status__c
          })     
          }
          }
          t4.sort((a, b) => {
            let fa = a.category_name && a.category_name.toLowerCase(),
                fb = b.category_name && b.category_name.toLowerCase();
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
      
        t1.sort((a, b) => {
          let fa = a.category && a.category.toLowerCase(),
              fb = b.category && b.category.toLowerCase();
      
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
      });
          setrows2(t1);
          setrows4(t4);
          setIsChecked2(t4 && (t4.slice().fill(false)))
          setIsChecked(res.data.sets && (res.data.sets.slice().fill(false)))
          // setIsChecked2(res.data.sets && (res.data.sets.slice().fill(false)))
          setshowForm(true);
          setLoading(false);
        })
    }
    catch (ex) {
      console.log(`Error ${ex}`);
      // alert("We've had trouble getting the Case Id.");
      props.displaySnackBarMessage("error", "We've had trouble getting the Case Sets")
    }
  }

  const compare = (a, b) => {
    const c1 = a.catalog_category;
    const c2 = b.catalog_category;
    var comp = 0;
    if (c1 > c2)
      comp = 1;
    else if (c1 < c2)
      comp = -1;
    return comp;
  }

  // useEffect(() => {
  //   const c = props.caseDetails.branchSfId;
  //   getSubBranches(c)
  //   .then(res => {
  //     const uniqueChecker = [];
  //     const finalSubBranches = [];
  //     res.get_sub_branches.map(item => {
  //         let index = uniqueChecker.indexOf(item.branchID);
  //         if (index === -1) {
  //             item.label = item.branchName;
  //             item.id = item.branchID;
  //             finalSubBranches.push({ id: item.branchID, label: item.branchName});
  //             uniqueChecker.push(item.id);
  //         }
  //     })
  //     if(finalSubBranches.length === 1){
  //       let event={};
  //       event.target={value:finalSubBranches[0]}
  //       handleSubBranchChange(event)
  //     }
  //     setSubBranchesOptions(finalSubBranches);
  //   })
  // }, [])

  useEffect(() => {
    getCatalogSets(
      props.caseDetails.procedures[0].sfid,
      subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
      props.caseDetails.storageExist,
      props.caseDetails.userRep)
      .then(res => {
        setCatalogValue(res.get_catalog_in_sets);
        const sortedCatalogSets = res.get_catalog_in_sets.sort(compare)
        return sortedCatalogSets.map(item => {
          item.catalog_category = item.catalog_category;
          item.caseProducts = item.caseProducts;
          return item;
        })
      })
      .then(res => { 
        const sortedCatalogSet = res.sort(compare) 
        setCatalog(sortedCatalogSet) 
      })
      .then(res => {
        getCaseById()
      })
  }, [subBranchesValue])

  useEffect(() => {
    if(props.caseDetails){
      setIsDisabled(
        props.caseDetails.status === SETS_PARTS_STATUS.ASSIGNED ||
        props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED ||
        props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED_READY)
    }
  }, [props.caseDetails])

  const buildSetValues = (rows) => {
    const setvalues = [];
    rows.map(r => {
      const itm = {
        "id": r.set_id,
        "type": "loaner",
        "quantity": 1,
        "is_preference_product__c": true,
        "status__c": "Requested"
      }
      setvalues.push(itm);
    });

    return setvalues;
  }


  const handleAddRow1 = () => {
    if (rows.findIndex(item => item.set_name === selectedProduct) > -1) {
      props.displaySnackBarMessage("error", 'Selected product already exists. Please select different product');
      return
    }

    setLoading(true);
    setshowForm(true)
    const newRowitem = {
      set_id: selectedProductId,
      set_name: selectedProduct,
      status__c: "Requested"
    }
    setrows([...rows, newRowitem])
    isChecked.push(false);
    const setvalues = buildSetValues(rows);
    // setrows is not async, so add new item in sets with selected id 
    const newSetValueItem = {
      "catalog_sfid" : selectedProcedureCategoryId,
      "id": selectedProductId,
      "type": "loaner",
      "quantity": 1,
      "is_preference_product__c": true,
      "status__c": "Requested"
    }
    const newSetValueItem2 = {
      "id": selectedProductId,
      "type": "loaner",
      "quantity": 1,
      "is_preference_product__c": true,
      "status__c": "Requested",
      "procedure_category__c": selectedCategory
    }
    setvalues.push(newSetValueItem);
    // setvalues2.push(newSetValueItem);
    Save(setvalues);
  };


  const handleAddRow = () => {
    if (typeof(selectedProductItem) != 'object') {
      handleAddRow1();
      return;
    }
    if(rows.map(item =>  {return selectedProduct && selectedProduct.includes(item.set_name)}).includes(true)){
      props.displaySnackBarMessage("error", 'Selected product already exists. Please select different product');
      return
    }  // validation for duplicate entries
    setLoading(true);
    setshowForm(true);
    const newRowitem = [];
    selectedProductItem.map(ite => 
      {
        const it = ite.toString().split(',')
        const item = {
          set_id: it[1].trim(),
          set_name: it[0].trim(),
          status__c: "Requested"
        }
        newRowitem.push(item)
        isChecked.push(false);
      }
      )
    setrows([...rows, ...newRowitem])
    const setvalues = buildSetValues(rows);
    // setrows is not async, so add new item in sets with selected id 
    selectedProductId.map(id => { 
        const newSetValueItem = {
          "catalog_sfid" : selectedProcedureCategoryId,
          "id": id,
          "type": "loaner",
          "quantity": 1,
          "is_preference_product__c": true,
          "status__c": "Requested"
        }
        setvalues.push(newSetValueItem);
    });
    Save(setvalues);
  };

  const Save = async (setvalues) => {
    setSelectedProductToDelete([]);
    const payload = {
      "case__c": props.caseDetails.sfId,
      "externalId": props.caseDetails.externalId,
      "sets": setvalues,
      "parts": props.caseDetails.parts,
      "is_hospital_owned": props.caseDetails.is_hospital_owned,
      "division": props.caseDetails.division,
      "is_submit": true,
      "case_current_status": "Requested",
      "branch": subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
    }
    const payload2 = {
      "externalId": props.caseDetails.externalId,
      "sets": "setvalues2",
    }
    try {
      // return setCaseProducts(payload)
      return setWebCaseProducts(payload)
        .then(response => {
          // let setsCount = response.data.setCaseProducts.sets.length;
          let setsCount = response.data.setWebCaseProducts.sets.length;
          if(setsCount != setvalues.length){
            let retryCount = 0;
            let intervalFunc = setInterval(() => {
              retryCount++
             if (retryCount<2){
              // if (typeof(selectedProductItem) != 'object') {
              //   settingSetId2 ();
              // }
              // else {
              // settingSetId ();
              // }
               getCaseById()
               setLoading(false);
               props.displaySnackBarMessage("success", 'Sets/Parts Details are saved successfully');
             }
             else{
              clearInterval(intervalFunc);
             }
           }, 7000)
          }
          else {
            // if (typeof(selectedProductItem) != 'object') {
            //   settingSetId2 ();
            // }
            // else {
            // settingSetId ();
            // }
            getCaseById()
            setLoading(false);
            props.displaySnackBarMessage("success", 'Sets/Parts Details are saved successfully');
          }
        })
    } catch (ex) {
      console.log(`Error ${ex}`);
    }
  }

  function settingSetId () {
    try {
      let newSetValues = [];
      let existingSets = props.caseDetails.sets;
      for (let i = 0; i < existingSets.length; i++) {
        newSetValues.push({
          "sfid": existingSets[i].set_id,
          "category":existingSets[i].category_sfid
    })
      }
      for (let i = 0; i < catalogValue.length; i++) {
        if (catalogValue[i].catalog_category ===  selectedCategory) {
        for (let m = 0; m < selectedProductItem.length; m++) {
        for (let k = 0; k < catalogValue[i].caseProducts.length; k++ ) {
          if (catalogValue[i].caseProducts[k].includes(selectedProductItem[m])) {
        let z = catalogValue[i].caseProducts[k];
        let e1 = z.split(", ");
            newSetValues.push({
              "sfid": e1[e1.length - 1],
              "category":catalogValue[i].catalog_sfid
        })
        break;
          }
        }
      }
        }
      }
      const payload2 = {
        "external_id": props.caseDetails.externalId,
        "sets": newSetValues,
      }
      return updateSetCatalogCategory(props.caseDetails.externalId, newSetValues)
        .then(response => {
            getCaseById()
               setLoading(false);
        })
    } catch (ex) {
        getCaseById()
               setLoading(false);
      console.log(`Error ${ex}`);
    }
  }

  function settingSetId2 () {
    try {
      let newSetValues = [];
      let existingSets = props.caseDetails.sets;
      for (let i = 0; i < existingSets.length; i++) {
        newSetValues.push({
          "sfid": existingSets[i].set_id,
          "category":existingSets[i].category_sfid
    })
      }
      for (let i = 0; i < catalogValue.length; i++) {
      if (catalogValue[i].catalog_category ===  selectedCategory) { 
        for (let k = 0; k < catalogValue[i].caseProducts.length; k++ ) {
          if (catalogValue[i].caseProducts[k].includes(selectedProductItem)) {
        let z = catalogValue[i].caseProducts[k];
        let e1 = z.split(", ");
            newSetValues.push({
              "sfid": e1[e1.length - 1],
              "category":catalogValue[i].catalog_sfid
        })
        break;
          }
        }
      
        }
      }
      const payload2 = {
        "external_id": props.caseDetails.externalId,
        "sets": newSetValues,
      }
      return updateSetCatalogCategory(props.caseDetails.externalId, newSetValues)
        .then(response => {
            getCaseById()
               setLoading(false);
        })
    } catch (ex) {
        getCaseById()
               setLoading(false);
      console.log(`Error ${ex}`);
    }
  }

  let [selectedId, setData] = useState('-1');
  const handleCheckBoxClicks = (index, item) => {

    setData(index == selectedId ? -1 :  index);

  //  checks(item);
  }

  const handleCheckBoxClick = (index, idx, item) => {
    // setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
    let e1 = [];
    if (selectedProductToDelete.includes(item)) {
      let index = selectedProductToDelete.indexOf(item);
    selectedProductToDelete.splice(index, 1);
    e1 = selectedProductToDelete;
    }
  else {
    for (let i = 0; i < selectedProductToDelete.length; i++) {
      e1.push(selectedProductToDelete[i]);
    }
    e1.push(item);
  }
    setSelectedProductToDelete(e1);
    let countIndex = 0;
    for (let i = 0; i < idx; i++) {
      countIndex = countIndex + rows2[i].requested_set_name.length;
    }
    countIndex = countIndex + index;
    setIsChecked(isChecked.map((v, i) => (i ===   index) ? !v : v));
    setIsChecked2(isChecked2.map((v, i) => (i === countIndex) ? !v : v));
  }

  const handleRemoveRow = () => {
    
    setDialogOpen(false);
    setLoading(true);
    setData(-1);
    const activeRows = [];
    isChecked2.map((r, idx) => {
      if (r === false) {
        activeRows.push(rows4[idx])
      }
    })
    // setrows2(activeRows);
    setIsChecked(isChecked.filter(i => i === false));
    setIsChecked2(isChecked2.filter(i => i === false));
    const setvalues = buildSetValues(activeRows)
    // Save(setvalues);
    Save1(setvalues);
  };

  const Save1 = async (setvalues) => {
    setSelectedProductToDelete([]);
    const payload = {
      "case__c": props.caseDetails.sfId,
      "externalId": props.caseDetails.externalId,
      "sets": setvalues,
      "parts": props.caseDetails.parts,
      "is_hospital_owned": props.caseDetails.is_hospital_owned,
      "division": props.caseDetails.division,
      "is_submit": true,
      "case_current_status": "Requested",
      "branch": subBranchesValue ? subBranchesValue : props.caseDetails.branchSfId,
    }
    try {
      // return setCaseProducts(payload)
      return setWebCaseProducts(payload)
        .then(response => {
          // let setsCount = response.data.setCaseProducts.sets.length;
          let setsCount = response.data.setWebCaseProducts.sets.length;
          if(setsCount != setvalues.length){
            let retryCount = 0;
            let intervalFunc = setInterval(() => {
              retryCount++
             if (retryCount<2){
               getCaseById()
              
               props.displaySnackBarMessage("success", 'Sets/Parts Details are saved successfully');
             }
             else{
              clearInterval(intervalFunc);
             }
           }, 7000)
          }
          else {
            getCaseById()
            props.displaySnackBarMessage("success", 'Sets/Parts Details are saved successfully');
          }
        })
    } catch (ex) {
      console.log(`Error ${ex}`);
    }
  }

  const handleChange = (event) => {
    const item = event.target.value;
    const id = []
    const val = []
    item.map(ite => 
    {
      const selectedValue = ite.toString().split(',');
      const set_name = ite.slice(0,-20).trim();
      id.push(!id.includes(selectedValue[selectedValue.length - 1]) && selectedValue[selectedValue.length - 1].trim());
      val.push(!val.includes(set_name.toString()) && set_name.toString());
    })
    setselectedProductItem(event.target.value);
    setselectedProduct(val);
    setselectedProductId(id);
  }

  const handleCategoryChange = (event) => {
    for (let i = 0; i < catalog.length; i++) {
      if (catalog[i].catalog_category == event.target.value) {
       setSelectedProcedureCategoryId(catalog[i].catalog_sfid);
      }
    }
    setSelectedCategory(event.target.value);
    setselectedProduct([]);
    setselectedProductItem([]);
    const product = event.target.value && catalog && catalog.filter(c => c.catalog_category === event.target.value)
    // handle duplicate product system
    const caseProducts = product && product[0].caseProducts;
    const uniqProductSys = caseProducts && caseProducts.filter((value, index) => caseProducts.indexOf(value) === index);
    setProductSystem(uniqProductSys ? uniqProductSys.sort((a,b) => a > b ? 1 :  -1) : []);
  }

  const handleReset = () => {
    setselectedProductItem([]);
    setselectedProduct([]);
    setselectedProductId([]);
    setSelectedCategory('');
    setProductSystem([]);
  }

  const handleSubBranchChange = async (event) => {
    const val = event.target.value.id ? event.target.value.id : event.target.value;
    setSubBranchesValue(val);
    if(!val){
      handleReset();
    }
  };

  const showKits = (selSet) => {
    setSelectedSet(selSet)
    setOpen(true);
  }
  

  return (
    loading ? <CircularProgress style={{ marginLeft: "50%", color: "gold" }} disableShrink /> :
      <div>
        <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete these Sets/Parts ?
          </DialogContentText>
          {/* <DialogContentText
            style={{ marginLeft: "30%" }}
            id="alert-dialog-description"
          >
            Sets/Parts ?
          </DialogContentText> */}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Buttons Icon="Submit" toSave={true} fnc={() => {handleRemoveRow()}} name="YES" />
          <Buttons Icon="Submit" fnc={() => setDialogOpen(false)} name="NO" />
          {/* <Button
            title="Yes"
            onClick={() => {
              handleRemoveRow();
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            title="No"
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            No
          </Button> */}
        </DialogActions>
      </Dialog>
        <Grid container spacing={0} >
          <Grid item xs={12} sm= {12} container justify='canter'>
            <Grid item xs={12} sm={4} container alignItems="baseline" className={classes.root}>
                  <Typography className={classes.formControl}>
                    Sub Branch/Agency
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    placeholder={"Sub Branch/Agency"}
                    disabled
                    value={subBranchName}
                  />
                {/* <Typography className={classes.label}>
                    Sub Branch/Agency
                  </Typography>
                  <FormControl className={classes.selectEmpty}>
                    <NativeSelect
                      labelId="dropdown-filled-label"
                      // disabled={isDisabled || rows.length > 0}
                      disabled={true}
                      className={subBranchesValue ? null : 'dropdownPlaceholder'}
                      value={subBranchesValue}
                      name="subBranch"
                      onChange={(e) => handleSubBranchChange(e)}
                      inputProps={{ "aria-label": "subBranch" }}
                    >
                      <option value=""> Please Select </option>
                      {subBranchesOptions.length > 0 && subBranchesOptions.map(item =>
                        <option value={item.id}>{item.label}</option>
                      )}
                    </NativeSelect>
                </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="baseline" justify='flex-end' className={classes.root} >
            <Typography className={classes.label}>Procedure Category </Typography>
                <FormControl className={classes.selectEmpty}>
                  <NativeSelect
                    labelId="dropdown-filled-label"
                    disabled={isDisabled || !subBranchesValue}
                    className={selectedCategory ? null : 'dropdownPlaceholder'}
                    // className={classes.selectEmpty}
                    value={selectedCategory}
                    name="age"
                    onChange={(e) => handleCategoryChange(e)}
                    inputProps={{ "aria-label": "age" }}
                  >
                    <option value="" selected>{"Procedure Category"}</option>
                    {catalog && catalog.map(option =>
                      <option value={option.catalog_category}>{option.catalog_category}</option>
                    )}
                  </NativeSelect>
                </FormControl>
                </Grid>

            <Grid item xs={12} sm={4} container  justify='flex-end' className={classes.root} >
              <Typography className={classes.label}>Product System</Typography>
              <FormControl className={classes.selectEmpty} style={{maxWidth: "250px"}}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedProductItem}
                    onChange={(e) => handleChange(e)}
                    //input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {productSystem && productSystem.map(item => {
                    const nameAndIdArray = item && item.split(',');
                    nameAndIdArray.pop();
                    const val = nameAndIdArray && nameAndIdArray.toString().trim();
                    return  <MenuItem key={val} value={item}>
                              <Checkbox checked={selectedProductItem.indexOf(item) > -1} />
                              <ListItemText primary={val} />
                            </MenuItem>
                      }
                    )}
                  </Select>
              </FormControl>
              {/* <Button size="medium" variant="contained" disabled={isDisabled || selectedProduct === ''} className={classes.AddButton} onClick={handleAddRow}>ADD</Button>
              <Button size="medium" variant="contained" disabled={isDisabled} className={classes.ResetBtn} onClick={handleReset}>RESET</Button> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container className={classes.root} >
            <Grid container className={classes.root} justify="flex-end" style={{ background: '#fff', margin: '0' }}>
              {/* <Button size="small" variant="contained" disabled={isDisabled || rows.length === 0 || !isChecked.find(i => i === true)} className={classes.DeleteButton} onClick={() => setDialogOpen(true)}>DELETE</Button> */}
              <Button size="medium" variant="contained" disabled={isDisabled || selectedProduct.length === 0} className={classes.AddButton} onClick={handleAddRow}>ADD</Button>
              <Button size="small" variant="contained" disabled={isDisabled || rows.length === 0 || !isChecked2.find(i => i === true)} className={classes.DeleteButton} onClick={() => setDialogOpen(true)}>DELETE</Button>
              <Button size="medium" variant="contained" disabled={isDisabled} className={classes.ResetBtn} onClick={handleReset}>RESET</Button>
              {
                (showForm && rows2.length !== 0) &&
                <>
                  <table className="addsetsControl" id="tab_logic" >
                    <thead>
                      <tr>
                        <th className="text-center">  </th>
                        <th className="text-center"> Product System </th>
                        <th className="text-center"> </th>
                        <th className="text-center"> Set Name </th>
                        <th className="text-center"> Status </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
{rows2.map((item, idx) => {
                        return  ( 
                          <>
                        <tr id="addr0" key={idx}>
                          {/* <td style={{width:"6%"}}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={idx}
                                  disabled={isDisabled || item.status__c == SETS_PARTS_STATUS.ASSIGNED || 
                                    item.status__c == SETS_PARTS_STATUS.SHIPPED || 
                                    item.status__c == SETS_PARTS_STATUS.SHIPPED_READY }
                                  color="default"
                                  checked={isChecked[idx]}
                                  onChange={() => handleCheckBoxClick(idx)}
                                  name={rows2.name}
                                  value="left"
                                  inputProps={{ 'aria-label': 'checkbox with default color' }} />
                              }
                            />
                          </td> */}

                          {idx == selectedId ? 
                         <td style={{fontSize: '25px'}}   onClick={() => handleCheckBoxClicks(idx, item)}> -</td> : <td style={{fontSize: '25px'}}  onClick={() => handleCheckBoxClicks(idx, item)}> +</td>
                        
                        }

                         
                          <td style={{width:"30%"}}>
                            <Typography>
                              {item.category}
                         
                             </Typography>
                             
                          </td>

                          <td style={{width:"15%"}}>
                            <Typography>
                            <span className={classes.dot}> {item.requested_set_name.length} </span>
                             </Typography>
                             
                          </td>
                        
                          <td style={{width:"30%"}}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography></Typography>
                          </td>
                          {
                            props.caseDetails.status === SETS_PARTS_STATUS.SHIPPED && item.status__c === SETS_PARTS_STATUS.SHIPPED &&
                            <td>
                              <Tooltip title="Show Kit BOM's" placement='top'>
                                <ChevronRightOutlinedIcon size="large" onClick={() =>  showKits(item)} />
                              </Tooltip>
                            </td>
                          }
                        </tr>

{idx == selectedId ? 
  <>
{item.requested_set_name.map((item2, idx2) => {
  return  ( 
<tr>
<td style={{width:"6%"}}>
      <FormControlLabel
        control={
          <Checkbox
            key={idx + idx2}
            disabled={isDisabled || item.status__c == SETS_PARTS_STATUS.ASSIGNED || 
              item2.status__c == SETS_PARTS_STATUS.SHIPPED || 
              item2.status__c == SETS_PARTS_STATUS.SHIPPED_READY }
            color="default"
            checked={selectedProductToDelete.includes(item2.set_name)}
            onChange={() => handleCheckBoxClick(idx2, idx, item2.set_name)}
            name={rows.name}
            value="left"
            inputProps={{ 'aria-label': 'checkbox with default color' }} />
        }
      />
    </td>
    <td > { item2.set_name} </td> 
    <td>  </td>
    <td>  </td>
    <td> {item2.status__c} </td>

</tr>
)})}
</> : ''}
</>
                        )})}
                    </tbody>
                  </table>
                </>
              }
            </Grid>
          </Grid>
        </Grid>
        <KitsDialog
          dialogOpen={open}
          selectedSet={selectedSet}
          setDialogOpen={() => setOpen(false)}
          displaySnackBarMessage={props.displaySnackBarMessage}
          caseDetails={props.caseDetails}
        />
      </div>
  );
}