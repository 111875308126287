import React, { useEffect, useState } from "react";
import { ASYNC_STORAGE } from "../../constants";
import { checkForInHousePersona } from "../../utils/tpr";
import NewSurgicalCase from "../SurgicalCase/NewSurgicalCase";
import NotAuthorized from "../../components/authenticate/NotAuthorized";

export default function EditSurgicalCase() {
  const [isInHouseUser, setIsInHouseUser] = useState(false);

  useEffect(() => {
    //Protect EditSurgicalCase route from IHS user
    const current_user = JSON.parse(
      localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
    );
    const isInHouseUser = checkForInHousePersona(current_user.personas);
    setIsInHouseUser(isInHouseUser);
  }, []);

  return (
    <div>
      {isInHouseUser ? <NotAuthorized /> : <NewSurgicalCase isEdit={true} />}
    </div>
  );
}
