import { deletePrefrences } from '../../graphql/mutations'
import { getSurgeonPreferences } from './getSurgeonPreferences'
//ActionTypes

const LOAD = 'deleteSurgeonPreferences/LOAD'
const LOAD_SUCCESS = 'deleteSurgeonPreferences/LOAD_SUCCESS'
const LOAD_FAILURE = 'deleteSurgeonPreferences/LOAD_FAILURE'
const RESET_DELETE_SURGEON_PREFERENCES = 'deleteSurgeonPreferences/RESET_DELETE_SURGEON_PREFERENCES'

const initialState = {
    loaded: false,
    loading: false,
    deletedSurgeonList: [],
    deleteSurgeonError: {}
}

export default function deleteSurgeonPref(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                deletedSurgeonList:action.payload,
                deleteSurgeonError:{}
            }
        case LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                loaded: false,
                deletedSurgeonList: {},
                deleteSurgeonError: action.payload
            }
        case RESET_DELETE_SURGEON_PREFERENCES:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export function deleteSurgeonPreferences(branchId, recordToDelete) {
    return dispatch => {
        dispatch({
            type: LOAD
        })
        if(recordToDelete.external_id__c === '' || recordToDelete.external_id__c === null){
            delete recordToDelete.external_id__c
        } else if(recordToDelete.sfid === '' || recordToDelete.sfid === null){
            delete recordToDelete.sfid
        }
        deletePrefrences(recordToDelete)
            .then(response => {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: response.data.deletePrefrences
                })
                dispatch(getSurgeonPreferences(branchId))
            })
            .catch(error => {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: error
                })
            })
    }
}

export function resetDeleteSurgeonPreferences() {
    return dispatch => {
        dispatch({
            type: RESET_DELETE_SURGEON_PREFERENCES
        })
    }
}