import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { Chip, FormControl, makeStyles } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectCheckBoxDropdown(props) {
  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "97%",
    },
  }));
  const classes = useStyles();
  const valueSetter = (value, options) => {
    if (value.length == 0) {
      return value;
    } else if (value.length > 0 && value[0].id) {
      return value;
    } else if (options.length > 0 && value.length > 0) {
      let values = [];
      options.map((option) => {
        if (value.indexOf(option.id) > -1) {
          values.push(option);
        }
      });
      props.fnc(values);
      return values;
    } else {
      return [];
    }
  };
  const name = (
    <div className="controllabelCreate">
      {props.label}
      {!props.isAstrikReq && <span style={{ color: "red" }}>*</span>}
    </div>
  );

  const MyChip = (props) => {
    const customWidth = props.width ? { width: props.width } : null;
    return (
      <Chip variant="outlined" size="small" style={customWidth} {...props} />
    );
  };

  return (
    <Autocomplete
      multiple
      disabled={props.disabled}
      limitTags={props.limitTags ? props.limitTags : 4}
      id="checkboxes-tags-demo"
      options={props.options}
      value={
        props.value.filter((item) => item.id).length > 0
          ? props.value
          : valueSetter(props.value, props.options)
      }
      onChange={(e, selctedOptions) => props.fnc(e, selctedOptions)}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) =>
          !option.members ? (
            <MyChip
              {...getTagProps({ index })}
              label={option.title}
              width={props.chipWidth}
            />
          ) : null
        );
      }}
      renderOption={(option, { selected }) =>
      props.selectedTeams && option.members ? (
          <React.Fragment>
            <Checkbox
              color="primary"
              name={option.title}
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                props.selectedTeams.find((item) => item.id === option.id)
                  ? true
                  : false
              }
            />
            {option.title}
            <GroupAddIcon style={{ margin: "0px 8px" }} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </React.Fragment>
        )
      }
      renderInput={(params) => (
        <FormControl className={classes.formControl}>
          <TextField
            {...params}
            style={{ overflow: "hidden" }}
            label={name}
            placeholder={props.value.length === 0 ? "Please Select" : ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      )}
    />
  );
}
